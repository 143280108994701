import { TAlertsState } from './types'

export default {
  loading: false,
  polling: null,
  alerts: {
    alerts: [],
    autoRefresh: false,
    lastTime: '',
    more: false,
    page: 1,
    pageSize: 1000,
    pages: 1,
    severityCounts: {},
    status: '',
    statusCounts: {},
    total: 0
  },
  filters: []
} as TAlertsState
