import { TProject, TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { TProjectState } from './types'

export default {
  areProjectsLoading: (state: TProjectState): boolean => state.loadingProjects,
  getProjectsWithContext: (state: TProjectState): TProjectWithContext[] => state.projects,
  getProjects: (state: TProjectState): TProject[] => state.projects.filter(
    (project: TProjectWithContext) => !!project.project
  ).sort((project: TProjectWithContext, compareProject: TProjectWithContext) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return project!.project!.name.localeCompare(compareProject!.project!.name)
  }).map(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (projectWC: TProjectWithContext) => projectWC.project!
  ),
  getProjectsName: (state: TProjectState) => (project_id: number): string => {
    const project: TProjectWithContext|undefined = state.projects.find((project: TProjectWithContext) =>
      project.project &&
      project.project.name &&
      project.project.id === project_id
    ) as TProjectWithContext

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return project ? project.project!.name : ''
  },
  getProjectComponentsOfComponent: (state: TProjectState) => (componentId: number): ComponentInProjectWithContext[] => {
    return state.projectComponents[componentId] || []
  },
  getProjectIds: (state: TProjectState): number[] => {
    return state.projects.map((project: TProjectWithContext) => project.project?.id || null)
      .filter((project_id: number|null) => project_id !== null) as number[]
  }
} as GetterTree<TProjectState, TRootState>
