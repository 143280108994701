import { TEnergyManagementState } from './types'

export default {
  meters: [],
  timeseries: [],
  timeseriesOfLastYear: null,
  loadingMeters: true,
  loadingMetersLatestTimeseries: true,
  showConsumption: false,
  loadingPdfReport: false,
  pdfReport: null,
  pdfReportId: null
} as TEnergyManagementState
