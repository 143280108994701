import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import store from '@/store'
import { TRootState } from '../types'
import { TProjectState, TSystemFaultsData } from './types'
import { TComponentAttribute, TComponentInProjectWithContextResponse, TPostComponentAttributeResponse, TProjectAvatarDeleteResponse, TProjectAvatarUplaodResponse, TProjectUpdateResponse, TProjectWithContext, TTimeseriesShort } from '@/services/aedifion/resources/project/responseTypes'
import get from 'lodash.get'
import { IRepository } from '@/utils/types/repository'
import mapboxApiRepository from '@/services/mapbox'
import aedifionApiRepository from '@/services/aedifion'
import weatherapiRepository from '@/services/weatherapi'
import i18n from '@/plugins/i18n'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import { getLastValue } from '@/utils/helpers/timeseries'
import { getSystemsWithDatapoints } from '@/utils/helpers/faults'
import { faultSystems } from '@/utils/constants'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'
const Tilequeries: IRepository = mapboxApiRepository.get('tilequeries')
const Datapoint: IRepository = aedifionApiRepository.get('datapoints')
const Project: IRepository = aedifionApiRepository.get('projects')
const CurrentWeather: IRepository = weatherapiRepository.get('current')

export default {
  deleteAvatarImage: async ({ commit, rootState, getters }) => {
    const token = rootState.auth.access_token
    const projectID: number|null = getters.getProjectID

    if (projectID === null) {
      Vue.notify({
        text: i18n.t('project.settings.requests.delete_avatar.no_project_selected') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return false
    }

    commit('SET_PROJECT_AVATAR_LOADING', true)
    try {
      const projectAvatarDeleteResponse: AxiosResponse<TProjectAvatarDeleteResponse> = await Project.deleteAvatar({
        token,
        id: projectID
      })
      commit('REMOVE_PROJECT_AVATAR')
      Vue.notify({
        text: i18n.t('project.settings.requests.delete_avatar.removed_successfully') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
    } catch (error) {
      Vue.notify({
        text: i18n.t('project.settings.requests.delete_avatar.failed') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_PROJECT_AVATAR_LOADING', false)
    }
  },
  updateDetails: async ({ commit, rootState, getters }, { name, description, latitude, longitude }: {
    name?: string;
    description?: string;
    latitude?: number;
    longitude?: number;
  }) => {
    const token = rootState.auth.access_token
    const projectID: number|null = getters.getProjectID

    if (projectID === null) {
      Vue.notify({
        text: i18n.t('project.settings.requests.update_project_details.no_project_selected') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return false
    }

    commit('SET_PROJECT_UPDATE_LOADING', true)
    try {
      const projectUpdateResponse: AxiosResponse<TProjectUpdateResponse> = await Project.updateDetails({
        token,
        id: projectID,
        body: {
          name,
          description,
          latitude,
          longitude
        }
      })

      commit('SET_PROJECT_UPDATE_DETAILS', {
        name: projectUpdateResponse.data.resource.name,
        description: projectUpdateResponse.data.resource.description,
        latitude: projectUpdateResponse.data.resource.latitude,
        longitude: projectUpdateResponse.data.resource.longitude
      })
      commit('SET_PROJECT_UPDATE_LOADING', false)
      Vue.notify({
        text: i18n.t('project.settings.requests.update_project_details.details_updated_successfully') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
      return {
        name: projectUpdateResponse.data.resource.name,
        description: projectUpdateResponse.data.resource.description,
        latitude: projectUpdateResponse.data.resource.latitude,
        longitude: projectUpdateResponse.data.resource.longitude
      }
    } catch (error) {
      Vue.notify({
        text: get(error, 'response.data.error', i18n.t('project.settings.requests.update_project_details.failed_update_details')) as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      commit('SET_PROJECT_UPDATE_LOADING', false)
      return false
    }
  },
  uploadAvatarImage: async ({ commit, rootState, getters }, formData: FormData) => {
    const token = rootState.auth.access_token
    const projectID: number|null = getters.getProjectID

    if (projectID === null) {
      Vue.notify({
        text: i18n.t('project.settings.requests.update_avatar.no_project_selcted') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return false
    }

    commit('SET_PROJECT_AVATAR_LOADING', true)
    try {
      const projectAvatarUploadResponse: AxiosResponse<TProjectAvatarUplaodResponse> = await Project.uploadAvatar({
        token,
        id: projectID,
        body: formData
      })
      commit('SET_PROJECT_AVATAR', projectAvatarUploadResponse.data.resource.avatar_url)
      Vue.notify({
        text: i18n.t('project.settings.requests.update_avatar.updated_avatar_successfully') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
    } catch (error) {
      Vue.notify({
        text: get(error, 'response.data.error', i18n.t('project.settings.requests.update_avatar.failed_update_avatar')) as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_PROJECT_AVATAR_LOADING', false)
    }
  },
  updateAttribute: async ({ commit, rootState, getters, state }, payload: {
    value: string;
    alphanumeric_id: string;
  }) => {
    const token = rootState.auth.access_token
    const projectID: number|null = getters.getProjectID
    const digitalTwinComponentID: number|null = get(state, 'digitalTwin.component.id', null)

    if (digitalTwinComponentID === null) {
      Vue.notify({
        text: i18n.t('project.settings.requests.update_attribute.no_digital_twin_found') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const digitalTwinAttributes: TComponentAttribute[] = get(state, 'digitalTwin.attributes', [])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const componentinproject_id: number|null = get(state, 'digitalTwin.id', null)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const oldValue: string|null = getAttrbiuteValueByAlphanumericId(digitalTwinAttributes, payload.alphanumeric_id)

    if (projectID === null) {
      Vue.notify({
        text: i18n.t('project.settings.requests.update_attribute.no_project_selected') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return false
    }

    commit('SET_PROJECT_ATTIRBUTE_LOADING', true)
    try {
      const { value } = payload
      if (oldValue === null && value !== null) {
        const createOccupantsResponse: AxiosResponse<TPostComponentAttributeResponse> = await Project.addComponentAttribute({
          token,
          id: projectID,
          params: {
            componentinproject_id,
            project_id: projectID
          },
          body: {
            key: payload.alphanumeric_id,
            value
          }
        })

        commit('SET_PROJECTS_COMPONENTS_ATTRIBUTE_VALUE', createOccupantsResponse.data.resource)
        Vue.notify({
          text: i18n.t('project.settings.requests.update_attribute.added_attribute_successfully') as string,
          group: 'requests',
          duration: 6000,
          type: 'success'
        })
      } else if (oldValue !== null && oldValue !== value && value !== null) {
        const updateOccupantsResponse: AxiosResponse<TPostComponentAttributeResponse> = await Project.updateComponentAttribute({
          token,
          id: projectID,
          params: {
            componentinproject_id,
            key: payload.alphanumeric_id,
            value
          }
        })

        commit('SET_PROJECTS_COMPONENTS_ATTRIBUTE_VALUE', updateOccupantsResponse.data.resource)
        Vue.notify({
          text: i18n.t('project.settings.requests.update_attribute.updated_attribute_successfully') as string,
          group: 'requests',
          duration: 6000,
          type: 'success'
        })
      } else if (oldValue !== null && value === null) {
        const deleteOccupantsResponse: AxiosResponse<TPostComponentAttributeResponse> = await Project.deleteComponentAttribute({
          token,
          id: projectID,
          params: {
            componentinproject_id,
            key: payload.alphanumeric_id
          }
        })

        commit('DELETE_PROJECTS_COMPONENTS_ATTRIBUTE_VALUE', deleteOccupantsResponse.data.resource)
        Vue.notify({
          text: i18n.t('project.settings.requests.update_attribute.deleted_attribute_successfully') as string,
          group: 'requests',
          duration: 6000,
          type: 'success'
        })
      }
    } catch (error) {
      Vue.notify({
        text: get(error, 'response.data.error', i18n.t('project.settings.requests.update_attribute.failed_update_attribute')) as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_PROJECT_ATTIRBUTE_LOADING', false)
    }
  },
  selectProject: async ({ commit, rootState, state }, project_id: number): Promise<boolean|Error> => new Promise((resolve, reject) => {
    if (rootState.projects.projects && rootState.projects.loadingProjects === false) {
      const foundProject: TProjectWithContext|undefined = rootState.projects.projects.find(
        (project: TProjectWithContext) => get(project, 'project.id', null) === project_id
      )
      if (foundProject) {
        commit('SELECT_PROJECT', foundProject)
        return resolve(true)
      } else {
        return reject(new Error('Project not found'))
      }
    } else {
      state.unsubscribeProjectsSubscription = store.subscribe((mutation) => {
        if (mutation.type === 'projects/SET_PROJECTS') {
          if (mutation.payload.projects.length > 0) {
            const foundProject: TProjectWithContext|undefined = rootState.projects.projects.find(
              (project: TProjectWithContext) => get(project, 'project.id', null) === project_id
            )
            if (foundProject) {
              commit('SELECT_PROJECT', foundProject)
              return resolve(true)
            } else {
              return reject(new Error('Project not found'))
            }
          }
        }
        if (mutation.type === 'projects/SET_PROJECTS_LOADING' && mutation.payload === false && rootState.projects.projects.length < 1) {
          return reject(new Error('Project not found'))
        }
      })
    }
  }),
  /**
   * Select the digital twin to use for the current page of the application
   * If the digital twin does not exist yet: try to fetch it
   * If it exists: Select it
   */
  // eslint-disable-next-line no-async-promise-executor
  selectDigitalTwin: async ({ commit, dispatch, state, rootState }): Promise<boolean|Error> => new Promise(async (resolve, reject) => {
    // is it loading all of the digital twins currently?
    // YES: wait for them to resolve and try to find the right one
    // this case can not happen because one can only select a project if its kpi tables finshed loading

    // NO: proceed

    if (state.digitalTwin && state.digitalTwin.project_id === state.projectId) {
      return resolve(true)
    }

    // try to get the digital twin from the digital twin collection
    const digitalTwin: TComponentInProjectWithContextResponse = rootState.components.digitalTwins[get(rootState, ['project', 'project', 'project', 'id'], null)]

    if (digitalTwin) {
      commit('SELECT_DIGITAL_TWIN', digitalTwin)
      dispatch('components/computeDigitalTwinKPIs', digitalTwin, { root: true })
      dispatch('elevators/clear', {}, { root: true })
      dispatch('elevators/fetchInformation', {}, { root: true })
      return resolve(true)
    }

    // digital twin not found, so try to fetch it
    const fetchedDigitalTwin: TComponentInProjectWithContextResponse = await dispatch('components/fetchBuildingComponentForProject', get(rootState, ['project', 'project', 'project', 'id'], false), { root: true })

    if (fetchedDigitalTwin) {
      commit('SELECT_DIGITAL_TWIN', fetchedDigitalTwin)
      dispatch('components/computeDigitalTwinKPIs', fetchedDigitalTwin, { root: true })
      dispatch('elevators/clear', {}, { root: true })
      dispatch('elevators/fetchInformation', {}, { root: true })
      return resolve(true)
    } else {
      return reject(new Error(`Digital Twin not found for project #${get(rootState, ['projects', 'selectedProject', 'project', 'id'], null)}`))
    }
  }),
  fetchTimezone: async ({ commit }, latLng: { lng: number; lat: number }) => {
    commit('SET_LOADING_TIMEZONE', true)
    try {
      const response: any = await Tilequeries.getTimezone({
        id: `${latLng.lng},${latLng.lat}.json`
      })
      const timezone = get(response, 'data.features.0.properties.TZID')
      commit('SET_CURRENT_TIMEZONE', timezone)
    } catch (error) {
      Vue.notify({
        text: i18n.t('project.timezone.request.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      commit('SET_CURRENT_TIMEZONE', null)
    } finally {
      commit('SET_LOADING_TIMEZONE', false)
    }
  },
  fetchWeather: async ({ commit }, latLng: { lng: number; lat: number }) => {
    commit('SET_LOADING_WEATHER', true)
    try {
      const response: any = await CurrentWeather.get({
        params: {
          q: `${latLng.lat},${latLng.lng}`
        }
      })
      const weather = get(response, 'data', null)
      commit('SET_CURRENT_WEATHER', weather)
    } catch (error) {
      Vue.notify({
        text: i18n.t('project.weather.request.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      commit('SET_CURRENT_WEATHER', null)
    } finally {
      commit('SET_LOADING_WEATHER', false)
    }
  },
  fetchOccupantsTimeseries: async ({ commit, rootState, getters }) => {
    const token = rootState.auth.access_token
    const project_id = getters.getProjectID
    const dataPointID = getters.getOccupantsDataPointID
    if (!dataPointID) {
      return null
    }
    commit('SET_LOADING_OCCUPANTS', true)
    try {
      const timeseriesResponse: AxiosResponse<[string, number][]> = await Datapoint.getTimeseries({
        token,
        params: {
          dataPointID,
          project_id,
          end: moment().toISOString(),
          short: true,
          max: 1,
          closed_interval: false
        }
      })
      commit('SET_OCCUPANTS', getLastValue(timeseriesResponse.data))
      return timeseriesResponse.data
    } catch (error) {
      Vue.notify({
        text: i18n.t('project.data.occupants.request.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return error
    } finally {
      commit('SET_LOADING_OCCUPANTS', false)
    }
  },
  fetchCarOccupantsTimeseries: async ({ commit, rootState, getters }) => {
    const token = rootState.auth.access_token
    const project_id = getters.getProjectID
    const dataPointID = getters.getCarOccupantsDataPointID
    if (!dataPointID) {
      return null
    }
    commit('SET_LOADING_CAR_OCCUPANTS', true)
    try {
      const timeseriesResponse: AxiosResponse<[string, number][]> = await Datapoint.getTimeseries({
        token,
        params: {
          dataPointID,
          project_id,
          end: moment().toISOString(),
          short: true,
          max: 1,
          closed_interval: false
        }
      })
      commit('SET_CAR_OCCUPANTS', getLastValue(timeseriesResponse.data))
      return timeseriesResponse.data
    } catch (error) {
      Vue.notify({
        text: i18n.t('project.data.car_occupants.request.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return error
    } finally {
      commit('SET_LOADING_CAR_OCCUPANTS', false)
    }
  },
  fetchFaults: async ({ commit, state, dispatch }, intervalLoading: boolean) => {
    commit('SET_FAULTS_LOADING', !intervalLoading)

    const digitalTwin: TComponentInProjectWithContextResponse = state.digitalTwin as TComponentInProjectWithContextResponse
    const availableFaultSources: { [id: string]: string|null } = getSystemsWithDatapoints(digitalTwin)

    // Fetch all the timeseries data for each dataPointID
    const timeseriesData: TTimeseriesShort = await dispatch(
      'components/fetchFaultRelevantTimeseries',
      {
        project_id: digitalTwin.project_id,
        dataPointIDs: Object.values(availableFaultSources).filter((dataPointID: string|null) => dataPointID !== null)
      },
      {
        root: true
      }
    )

    const systemsWithTimeseriesData: TSystemFaultsData = {}
    /* faultSystems.forEach((faultSystem: string) => {
      systemsWithTimeseriesData[faultSystem] = null
    }) */

    // Iterate through each timeseries, get the latest value and store it in the modules store
    Object.keys(availableFaultSources).forEach((fault: string) => {
      const datapoint: string|null = get(availableFaultSources, fault, null)
      if (datapoint) {
        systemsWithTimeseriesData[fault] = getLastValue(get(timeseriesData, datapoint, []))
      }
    })

    commit('SET_FAULTS', systemsWithTimeseriesData)
    commit('SET_FAULTS_LOADING', false)
  }
} as ActionTree<TProjectState, TRootState>
