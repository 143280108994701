import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TAlertPutNoteRequest, TAlertPutStatusRequest, TAlertTagRequest } from './requestTypes'
import { TAlert } from '@/services/alerta/resources/common/responseTypes'

const resource = 'alert'

export default {
  getAlert: (request: {
    token: string|null;
    id: string;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TAlert>> => {
    return axiosInstance.get(`/${resource}/${request.id}`, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },

  setStatus: (request: {
    token: string|null;
    id: string;
    body: TAlertPutStatusRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`/${resource}/${request.id}/status`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },

  tag: (request: {
    token: string|null;
    id: number;
    body: TAlertTagRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`/${resource}/${request.id}/tag`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },

  untag: (request: {
    token: string|null;
    id: number;
    body: TAlertTagRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<any>> => {
    return axiosInstance.put(`/${resource}/${request.id}/untag`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },

  addNote: (request: {
    token: string|null;
    id: number;
    body: TAlertPutNoteRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> => {
    return axiosInstance.put(`/${resource}/${request.id}/note`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  }
} as IRepository
