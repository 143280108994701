import {
  TAssignedPin,
  TComponentInProjectWithContextResponse
} from '@/services/aedifion/resources/project/responseTypes'
import { TKpiPinDataPointId } from '@/utils/types/kpipindatapointid'

export const calculateEnergyGeneration = (heatGeneration: number|null, electricityGeneration: number|null): number|null => {
  if (heatGeneration === null && electricityGeneration === null) {
    return null
  }
  let result = 0
  if (electricityGeneration) {
    result += electricityGeneration
  }
  if (heatGeneration) {
    result += heatGeneration
  }
  return result
}

export const calculateTotalEnergyGeneration = (
  boilerHeatGeneration: number|null,
  heatPumpHeatGeneration: number|null,
  chpHeatGeneration: number|null,
  solarHeatGeneration: number|null,
  chpElectricityGeneration: number|null,
  photovoltaikElectricityGeneration: number|null
): number|null => {
  if (
    boilerHeatGeneration === null &&
    heatPumpHeatGeneration === null &&
    chpHeatGeneration === null &&
    solarHeatGeneration === null &&
    chpElectricityGeneration === null &&
    photovoltaikElectricityGeneration === null
  ) return null
  let result = 0
  if (boilerHeatGeneration) result += boilerHeatGeneration
  if (heatPumpHeatGeneration) result += heatPumpHeatGeneration
  if (chpHeatGeneration) result += chpHeatGeneration
  if (solarHeatGeneration) result += solarHeatGeneration
  if (chpElectricityGeneration) result += chpElectricityGeneration
  if (photovoltaikElectricityGeneration) result += photovoltaikElectricityGeneration
  return result
}

export const calculateCo2PerSquaredMeterEmissions = (
  heatCo2Emissions: number|null,
  electricityCo2Emissions: number|null,
  grossFloorArea: string|null
): number|null => {
  if (!grossFloorArea || (heatCo2Emissions === null && electricityCo2Emissions === null)) {
    return null
  }
  let co2Total = 0
  if (heatCo2Emissions) {
    co2Total += heatCo2Emissions
  }
  if (electricityCo2Emissions) {
    co2Total += electricityCo2Emissions
  }
  return co2Total / parseFloat(grossFloorArea)
}

export const calculateWaterConsumptionPerPerson = (
  freshWaterConsumption: number|null,
  grayWaterConsumption: number|null,
  rainWaterConsumption: number|null,
  occupants: number|null
): number|null => {
  if (!occupants || (freshWaterConsumption === null && grayWaterConsumption === null && rainWaterConsumption === null)) {
    return null
  }
  let totalWaterConsumption = 0
  if (freshWaterConsumption) {
    totalWaterConsumption += freshWaterConsumption
  }
  if (grayWaterConsumption) {
    totalWaterConsumption += grayWaterConsumption
  }
  if (rainWaterConsumption) {
    totalWaterConsumption += rainWaterConsumption
  }
  return totalWaterConsumption / occupants
}

export const calculateWaterConsumptionPerSquaredMeters = (
  freshWaterConsumption: number|null,
  grayWaterConsumption: number|null,
  grossFloorArea: string|null
): number|null => {
  if (!grossFloorArea || (freshWaterConsumption === null && grayWaterConsumption === null)) {
    return null
  }
  let totalWaterConsumptionCubic = 0
  if (freshWaterConsumption) {
    totalWaterConsumptionCubic += freshWaterConsumption
  }
  if (grayWaterConsumption) {
    totalWaterConsumptionCubic += grayWaterConsumption
  }
  const totalWaterConsumptionLiters = totalWaterConsumptionCubic * 1000
  return totalWaterConsumptionLiters / parseFloat(grossFloorArea)
}

/**
 * Alphanumeric IDs for datapoints that are required to compute the KPIs on the Portfolio.vue page
 * Excluded: Fault indicators
 */
const projectKpis: string[] = [
  'B+EN_H_CONSUM_SUM',
  'B+ELS+EN_EL_CONSUM_SUM',
  'B+HS+EN_H_GEN_SUM',
  'B+ELS+EN_EL_GEN_SUM',
  'B+HS+EN_H_GEN_SUM_PRJ', // heat generation projection
  'B+ELS+EN_EL_GEN_SUM_PRJ', // electricity generation projection
  'B+EL_CO2_EMI', // electricity related co2 emissions
  'B+EN_H_CONSUM_SUM_PRJ', // ernergy consumption heat projection
  'B+ELS+EN_EL_CONSUM_SUM_PRJ', // ernergy consumption electricity projection
  'B+H_CO2_EMI_SUM', // heat related co2 emissions
  'B+ELS+PV_CO2_EMI_AVD', // co2 emission avoidance from photovoltaik
  'B+HS+SOL_CO2_EMI_AVD', // co2 emission avoidance from solar heat
  'B+HS+CHP_CO2_EMI_AVD_SUM', // co2 emission avoidance from CHP
  'B+HS+HP_CO2_EMI_AVD_SUM', // co2 emission avoidance from heat pump
  'B+H_CO2_EMI_SUM_PRJ', // co2 emission generation projection heat
  'B+EL_CO2_EMI_PRJ', // co2 emission generation projection electricity
  'B+WAS+WS_FRESH_CONSUM_PRJ', // fresh water consumption projection
  'B+WAS+WS_GRAY_CONSUM_PRJ', // gray water consumption projection
  'B+WAS+RAIN_WS_CONSUM_PRJ', // rain water consumption projection
  'B+WAS+WS_FRESH_CONSUM',
  'B+WAS+WS_GRAY_CONSUM',
  'B+WAS+RAIN_WS_CONSUM',
  'B+COUNT_PEO',
  'B+ELS+ELE_AVAIL',
  'B+HS+BOI_EN_H_GEN_SUM', // boiler heat
  'B+HS+CHP_EN_H_GEN_SUM', // chp heat
  'B+HS+HP_EN_H_GEN_SUM', // heat pump heat
  'B+HS+SOL_EN_H_GEN_SUM', // solar heat
  'B+HS+CHP_EN_EL_GEN_SUM', // chp electricity
  'B+ELS+PV_EL_GEN_SUM', // photovoltaik electricity,
  'B+H_DISCT+EN_H_CONSUM' // district heating
]

/**
 * Filter through the digital twin component and search for PINs that are mapped and match the projectKpis signature
 * Get the DataPointID from the PINs
 * Those DataPointIDs are referring necessarry timeseries for the KPI table calculation
 */
export const getPinDataPointIdsForKPIs = (digitalTwin: TComponentInProjectWithContextResponse): [TKpiPinDataPointId] => {
  const projectKpiPins: TAssignedPin[] = digitalTwin.pins.filter(
    (pin: TAssignedPin) => pin && pin.alphanumeric_id && projectKpis.includes(pin.alphanumeric_id)
  )
  return projectKpiPins.map(
    (pin: TAssignedPin) => {
      return {
        alphanumeric_id: pin.alphanumeric_id,
        dataPointID: pin.dataPointID
      }
    }
  ) as [{
    alphanumeric_id: string;
    dataPointID: string;
  }]
}
