import Vue from 'vue'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { TAlertsState } from './types'

// Load the alertaApiRepository for alerts
import alertaApiRepository from '@/services/alerta'
import { IRepository } from '@/utils/types/repository'
import { STATUS, TAlertResponse, TAlertsResponse } from '@/services/alerta/resources/common/responseTypes'
import { AxiosError, AxiosResponse } from 'axios'
import i18n from '@/plugins/i18n'
import { of } from 'rxjs'
import { delay, repeat, switchMap, timeout } from 'rxjs/operators'
import get from 'lodash.get'

const Alerts: IRepository = alertaApiRepository.get('alerts')
const Alert: IRepository = alertaApiRepository.get('alert')

export default {
  fetchAlerts: async ({ commit, rootState }) => {
    commit('SET_LOADING', true)
    const token = rootState.auth.access_token
    try {
      const alertsResponse: AxiosResponse<TAlertsResponse> = await Alerts.getAlerts({
        token
      })
      const alerts: TAlertsResponse = alertsResponse.data
      commit('SET_ALERTS', alerts)
      return alerts
    } catch (error) {
      console.error(error)
      const errorMessage: string = get(error.response, 'data.message', 'Unknown error')
      Vue.notify({
        text: i18n.t('alerts.requests.get_all_alerts.failed', { reason: errorMessage }) as string,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  startPollingAlerts: ({ commit, dispatch }) => {
    commit('START_POLLING', of({}).pipe(
      switchMap(
        _ => dispatch('fetchAlerts')
      ),
      timeout(10000),
      delay(30000),
      repeat()
    ).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: (err: AxiosError) => {
        dispatch('stopPollingAlerts')
        const errorMessage: string = get(err.response, 'data.message', 'Unknown error')
        Vue.notify({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: i18n.t('alerts.requests.get_all_alerts.failed', { reason: errorMessage }) as string,
          group: 'all',
          duration: 6000,
          type: 'error'
        })
      }
    })
    )
  },

  stopPollingAlerts: ({ commit, state }) => {
    if (state.polling !== null) {
      state.polling.unsubscribe()
      commit('END_POLLING')
    }
  },

  setStatus: async ({ commit, rootState }, payload: { alertId: string; status: STATUS }) => {
    commit('SET_LOADING', true)
    const token = rootState.auth.access_token
    try {
      await Alert.setStatus({
        token,
        id: payload.alertId,
        body: {
          status: payload.status
        }
      })
      const updatedAlert: AxiosResponse<TAlertResponse> = await Alert.getAlert({
        token,
        id: payload.alertId
      })
      if (updatedAlert.data.total === 1) {
        commit('UPDATE_ALERT', updatedAlert.data.alert)
        return updatedAlert.data
      }
    } catch (error) {
      console.error(error)
      const errorMessage: string = get(error.response, 'data.message', 'Unknown error')
      Vue.notify({
        text: i18n.t('alerts.requests.get_all_alerts.failed', { reason: errorMessage }) as string,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  toggleWatched: async ({ commit, rootGetters, rootState }, payload: { alertId: string; watched: boolean }) => {
    commit('SET_LOADING', true)
    const token = rootState.auth.access_token
    try {
      const body = {
        tags: [`watch:${rootGetters['user/getUser'].email}`]
      }
      if (payload.watched) {
        await Alert.tag({
          token,
          id: payload.alertId,
          body: body
        })
      } else {
        await Alert.untag({
          token,
          id: payload.alertId,
          body: body
        })
      }
      const updatedAlert: AxiosResponse<TAlertResponse> = await Alert.getAlert({
        token,
        id: payload.alertId
      })
      if (updatedAlert.data.total === 1) {
        commit('UPDATE_ALERT', updatedAlert.data.alert)
        return updatedAlert.data
      }
    } catch (error) {
      console.error(error)
      const errorMessage: string = get(error.response, 'data.message', 'Unknown error')
      Vue.notify({
        text: i18n.t('alerts.requests.get_all_alerts.failed', { reason: errorMessage }) as string,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  addNote: async ({ commit, rootState }, payload: { alertId: string; noteText: string }) => {
    commit('SET_LOADING', true)
    const token = rootState.auth.access_token
    try {
      await Alert.addNote({
        token,
        id: payload.alertId,
        body: {
          note: payload.noteText
        }
      })
      const updatedAlert: AxiosResponse<TAlertResponse> = await Alert.getAlert({
        token,
        id: payload.alertId
      })
      if (updatedAlert.data.total === 1) {
        commit('UPDATE_ALERT', updatedAlert.data.alert)
        return updatedAlert.data
      }
    } catch (error) {
      const errorMessage: string = get(error.response, 'data.message', 'Unknown error')
      Vue.notify({
        text: i18n.t('alerts.requests.get_all_alerts.failed', { reason: errorMessage }) as string,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  }
} as ActionTree<TAlertsState, TRootState>
