import { IRepository } from '@/utils/types/repository'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { TElevatorsState } from './types'
import aedifionApiRepository from '@/services/aedifion'
import i18n from '@/plugins/i18n'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { TGetAnalyticsFunctionsResponse, TGetAnalyticsInstanceResultsResponse, TGetAnalyticsInstancesResponse } from '@/services/aedifion/resources/analytics/responseTypes'
import { TComponentsInProjectResponse } from '@/services/aedifion/resources/project/responseTypes'
import moment, { Moment } from 'moment'
import { get } from 'lodash'
const Analytics: IRepository = aedifionApiRepository.get('analytics')
const Projects: IRepository = aedifionApiRepository.get('projects')

export default {
  fetchElevators: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_ELEVATORS', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']

    try {
      if (!token) throw new Error(i18n.t('elevators.requests.throw_error') as string)
      if (!project_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)

      const elevatorsResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params: {
          page: 1,
          per_page: 100,
          filter: 'alphanumeric_id=ELE'
        }
      })

      commit('SET_ELEVATORS', elevatorsResponse.data.items)
    } catch (error) {
      Vue.notify({
        text: i18n.t('elevators.requests.get_elevators.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_ELEVATORS', false)
    }
  },
  fetchAnalyticsFunctions: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_ANALYTICS_FUNCTIONS', true)
    const token = rootGetters['auth/oidcAccessToken']

    try {
      if (!token) throw new Error(i18n.t('elevators.requests.throw_error') as string)

      const analysisFunctionResults: AxiosResponse<TGetAnalyticsFunctionsResponse> = await Analytics.getFunctions({
        token,
        params: {
          component_alphanumeric_id: 'B'
        }
      })

      commit('SET_ANALYSIS_FUNCTIONS', analysisFunctionResults.data)
    } catch (error) {
      Vue.notify({
        text: i18n.t('elevators.requests.get_analysis_functions.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_ANALYTICS_FUNCTIONS', false)
    }
  },
  fetchInstances: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_INSTANCES', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']

    try {
      if (!token) throw new Error(i18n.t('elevators.requests.throw_error') as string)
      if (!project_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)

      const instanceResults: AxiosResponse<TGetAnalyticsInstancesResponse> = await Analytics.getInstances({
        token,
        params: {
          project_id
        }
      })

      commit('SET_INSTANCES', instanceResults.data)
    } catch (error) {
      Vue.notify({
        text: i18n.t('elevators.requests.get_instances.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_INSTANCES', false)
    }
  },
  fetchInstanceResults: async ({ commit, rootGetters, getters }) => {
    commit('SET_LOADING_INSTANCE_RESULTS', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    const instance_id = getters.getElevatorsAnalysisInstanceId
    try {
      if (!token) throw new Error(i18n.t('elevators.requests.throw_error') as string)
      if (!project_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)
      if (!instance_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)
      const analysisFunctionResults: AxiosResponse<TGetAnalyticsInstanceResultsResponse> = await Analytics.getInstanceResults({
        token,
        id: instance_id,
        params: {
          project_id
        }
      })

      commit('SET_INSTANCE_RESULTS', analysisFunctionResults.data)
    } catch (error) {
      Vue.notify({
        text: i18n.t('elevators.requests.get_instance_results.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_INSTANCE_RESULTS', false)
    }
  },
  fetchResult: async ({ commit, rootGetters, getters }) => {
    commit('SET_LOADING_INSTANCE_RESULT', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    const instance_id = getters.getElevatorsAnalysisInstanceId
    const result_id: string|null = getters.getAnalysisResultForCurrentTimeSelection
    if (result_id !== null) {
      try {
        if (!token) throw new Error(i18n.t('elevators.requests.throw_error') as string)
        if (!project_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)
        if (!instance_id) throw new Error(i18n.t('elevators.requests.throw_error') as string)
        const elevatorAnalysisResponse: AxiosResponse<TGetAnalyticsInstanceResultsResponse> = await Analytics.getResult({
          token,
          id: instance_id,
          second_level_id: result_id,
          params: {
            project_id,
            result_language: 'en'
          }
        })
        commit('SET_ELEVATOR_ANALYSIS_RESULT', elevatorAnalysisResponse.data)
      } catch (error) {
        Vue.notify({
          text: i18n.t('elevators.requests.get_instance_result.error') as string,
          group: 'requests',
          duration: 6000,
          type: 'error'
        })
      } finally {
        commit('SET_LOADING_INSTANCE_RESULT', false)
      }
    } else {
      commit('SET_ELEVATOR_ANALYSIS_RESULT', null)
      commit('SET_LOADING_INSTANCE_RESULT', false)
    }
  },
  fetchInformation: async ({ commit, dispatch, getters }) => {
    commit('SET_LOADING', true)
    await dispatch('fetchElevators')
    if (getters.hasElevators) {
      await dispatch('fetchAnalyticsFunctions')
      await dispatch('fetchInstances')
      await dispatch('fetchInstanceResults')
      await dispatch('fetchResult')
    }
    commit('SET_LOADING', false)
  },
  switchChartToMonth: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', false)
    dispatch('fetchResult')
  },
  switchChartToYear: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', true)
    dispatch('fetchResult')
  },
  selectPreviousYear: ({ state, commit, dispatch }) => {
    const previous: number = get(state, 'year', moment().year()) - 1
    commit('SWITCH_YEAR', previous)
    dispatch('fetchResult')
  },
  selectPreviousMonth: ({ state, commit, dispatch }) => {
    const previous: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).subtract(1, 'month')
    commit('SWITCH_MONTH', previous.month())
    commit('SWITCH_YEAR', previous.year())
    dispatch('fetchResult')
  },
  selectNextYear: ({ state, commit, dispatch }) => {
    const next: number = get(state, 'year', moment().year()) + 1
    commit('SWITCH_YEAR', next)
    dispatch('fetchResult')
  },
  selectNextMonth: ({ state, commit, dispatch }) => {
    const next: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).add(1, 'month')
    commit('SWITCH_MONTH', next.month())
    commit('SWITCH_YEAR', next.year())
    dispatch('fetchResult')
  },
  clear: ({ commit }) => {
    commit('SET_ANALYSIS_FUNCTIONS', [])
    commit('SET_ELEVATORS', [])
    commit('SET_INSTANCES', [])
    commit('SET_INSTANCE_RESULTS', [])
    commit('SET_ELEVATOR_ANALYSIS_RESULT', null)
    commit('SWITCH_YEAR', moment().year())
    commit('SWITCH_MONTH', moment().month())
    commit('SET_YEAR_SELECTED', true)
  }
} as ActionTree<TElevatorsState, TRootState>
