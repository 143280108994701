




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import bullet from 'highcharts/modules/bullet'
import { TBenchmarkZones } from '@/utils/helpers/types'

bullet(Highcharts)

export default Vue.extend({
  name: 'BulletGraph',
  components: {
    highcharts: Chart
  },
  props: {
    title: {
      required: false,
      type: String as PropType<string>
    },
    subtitle: {
      required: true,
      type: String as PropType<string>
    },
    seriesName: {
      required: false,
      type: String as PropType<string>
    },
    setValue: {
      required: true,
      type: Number as PropType<number>
    },
    isValue: {
      required: false,
      type: Number as PropType<number>
    },
    projectionValue: {
      required: false,
      type: Number as PropType<number>
    },
    benchmarkZones: {
      required: true,
      type: Object as PropType<TBenchmarkZones>
    },
    unit: {
      required: true,
      type: String as PropType<string>
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal === true) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  computed: {
    plotLines (): any {
      const lines = [
        {
          value: this.isValue,
          width: 2,
          color: 'rgba(255,255,255,0.0)',
          zIndex: 5,
          label: {
            useHTML: true,
            rotation: 0,
            align: 'center',
            y: 20,
            text: `<div class="set-tooltip primary--text"><div class="arrow-up"></div><div style="background-color: white" class="target-label">${this.$t('benchmark.is')}</div></div>`
          }
        },
        {
          value: this.setValue,
          width: 2,
          color: 'rgba(255,255,255,0.0)',
          zIndex: 5,
          label: {
            useHTML: true,
            rotation: 0,
            align: 'center',
            y: -10,
            text: `<div class="set-tooltip green--text"><span class="target-label">${this.$t('benchmark.should')}</span><div class="arrow-down"></div></div>`
          }
        }
      ]

      if (this.projectionValue) {
        lines.push({
          value: this.projectionValue,
          width: 2,
          color: 'rgba(255,255,255,0.0)',
          zIndex: 5,
          label: {
            useHTML: true,
            rotation: 0,
            align: 'center',
            y: -10,
            text: `<div class="set-tooltip blue--text"><span class="target-label">${this.$t('benchmark.prediction')}</span><div class="arrow-down"></div></div>`
          }
        })
      }

      return lines
    },
    chartOptions (): any {
      return {
        chart: {
          inverted: true,
          type: 'bullet',
          height: '120px',
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E'
          }
        },
        title: {
          useHTML: true,
          text: this.title ? this.title : null,
          margin: 30,
          align: 'left',
          style: {
            color: '#004B8E'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: { enabled: false }, /* {
          useHTML: true,
          pointFormat: `<b>{point.y}</b> ${this.unit}`
        } */
        series: [{
          pointPadding: 0.25,
          borderWidth: 0,
          targetOptions: {
            width: '200%'
          },
          name: this.seriesName ? this.seriesName : null,
          color: 'transparent',
          data: [{
            y: this.isValue
          }]
        }],
        xAxis: {
          categories: [
            this.subtitle
          ],
          labels: {
            useHTML: true,
            padding: 30,
            style: {
              color: '#004B8E'
            }
          }
        },
        yAxis: {
          title: {
            enabled: false,
            style: {
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              color: '#004B8E'
            }
          },
          max: Math.max(this.benchmarkZones.green[1], this.benchmarkZones.red[1]),
          min: Math.min(this.benchmarkZones.red[0], this.benchmarkZones.green[0]),
          gridLineWidth: 0,
          plotBands: [
            {
              id: 'left',
              from: Math.min(this.benchmarkZones.red[0], this.benchmarkZones.green[0]),
              to: Math.min(this.benchmarkZones.red[1], this.benchmarkZones.green[1]),
              color: this.benchmarkZones.green[0] < this.benchmarkZones.red[0] ? '#288970' : '#931322'
            },
            {
              id: 'middle',
              from: Math.min(this.benchmarkZones.red[1], this.benchmarkZones.green[1]),
              to: Math.max(this.benchmarkZones.red[0], this.benchmarkZones.green[0]),
              color: {
                linearGradient: { x1: 0, x2: 1, y1: 1, y2: 1 },
                stops: [
                  [0, this.benchmarkZones.green[0] < this.benchmarkZones.red[0] ? '#288970' : '#931322'],
                  [0.15, '#D4A92A'],
                  [0.80, '#D4A92A'],
                  [1, this.benchmarkZones.green[0] < this.benchmarkZones.red[0] ? '#931322' : '#288970']
                ]
              }
            },
            {
              id: 'right',
              from: Math.max(this.benchmarkZones.red[0], this.benchmarkZones.green[0]),
              to: Math.max(this.benchmarkZones.green[1], this.benchmarkZones.red[1]),
              color: this.benchmarkZones.green[0] < this.benchmarkZones.red[0] ? '#931322' : '#288970'
            }
          ],
          plotLines: this.plotLines
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        }
      }
    }
  }
})
