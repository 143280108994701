




import Vue from 'vue'
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

export default Vue.extend({
  name: 'AuthSilentRenew',

  mounted () {
    vuexOidcProcessSilentSignInCallback()
  }
})
