import { TCompanyRole } from '@/services/aedifion/resources/company/responseTypes'
import { TRole } from '@/services/aedifion/resources/project/responseTypes'
import { TUser } from '@/services/aedifion/resources/user/responseTypes'
import { MutationTree } from 'vuex'
import { TUserState } from './types'

export default {
  SET_USER_LOADING: (state: TUserState, loading: boolean) => {
    state.loading = loading
  },
  SET_USER: (state: TUserState, payload: { user: TUser; password_lock?: string }) => {
    state.address = payload.user.address
    state.firstName = payload.user.firstName
    state.lastName = payload.user.lastName
    state.id = payload.user.id
    state.company_id = payload.user.company_id
    state.avatar_url = payload.user.avatar_url
    state.email = payload.user.email
    state.contact = payload.user.contact
    state.phone = payload.user.phone
    if (payload.password_lock) state.password_locked_until = payload.password_lock
  },
  SET_USER_AVATAR: (state: TUserState, avatarUrl: string) => {
    state.avatar_url = avatarUrl
  },
  SET_USER_ROLES: (state: TUserState, payload: {
    companyRoles: TCompanyRole[];
    projectRoles: TRole[];
  }) => {
    state.companyRoles = payload.companyRoles
    state.projectRoles = payload.projectRoles
  }
} as MutationTree<TUserState>
