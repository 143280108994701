import { Module } from 'vuex'
import { TRootState } from '../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { TProjectState } from './types'

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
} as Module<TProjectState, TRootState>
