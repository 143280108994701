import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  TComponentInProjectWithContextResponse,
  TComponentsInProjectResponse,
  TPostComponentAttributeResponse,
  TProjectAvatarDeleteResponse,
  TProjectAvatarUplaodResponse,
  TProjectTimeseriesResponse,
  TProjectUpdateResponse,
  TDatapointsByPageResponse
} from './responseTypes'
import {
  TDeleteComponentAttributeRequestParams,
  TPostComponentAttributeRequestBody,
  TPostComponentAttributeRequestParams,
  TProjectUpdateRequest,
  TPutComponentAttributeRequestParams,
  TComponentsInProjectRequest,
  TProjectTimeseriesRequest,
  TDatapointsByPageRequest
} from './requestTypes'

const resource = 'project'

export default {
  getComponentsInProject: (request: {
    token: string|null;
    id: number;
    params: TComponentsInProjectRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TComponentsInProjectResponse>> => {
    return axiosInstance.get(`/${resource}/${request.id}/componentsInProject`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getComponentInProject: (request: {
    token: string|null;
    id: number;
    second_level_id: number;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TComponentInProjectWithContextResponse>> => {
    return axiosInstance.get(`/${resource}/${request.id}/componentInProject/${request.second_level_id}`, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  deleteAvatar: (request: {
    token: string|null;
    id: number;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TProjectAvatarDeleteResponse>> => {
    return axiosInstance.delete(`/${resource}/${request.id}/avatar`, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  updateDetails: (request: {
    token: string|null;
    id: number;
    body: TProjectUpdateRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TProjectUpdateResponse>> => {
    return axiosInstance.put(`/${resource}/${request.id}`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  uploadAvatar: (request: {
    token: string|null;
    id: number;
    body: FormData;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TProjectAvatarUplaodResponse>> => {
    return axiosInstance.post(`/${resource}/${request.id}/avatar`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getTimeseries: (request: {
    token: string|null;
    id: number;
    params: TProjectTimeseriesRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TProjectTimeseriesResponse>> => {
    return axiosInstance.get(`/${resource}/${request.id}/timeseries`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  addComponentAttribute: (request: {
    token: string|null;
    id: number;
    params: TPostComponentAttributeRequestParams;
    body: TPostComponentAttributeRequestBody;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TPostComponentAttributeResponse>> => {
    return axiosInstance.post(`/${resource}/${request.id}/componentInProject/attribute`, request.body, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  updateComponentAttribute: (request: {
    token: string|null;
    id: number;
    params: TPutComponentAttributeRequestParams;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TPostComponentAttributeResponse>> => {
    return axiosInstance.put(`/${resource}/${request.id}/componentInProject/attribute`, {}, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  deleteComponentAttribute: (request: {
    token: string|null;
    id: number;
    params: TDeleteComponentAttributeRequestParams;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TPostComponentAttributeResponse>> => {
    return axiosInstance.delete(`/${resource}/${request.id}/componentInProject/attribute`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getDatapointsByPage: (request: {
    token: string|null;
    id: number;
    params: TDatapointsByPageRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TDatapointsByPageResponse>> => {
    return axiosInstance.get(`/${resource}/${request.id}/datapoints/byPage`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  }
} as IRepository
