import { ComponentsState } from './types'
import { MutationTree } from 'vuex'
import { Component } from '@aedifion.io/aedifion-api'

export default {
  SET_COMPONENTS_LOADING: (state: ComponentsState, loading: boolean) => {
    state.loading = loading
  },
  SET_COMPONENTS: (state: ComponentsState, components: Component[]) => {
    state.components = components
  }
} as MutationTree<ComponentsState>
