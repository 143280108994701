import { TBenchmarkAttributeZones, TBenchmarkZones } from '@/utils/helpers/types'
import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TEnergyGenerationState } from './types'
import get from 'lodash.get'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'

export default {
  getTargetGenerationValue: (state: TEnergyGenerationState, getters, rootState): number|null => {
    if (!rootState.project.digitalTwin) return null
    const fountTargetValue: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes', []), 'B_TAR_EN_EL_GEN_MAX')
    if (fountTargetValue === null) return null
    return parseFloat(fountTargetValue)
  },
  getGenerationBenchmarkZones: (state: TEnergyGenerationState, gettters, rootState): TBenchmarkZones|null => {
    if (!rootState.project.digitalTwin) return null
    const benchmarkZones: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes', []), 'B_EFZ')
    if (benchmarkZones === null) return null
    try {
      const benchmarks: TBenchmarkAttributeZones = JSON.parse(benchmarkZones)
      if (benchmarks.benchmark_energy_generation) {
        return benchmarks.benchmark_energy_generation
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },

  getBoilerGeneration: (state: TEnergyGenerationState): number|null => state.chart.boiler,
  getHeatpumpGeneration: (state: TEnergyGenerationState): number|null => state.chart.heat_pump,
  getDistrictHeatingGeneration: (state: TEnergyGenerationState): number|null => state.chart.district_heating,
  getBhkwThGeneration: (state: TEnergyGenerationState): number|null => state.chart.bhkw_th,
  getBhkwElGeneration: (state: TEnergyGenerationState): number|null => state.chart.bhkw_el,
  getPhotovoltaicsGeneration: (state: TEnergyGenerationState): number|null => state.chart.photovoltaics,
  getSolarGeneration: (state: TEnergyGenerationState): number|null => state.chart.solar,

  getGenerationBenchmark: (state: TEnergyGenerationState): number|null => state.benchmark.energyGeneration,
  getGenerationBenchmarkColor: (state: TEnergyGenerationState): string|null => state.benchmark.energyGenerationColor,

  getYear: (state: TEnergyGenerationState): string|number|null => state.year,
  getMonth: (state: TEnergyGenerationState): string|number|null => state.month,
  isYearSelected: (state: TEnergyGenerationState): boolean => state.isYearSelected,
  isMonthSelected: (state: TEnergyGenerationState): boolean => !state.isYearSelected,
  hasPrevious: (state: TEnergyGenerationState): boolean => state.hasPrevious,
  hasNext: (state: TEnergyGenerationState): boolean => state.hasNext,
  isLoadingData: (state: TEnergyGenerationState): boolean => state.loading
} as GetterTree<TEnergyGenerationState, TRootState>
