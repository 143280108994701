






















import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'AuthCallback',
  computed: {
    isAuthenticated (): boolean {
      return this.$store.getters['auth/oidcIsAuthenticated']
    }
  },
  methods: {
    ...mapActions({
      oidcSignInCallback: 'auth/oidcSignInCallback'
    })
  },
  async mounted () {
    try {
      const redirectPath: string = await this.oidcSignInCallback()
      this.$router.push(redirectPath)
    } catch (error) {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'portfolio' })
      } else {
        this.$router.push({ name: 'auth-failed' })
      }
    }
  }
})
