import { TTimeseriesShort } from '@/services/aedifion/resources/project/responseTypes'
import {
  MutationTree
} from 'vuex'
import { TEnergyManagementState } from './types'
import { ComponentWithContext } from '@aedifion.io/aedifion-api'

export default {
  SET_LOADING_METERS: (state: TEnergyManagementState, loading: boolean) => {
    state.loadingMeters = loading
  },
  SET_LOADING_METERS_LATEST_TIMESERIES: (state: TEnergyManagementState, loading: boolean) => {
    state.loadingMetersLatestTimeseries = loading
  },
  TOGGLE_CONSUMPTION: (state: TEnergyManagementState, showConsumption: boolean) => {
    state.showConsumption = showConsumption
  },
  SET_METERS: (state: TEnergyManagementState, meters: ComponentWithContext[]) => {
    state.meters = meters
  },
  SET_METERS_TIMESERIES: (state: TEnergyManagementState, payload: {
    currentYearsData: Array<TTimeseriesShort|null>;
    lastYearsLastData: TTimeseriesShort|null;
  }) => {
    state.timeseries = payload.currentYearsData
    state.timeseriesOfLastYear = payload.lastYearsLastData
  },
  SET_LOADING_PDF_REPORT: (state: TEnergyManagementState, loading: boolean) => {
    state.loadingPdfReport = loading
  },
  SET_PDF_REPORT: (state: TEnergyManagementState, report: any) => {
    state.pdfReport = report
  },
  SET_PDF_REPORT_ID: (state: TEnergyManagementState, id: string) => {
    state.pdfReportId = id
  }
} as MutationTree<TEnergyManagementState>
