





















































import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    icon: {
      required: false,
      type: String as PropType<string>
    },
    status: {
      required: false,
      type: Array as PropType<{icon: string; title: string; color: string}[]>
    }
  }
})
