var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({class:[
        'pa-4',
        'rom__status__card',
        'elevation-4',
        'd-flex',
        'justify-start',
        'align-center',
        _vm.color
      ],staticStyle:{"background-color":"white !important"}},'v-card',attrs,false),on),[(_vm.icon)?_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","left":"","color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('div',{staticClass:"fullwidth"},[_vm._t("default")],2)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltip)}})])}
var staticRenderFns = []

export { render, staticRenderFns }