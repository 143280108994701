import { TComponentInProject, TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import { MutationTree } from 'vuex'
import { TComponentsState, TProjectKpiTable } from './types'

export default {
  SET_COMPONENTS_IN_PROJECT_LOADING: (state: TComponentsState, loading: boolean) => {
    state.componentInProjectLoading = loading
  },
  SET_COMPONENTS_IN_PROJECTS_LOADING: (state: TComponentsState, loading: boolean) => {
    state.componentsInProjectsLoading = loading
  },
  SET_KPI_TABLES_LOADING: (state: TComponentsState, loading: boolean) => {
    state.kpisLoading = loading
  },
  SET_DIGITAL_TWIN_COMPONENT_FOR_PROJECT: (state: TComponentsState, payload: { digitalTwin: TComponentInProjectWithContextResponse; project_id: number }) => {
    state.digitalTwins[payload.project_id] = payload.digitalTwin
  },
  SET_COMPONENTS_IN_PROJECT: (state: TComponentsState, payload: { componentsInProject: TComponentInProject[]; project_id: number }) => {
    state.componentsInProject[payload.project_id] = payload.componentsInProject
  },
  SET_DIGITAL_TWINS_KPI_TABLE: (state: TComponentsState, kpiTable: TProjectKpiTable) => {
    // search for existing kpi tables and replace them
    const kpiTableIndex: number = state.kpiTables.findIndex((kpiTableState: TProjectKpiTable) => kpiTableState.project_id === kpiTable.project_id)
    if (kpiTableIndex !== -1) {
      state.kpiTables.splice(kpiTableIndex, 1, kpiTable)
    } else {
      state.kpiTables.push(kpiTable)
    }
  }
} as MutationTree<TComponentsState>
