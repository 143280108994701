import get from 'lodash.get'
import { IRepository, IRepositoryFactory } from '@/utils/types/repository'

// Import the resources
import AlertRepository from './resources/alert'
import AlertsRepository from './resources/alerts'

const repositories: { [key: string]: IRepository } = {
  alert: AlertRepository,
  alerts: AlertsRepository
}

export default {
  get: (name: string): IRepository => {
    return get(repositories, name)
  }
} as IRepositoryFactory
