

































































































































































































































































































import Vue from 'vue'

import PieChart from '@/components/ui/Charts/PieChart.vue'
import BulletGraph from '@/components/ui/Charts/BulletGraph.vue'
import WaterConsumptionInformation from './InformationTables/WaterConsumption/WaterConsumptionInformation.vue'
import { computeRelativeValue } from '@/utils/helpers/timeseries'
import moment from 'moment'
import { TBenchmarkZones } from '@/utils/helpers/types'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  components: {
    PieChart,
    BulletGraph,
    WaterConsumptionInformation
  },
  name: 'WaterConsumption',
  mounted () {
    this.$store.dispatch('water_consumption/fetchData')
  },
  methods: {
    selectMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('water_consumption/switchChartToMonth')
      }
    },
    selectYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('water_consumption/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('water_consumption/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('water_consumption/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('water_consumption/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('water_consumption/selectNextMonth')
      }
    }
  },
  computed: {
    loadingProjectsDigitalTwin (): boolean {
      return this.$store.getters['project/areComponentsInProjectLoading']
    },
    loadingData (): boolean {
      return this.$store.getters['water_consumption/isLoadingData']
    },
    hasConsumptionData (): boolean {
      return this.$store.getters['media_tables/getWaterConsumption'] !== null
    },
    waterConsumptionProjection (): number|null {
      return this.$store.state.water_consumption.benchmark.waterConsumptionProjection
    },
    freshWater (): number|null {
      return this.$store.getters['water_consumption/getFreshWaterConsumption']
    },
    hasFreshWaterData (): boolean {
      return this.freshWater !== null
    },
    freshWaterRelative (): string|null {
      return computeRelativeValue(this.freshWater, [
        this.grayWater,
        this.rainWater
      ], true, 0)
    },
    grayWater (): number|null {
      return this.$store.getters['water_consumption/getGrayWaterConsumption']
    },
    hasGrayWaterData (): boolean {
      return this.grayWater !== null
    },
    hasGrayWaterSystem (): boolean {
      return this.$store.getters['water_consumption/hasGrayWaterSystem']
    },
    grayWaterRelative (): string|null {
      return computeRelativeValue(this.grayWater, [
        this.freshWater,
        this.rainWater
      ], true, 0)
    },
    rainWater (): number|null {
      return this.$store.getters['water_consumption/getRainWaterConsumption']
    },
    hasRainWaterData (): boolean {
      return this.rainWater !== null
    },
    hasRainWaterSystem (): boolean {
      return this.$store.getters['water_consumption/hasRainWaterSystem']
    },
    rainWaterRelative (): string|null {
      return computeRelativeValue(this.rainWater, [
        this.grayWater,
        this.freshWater
      ], true, 0)
    },

    hasAnyData (): boolean {
      return this.hasGrayWaterData || this.hasRainWaterData || this.hasFreshWaterData
    },

    waterConsumptionBenchmark (): number|null {
      return this.$store.getters['water_consumption/getWaterConsumptionBenchmark']
    },
    waterConsumptionBenchmarkColor (): string|null {
      return this.$store.getters['water_consumption/getWaterConsumptionBenchmarkColor']
    },
    year (): number {
      return this.$store.getters['water_consumption/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['water_consumption/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['water_consumption/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['water_consumption/hasPrevious']
    },
    month (): number {
      return this.$store.getters['water_consumption/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['water_consumption/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
    },
    timeseries (): any[] {
      const timeseriesData: any = {
        data: []
      }

      if (this.freshWater !== null && this.freshWaterRelative) {
        timeseriesData.data.push({
          name: `${this.$t('water_consumption.fresh_water')}: ${this.freshWaterRelative}%`,
          y: parseInt(this.freshWaterRelative),
          unit: 'l',
          absValue: formatValue(this.freshWater, 2)
        })
      }

      if (this.grayWater !== null && this.grayWaterRelative) {
        timeseriesData.data.push({
          name: `${this.$t('water_consumption.grey_water')}: ${this.grayWaterRelative}%`,
          y: parseInt(this.grayWaterRelative),
          unit: 'l',
          absValue: formatValue(this.grayWater, 2)
        })
      }

      if (this.rainWater !== null && this.rainWaterRelative) {
        timeseriesData.data.push({
          name: `${this.$t('water_consumption.rain_water')}: ${this.rainWaterRelative}%`,
          y: parseInt(this.rainWaterRelative),
          unit: 'l',
          absValue: formatValue(this.rainWater, 2)
        })
      }

      return [timeseriesData]
    },
    waterConsumptionBenchmarkZones (): TBenchmarkZones|null {
      return this.$store.getters['water_consumption/getWaterConsumptionBenchmarkZones']
    },
    waterConsumptionSetValue (): number|null {
      return this.$store.getters['water_consumption/getTargetWaterConsumptionValue']
    },
    benchmark (): any {
      return this.waterConsumptionSetValue !== null && this.waterConsumptionBenchmarkZones ? {
        title: this.$t('water_consumptions.benchmark.title', { year: moment().year() }),
        subtitle: `<span class="hc-cat-title">${this.$t('water_consumption.benchmark.title', { year: moment().year() })}</span><br><b>l/(m<sup>2</sup>a)</b>`,
        setValue: this.waterConsumptionSetValue,
        projectionValue: this.waterConsumptionProjection ? parseFloat(this.waterConsumptionProjection.toFixed(0)) : null,
        isValue: this.waterConsumptionBenchmark !== null ? parseFloat(this.waterConsumptionBenchmark.toFixed(0)) : null,
        isColor: this.waterConsumptionBenchmarkColor,
        unit: 'l/(m2a)',
        seriesName: this.$t('water_consumption.per_squared_meters_yearly'),
        zones: this.waterConsumptionBenchmarkZones
      } : null
    }
  }
})
