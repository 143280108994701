import Vue from 'vue'
import { ActionTree } from 'vuex'
import { TRootState } from '@/store/types'
import { TCompanyState } from './types'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import { TCompanyAvatarUplaodResponse, TCompanyUpdateResponse } from '@/services/aedifion/resources/company/responseTypes'

// Load the aedifionApiRepository for companies
import aedifionApiRepository from '@/services/aedifion'
import { TCompanyUpdateRequest } from '@/services/aedifion/resources/company/requestTypes'
import i18n from '@/plugins/i18n'
const Company: IRepository = aedifionApiRepository.get('company')

export default {
  update: async ({ commit, rootState }, companyPayload: TCompanyUpdateRequest) => {
    const token = rootState.auth.access_token

    commit('SET_COMPANY_LOADING', true)
    try {
      const companyResponse: AxiosResponse<TCompanyUpdateResponse> = await Company.update({
        token,
        body: companyPayload
      })
      const company: TCompanyUpdateResponse = companyResponse.data
      commit('SET_COMPANY', company.resource)
      Vue.notify({
        text: i18n.t('account.company.update.success') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
    } catch (error) {
      console.error(error)
      if (error.code && error.code !== 401) {
        Vue.notify({
          text: i18n.t('account.company.update.error') as string,
          group: 'requests',
          duration: 6000,
          type: 'error'
        })
      }
      throw error
    } finally {
      commit('SET_COMPANY_LOADING', false)
    }
  },
  uploadAvatar: async ({ commit, rootState }, formData: FormData) => {
    const token = rootState.auth.access_token

    commit('SET_COMPANY_LOADING', true)
    try {
      const companyAvatarResponse: AxiosResponse<TCompanyAvatarUplaodResponse> = await Company.uploadAvatar({
        token,
        body: formData
      })
      const copmany: TCompanyAvatarUplaodResponse = companyAvatarResponse.data
      commit('SET_COMPANY_AVATAR', copmany.resource.avatar_url)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('SET_COMPANY_LOADING', false)
    }
  }
} as ActionTree<TCompanyState, TRootState>
