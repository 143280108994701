













































































































































































































































import Vue from 'vue'
import Card from '@/components/ui/Card.vue'
import CardLegendItem from '@/components/ui/CardLegendItem.vue'
import ProjectCard from '@/components/ui/ProjectCard.vue'
import mapboxgl, { Marker, Popup } from 'mapbox-gl'
import values from 'lodash.values'
import { TComponentInProjectWithContextResponse, TProject, TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import { TProjectKpiTable } from '@/store/components/types'
import StatusCard from '@/components/ui/StatusCard.vue'
import { formatValue } from '@/filters/formatting'

mapboxgl.accessToken = window.secrets.MAPBOX_TOKEN

export default Vue.extend({
  name: 'Portfolio',
  data () {
    return {
      unsubscribeStore: null,
      map: null,
      mapMarkers: [],
      mapboxLoaded: false,
      page: 1,
      search: '',
      kpi: {
        headers: [
          {
            text: this.$t('portfolio.kpis.headers.project'),
            align: 'start',
            sortable: true,
            value: 'name'
          },
          {
            text: this.$t('portfolio.kpis.headers.incidentscount'),
            align: 'end',
            value: 'incidents'
          },
          {
            text: this.$t('portfolio.kpis.headers.energyheat'),
            align: 'end',
            value: 'consumptionHeat'
          },
          {
            text: this.$t('portfolio.kpis.headers.energyelectricity'),
            align: 'end',
            value: 'consumptionElectricity'
          },
          {
            text: this.$t('portfolio.kpis.headers.powergeneration'),
            align: 'end',
            value: 'powerGeneration'
          },
          {
            text: 'CO<sub>2</sub>/m<sup>2</sup>',
            align: 'end',
            value: 'co2',
            width: '10em'
          },
          {
            text: this.$t('portfolio.kpis.headers.waterperperson'),
            align: 'end',
            value: 'waterPerPerson'
          },
          {
            text: this.$t('portfolio.kpis.headers.waterpersquaredmeter'),
            align: 'end',
            value: 'waterPerSquaredMetersPerYear',
            width: '10em'
          },
          {
            text: this.$t('portfolio.kpis.headers.elevatorsavailability'),
            align: 'end',
            value: 'elevatorsAvailability'
          }
        ]
      },
      criticality: null
    } as {
      unsubscribeStore: any;
      map: any;
      mapMarkers: Marker[];
      mapboxLoaded: boolean;
      page: number;
      search: string | null;
      kpi: {
        headers: Array<{ text?: string; align: string; value?: string; width?: string }>;
      };
      criticality: string|null;
    }
  },
  mounted () {
    this.renderMap()

    if (this.projects.length > 0 && this.kpitables.length < this.projects.length) {
      this.fetchBuildingComponents()
    }

    this.unsubscribeStore = this.$store.subscribe((mutation) => {
      if (mutation.type === 'projects/SET_PROJECTS') {
        this.addMarkers(mutation.payload.projects.map((project: TProjectWithContext) => {
          return project.project
        }))

        this.fetchBuildingComponents(mutation.payload.projects)
      }
    })
    this.map.on('idle', () => {
      const markersRendered = this.markersRendered(this.$store.getters['projects/getProjects'])

      if (this.mapMarkers.length < markersRendered.length) {
        this.addMarkers(this.$store.getters['projects/getProjects'])
      }
      this.map.resize()
      this.mapboxLoaded = true
    })

    if (this.$route.query.error !== undefined) {
      this.$notify({
        title: this.$t('errors.digital_twin_missing') as string,
        text: this.$t('errors.project_not_fully_configured') as string,
        group: 'all',
        duration: 12000,
        type: 'warning'
      })
    }
  },
  destroyed () {
    if (this.unsubscribeStore !== null) {
      this.unsubscribeStore()
    }
  },
  components: {
    Card,
    CardLegendItem,
    ProjectCard,
    StatusCard
  },
  computed: {
    projects (): TProject[] {
      const projects: TProject[] = this.$store.getters['projects/getProjects']
      const redProjects: TProject[] = []
      const yellowProjects: TProject[] = []
      const greenProjects: TProject[] = []
      const greyProjects: TProject[] = []
      projects.forEach((project: TProject) => {
        const projectColor: string = this.getProjectsBenchmarkSum(project.id)
        switch (projectColor) {
          case 'error':
            redProjects.push(project)
            break
          case 'warning':
            yellowProjects.push(project)
            break
          case 'success':
            greenProjects.push(project)
            break
          default:
            greyProjects.push(project)
        }
      })
      let returnProjectsSorted: TProject[] = []
      switch (this.criticality) {
        case null:
          returnProjectsSorted = projects
          break
        case 'error':
          returnProjectsSorted = redProjects
          break
        case 'warning':
          returnProjectsSorted = yellowProjects
          break
        case 'secondary':
          returnProjectsSorted = greenProjects
          break
        default:
          returnProjectsSorted = projects
          break
      }
      return returnProjectsSorted.filter((project: TProject) => !!project.name.toLowerCase().includes(this.search !== null ? this.search : ''))
    },
    areProjectsLoading (): boolean {
      return this.$store.getters['projects/areProjectsLoading']
    },
    areKpiTablesLoading (): boolean {
      return this.$store.getters['components/areKpiTablesLoading']
    },
    paginatedProjects (): TProject[] {
      return this.projects.slice((this.itemsPerPageByBreakpoint * this.page) - this.itemsPerPageByBreakpoint, this.itemsPerPageByBreakpoint * this.page)
    },
    itemsPerPageByBreakpoint (): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 2
        case 'sm': return 4
        case 'md': return 6
        case 'lg': return 6
        case 'xl': return 8
        default: return 2
      }
    },
    areComponentsForProjectsLoading (): boolean {
      return this.$store.getters['components/areComponentsLoadingForAllProjects']
    },
    digitalTwins (): { [project_id: number]: TComponentInProjectWithContextResponse } {
      return this.$store.getters['components/getDigitalTwins']
    },
    kpitables (): TProjectKpiTable[] {
      return this.$store.getters['components/getKpiTables']
    },
    kpiTablesLoading (): boolean {
      return this.$store.getters['components/arekpiTablesLoading']
    },
    energyConsumptionPortfolio (): number|null {
      const value = this.$store.getters['app/portfolioEnergyConsumption']
      const unit = this.$store.getters['app/portfolioEnergyConsumptionUnit']
      if (unit === 'MWh' && value !== null) {
        return value * 1000.0
      }
      return value
    },
    co2EmissionsPortfolio (): number|null {
      const value = this.$store.getters['app/portfolioCo2Emissions']
      const unit = this.$store.getters['app/portfolioCo2EmissionsUnit']
      if (unit === 'kg' && value !== null) {
        return value / 1000.0
      }
      return value
    },
    portfolioCo2EmissionsUnit (): string|null {
      const unit = this.$store.getters['app/portfolioCo2EmissionsUnit']
      if (unit === 'kg') return 't'
      return unit
    },
    portfolioEnergyConsumptionUnit (): string|null {
      const unit = this.$store.getters['app/portfolioEnergyConsumptionUnit']
      if (unit === 'MWh') return 'kWh'
      return unit
    }
  },
  methods: {
    selectCriticality (crit: string) {
      if (this.criticality === crit) this.criticality = null
      else this.criticality = crit

      this.clearMarkers()
      this.addMarkers(this.projects)
    },
    markersRendered (projects: TProject[]): TProject[] {
      return projects.filter((project: TProject) => !!project.latitude && !!project.longitude)
    },
    renderMap (): void {
      this.map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [9.97897026854696, 53.55375381277756],
        zoom: 9
      })
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      )
      this.map.addControl(new mapboxgl.FullscreenControl())
    },
    fetchBuildingComponents (projects?: TProjectWithContext[]) {
      if (projects) {
        this.$store.dispatch('components/fetchBuildingComponentsForAllProjects', projects.map((project: TProjectWithContext) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return project.project!.id
        }))
      } else {
        const availableKpiTableProjectIDs: number[] = this.kpitables.map((kpiTable: TProjectKpiTable) => kpiTable.project_id)
        this.$store.dispatch(
          'components/fetchBuildingComponentsForAllProjects',
          this.projects
            .map((project: TProject) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return project.id
            })
            .filter((projectID: number) => !availableKpiTableProjectIDs.includes(projectID))
        )
      }
    },
    searchProjects (searchString: string) {
      this.search = searchString.toLowerCase()
    },
    clearMarkers (): void {
      this.mapMarkers.map((marker: Marker) => {
        marker.remove()
      })
    },

    addMarkers (projects: TProject[]): void {
      this.clearMarkers()

      const popup: Popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'rom__map__marker--popup'
      })

      const createMapMarkerForProject = (project: TProject) => {
        const marker = new mapboxgl.Marker({
          color: '#288946'
        })
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .setLngLat([parseFloat(project.longitude!), parseFloat(project.latitude!)])
          .addTo(this.map)

        marker.getElement().style.cursor = 'pointer'

        marker.getElement().addEventListener('click', () => {
          this.$router.push(`/project/${project.id}`)
        })

        marker.getElement().addEventListener('mouseenter', () => {
          popup.setText(project.name).addTo(this.map)
          marker.setPopup(popup)
        })

        marker.getElement().addEventListener('mouseleave', () => {
          marker.setPopup()
        })
        this.mapMarkers.push(marker)
      }
      const projectsWithCoordinates = this.markersRendered(projects) as TProject[]
      projectsWithCoordinates.forEach(createMapMarkerForProject)
    },

    energyBenchmarkColor (project_id: number): string {
      const kpis: TProjectKpiTable|undefined = this.kpitables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === project_id)
      if (kpis === undefined) return 'info'
      if (kpis.benchmarks.electricity_consumption === 'red' || kpis.benchmarks.heat_consumption === 'red') return 'error'
      else if (kpis.benchmarks.electricity_consumption === 'warning' || kpis.benchmarks.heat_consumption === 'warning') return 'warning'
      else if (kpis.benchmarks.electricity_consumption === 'green' || kpis.benchmarks.heat_consumption === 'green') return 'success'
      else if (kpis.benchmarks.electricity_consumption === 'grey' || kpis.benchmarks.heat_consumption === 'grey') return 'grey'
      else return 'info'
    },

    co2BenchmarkColor (project_id: number): string {
      const kpis: TProjectKpiTable|undefined = this.kpitables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === project_id)
      if (kpis === undefined) return 'info'
      if (kpis.benchmarks.co2_emissions === 'green') return 'success'
      else if (kpis.benchmarks.co2_emissions === 'warning') return 'warning'
      else if (kpis.benchmarks.co2_emissions === 'red') return 'error'
      else if (kpis.benchmarks.co2_emissions === 'grey') return 'grey'
      else return 'info'
    },

    getProjectsBenchmarkSum (project_id: number): string {
      const kpis: TProjectKpiTable|undefined = this.kpitables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === project_id)
      if (kpis) {
        let redSum = 0
        let yellowSum = 0
        let greenSum = 0
        values(kpis.benchmarks).forEach((benchmark: any) => {
          if (benchmark === 'red') {
            redSum++
          } else if (benchmark === 'yellow') {
            yellowSum++
          } else if (benchmark === 'green') {
            greenSum++
          }
        })
        if (redSum > 0) {
          return 'error'
        } else if (yellowSum > 0) {
          return 'warning'
        } else if (greenSum > 0) {
          return 'success'
        } else {
          return 'grey'
        }
      } else {
        return 'grey'
      }
    },
    withoutComma (value: string): string {
      return value !== null ? formatValue(parseFloat(value), 0) : this.$t('portfolio.kpis.data.no_data_available') as string
    }
  }
})
