import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import { TRootState } from '../types'
import { TEnergyConsumptionState } from './types'
import get from 'lodash.get'
import moment, { Moment } from 'moment'
import { TAssignedPin } from '@/services/aedifion/resources/project/responseTypes'
import { getEndDate, getStartDate } from '@/utils/helpers/dates'
import { computeTotalConsumptionValue, convertObservationsToTimeseries } from '@/utils/helpers/timeseries'
import i18n from '@/plugins/i18n'
import { TimeseriesWithContext, aedifionApi } from '@aedifion.io/aedifion-api'

export default {
  fetchData: async ({ commit, state, rootState, rootGetters }) => {
    commit('SET_LOADING_DATA', true)
    const project_id = rootGetters['project/getProjectID']
    const month: number = get(state, 'month', moment().month())
    const year: number = get(state, 'year', moment().year())
    const hasYear = state.isYearSelected
    const heatDatapoint: TAssignedPin|undefined = rootState.project.digitalTwin?.pins.find((pin: TAssignedPin) =>
      pin.alphanumeric_id === 'B+EN_H_CONSUM_SUM'
    )
    const electricityDatapoint: TAssignedPin|undefined = rootState.project.digitalTwin?.pins.find((pin: TAssignedPin) =>
      pin.alphanumeric_id === 'B+ELS+EN_EL_CONSUM_SUM'
    )
    if (!heatDatapoint && !electricityDatapoint) {
      commit('SET_LOADING_DATA', false)
      return null
    }
    const datapoints: string[] = []
    if (heatDatapoint && heatDatapoint.dataPointID) datapoints.push(heatDatapoint.dataPointID)
    if (electricityDatapoint && electricityDatapoint.dataPointID) datapoints.push(electricityDatapoint.dataPointID)
    try {
      const timeseriesResponse = await Promise.all([
        aedifionApi.Project.getProjectTimeseries(
          project_id,
          datapoints,
          new Date(getStartDate(hasYear, year, month)),
          new Date(getEndDate(hasYear, year, month)),
          undefined,
          '1d',
          undefined,
          undefined,
          false,
          true,
          'metric',
          'EUR',
          datapoints.map(() => 'kilowatt-hours')
        ),
        aedifionApi.Project.getProjectTimeseries(
          project_id,
          datapoints,
          undefined,
          new Date(getEndDate(hasYear, year, month, hasYear, !hasYear)),
          1,
          undefined,
          'previous',
          undefined,
          true
        ),
        aedifionApi.Project.getProjectTimeseries(
          project_id,
          datapoints,
          new Date(getStartDate(hasYear, year, month, false, false, hasYear, !hasYear)),
          new Date('2100-01-01'),
          undefined,
          '1d',
          'none',
          undefined,
          true,
          false
        )
      ])

      const hasPreviousData: boolean = get(timeseriesResponse[1], `${heatDatapoint?.dataPointID}`, []).length > 0 ||
        get(timeseriesResponse[1], `${electricityDatapoint?.dataPointID}`, []).length > 0

      const hasNextData: boolean = get(timeseriesResponse[2], `${heatDatapoint?.dataPointID}`, []).length > 0 ||
        get(timeseriesResponse[2], `${electricityDatapoint?.dataPointID}`, []).length > 0

      const heatConsumptionTimeseries: TimeseriesWithContext|null = timeseriesResponse[0].find((response: TimeseriesWithContext) => {
        return response.dataPointID === heatDatapoint?.dataPointID
      }) || null

      const electricityConsumptionTimeseries: TimeseriesWithContext|null = timeseriesResponse[0].find((response: TimeseriesWithContext) => {
        return response.dataPointID === electricityDatapoint?.dataPointID
      }) || null

      const heatConsumption = computeTotalConsumptionValue(
        convertObservationsToTimeseries(
          heatConsumptionTimeseries?.data ?? []
        )
      )

      const electricityConsumption = computeTotalConsumptionValue(
        convertObservationsToTimeseries(
          electricityConsumptionTimeseries?.data ?? []
        )
      )

      commit('SET_DATA', {
        heatConsumption: heatConsumption || null,
        electricityConsumption: electricityConsumption || null,
        hasPreviousData,
        hasNextData
      })
    } catch (error) {
      Vue.notify({
        text: 'Could not load energy consumption data',
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return error
    } finally {
      commit('SET_LOADING_DATA', false)
    }
  },
  switchChartToMonth: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', false)
    dispatch('fetchData')
  },
  switchChartToYear: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', true)
    dispatch('fetchData')
  },
  selectPreviousYear: ({ state, commit, dispatch }) => {
    const previous: number = get(state, 'year', moment().year()) - 1
    commit('SWITCH_YEAR', previous)
    dispatch('fetchData')
  },
  selectPreviousMonth: ({ state, commit, dispatch }) => {
    const previous: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).subtract(1, 'month')
    commit('SWITCH_MONTH', previous.month())
    commit('SWITCH_YEAR', previous.year())
    dispatch('fetchData')
  },
  selectNextYear: ({ state, commit, dispatch }) => {
    const next: number = get(state, 'year', moment().year()) + 1
    commit('SWITCH_YEAR', next)
    dispatch('fetchData')
  },
  selectNextMonth: ({ state, commit, dispatch }) => {
    const next: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).add(1, 'month')
    commit('SWITCH_MONTH', next.month())
    commit('SWITCH_YEAR', next.year())
    dispatch('fetchData')
  }
} as ActionTree<TEnergyConsumptionState, TRootState>
