import get from 'lodash.get'
import { IRepository, IRepositoryFactory } from '@/utils/types/repository'

// Import the resources
import CurrentRepository from './resources/current'

const repositories: { [key: string]: IRepository } = {
  current: CurrentRepository
}

export default {
  get: (name: string): IRepository => {
    return get(repositories, name)
  }
} as IRepositoryFactory
