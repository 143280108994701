




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import nodata from 'highcharts/modules/no-data-to-display'
import i18n from '../../../plugins/i18n'

nodata(Highcharts)

export default Vue.extend({
  name: 'LineChart',
  components: {
    highcharts: Chart
  },
  beforeCreate () {
    /**
     * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
     * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
     */
    Highcharts.setOptions({
      lang: {
        loading: this.$t('data.loading') as string,
        noData: this.$t('data.not_available') as string
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      }
    })
  },
  props: {
    title: {
      required: false,
      type: String as PropType<string>
    },
    series: {
      required: true,
      type: Array as PropType<Array<any>>
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableLegend: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableTitle: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    xPlotLines: {
      required: false,
      type: Array as PropType<Array<any>>,
      default: null
    },
    yAxisText: {
      required: false,
      type: String as PropType<string>
    },
    yPlotLines: {
      required: false,
      type: Array as PropType<Array<any>>,
      default: null
    },
    yRange: {
      required: false,
      type: Array as PropType<Array<number>>,
      default: null
    },
    setStep: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: true
    },
    type: {
      default: 'datetime',
      required: false,
      type: String as PropType<string>
    },
    noDataMessageKey: {
      default: 'data.not_available',
      required: false,
      type: String as PropType<string>
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal === true) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  computed: {
    chartOptions () {
      return {
        noData: {
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        loading: {
          style: {
            fontFamily: 'myriad-pro',
            fontWeight: 'bold',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        chart: {
          type: 'line',
          step: true,
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E'
          }
        },
        plotOptions: {
          series: {
            lineWidth: 2
          }
        },
        legend: {
          enabled: !this.disableLegend,
          itemStyle: {
            color: '#004B8E',
            fontWeight: 'semibold'
          }
        },
        colors: [
          '#004B8E',
          '#638A82',
          '#936362',
          '#9C9770',
          '#AEBFC9',
          '#931322',
          '#D4A92A'
        ],
        credits: {
          enabled: false
        },
        title: {
          text: this.title ? this.title : null,
          style: {
            color: '#004B8E'
          }
        },
        xAxis: {
          type: this.type,
          categories: this.type === 'category' ? i18n.t('energy_management.meters.chart.shortMonths') : undefined,
          min: this.type === 'category' ? 0 : undefined,
          max: this.type === 'category' ? 11 : undefined,
          labels: {
            style: {
              fontFamily: 'myriad-pro',
              color: '#004B8E'
            }
          },
          plotLines: this.xPlotLines
        },
        yAxis: {
          title: {
            enabled: !this.disableTitle,
            text: this.yAxisText ?? i18n.t('chart.y_axis'),
            style: {
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              color: '#004B8E'
            }
          },
          plotLines: this.yPlotLines,
          min: this.yRange ? this.yRange[0] : null,
          max: this.yRange ? this.yRange[1] : null
        },
        series: this.series,
        lang: {
          noData: i18n.t(this.noDataMessageKey) as string
        }
      }
    }
  }
})
