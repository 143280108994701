import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import en from 'vuetify/src/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      expand: 'fal fa-angle-up'
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#003463',
        secondary: '#288970',
        accent: '#288946',
        error: '#931322',
        info: '#618197',
        success: '#288970',
        warning: '#D4A92A'
      }
    }
  },
  lang: {
    locales: { en, de },
    current: 'de'
  }
})
