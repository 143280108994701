import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TUserAvatarUplaodResponse, TUserUpdatePasswordResponse, TUserUpdateResponse, TUserWithContext } from './responseTypes'
import { TUserUpdatePasswordRequest, TUserUpdateRequest } from './requestTypes'
import { TProjectWithContext } from '../project/responseTypes'

const resource = 'user'

export default {
  get: (request: {
    token: string|null;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TUserWithContext>> => {
    return axiosInstance.get(`/${resource}`, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getProjects: (request: {
    token: string|null;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TProjectWithContext>> => {
    return axiosInstance.get(`/${resource}/projects`, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  update: (request: {
    token: string|null;
    body: TUserUpdateRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TUserUpdateResponse>> => {
    return axiosInstance.put(`/${resource}`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  uploadAvatar: (request: {
    token: string|null;
    body: FormData;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TUserAvatarUplaodResponse>> => {
    return axiosInstance.post(`/${resource}/avatar`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updatePassword: (request: {
    token: string|null;
    body: TUserUpdatePasswordRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TUserUpdatePasswordResponse>> => {
    const formData: FormData = new FormData()
    formData.append('current_password', request.body.current_password)
    formData.append('new_password', request.body.new_password)
    return axiosInstance.put(`/${resource}/password`, formData, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
} as IRepository
