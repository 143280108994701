/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TComponentsInProjectRequest } from '@/services/aedifion/resources/project/requestTypes'
import {
  TComponentAttribute,
  TComponentInProjectWithContextResponse,
  TComponentsInProjectResponse,
  TTimeseriesShort
} from '@/services/aedifion/resources/project/responseTypes'
import { IRepository } from '@/utils/types/repository'
import { TKpiPinDataPointId } from '@/utils/types/kpipindatapointid'
import { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { FetchEnergyGenerationProjectionsPayload, TComponentsState, TProjectKpiTable } from './types'
import aedifionApiRepository from '@/services/aedifion'
import {
  getLastValue,
  computeTotalConsumptionValue,
  convertObservationsToTimeseries,
  extractKPIValueFromTimeseries
} from '@/utils/helpers/timeseries'
import {
  calculateAllBenchmarks
} from '@/utils/helpers/benchmarks'
import moment, { Moment } from 'moment'
import get from 'lodash.get'
import { TBenchmarks } from '@/utils/helpers/types'
import { computeAllFaults, getAvailableFaultSources } from '@/utils/helpers/faults'
import {
  calculateCo2PerSquaredMeterEmissions,
  calculateEnergyGeneration,
  calculateWaterConsumptionPerPerson,
  calculateWaterConsumptionPerSquaredMeters,
  getPinDataPointIdsForKPIs
} from '@/utils/helpers/kpis'
import { InstanceConfig, TGetAnalyticsInstancesResponse, AnalysisFunction, TGetAnalyticsInstanceResultsResponse, AnalysisResultSummery } from '@/services/aedifion/resources/analytics/responseTypes'
import { TimeseriesWithContext, aedifionApi } from '@aedifion.io/aedifion-api'
import i18n from '@/plugins/i18n'
import Vue from 'vue'

const Projects: IRepository = aedifionApiRepository.get('projects')
const Analytics: IRepository = aedifionApiRepository.get('analytics')

function extractLastKPIValueFromTimeseriesWithContext (timeseries: TimeseriesWithContext[], datapointId: string): number|null {
  const timeseriesData = timeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === datapointId) ?? null
  const timeseriesShort: [string, number|null][]|null = timeseriesData !== null ? convertObservationsToTimeseries(timeseriesData.data!) : null
  return timeseriesShort !== null ? getLastValue(timeseriesShort) : null
}

function extractDatapointsIDsFromDatapointsMap (datapointsMap: Record<string, string>, pinDataPointsMap: { [alphanumeric_id: string]: string }) {
  return Object.values(datapointsMap).map((datapointId: string) => pinDataPointsMap[datapointId] ?? null).filter((datapointId: string|null) => datapointId !== null)
}

type ErrorResponse = {
  error: string;
  operation: string;
  success: boolean;
}

async function fetchKPITimeseries (
  project_id: number,
  dataPointIDs: string[],
  unit: string
): Promise<TimeseriesWithContext[]> {
  let timeseries: TimeseriesWithContext[] = []

  try {
    timeseries = await aedifionApi.Project.getProjectTimeseries(
      project_id,
      dataPointIDs,
      new Date(moment().startOf('year').format()),
      new Date(moment().endOf('year').format()),
      undefined,
      '1d',
      undefined,
      undefined,
      false,
      true,
      'metric',
      'EUR',
      dataPointIDs.map(() => unit)
    )
  } catch (error) {
    const jsonedError = await (error as Response).json() as ErrorResponse
    let errorMessage
    if (jsonedError.error.startsWith('Cannot convert')) {
      errorMessage = `${i18n.t('errors.project_timeseries_unit_not_correctly_configured', { id: project_id })}`
    } else {
      errorMessage = `${i18n.t('datapoints.notBeFetched', { id: project_id })}`
    }

    Vue.notify({
      text: errorMessage,
      group: 'all',
      duration: 6000,
      type: 'warning'
    })
    console.error({
      error: jsonedError,
      message: errorMessage
    })
  }

  return timeseries
}

const energyGenerationDatapointsMap = {
  heatGeneration: 'B+HS+EN_H_GEN_SUM',
  electricityGeneration: 'B+ELS+EN_EL_GEN_SUM',
  boilerHeatGeneration: 'B+HS+BOI_EN_H_GEN_SUM',
  chpHeatGeneration: 'B+HS+CHP_EN_H_GEN_SUM',
  heatPumpHeatGeneration: 'B+HS+HP_EN_H_GEN_SUM',
  solarHeatGeneration: 'B+HS+SOL_EN_H_GEN_SUM',
  chpElectricityGeneration: 'B+HS+CHP_EN_EL_GEN_SUM',
  photovoltaikElectricityGeneration: 'B+ELS+PV_EL_GEN_SUM',
  districtHeatGeneration: 'B+H_DISCT+EN_H_CONSUM'
} as const

const energyConsumptionDatapointsMap = {
  heatConsumption: 'B+EN_H_CONSUM_SUM',
  heatConsumptionProjection: 'B+EN_H_CONSUM_SUM_PRJ',
  electricityConsumption: 'B+ELS+EN_EL_CONSUM_SUM',
  electricityConsumptionProjection: 'B+ELS+EN_EL_CONSUM_SUM_PRJ'
} as const

const co2EmissionsDatapointsMap = {
  electricityCo2Emissions: 'B+EL_CO2_EMI',
  heatCo2Emissions: 'B+H_CO2_EMI_SUM',
  co2AvoidancePhotovoltaik: 'B+ELS+PV_CO2_EMI_AVD',
  co2AvoidanceSolar: 'B+HS+SOL_CO2_EMI_AVD',
  co2AvoidanceCHP: 'B+HS+CHP_CO2_EMI_AVD_SUM',
  co2AvoidanceHeatPump: 'B+HS+HP_CO2_EMI_AVD_SUM',
  co2EmissionHeatProjection: 'B+H_CO2_EMI_SUM_PRJ',
  co2EmissionElectricityProjection: 'B+EL_CO2_EMI_PRJ'
} as const

const waterConsumptionDatapointsMap = {
  freshWaterConsumption: 'B+WAS+WS_FRESH_CONSUM',
  freshWaterConsumptionProjection: 'B+WAS+WS_FRESH_CONSUM_PRJ',
  grayWaterConsumption: 'B+WAS+WS_GRAY_CONSUM',
  grayWaterConsumptionProjection: 'B+WAS+WS_GRAY_CONSUM_PRJ',
  rainWaterConsumption: 'B+WAS+RAIN_WS_CONSUM',
  rainWaterConsumptionProjection: 'B+WAS+RAIN_WS_CONSUM_PRJ'
} as const

export default {
  fetchElevatorsAvailibility: async ({ commit, rootState, rootGetters }, project_id: number): Promise<number|null> => {
    const token = rootGetters['auth/oidcAccessToken']
    // fetch elevators to see if a result can be fetched
    try {
      const elevatorsResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params: {
          page: 1,
          per_page: 100,
          filter: 'alphanumeric_id=ELE'
        }
      })
      if (elevatorsResponse.data.items.length === 0) return null
      // fetch all instances
      const instanceResults: AxiosResponse<TGetAnalyticsInstancesResponse> = await Analytics.getInstances({
        token,
        params: {
          project_id
        }
      })
      const instances: InstanceConfig[] = instanceResults.data
      if (instances.length === 0) return null

      // get the elevators analysis function ID
      const elevatorsAnalysisFunction: AnalysisFunction|undefined = rootState.elevators.functions.find((analysisFunction: AnalysisFunction) => analysisFunction.alphanumeric_id === 'elevators_operating_time_analysis')
      if (elevatorsAnalysisFunction === undefined) return null

      // get the elvators analysis instance
      const elevatorsAnalysisInstance: InstanceConfig|undefined = instances.find((instance: InstanceConfig) => instance.config.analysisfunction_id === elevatorsAnalysisFunction.id)
      if (elevatorsAnalysisInstance === undefined) return null

      const analysisFunctionResults: AxiosResponse<TGetAnalyticsInstanceResultsResponse> = await Analytics.getInstanceResults({
        token,
        id: elevatorsAnalysisInstance.id,
        params: {
          project_id
        }
      })
      const results = analysisFunctionResults.data
      if (results.length === 0) return null

      let resultIdForCurrentYear: string|null = null
      const yearlyResults: Map<string, AnalysisResultSummery> = new Map()
      results.filter((result: AnalysisResultSummery) => result.status === 'Success.').forEach((result: AnalysisResultSummery) => {
        const startDate: Moment = moment(result.input_parameters.start)
        const endDate: Moment = moment(result.input_parameters.end)
        if ((startDate.date() === 1) && (startDate.month() === 0) && (startDate.year() === endDate.year())) {
          const year: string = startDate.format('YYYY')
          if (yearlyResults.has(year)) {
            const lastInput: AnalysisResultSummery|undefined = yearlyResults.get(year)
            if (lastInput !== undefined && moment(lastInput.input_parameters.end).isBefore(moment(result.input_parameters.end))) {
              yearlyResults.delete(year)
              yearlyResults.set(year, result)
            }
          } else {
            yearlyResults.set(year, result)
          }
        }
      })
      const currentYear: string = moment().year().toString()
      resultIdForCurrentYear = yearlyResults.get(currentYear)!.result_id || null

      if (resultIdForCurrentYear === null) return null

      // fetch the result for the current year
      const elevatorAnalysisResponse: AxiosResponse<any> = await Analytics.getResult({
        token,
        id: elevatorsAnalysisInstance.id,
        second_level_id: resultIdForCurrentYear,
        params: {
          project_id,
          result_language: 'en'
        }
      })
      return elevatorAnalysisResponse.data.kpi[1].value
    } catch (error) {
      return null
    }
  },
  fetchBuildingComponentForProject: async ({ commit, rootState }, project_id: number): Promise<TComponentInProjectWithContextResponse|boolean> => {
    const token = rootState.auth.access_token
    commit('SET_COMPONENTS_IN_PROJECT_LOADING', true)
    try {
      if (project_id === null) {
        throw new Error(`Project with ID ${project_id} not found.`)
      }

      // fetch the building component for the selected project
      const componentInProjectResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params: {
          page: 1,
          per_page: 1,
          search: 'building'
        }
      })

      // check if the component has results
      if (componentInProjectResponse.data.items.length === 0) {
        commit('SET_COMPONENTS_IN_PROJECT_LOADING', false)
        return false
      }

      // now fetch the digitalTwin
      const digitalTwin: AxiosResponse<TComponentInProjectWithContextResponse> = await Projects.getComponentInProject({
        token,
        id: project_id,
        second_level_id: componentInProjectResponse.data.items[0].id
      })

      // now save it in the store
      commit('SET_DIGITAL_TWIN_COMPONENT_FOR_PROJECT', { project_id: project_id, digitalTwin: digitalTwin.data })
      return digitalTwin.data
    } catch (error) {
      commit('SET_COMPONENTS_IN_PROJECT_LOADING', false)
      return false
    } finally {
      commit('SET_COMPONENTS_IN_PROJECT_LOADING', false)
    }
  },
  fetchBuildingComponentsForAllProjects: async ({ commit, rootState, dispatch }, projects: number[]) => {
    const token = rootState.auth.access_token
    commit('SET_COMPONENTS_IN_PROJECTS_LOADING', true)
    try {
      // Fetch building components for every project that was passed
      const componentsInProjectsResponse: AxiosResponse<TComponentsInProjectResponse>[] = await Promise.all(projects.map(async (project_id: number) => {
        return await Projects.getComponentsInProject({
          token,
          id: project_id,
          params: {
            page: 1,
            per_page: 1,
            search: 'building'
          }
        })
      }))

      // Now fetch the component for every project
      const digitalTwinComponentsPerProjectResponse: AxiosResponse<TComponentInProjectWithContextResponse>[] = await Promise.all(
        componentsInProjectsResponse
          .filter((componentsResponse: AxiosResponse<TComponentsInProjectResponse>) => {
            return componentsResponse.data.items.length > 0
          })
          .map(async (componentsResponse: AxiosResponse<TComponentsInProjectResponse>) => {
            return await Projects.getComponentInProject({
              token,
              id: componentsResponse.data.items[0].project_id,
              second_level_id: componentsResponse.data.items[0].id
            })
          })
      )

      // Iterate through the results and save the digital twins in the store
      commit('SET_KPI_TABLES_LOADING', true)
      await Promise.all(
        digitalTwinComponentsPerProjectResponse.map(
          async (componentInProjectResponse: AxiosResponse<TComponentInProjectWithContextResponse>) => {
            // save the digital twin in the store
            commit('SET_DIGITAL_TWIN_COMPONENT_FOR_PROJECT', { project_id: componentInProjectResponse.data.project_id, digitalTwin: componentInProjectResponse.data })

            // fetch timeseries for kpis and compute them for every digital twin
            await dispatch('computeDigitalTwinKPIs', componentInProjectResponse.data)
          }
        )
      )
      commit('SET_KPI_TABLES_LOADING', false)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('SET_COMPONENTS_IN_PROJECTS_LOADING', false)
    }
  },

  fetchComponentsInProject: async ({ commit, rootState }, payload: {
    project_id: number;
  } & TComponentsInProjectRequest) => {
    const token = rootState.auth.access_token
    const { project_id, ...params } = payload

    commit('SET_COMPONENTS_IN_PROJECT_LOADING', true)
    try {
      const componentsInProjectResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params
      })
      const componentsInProject: TComponentsInProjectResponse = componentsInProjectResponse.data
      commit('SET_COMPONENTS_IN_PROJECT', componentsInProject.items)
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_COMPONENTS_IN_PROJECT_LOADING', false)
    }
  },

  fetchEnergyConsumptionProjections: async (_, payload: {
    project_id: number;
    heatProjectionDatapoint: string|null;
    electricityProjectionDatapoint: string|null;
    grossFloorArea: number|null;
  }) => {
    const { project_id, heatProjectionDatapoint, electricityProjectionDatapoint, grossFloorArea } = payload
    if (grossFloorArea === null || (heatProjectionDatapoint === null && electricityProjectionDatapoint === null)) {
      return {
        heatConsumptionProjection: null,
        electricityConsumptionProjection: null
      }
    }

    const datapoints = [heatProjectionDatapoint, electricityProjectionDatapoint].filter((datapoint: string|null) => datapoint !== null) as string[]

    let projectionTimeseries: TimeseriesWithContext[]

    try {
      projectionTimeseries = await fetchKPITimeseries(project_id, datapoints, 'kilowatt-hours')
    } catch (error) {
      console.error(error)
      return {
        heatConsumptionProjection: null,
        electricityConsumptionProjection: null
      }
    }

    const heatConsumptionTimeseries: TimeseriesWithContext|null = projectionTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === heatProjectionDatapoint) ?? null
    const electricityConsumptionTimeseries: TimeseriesWithContext|null = projectionTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === electricityProjectionDatapoint) ?? null

    const heatConsumptionTimeseriesShort = heatConsumptionTimeseries ? convertObservationsToTimeseries(heatConsumptionTimeseries.data!) : null
    const electricityConsumptionTimeseriesShort = electricityConsumptionTimeseries ? convertObservationsToTimeseries(electricityConsumptionTimeseries.data!) : null

    let heatConsumptionProjection = null
    let electricityConsumptionProjection = null

    if (heatConsumptionTimeseriesShort !== null) {
      heatConsumptionProjection = getLastValue(heatConsumptionTimeseriesShort)
    }

    if (electricityConsumptionTimeseriesShort !== null) {
      electricityConsumptionProjection = getLastValue(electricityConsumptionTimeseriesShort)
    }

    const heatProjectionPerSquaredMeters = heatConsumptionProjection !== null ? heatConsumptionProjection / grossFloorArea : null
    const electricityProjectionPerSquaredMeters = electricityConsumptionProjection !== null ? electricityConsumptionProjection / grossFloorArea : null

    return {
      heatConsumptionProjection: heatProjectionPerSquaredMeters,
      electricityConsumptionProjection: electricityProjectionPerSquaredMeters
    }
  },

  fetchEnergyGenerationProjections: async (_, payload: FetchEnergyGenerationProjectionsPayload) => {
    const { project_id, heatGenerationProjectionDatapoint, electricityGenerationProjectionDatapoint, grossFloorArea } = payload
    if (grossFloorArea === null || (heatGenerationProjectionDatapoint === null && electricityGenerationProjectionDatapoint === null)) {
      return {
        heatGenerationProjection: null,
        electricityGenerationProjection: null
      }
    }

    const datapoints = [heatGenerationProjectionDatapoint, electricityGenerationProjectionDatapoint].filter((datapoint: string|null) => datapoint !== null) as string[]

    let projectionTimeseries: TimeseriesWithContext[]

    try {
      projectionTimeseries = await fetchKPITimeseries(project_id, datapoints, 'kilowatt-hours')
    } catch (error) {
      console.error(error)
      return {
        heatGenerationProjection: null,
        electricityGenerationProjection: null
      }
    }

    const heatGenerationTimeseries: TimeseriesWithContext|null = projectionTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === heatGenerationProjectionDatapoint) ?? null
    const electricityGenerationTimeseries: TimeseriesWithContext|null = projectionTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === electricityGenerationProjectionDatapoint) ?? null

    const heatGenerationTimeseriesShort = heatGenerationTimeseries ? convertObservationsToTimeseries(heatGenerationTimeseries.data!) : null
    const electricityGenerationTimeseriesShort = electricityGenerationTimeseries ? convertObservationsToTimeseries(electricityGenerationTimeseries.data!) : null

    let heatGenerationProjection = null
    let electricityGenerationProjection = null

    if (heatGenerationTimeseriesShort !== null) {
      heatGenerationProjection = getLastValue(heatGenerationTimeseriesShort)
    }

    if (electricityGenerationTimeseriesShort !== null) {
      electricityGenerationProjection = getLastValue(electricityGenerationTimeseriesShort)
    }

    const heatProjectionPerSquaredMeters = heatGenerationProjection !== null ? heatGenerationProjection / grossFloorArea : null
    const electricityProjectionPerSquaredMeters = electricityGenerationProjection !== null ? electricityGenerationProjection / grossFloorArea : null

    return {
      heatGenerationProjection: heatProjectionPerSquaredMeters,
      electricityGenerationProjection: electricityProjectionPerSquaredMeters
    }
  },

  computeDigitalTwinKPIs: async ({ commit, dispatch, rootGetters }, digitalTwin: TComponentInProjectWithContextResponse) => {
    const projectName = rootGetters['projects/getProjectsName'](digitalTwin.project_id)
    const pinDataPointIds: [TKpiPinDataPointId] = getPinDataPointIdsForKPIs(digitalTwin)
    const pinDataPointMap: { [alphanumeric_id: string]: string } = {}

    pinDataPointIds.forEach(
      (dataPointPair) => {
        pinDataPointMap[dataPointPair.alphanumeric_id] = dataPointPair.dataPointID
      }
    )

    const availableFaultSources: string[] = getAvailableFaultSources(digitalTwin)

    /**
     * Get the gross floor area of the building
     * The gross floor area is an attribute in the digital twin component
     */
    const grossFloorArea: TComponentAttribute|undefined = digitalTwin.attributes.find(
      (attribute: TComponentAttribute) => attribute.alphanumeric_id === 'B_GFA_AV'
    )

    /**
     * Get the efficiency zones object for the benchmark calculation
     * The efficiency zones onject is an attribute in the digital twin component
     */
    const efficiencyZonesAttribute: TComponentAttribute|undefined = digitalTwin.attributes.find(
      (attribute: TComponentAttribute) => attribute.alphanumeric_id === 'B_EFZ'
    )

    // #region FETCH TIMESERIES FOR ALL KPIS
    const energyGenerationDatapointsIds = extractDatapointsIDsFromDatapointsMap(energyGenerationDatapointsMap, pinDataPointMap)

    const energyConsumptionDatapointsIds = extractDatapointsIDsFromDatapointsMap(energyConsumptionDatapointsMap, pinDataPointMap)

    const co2EmissionsDatapointsIds = extractDatapointsIDsFromDatapointsMap(co2EmissionsDatapointsMap, pinDataPointMap)

    const waterConsumptionDatapointsIds = extractDatapointsIDsFromDatapointsMap(waterConsumptionDatapointsMap, pinDataPointMap)

    const [
      energyGenerationTimeseries,
      energyConsumptionTimeseries,
      co2EmissionsTimeseries,
      waterConsumptionTimeseries
    ] = await Promise.all([
      fetchKPITimeseries(digitalTwin.project_id!, energyGenerationDatapointsIds, 'kilowatt-hours'),
      fetchKPITimeseries(digitalTwin.project_id!, energyConsumptionDatapointsIds, 'kilowatt-hours'),
      fetchKPITimeseries(digitalTwin.project_id!, co2EmissionsDatapointsIds, 'kilograms'),
      fetchKPITimeseries(digitalTwin.project_id!, waterConsumptionDatapointsIds, 'cubic-meters')
    ])
    // #endregion

    // #region ENERGY GENERATION
    /* ----------------------------------------------------------------- */
    /* ⚡ ENERGY GENERATION ⚡ */
    /* ----------------------------------------------------------------- */

    // compute heat generation for this year
    const heatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.heatGeneration])

    // compute electricity generation for this year
    const electricityGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.electricityGeneration])

    // compute boiler heat generation for this year
    const boilerHeatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.boilerHeatGeneration])

    // compute chp heat generation for this year
    const chpHeatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.chpHeatGeneration])

    // compute heat pump heat generation for this year
    const heatPumpHeatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.heatPumpHeatGeneration])

    // compute solar heat generation for this year
    const solarHeatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.solarHeatGeneration])

    // compute chp electricity generation for this year
    const chpElectricityGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.chpElectricityGeneration])

    // compute photovoltaik electricity generation for this year
    const photovoltaikElectricityGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.photovoltaikElectricityGeneration])

    // compute the district heat generation for this year
    const districtHeatGeneration = extractKPIValueFromTimeseries(energyGenerationTimeseries, pinDataPointMap[energyGenerationDatapointsMap.districtHeatGeneration])

    // compute the energy generation for this year
    const energyGeneration: number|null = calculateEnergyGeneration(heatGeneration, electricityGeneration)

    // energy generation per squared meters for this year
    let energyGenerationPerSquaredMeters: number|null = null
    if (grossFloorArea?.value && energyGeneration !== null) {
      energyGenerationPerSquaredMeters = energyGeneration / parseFloat(grossFloorArea.value)
    }

    let energyGenerationProjection: number|null = null

    const {
      heatGenerationProjection,
      electricityGenerationProjection
    } = await dispatch('fetchEnergyGenerationProjections', {
      project_id: digitalTwin.project_id,
      heatProjectionDatapoint: get(pinDataPointMap, 'B+HS+EN_H_GEN_SUM_PRJ', null),
      electricityProjectionDatapoint: get(pinDataPointMap, 'B+ELS+EN_EL_GEN_SUM_PRJ', null),
      grossFloorArea: grossFloorArea?.value ? parseFloat(grossFloorArea?.value) : null
    })

    if (heatGenerationProjection !== null) energyGenerationProjection = heatGenerationProjection
    if (electricityGenerationProjection !== null && energyGenerationProjection === null) energyGenerationProjection = electricityGenerationProjection
    else if (electricityGenerationProjection !== null && energyGenerationProjection !== null) energyGenerationProjection += electricityGenerationProjection

    if (grossFloorArea?.value && energyGenerationProjection !== null) {
      energyGenerationProjection = energyGenerationProjection / parseFloat(grossFloorArea.value)
    }
    // #endregion

    // #region ENERGY CONSUMPTION
    /* ----------------------------------------------------------------- */
    /* 🔋 ENERGY CONSUMPTION 🔋 */
    /* ----------------------------------------------------------------- */

    // fetch the heat consumption & electricity consumption for this year

    // get the timeseries for the heat consumption by finding the DP-ID in the response
    const heatConsumptionTimeseries: TimeseriesWithContext|null = energyConsumptionTimeseries.find(
      (timeseries: TimeseriesWithContext) => timeseries.dataPointID === pinDataPointMap[energyConsumptionDatapointsMap.heatConsumption]
    ) ?? null

    // get the timeseries for the electricity consumption by finding the DP-ID in the response
    const electricityConsumptionTimeseries: TimeseriesWithContext|null = energyConsumptionTimeseries.find(
      (timeseries: TimeseriesWithContext) => timeseries.dataPointID === pinDataPointMap[energyConsumptionDatapointsMap.electricityConsumption]
    ) ?? null

    // convert the timeseries into short representation
    const heatConsumptionTimeseriesShort: [string, number|null][]|null = heatConsumptionTimeseries ? convertObservationsToTimeseries(heatConsumptionTimeseries.data!) : null

    // convert the timeseries into short representation
    const electricityConsumptionTimeseriesShort: [string, number|null][]|null = electricityConsumptionTimeseries ? convertObservationsToTimeseries(electricityConsumptionTimeseries.data!) : null

    // compute heat consumption for this year
    const heatConsumptionValue: number|null = heatConsumptionTimeseriesShort ? computeTotalConsumptionValue(
      heatConsumptionTimeseriesShort
    ) : null

    // compute electricity consumption for this year
    const electricityConsumptionValue: number|null = electricityConsumptionTimeseriesShort ? computeTotalConsumptionValue(
      electricityConsumptionTimeseriesShort
    ) : null

    // electricity consumption per squared meters for this year
    let electricityConsumptionPerSquaredMeters: number|null = null
    if (grossFloorArea?.value && electricityConsumptionValue !== null) {
      electricityConsumptionPerSquaredMeters = (electricityConsumptionValue) / parseFloat(grossFloorArea.value)
    }

    // electricity consumption per squared meters for this year
    let heatConsumptionPerSquaredMeters: number|null = null
    if (grossFloorArea?.value && heatConsumptionValue !== null) {
      heatConsumptionPerSquaredMeters = (heatConsumptionValue) / parseFloat(grossFloorArea.value)
    }

    const {
      heatConsumptionProjection,
      electricityConsumptionProjection
    } = await dispatch('fetchEnergyConsumptionProjections', {
      project_id: digitalTwin.project_id,
      heatProjectionDatapoint: get(pinDataPointMap, 'B+EN_H_CONSUM_SUM_PRJ', null),
      electricityProjectionDatapoint: get(pinDataPointMap, 'B+ELS+EN_EL_CONSUM_SUM_PRJ', null),
      grossFloorArea: grossFloorArea?.value ? parseFloat(grossFloorArea?.value) : null
    })
    // #endregion

    // #region CO2 EMISSIONS
    /* ----------------------------------------------------------------- */
    /* 💨 CO2 EMISSIONS 💨 */
    /* ----------------------------------------------------------------- */

    // compute the co2 emissions related electricity for this year
    const electricityCo2Emissions = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.electricityCo2Emissions])

    // compute the co2 emissions related heat for this year
    const heatCo2Emissions = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.heatCo2Emissions])

    // computed the co2 emission avoidance by photovoltaik
    const co2AvoidancePhotovoltaik = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.co2AvoidancePhotovoltaik])

    // computed the co2 emission avoidance by solar heat
    const co2AvoidanceSolar = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.co2AvoidanceSolar])

    // computed the co2 emission avoidance by CHP
    const co2AvoidanceCHP = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.co2AvoidanceCHP])

    // computed the co2 emission avoidance by heat pump

    const co2AvoidanceHeatPump = extractKPIValueFromTimeseries(co2EmissionsTimeseries, pinDataPointMap[co2EmissionsDatapointsMap.co2AvoidanceHeatPump])

    // compute the co2 emissions per squared meters for this year
    const co2perSquaredMeters: number|null = calculateCo2PerSquaredMeterEmissions(
      heatCo2Emissions,
      electricityCo2Emissions,
      get(grossFloorArea, 'value', null)
    )
    // get projection of heat-related co2 emissions
    const co2EmissionHeatProjectionTimeseriesData = co2EmissionsTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === pinDataPointMap[co2EmissionsDatapointsMap.co2EmissionHeatProjection]) ?? null
    const co2EmissionHeatProjectionTimeseriesShort: [string, number|null][]|null = co2EmissionHeatProjectionTimeseriesData !== null ? convertObservationsToTimeseries(co2EmissionHeatProjectionTimeseriesData.data!) : null
    const co2EmissionHeatProjection: number|null = co2EmissionHeatProjectionTimeseriesShort !== null ? getLastValue(co2EmissionHeatProjectionTimeseriesShort) : null

    // get projection of electricity-related co2 emissions
    const co2EmissionElectricityProjectionTimeseriesData = co2EmissionsTimeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === pinDataPointMap[co2EmissionsDatapointsMap.co2EmissionElectricityProjection]) ?? null
    const co2EmissionElectricityProjectionTimeseriesShort: [string, number|null][]|null = co2EmissionElectricityProjectionTimeseriesData !== null ? convertObservationsToTimeseries(co2EmissionElectricityProjectionTimeseriesData.data!) : null
    const co2EmissionElectricityProjection: number|null = co2EmissionElectricityProjectionTimeseriesShort !== null ? getLastValue(co2EmissionElectricityProjectionTimeseriesShort) : null

    // get combined projection of heat- and electricity-related co2 emissions
    let co2EmissionProjection: number|null = null
    if (co2EmissionHeatProjection !== null) co2EmissionProjection = co2EmissionHeatProjection
    if (co2EmissionElectricityProjection !== null && co2EmissionProjection === null) co2EmissionProjection = co2EmissionElectricityProjection
    else if (co2EmissionElectricityProjection !== null && co2EmissionProjection !== null) co2EmissionProjection += co2EmissionElectricityProjection

    if (grossFloorArea && grossFloorArea.value && co2EmissionProjection !== null) {
      co2EmissionProjection = co2EmissionProjection / parseFloat(grossFloorArea.value)
    }
    let co2EmissionSum: number|null = null
    if (heatCo2Emissions !== null && electricityCo2Emissions !== null) co2EmissionSum = heatCo2Emissions + electricityCo2Emissions
    else if (heatCo2Emissions !== null && electricityCo2Emissions === null) co2EmissionSum = heatCo2Emissions
    else if (electricityCo2Emissions !== null && heatCo2Emissions === null) co2EmissionSum = electricityCo2Emissions
    // #endregion

    // #region WATER CONSUMPTION
    /* ----------------------------------------------------------------- */
    /*  💧 WATER CONSUMPTION 💧 */
    /* ----------------------------------------------------------------- */

    // compute the fresh water consumption for this year
    const freshWaterConsumption = extractKPIValueFromTimeseries(waterConsumptionTimeseries, pinDataPointMap[waterConsumptionDatapointsMap.freshWaterConsumption])

    // compute the gray water consumption for this year
    const grayWaterConsumption = extractKPIValueFromTimeseries(waterConsumptionTimeseries, pinDataPointMap[waterConsumptionDatapointsMap.grayWaterConsumption])

    // compute the rain water consumption for this year
    const rainWaterConsumption = extractKPIValueFromTimeseries(waterConsumptionTimeseries, pinDataPointMap[waterConsumptionDatapointsMap.rainWaterConsumption])

    // compute the latest amount of occupants for this year
    const occupants = extractLastKPIValueFromTimeseriesWithContext(waterConsumptionTimeseries, pinDataPointMap['B+COUNT_PEO'])

    // compute the availability of elevators for this year
    const availability = extractLastKPIValueFromTimeseriesWithContext(waterConsumptionTimeseries, pinDataPointMap['B+ELS+ELE_AVAIL'])

    // compute the summed water consumption per person for this year
    const summedWaterConsumptionPerPerson: number|null = calculateWaterConsumptionPerPerson(
      freshWaterConsumption,
      grayWaterConsumption,
      rainWaterConsumption,
      occupants
    )

    // compute summed water consumption per squared meters for this year
    const summedWaterConsumptionPerSquaredMeters: number|null = calculateWaterConsumptionPerSquaredMeters(
      freshWaterConsumption,
      grayWaterConsumption,
      get(grossFloorArea, 'value', null)
    )

    const freshWaterConsumptionProjection = extractLastKPIValueFromTimeseriesWithContext(waterConsumptionTimeseries, pinDataPointMap['B+WAS+WS_FRESH_CONSUM_PRJ'])

    const grayWaterConsumptionProjection = extractLastKPIValueFromTimeseriesWithContext(waterConsumptionTimeseries, pinDataPointMap['B+WAS+WS_GRAY_CONSUM_PRJ'])

    const rainWaterConsumptionProjection = extractLastKPIValueFromTimeseriesWithContext(waterConsumptionTimeseries, pinDataPointMap['B+WAS+RAIN_WS_CONSUM_PRJ'])

    let waterConsumptionProjection: number|null = null
    if (freshWaterConsumptionProjection !== null && grayWaterConsumptionProjection !== null && rainWaterConsumptionProjection !== null) waterConsumptionProjection = freshWaterConsumptionProjection + grayWaterConsumptionProjection + rainWaterConsumptionProjection
    else {
      if (freshWaterConsumptionProjection !== null) waterConsumptionProjection = freshWaterConsumptionProjection

      if (grayWaterConsumptionProjection !== null && waterConsumptionProjection === null) waterConsumptionProjection = grayWaterConsumptionProjection
      else if (grayWaterConsumptionProjection !== null && waterConsumptionProjection !== null) waterConsumptionProjection += grayWaterConsumptionProjection

      if (rainWaterConsumptionProjection !== null && waterConsumptionProjection === null) waterConsumptionProjection = rainWaterConsumptionProjection
      else if (rainWaterConsumptionProjection !== null && waterConsumptionProjection !== null) waterConsumptionProjection += rainWaterConsumptionProjection
    }

    if (grossFloorArea && grossFloorArea.value && waterConsumptionProjection !== null) {
      waterConsumptionProjection = (waterConsumptionProjection * 1000.0) / parseFloat(grossFloorArea.value)
    }
    // #endregion

    const benchmarks: TBenchmarks = calculateAllBenchmarks(
      electricityConsumptionProjection ?? electricityConsumptionPerSquaredMeters,
      heatConsumptionProjection ?? heatConsumptionPerSquaredMeters,
      energyGenerationProjection ?? energyGenerationPerSquaredMeters,
      co2EmissionProjection ?? co2perSquaredMeters,
      waterConsumptionProjection ?? summedWaterConsumptionPerSquaredMeters,
      efficiencyZonesAttribute ? JSON.parse(efficiencyZonesAttribute.value ? efficiencyZonesAttribute.value : '{}') : null
    )

    /**
     * Compute the faults sum of the digital twin
     */
    const faults: number|null = computeAllFaults(
      await dispatch(
        'fetchFaultRelevantTimeseries', {
          project_id: digitalTwin.project_id,
          dataPointIDs: availableFaultSources
        }
      )
    )

    // elevators availability
    const elevatorsAvailability: number|null = await dispatch('fetchElevatorsAvailibility', digitalTwin.project_id)

    const digitalTwinsKpis: TProjectKpiTable = {
      incidents: faults,
      consumptionHeatProjection: heatConsumptionProjection,
      consumptionHeat: heatConsumptionValue,
      consumptionHeatPerSquaredMeters: heatConsumptionPerSquaredMeters,
      consumptionElectricityProjection: electricityConsumptionProjection,
      consumptionElectricity: electricityConsumptionValue,
      consumptionElectricityPerSquaredMeters: electricityConsumptionPerSquaredMeters,
      elevatorsAvailability,
      powerGeneration: energyGeneration,
      co2: co2perSquaredMeters,
      co2Projection: co2EmissionProjection,
      waterPerPerson: summedWaterConsumptionPerPerson,
      waterPerSquaredMetersPerYear: summedWaterConsumptionPerSquaredMeters,
      availability,
      project_id: digitalTwin.project_id as number,
      name: projectName,
      benchmarks
    }

    commit(
      'energy_consumption/SET_CHART_AND_BENCHMARK_DATA',
      {
        heatColor: benchmarks.heat_consumption,
        electricityColor: benchmarks.electricity_consumption,
        heatPerSquaredMeters: heatConsumptionPerSquaredMeters,
        heatProjection: heatConsumptionProjection,
        electricityPerSquaredMeters: electricityConsumptionPerSquaredMeters,
        electricityProjection: electricityConsumptionProjection,
        heat: heatConsumptionValue,
        electricity: electricityConsumptionValue
      },
      {
        root: true
      }
    )

    commit(
      'energy_generation/SET_CHART_AND_BENCHMARK_DATA',
      {
        energyGenerationPerSquaredMeters: energyGenerationPerSquaredMeters,
        energyGenerationColor: benchmarks.energy_generation,
        energyGenerationProjection: energyGenerationProjection,
        boiler: boilerHeatGeneration, // use server-side unit
        district_heating: districtHeatGeneration, // use server-side unit
        heat_pump: heatPumpHeatGeneration, // use server-side unit
        bhkw_th: chpHeatGeneration, // use server-side unit
        bhkw_el: chpElectricityGeneration, // use server-side unit
        photovoltaics: photovoltaikElectricityGeneration, // use server-side unit
        solar: solarHeatGeneration // use server-side unit
      },
      {
        root: true
      }
    )

    commit(
      'co2_emission/SET_CHART_AND_BENCHMARK_DATA',
      {
        co2EmissionsPerSquaredMeters: co2perSquaredMeters,
        co2EmissionsColor: benchmarks.co2_emissions,
        co2Projection: co2EmissionProjection,
        heat: heatCo2Emissions,
        electricity: electricityCo2Emissions,
        photovoltaics: co2AvoidancePhotovoltaik,
        chp: co2AvoidanceCHP,
        solar: co2AvoidanceSolar,
        heat_pump: co2AvoidanceHeatPump
      },
      {
        root: true
      }
    )

    commit(
      'water_consumption/SET_CHART_AND_BENCHMARK_DATA',
      {
        waterConsumption: summedWaterConsumptionPerSquaredMeters,
        waterConsumptionColor: benchmarks.water_consumption,
        waterConsumptionProjection,
        fresh_water: freshWaterConsumption,
        rain_water: rainWaterConsumption,
        gray_water: grayWaterConsumption
      },
      {
        root: true
      }
    )

    commit('SET_DIGITAL_TWINS_KPI_TABLE', digitalTwinsKpis)

    return digitalTwinsKpis
  },
  /**
   * Get the digital twins timeseries for all KPI relevant datapoints
   */
  fetchKpiRelevantTimeseries: async ({ rootState }, payload: {
    dataPointIDs: string[];
    project_id: number;
  }): Promise<TTimeseriesShort> => {
    const token = rootState.auth.access_token
    const { dataPointIDs, project_id } = payload
    try {
      const timeseriesResponse: AxiosResponse<TTimeseriesShort> = await Projects.getTimeseries({
        token,
        id: project_id,
        params: {
          dataPointIDs: dataPointIDs.join(','),
          start: moment().startOf('year').toISOString(true),
          end: moment().toISOString(true),
          short: true,
          samplerate: '1d',
          closed_interval: true
        }
      })
      return timeseriesResponse.data
    } catch (error) {
      console.error(error)
      const errorData: { [dataPointID: string]: [string, number][] } = {}
      dataPointIDs.map((dataPointID: string) => {
        errorData[dataPointID] = []
      })
      return errorData
    }
  },
  /**
   * Get the digital twins timeseries for all fault relevant datapoints
   */
  fetchFaultRelevantTimeseries: async ({ rootState }, payload: {
    project_id: number;
    dataPointIDs: string[];
  }): Promise<TTimeseriesShort> => {
    const token = rootState.auth.access_token
    const { dataPointIDs, project_id } = payload
    try {
      const timeseriesResponse: AxiosResponse<TTimeseriesShort> = await Projects.getTimeseries({
        token,
        id: project_id,
        params: {
          dataPointIDs: dataPointIDs.join(','),
          end: moment().toISOString(),
          short: true,
          max: 1,
          closed_interval: false
        }
      })
      return timeseriesResponse.data
    } catch (error) {
      console.error(error)
      return {}
    }
  }
} as ActionTree<TComponentsState, TRootState>
