
































































































































































































import Vue from 'vue'

import BulletGraph from '@/components/ui/Charts/BulletGraph.vue'
import BarChart from '@/components/ui/Charts/BarChart.vue'
import EnergyGenerationInformation from './InformationTables/EnergyGeneration/EnergyGenerationInfromation.vue'
import moment from 'moment'
import { TBenchmarkZones } from '@/utils/helpers/types'
import { computeRelativeValue } from '@/utils/helpers/timeseries'

export default Vue.extend({
  name: 'EnergyGeneration',
  components: {
    BulletGraph,
    BarChart,
    EnergyGenerationInformation
  },
  mounted () {
    this.$store.dispatch('energy_generation/fetchData')
  },
  data () {
    return {
      heatSelected: true,
      electricitySelected: true
    }
  },
  methods: {
    switchToMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('energy_generation/switchChartToMonth')
      }
    },
    switchToYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('energy_generation/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('energy_generation/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('energy_generation/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('energy_generation/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('energy_generation/selectNextMonth')
      }
    }
  },
  computed: {
    loadingProjectsDigitalTwin (): boolean {
      return this.$store.getters['project/areComponentsInProjectLoading']
    },
    loadingData (): boolean {
      return this.$store.getters['energy_generation/isLoadingData']
    },
    energyGenerationProjectionValue (): number|null {
      return this.$store.state.energy_generation.benchmark.energyGenerationProjection
    },
    generationBenchmark (): number|null {
      return this.$store.getters['energy_generation/getGenerationBenchmark']
    },
    generationColor (): string|null {
      return this.$store.getters['energy_generation/getGenerationBenchmarkColor']
    },
    boiler (): number|null {
      return this.$store.getters['energy_generation/getBoilerGeneration']
    },
    boilerRelative (): string|null {
      return computeRelativeValue(this.boiler, [
        this.heatPump,
        this.bhkwTh,
        this.bhkwEl,
        this.photovoltaics,
        this.solar,
        this.districtHeating
      ], true, 0)
    },
    districtHeating (): number|null {
      return this.$store.getters['energy_generation/getDistrictHeatingGeneration']
    },
    districtHeatingRelative (): string|null {
      return computeRelativeValue(this.districtHeating, [
        this.heatPump,
        this.bhkwTh,
        this.bhkwEl,
        this.photovoltaics,
        this.solar,
        this.boiler
      ], true, 0)
    },
    heatPump (): number|null {
      return this.$store.getters['energy_generation/getHeatpumpGeneration']
    },
    heatPumpRelative (): string|null {
      return computeRelativeValue(this.heatPump, [
        this.boiler,
        this.bhkwTh,
        this.bhkwEl,
        this.photovoltaics,
        this.solar,
        this.districtHeating
      ], true, 0)
    },
    bhkwTh (): number|null {
      return this.$store.getters['energy_generation/getBhkwThGeneration']
    },
    bhkwThRelative (): string|null {
      return computeRelativeValue(this.bhkwTh, [
        this.heatPump,
        this.boiler,
        this.bhkwEl,
        this.photovoltaics,
        this.solar,
        this.districtHeating
      ], true, 0)
    },
    bhkwEl (): number|null {
      return this.$store.getters['energy_generation/getBhkwElGeneration']
    },
    bhkwElRelative (): string|null {
      return computeRelativeValue(this.bhkwEl, [
        this.heatPump,
        this.boiler,
        this.bhkwTh,
        this.photovoltaics,
        this.solar,
        this.districtHeating
      ], true, 0)
    },
    photovoltaics (): number|null {
      return this.$store.getters['energy_generation/getPhotovoltaicsGeneration']
    },
    photovoltaicsRelative (): string|null {
      return computeRelativeValue(this.photovoltaics, [
        this.heatPump,
        this.boiler,
        this.bhkwEl,
        this.bhkwTh,
        this.solar,
        this.districtHeating
      ], true, 0)
    },
    solar (): number|null {
      return this.$store.getters['energy_generation/getSolarGeneration']
    },
    solarRelative (): string|null {
      return computeRelativeValue(this.solar, [
        this.heatPump,
        this.boiler,
        this.bhkwEl,
        this.photovoltaics,
        this.bhkwTh,
        this.districtHeating
      ], true, 0)
    },
    year (): number {
      return this.$store.getters['energy_generation/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['energy_generation/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['energy_generation/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['energy_generation/hasPrevious']
    },
    month (): number {
      return this.$store.getters['energy_generation/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['energy_generation/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
    },
    generationBenchmarkZones (): TBenchmarkZones|null {
      return this.$store.getters['energy_generation/getGenerationBenchmarkZones']
    },
    generationTargetValue (): number|null {
      return this.$store.getters['energy_generation/getTargetGenerationValue']
    },
    benchmark (): any {
      return this.generationTargetValue !== null && this.generationBenchmarkZones !== null ? {
        title: `${this.$t('energy_generation.benchmark.generation.title')} ${moment().year()}`,
        subtitle: `<span class="hc-cat-title">${this.$t('project.media.title.energy_generation')}</span><br><b>kWh/(m<sup>2</sup>a)</b>`,
        setValue: this.generationTargetValue,
        projectionValue: this.energyGenerationProjectionValue ? parseFloat(this.energyGenerationProjectionValue.toFixed(0)) : null,
        isValue: this.generationBenchmark !== null ? parseFloat(this.generationBenchmark.toFixed(0)) : 0,
        isColor: this.generationColor,
        unit: 'kWh/m2a',
        seriesName: this.$t('energy_generation.per_squared_meters_yearly'),
        zones: this.generationBenchmarkZones
      } : null
    },
    timeseries (): any[] {
      const timeseriesData: any[] = []

      if (this.boiler !== null && this.boilerRelative !== null && this.heatSelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.boiler'),
              y: parseFloat(this.boiler.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.boilerRelative} %`
            }
          ]
        })
      }

      if (this.districtHeating !== null && this.districtHeatingRelative !== null && this.heatSelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.district_heating'),
              y: parseFloat(this.districtHeating.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.districtHeatingRelative} %`
            }
          ]
        })
      }

      if (this.heatPump !== null && this.heatPumpRelative !== null && this.heatSelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.heat_pump'),
              y: parseFloat(this.heatPump.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.heatPumpRelative} %`
            }
          ]
        })
      }

      if (this.bhkwTh !== null && this.bhkwThRelative !== null && this.heatSelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.chp_th'),
              y: parseFloat(this.bhkwTh.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.bhkwThRelative} %`
            }
          ]
        })
      }

      if (this.solar !== null && this.solarRelative !== null && this.heatSelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.solar'),
              y: parseFloat(this.solar.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.solarRelative} %`
            }
          ]
        })
      }

      if (this.bhkwEl !== null && this.bhkwElRelative !== null && this.electricitySelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.chp_el'),
              y: parseFloat(this.bhkwEl.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.bhkwElRelative} %`
            }
          ]
        })
      }

      if (this.photovoltaics !== null && this.photovoltaicsRelative !== null && this.electricitySelected) {
        timeseriesData.push({
          data: [
            {
              name: this.$t('energy_generation.source.photovoltaics'),
              y: parseFloat(this.photovoltaics.toFixed(0)),
              unit: this.$t('units.kilowatthours'),
              absValue: `${this.photovoltaicsRelative} %`
            }
          ]
        })
      }

      return timeseriesData
    },
    positiveEnergyGeneration (): string|null {
      let valueToUse = null
      if (this.energyGenerationProjectionValue !== null) valueToUse = this.energyGenerationProjectionValue
      if (this.generationBenchmark === null) return null
      if (valueToUse === null) valueToUse = this.generationBenchmark
      const energyConsumption: string|null = computeRelativeValue(
        valueToUse,
        [
          this.$store.getters['energy_consumption/getHeatConsumptionBenchmark'],
          this.$store.getters['energy_consumption/getElectricityConsumptionBenchmark']
        ],
        false
      )

      if (energyConsumption === null) return null

      if (parseFloat(energyConsumption) > 70) {
        return this.$t('energy_generation.feedback.generation', { percent: '70' }) as string
      } else if (parseFloat(energyConsumption) > 50) {
        return this.$t('energy_generation.feedback.generation', { percent: '50' }) as string
      } else if (parseFloat(energyConsumption) > 30) {
        return this.$t('energy_generation.feedback.generation', { percent: '30' }) as string
      } else {
        return null
      }
    }
  }
})
