import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import moment from 'moment'
import get from 'lodash.get'
import { TRootState } from '../types'
import { TLightingSystemConsumptionData, TLightingSystemsState } from './types'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import {
  TComponentInProjectWithContextResponse,
  TComponentsInProjectResponse,
  TComponentInProject,
  TTimeseriesShort
} from '@/services/aedifion/resources/project/responseTypes'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { getEndDate, getStartDate } from '@/utils/helpers/dates'
import { computeTotalConsumptionValue, getLastValue } from '@/utils/helpers/timeseries'
import { isSystemRunning } from '@/utils/helpers/faults'
import { aedifionApi } from '@aedifion.io/aedifion-api'
const Projects: IRepository = aedifionApiRepository.get('projects')

export default {
  fetchLightingSystems: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_SYSTEMS', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    try {
      // fetch the available lighting systems
      const lightingSystemsResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params: {
          page: 1,
          per_page: 100,
          filter: 'alphanumeric_id=LIG'
        }
      })

      // iterate through all lighting systems and fetch the components with context
      const lightingSystemsWithContextResponse: AxiosResponse<TComponentInProjectWithContextResponse>[] = await Promise.all(
        lightingSystemsResponse.data.items.map(
          (lightingSystem: TComponentInProject) => {
            return Projects.getComponentInProject({
              token,
              id: project_id,
              second_level_id: lightingSystem.id
            })
          })
      )

      const lightingSystemsWithContext: TComponentInProjectWithContextResponse[] = lightingSystemsWithContextResponse.map((component: AxiosResponse<TComponentInProjectWithContextResponse>) => component.data)
      commit('SET_LIGHTING_SYSTEMS', lightingSystemsWithContext)
    } catch (error) {
      Vue.notify({
        text: 'Lighting systems could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_SYSTEMS', false)
    }
  },
  fetchEnergyData: async ({ commit, rootGetters, getters }) => {
    commit('SET_LOADING_DATA', true)
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    const datapoints: string[] = getters.getSystems
      .map((system: TComponentInProjectWithContextResponse) => getPinsDatapointByAlphanumericId(system.pins, 'LIG+EN_EL_CONSUM'))
      .filter((datapoint: string|null) => datapoint !== null)

    try {
      if (datapoints.length > 0) {
        const response = await aedifionApi.Project.getProjectTimeseries(
          project_id,
          datapoints,
          new Date(getStartDate(true, moment().year(), moment().month(), false, false, false, false)),
          new Date(getEndDate(true, moment().year(), moment().month(), false, false, false, false)),
          undefined,
          '1d',
          undefined,
          undefined,
          true,
          true,
          undefined,
          undefined,
          datapoints.map(() => 'kilowatt-hours')
        )

        const lastYearsResponse = await aedifionApi.Project.getProjectTimeseries(
          project_id,
          datapoints,
          new Date(getStartDate(true, moment().year(), moment().month(), true, false, false, false)),
          new Date(getEndDate(true, moment().year(), moment().month(), true, false, false, false)),
          undefined,
          '1d',
          undefined,
          undefined,
          true,
          true,
          undefined,
          undefined,
          datapoints.map(() => 'kilowatt-hours')
        )

        const consumptionData: TLightingSystemConsumptionData[] = []

        getters.getSystems.forEach((system: TComponentInProjectWithContextResponse) => {
          const energyConsumptionDatapointID: string|null = getPinsDatapointByAlphanumericId(system.pins, 'LIG+EN_EL_CONSUM')
          if (energyConsumptionDatapointID) {
            consumptionData.push({
              id: system.id!,
              nameEN: system.nameEN!,
              nameDE: system.nameDE!,
              currentConsumption: computeTotalConsumptionValue(
                get(
                  response,
                  energyConsumptionDatapointID,
                  null
                )
              ),
              pastConsumption: computeTotalConsumptionValue(
                get(
                  lastYearsResponse,
                  energyConsumptionDatapointID,
                  null
                )
              ),
              status: null
            })
          }
        })

        commit('SET_DATA', consumptionData)
      } else {
        // noop
      }
    } catch (error) {
      Vue.notify({
        text: 'Energy consumption data for lighting systems could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_DATA', false)
    }
  },
  fetchStatus: async ({ commit, rootGetters, getters }) => {
    commit('SET_LOADING_OPERATION_INFORMATION', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    const datapoints: string[] = getters.getSystems
      .map((system: TComponentInProjectWithContextResponse) => {
        return getPinsDatapointByAlphanumericId(system.pins, 'LIG+STAT_OPR')
      })
      .filter((datapoint: string|null) => datapoint !== null)

    try {
      if (datapoints.length > 0) {
        const timeseriesResponse: AxiosResponse<TTimeseriesShort> = await Projects.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: datapoints.join(','),
            end: moment().toISOString(true),
            short: true,
            closed_interval: false
          }
        })

        const consumptionData: TLightingSystemConsumptionData[] = getters.getSystemsWithConsumption

        getters.getSystems.forEach((system: TComponentInProjectWithContextResponse) => {
          const statusDatapointID: string|null = getPinsDatapointByAlphanumericId(system.pins, 'LIG+STAT_OPR')
          if (statusDatapointID) {
            const foundConsumptionIndex: number|undefined = consumptionData.findIndex((consumption: TLightingSystemConsumptionData) => {
              return consumption.id === system.id
            })

            if (foundConsumptionIndex > -1) {
              consumptionData[foundConsumptionIndex].status = isSystemRunning(getLastValue(get(timeseriesResponse, `data.${statusDatapointID}`, null)))
            }
          }
        })

        commit('SET_DATA', consumptionData)
      } else {
        // noop
      }
    } catch (error) {
      Vue.notify({
        text: 'Status information for lighting systems could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_OPERATION_INFORMATION', false)
    }
  }
} as ActionTree<TLightingSystemsState, TRootState>
