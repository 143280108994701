import { TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import { MutationTree } from 'vuex'
import { TProjectState } from './types'
import { ComponentInProject } from '@aedifion.io/aedifion-api'
import Vue from 'vue'

export default {
  SET_PROJECTS_LOADING: (state: TProjectState, loading: boolean) => {
    state.loadingProjects = loading
  },
  SET_PROJECTS: (state: TProjectState, payload: { projects: TProjectWithContext[] }) => {
    state.projects = payload.projects
  },
  SET_PROJECT_COMPONENTS: (state: TProjectState, payload: { components: ComponentInProject[]; componentId: number }) => {
    Vue.set(state.projectComponents, payload.componentId, payload.components)
  }
} as MutationTree<TProjectState>
