import { Module } from 'vuex'
import { TRootState } from '../types'
import { AppState } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import state from './state'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
} as Module<AppState, TRootState>
