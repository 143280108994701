import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TWaterConsumptionState } from './types'
import get from 'lodash.get'
import { TComponentAttribute } from '@/services/aedifion/resources/project/responseTypes'
import { TBenchmarkAttributeZones, TBenchmarkZones } from '@/utils/helpers/types'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'

export default {
  getTargetWaterConsumptionValue: (state: TWaterConsumptionState, getters, rootState): number|null => {
    if (!rootState.project.digitalTwin) return null
    const foundSetValue: TComponentAttribute|undefined = rootState.project.digitalTwin.attributes.find((attribute: TComponentAttribute) => {
      return attribute.alphanumeric_id === 'B_TAR_WS_FRESH_CONSUM' && !!attribute.value
    })

    if (!foundSetValue || !foundSetValue.value) return null
    return parseFloat(foundSetValue.value)
  },

  getFreshWaterConsumption: (state: TWaterConsumptionState): number|null => state.chart.fresh_water,
  getRainWaterConsumption: (state: TWaterConsumptionState): number|null => state.chart.rain_water,
  getGrayWaterConsumption: (state: TWaterConsumptionState): number|null => state.chart.gray_water,

  hasGrayWaterSystem: (state: TWaterConsumptionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+WAS+WS_GRAY_CONSUM')
  },
  hasRainWaterSystem: (state: TWaterConsumptionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+WAS+RAIN_WS_CONSUM')
  },

  getWaterConsumptionBenchmark: (state: TWaterConsumptionState): number|null => state.benchmark.waterConsumption,
  getWaterConsumptionBenchmarkColor: (state: TWaterConsumptionState): string|null => state.benchmark.waterConsumptionColor,

  getYear: (state: TWaterConsumptionState): string|number|null => state.year,
  getMonth: (state: TWaterConsumptionState): string|number|null => state.month,
  getWaterConsumptionBenchmarkZones: (state: TWaterConsumptionState, gettters, rootState): TBenchmarkZones|null => {
    const benchmarkZones: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_EFZ')
    if (benchmarkZones === null) return null
    try {
      const benchmarks: TBenchmarkAttributeZones = JSON.parse(benchmarkZones)
      if (benchmarks.benchmark_water_consumption) {
        return benchmarks.benchmark_water_consumption
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },

  isYearSelected: (state: TWaterConsumptionState): boolean => state.isYearSelected,
  isMonthSelected: (state: TWaterConsumptionState): boolean => !state.isYearSelected,
  hasPrevious: (state: TWaterConsumptionState): boolean => state.hasPrevious,
  hasNext: (state: TWaterConsumptionState): boolean => state.hasNext,
  isLoadingData: (state: TWaterConsumptionState): boolean => state.loading
} as GetterTree<TWaterConsumptionState, TRootState>
