import {
  MutationTree
} from 'vuex'
import { OperationsState, TimeseriesShort } from './types'

type SetTimeseriesPayload = {
  name?: string;
  step?: string;
  data: Array<TimeseriesShort<string>>;
  dataPointID: string;
}

export default {
  CLEAR_TIMESEREIS: (state: OperationsState) => {
    state.timeseries = []
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SET_TIMESERIES: (state: OperationsState, payload: SetTimeseriesPayload) => {
    const index = state.timeseries.findIndex((timeseries) => timeseries.dataPointID === payload.dataPointID)
    if (index === -1) {
      state.timeseries.push({
        name: payload.name ?? 'Unknown',
        data: payload.data,
        step: payload.step ?? 'left',
        dataPointID: payload.dataPointID
      })
    } else {
      state.timeseries[index].data = payload.data
    }
  },

  REMOVE_TIMESERIES: (state: OperationsState, dataPointID: string) => {
    const index = state.timeseries.findIndex((timeseries) => timeseries.dataPointID === dataPointID)
    if (index !== -1) {
      state.timeseries.splice(index, 1)
    }
  },

  SET_ACTIVE_PINS: (state: OperationsState, dataPointIDs: string[]) => {
    state.activePins = dataPointIDs
  }
} as MutationTree<OperationsState>
