import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import moment, { Moment } from 'moment'
import get from 'lodash.get'
import { TRootState } from '../types'
import { TCharginStationsState } from './types'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import {
  TComponentInProjectWithContextResponse,
  TComponentsInProjectResponse,
  TComponentInProject,
  TTimeseriesShort
} from '@/services/aedifion/resources/project/responseTypes'
import { computeNonOperatingSystems } from '@/utils/helpers/faults'
import {
  computeTotalConsumptionValue,
  computeRelativeValue
} from '@/utils/helpers/timeseries'
import {
  getStartDate,
  getEndDate
} from '@/utils/helpers/dates'
import { INTERPOLATION_METHODS } from '@/services/aedifion/resources/project/requestTypes'
import i18n from '@/plugins/i18n'
import { aedifionApi } from '@aedifion.io/aedifion-api'
const Projects: IRepository = aedifionApiRepository.get('projects')
const Datapoint: IRepository = aedifionApiRepository.get('datapoints')

const chargingStationsElectricityConsumptionID = 'B+ELS+CST_EN_EL_CONSUM_SUM'
const chargingStationsAbsoluteTimeUsageID = 'B+ELS+CST_TIM_USA_SUM'

async function calculateAbsoluteTimeUsageData (project_id: number, datapoint: string): Promise<{
  thisYearsTimeUsage: number|null;
  lastYearsTimeUsage: number|null;
}> {
  // fetch the timeseries data for this
  const beginningOfThisYear = moment().startOf('year').toISOString(true)
  const currentDateOfThisYear = moment().toISOString(true)

  // fetch the timeseries data for the last year
  const beginningOfLastYear = moment().subtract(1, 'year').startOf('year').toISOString(true)
  const endOfLastYear = moment().subtract(1, 'year').endOf('year').toISOString(true)

  // make request
  const timeseriesResponse = await Promise.all(
    [
      aedifionApi.Project.getProjectTimeseries(
        project_id,
        [datapoint],
        new Date(beginningOfThisYear),
        new Date(currentDateOfThisYear),
        undefined,
        '1d',
        undefined,
        undefined,
        true,
        true
      ),
      aedifionApi.Project.getProjectTimeseries(
        project_id,
        [datapoint],
        new Date(beginningOfLastYear),
        new Date(endOfLastYear),
        undefined,
        '1d',
        undefined,
        undefined,
        true,
        true
      )
    ]
  )

  // calculate this years time usage
  const thisYearsAbsoluteTimeUsage = computeTotalConsumptionValue(get(timeseriesResponse[0], datapoint, null))

  // calculate last years time usage
  const lastYearsAbsoluteTimeUsage = computeTotalConsumptionValue(get(timeseriesResponse[1], datapoint, null))

  return {
    thisYearsTimeUsage: thisYearsAbsoluteTimeUsage,
    lastYearsTimeUsage: lastYearsAbsoluteTimeUsage
  }
}

async function calculateAbsoluteElectricityUsageData (project_id: number, datapoint: string): Promise<{
  thisYearsElectricityConsumption: number|null;
  lastYearsElectricityConsumption: number|null;
}> {
  // fetch the timeseries data for this
  const beginningOfThisYear = moment().startOf('year').toISOString(true)
  const currentDateOfThisYear = moment().toISOString(true)

  // fetch the timeseries data for the last year
  const beginningOfLastYear = moment().subtract(1, 'year').startOf('year').toISOString(true)
  const endOfLastYear = moment().subtract(1, 'year').endOf('year').toISOString(true)

  // make request
  const timeseriesResponse = await Promise.all(
    [
      aedifionApi.Project.getProjectTimeseries(
        project_id,
        [datapoint],
        new Date(beginningOfThisYear),
        new Date(currentDateOfThisYear),
        undefined,
        '1d',
        undefined,
        undefined,
        true,
        true,
        undefined,
        undefined,
        ['kilowatt-hours']
      ),
      aedifionApi.Project.getProjectTimeseries(
        project_id,
        [datapoint],
        new Date(beginningOfLastYear),
        new Date(endOfLastYear),
        undefined,
        '1d',
        undefined,
        undefined,
        true,
        true,
        undefined,
        undefined,
        ['kilowatt-hours']
      )
    ]
  )

  // calculate this years time usage
  const thisYearsAbsoluteElectricityUsage = computeTotalConsumptionValue(get(timeseriesResponse[0], datapoint, null))

  // calculate last years time usage
  const lastYearsAbsoluteElectricityUsage = computeTotalConsumptionValue(get(timeseriesResponse[1], datapoint, null))

  return {
    thisYearsElectricityConsumption: thisYearsAbsoluteElectricityUsage,
    lastYearsElectricityConsumption: lastYearsAbsoluteElectricityUsage
  }
}

export default {
  /**
   * Fetches all available charging station components as ComponentWithContext and stores the list in the store.
   */
  fetchChargingStations: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_STATIONS', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    try {
      // fetch the available charging stations
      const chargingStationsResponse: AxiosResponse<TComponentsInProjectResponse> = await Projects.getComponentsInProject({
        token,
        id: project_id,
        params: {
          page: 1,
          per_page: 100,
          filter: 'alphanumeric_id=CST'
        }
      })

      // iterate through all charging stations and fetch the components with context
      const chargingStationsWithContextResponse: AxiosResponse<TComponentInProjectWithContextResponse>[] = await Promise.all(
        chargingStationsResponse.data.items.map(
          (chargingStation: TComponentInProject) => {
            return Projects.getComponentInProject({
              token,
              id: project_id,
              second_level_id: chargingStation.id
            })
          })
      )

      const chargingStationsWithContext: TComponentInProjectWithContextResponse[] = chargingStationsWithContextResponse.map((component: AxiosResponse<TComponentInProjectWithContextResponse>) => component.data)
      commit('SET_CHARGING_STATIONS', chargingStationsWithContext)
    } catch (error) {
      Vue.notify({
        text: i18n.t('charging_stations.requests.get_all_charging_stations.failed') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_STATIONS', false)
    }
  },

  fetchOccupancy: async ({ commit, getters, rootGetters }) => {
    commit('SET_LOADING_OCCUPANCY', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    // Iterate through every single charging station component and fetch
    const datapoints: string[] = getters.getStations
      .map((station: TComponentInProjectWithContextResponse) => getPinsDatapointByAlphanumericId(station.pins, 'CST+STAT_OPR'))
      .filter((datapoint: string|null) => datapoint !== null)

    try {
      if (datapoints.length > 0) {
        const timeseriesResponse: AxiosResponse<TTimeseriesShort> = await Projects.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: datapoints.join(','),
            end: moment().toISOString(true),
            short: true,
            max: 1,
            closed_interval: false
          }
        })

        const nonOperatingSystems: number|null = computeNonOperatingSystems(timeseriesResponse.data)
        commit('SET_SYSTEMS_OPERATION_STATE', nonOperatingSystems === null ? null : nonOperatingSystems === 0)
        commit('SET_OCCUPANCY', nonOperatingSystems)
      } else {
        // TODO: No datapoints available for this operation
      }
    } catch (error) {
      Vue.notify({
        text: i18n.t('charging_stations.requests.get_occupancy_data.failed') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_OCCUPANCY', false)
    }
  },
  fetchTableData: async ({ commit, rootGetters, rootState }) => {
    commit('SET_LOADING_TABLE_DATA', true)
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    // get the absolute time-usage PIN datapoint
    const absoluteTimeUsageDatapoint: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), chargingStationsAbsoluteTimeUsageID)

    // get the absolute energy consumption PIN datapoint
    const absoluteElectricityConsumptionDatapoint: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), chargingStationsElectricityConsumptionID)

    if (absoluteTimeUsageDatapoint !== null || absoluteElectricityConsumptionDatapoint !== null) {
      try {
        if (absoluteTimeUsageDatapoint !== null) {
          const {
            thisYearsTimeUsage,
            lastYearsTimeUsage
          } = await calculateAbsoluteTimeUsageData(project_id, absoluteTimeUsageDatapoint)

          commit('SET_TIME_USAGE', {
            thisYearsTimeUsage,
            lastYearsTimeUsage
          })
        }

        if (absoluteElectricityConsumptionDatapoint !== null) {
          const {
            thisYearsElectricityConsumption,
            lastYearsElectricityConsumption
          } = await calculateAbsoluteElectricityUsageData(project_id, absoluteElectricityConsumptionDatapoint)

          commit('SET_ELECTRICITY_USAGE', {
            thisYearsElectricityConsumption,
            lastYearsElectricityConsumption
          })
        }
      } catch (error) {
        Vue.notify({
          text: i18n.t('charging_stations.requests.get_consumption_data.failed') as string,
          group: 'requests',
          duration: 6000,
          type: 'error'
        })
      } finally {
        commit('SET_LOADING_TABLE_DATA', false)
      }
    } else {
      commit('SET_LOADING_TABLE_DATA', false)
    }
  },
  fetchChartData: async ({ commit, state, rootGetters, rootState }) => {
    commit('SET_LOADING_CHART_DATA', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    if (project_id === null) throw new Error('No project selected.')

    const month: number = get(state, 'month', moment().month())
    const year: number = get(state, 'year', moment().year())
    const hasYear = state.isYearSelected

    // get the absolute time-usage PIN datapoint
    const absoluteTimeUsageDatapoint: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), chargingStationsAbsoluteTimeUsageID)

    try {
      if (absoluteTimeUsageDatapoint !== null) {
        const timeseriesResponse: AxiosResponse<[string, number][]>[] = await Promise.all([
          Datapoint.getTimeseries({
            token,
            id: absoluteTimeUsageDatapoint,
            params: {
              dataPointID: absoluteTimeUsageDatapoint,
              project_id,
              start: getStartDate(hasYear, year, month),
              end: getEndDate(hasYear, year, month),
              samplerate: '1d',
              short: true,
              closed_interval: true
            }
          }),
          Datapoint.getTimeseries({
            token,
            id: absoluteTimeUsageDatapoint,
            params: {
              dataPointID: absoluteTimeUsageDatapoint,
              project_id,
              end: getEndDate(hasYear, year, month, hasYear, !hasYear),
              short: true,
              max: 1,
              interpolation: INTERPOLATION_METHODS.PREVIOUS
            }
          }),
          Datapoint.getTimeseries({
            token,
            id: absoluteTimeUsageDatapoint,
            params: {
              dataPointID: absoluteTimeUsageDatapoint,
              project_id,
              start: getStartDate(hasYear, year, month, false, false, hasYear, !hasYear),
              end: '2100-01-01',
              samplerate: '1d',
              short: true,
              closed_interval: false,
              interpolation: INTERPOLATION_METHODS.NONE
            }
          })
        ])

        const hasPreviousData = timeseriesResponse[1].data.length > 0
        const hasNextData = timeseriesResponse[2].data.length > 0
        const hoursOfSelectedTime = moment(getEndDate(hasYear, year, month)).diff(moment(getStartDate(hasYear, year, month)), 'hours')
        const usageTime = computeTotalConsumptionValue(get(timeseriesResponse[0], 'data', []))
        const noUsageTime = usageTime !== null ? (hoursOfSelectedTime - usageTime) : hoursOfSelectedTime
        const relativeTimeUsageOfSelectedYear: string|null = computeRelativeValue(usageTime, [hoursOfSelectedTime], true)

        commit('SET_CHART_DATA', {
          hasPreviousData,
          hasNextData,
          usageTime,
          relativeUsage: relativeTimeUsageOfSelectedYear !== null ? parseFloat(relativeTimeUsageOfSelectedYear) : null,
          noUsageTime,
          hoursPerSelectedTime: hoursOfSelectedTime
        })
      }
    } catch (error) {
      Vue.notify({
        text: 'Usage time of charging stations could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_LOADING_CHART_DATA', false)
    }
  },
  switchChartToMonth: ({ state, commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', false)
    if (moment({ year: get(state, 'year', moment().year()), month: get(state, 'month', moment().month()) }).isAfter(moment())) {
      commit('SWITCH_MONTH', moment().month())
    }
    dispatch('fetchChartData')
  },
  switchChartToYear: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', true)
    dispatch('fetchChartData')
  },
  selectPreviousYear: ({ state, commit, dispatch }) => {
    const previous: number = get(state, 'year', moment().year()) - 1
    commit('SWITCH_YEAR', previous)
    dispatch('fetchChartData')
  },
  selectPreviousMonth: ({ state, commit, dispatch }) => {
    const previous: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).subtract(1, 'month')
    commit('SWITCH_MONTH', previous.month())
    commit('SWITCH_YEAR', previous.year())
    dispatch('fetchChartData')
  },
  selectNextYear: ({ state, commit, dispatch }) => {
    const next: number = get(state, 'year', moment().year()) + 1
    commit('SWITCH_YEAR', next)
    dispatch('fetchChartData')
  },
  selectNextMonth: ({ state, commit, dispatch }) => {
    const next: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).add(1, 'month')
    commit('SWITCH_MONTH', next.month())
    commit('SWITCH_YEAR', next.year())
    dispatch('fetchChartData')
  }
} as ActionTree<TCharginStationsState, TRootState>
