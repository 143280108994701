import { TComponentsState } from './types'

export default {
  componentInProjectLoading: false,
  componentsInProjectsLoading: true,
  faultsLoading: true,
  kpisLoading: true,
  componentsInProject: {},
  digitalTwins: {},
  kpiTables: []
} as TComponentsState
