import { aedifionApi } from '@aedifion.io/aedifion-api'
import { textForLocale } from '@/utils/helpers/locale'
import { getStartAndEndDate } from '@/utils/helpers/timerange'
import { convertToPlottableTimeseries } from '@/utils/helpers/timeseries'
import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import { TRootState } from '../types'
import { OperationsState, ProjectTimeseriesShort, TimeseriesShort } from './types'

export default {
  clearTimeseries: ({ commit }) => {
    commit('CLEAR_TIMESEREIS')
  },

  fetchTimeseriesForAllDatapoints: async ({ commit, rootGetters, state }, payload: {
    dateRange: [string, string?];
  }) => {
    state.loadingTimeseries = true
    try {
      const projectId = rootGetters['project/getProjectID']
      const { start, end } = getStartAndEndDate(payload.dateRange)

      const timeseriesResponses: ProjectTimeseriesShort<string> = await aedifionApi.Project.getProjectTimeseries(
        projectId,
        state.activePins,
        start,
        end,
        undefined,
        'auto',
        undefined,
        undefined,
        true,
        true
      ) as unknown as ProjectTimeseriesShort<string>

      for (const [dataPointID, timeseries] of Object.entries(timeseriesResponses)) {
        commit('SET_TIMESERIES', {
          data: convertToPlottableTimeseries(timeseries),
          dataPointID
        })
      }
    } catch (error) {} finally {
      state.loadingTimeseries = false
    }
  },

  fetchTimeseriesForDatapoint: async ({ commit, rootGetters, state }, payload: {
    pinNames: {
      nameDE?: string;
      nameEN?: string;
    };
    dataPointID: string;
    name: string|null;
    dateRange: [string, string?];
  }) => {
    state.loadingTimeseries = true
    try {
      const projectId = rootGetters['project/getProjectID']
      const { start, end } = getStartAndEndDate(payload.dateRange)

      const timeseriesResponse: TimeseriesShort<string> = await aedifionApi.Datapoint.getDatapointTimeseries(
        projectId,
        payload.dataPointID,
        start,
        end,
        undefined,
        'auto',
        undefined,
        undefined,
        true,
        true
      ) as TimeseriesShort<string>

      const timeseries: TimeseriesShort<number> = convertToPlottableTimeseries(timeseriesResponse)

      commit('SET_TIMESERIES', {
        name: `${payload.name ? (payload.name + ': ') : ''}${textForLocale(payload.pinNames.nameDE, payload.pinNames.nameEN) ?? payload.dataPointID}`,
        data: timeseries,
        step: 'left',
        dataPointID: payload.dataPointID
      })
    } catch (error) {
      const errorMessage: string = (error as Error).message
      Vue.notify({
        duration: 6000,
        group: 'request',
        text: errorMessage,
        type: 'error'
      })
    } finally {
      state.loadingTimeseries = false
    }
  },

  removeTimeseriesForDatapoint: ({ commit }, dataPointID: string) => {
    commit('REMOVE_TIMESERIES', dataPointID)
  },

  setActivePins: ({ commit }, dataPointIDs: string[]) => {
    commit('SET_ACTIVE_PINS', dataPointIDs)
  }
} as ActionTree<OperationsState, TRootState>
