/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { formatValue } from '@/filters/formatting'
import { TimeseriesShort, TimeseriesValue } from '@/store/operations/types'
import { Observation, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import moment, { Moment } from 'moment'

function getFirstNonNullTimeseriesValue (timeseries: [string, number|null][], offset = 0): number|null {
  let i = 0
  while (timeseries[offset + i][1] === null && offset + i < timeseries.length - 2) {
    i++
  }
  return timeseries[offset + i][1]
}

export const getLastValue = (timeseries: [string, number|null][], offset = 0): number|null => {
  if (!!timeseries && timeseries.length > offset) {
    return timeseries[timeseries.length - 1][1] !== null ? parseFloat(timeseries[timeseries.length - 1][1]!.toFixed(2)) : null
  } else {
    return null
  }
}

/**
 * Converts a timeseries of type Observation[] into [string, number|null][]
 * @param observations Timeseries of type Observation[].
 * @returns Timeseries of type [string, number|null][].
 */
export const convertObservationsToTimeseries = (observations: Observation[]): [string, number|null][] => {
  return observations.map((observation: Observation) => {
    return [
      observation.time,
      observation.value
    ]
  })
}

export const computeTotalConsumptionValue = (timeseries: [string, number|null][], offset = 0): number|null => {
  let result = getLastValue(timeseries, offset)
  if (result !== null && timeseries.length >= 2) {
    const firstNonNullValue = getFirstNonNullTimeseriesValue(timeseries, offset)
    if (timeseries[offset][1] !== null) {
      result -= timeseries[offset][1]!
    } else if (firstNonNullValue !== null) {
      result -= firstNonNullValue
    }
  } else if (result !== null && timeseries.length < 2) {
    return 0.0
  }
  return result !== null ? parseFloat(result.toFixed(2)) : null
}

/**
 * Calculates the total consumption value per day on a given timeseries.
 * @param timeseries Timeseries to compute the total consumption value for.
 * @param offset Offset from where to start using the timeseries observations.
 * @returns Number if valid timeseries was passed for this calculation. Else returns null.
 */
export const computeTotalConsumptionValuePerDay = (timeseries: [string, number|null][], offset = 0): number|null => {
  if (timeseries.length === 0) throw new Error('Invalid input of timeseries data: No observations')
  const startDate: Moment = moment(timeseries[0][0])
  const endDate: Moment = moment(timeseries[timeseries.length - 1][0])
  const daysDifference = endDate.diff(startDate, 'days')
  if (daysDifference < 0) throw new Error(`Invalid input of timeseires data: Observations are in the wrong order - difference in days is negative: ${daysDifference}`)
  const totalConsumptionValue: number|null = computeTotalConsumptionValue(timeseries, offset)
  if (totalConsumptionValue === null) return null
  if (daysDifference === 0) return totalConsumptionValue
  return totalConsumptionValue / daysDifference
}

/**
 * Calculates the relative proportion of a value to several other values. If required, the value to be calculated as a proportion can be excluded from the other values.
 * @param main The value to calculate the proportion of.
 * @param values The values that will be used to calculate the proportion against.
 * @param includingMain Whether to include the main value in the the other values to calculate the proportion or not. By default, this is set to `true`.
 * @returns The return value is a string of the proporion, interpreted as a percentage, e.g. 53 (Percent = %). The result is rounded to floor, and has no decimal places.
 */
export const computeRelativeValue = (main: number|null, values: Array<number|null>, includingMain = true, commaPositions = 2): string|null => {
  if (main === null) return null
  if (main === 0) return formatValue(0, commaPositions)
  let sum: number = includingMain ? main : 0
  values.forEach((value: number|null) => {
    if (value !== null) sum += value
  })

  if (!includingMain && sum === 0) return null

  // return (Math.floor((((main / sum) * 100.0) * 10000.0) / 10000.0)).toString()
  return formatValue((main / sum) * 100, commaPositions)
}

/**
 * Calculates the base peak value of a timeseries.
 * @param maxValue The max value of the given timeseries.
 * @param normalizeToKilo Whether to normalize the data to kilo, by muliplying with 1000.0. Default: false.
 * @returns Base peak as float.
 */
export const calculateLoad = (maxValue: number, normalizeToKilo = false): number => {
  return normalizeToKilo ? maxValue * 1000 : maxValue
}

/**
 * Takes a timeseries which represents averaged data of 15-minute intervals and calculates the base peak for it, normalized to
 * @param timeseries The timeseries to calculate the base peak for.
 * @param normalizeToKilo Whether to normalize the data to kilo, by muliplying with 1000.0. Default: false.
 * @param peakLoad Whether to compute the peak load or not. If not, the base load will be calculated. Default: false.
 * @returns Base peak as float.
 */
export const calculateLoadFrom15MinAvgData = (timeseries: [string, number|null][], normalizeToKilo = false, peakLoad = false): number|null => {
  if (timeseries.length === 0) return null
  const observationsValuesOnly: Array<number|null> = timeseries.map((observation: [string, number|null]) => observation[1])
  const observationsWithoutNull: number[] = observationsValuesOnly.filter((value: number|null) => value !== null) as number[]
  if (observationsWithoutNull.length === 0) return null
  const extremeValue: number = peakLoad ? Math.max(...observationsWithoutNull) : Math.min(...observationsWithoutNull)
  return calculateLoad(extremeValue, normalizeToKilo)
}

/**
  *
  * NEW SECTION - COPIED FROM aedifion-frontend
  *
  */

/**
 * Converts timeseries observations time strings into millisecond values as numbers and each observation into a pair of timestamp and value.
 * The aedifion API originally returns timestamps as ISO strings.
 * @param timeseries Timeseries response from the aedifion API.
 * @returns Array of observations in array format with time values as numbers.
 */
export const convertToPlottableTimeseries = (timeseries: TimeseriesShort<string>): TimeseriesShort<number> => {
  return timeseries.map((obs: TimeseriesValue<string>) => {
    return [
      new Date(obs[0]).valueOf(),
      obs[1]
    ]
  }) as TimeseriesShort<number>
}

/**
 * Extracts the KPI value from a timeseries data for a given datapoint ID.
 * @param timeseries - An array of timeseries data with context information.
 * @param datapointId - The ID of the datapoint to extract the KPI value for.
 * @returns The KPI value as a number, or null if the datapoint ID is not found in the timeseries data.
 */
export function extractKPIValueFromTimeseries (timeseries: TimeseriesWithContext[], datapointId: string): number|null {
  const timeseriesData = timeseries.find((timeseries: TimeseriesWithContext) => timeseries.dataPointID === datapointId) ?? null
  const timeseriesShort = timeseriesData !== null ? convertObservationsToTimeseries(timeseriesData.data!) : null
  return timeseriesShort !== null ? computeTotalConsumptionValue(timeseriesShort) : null
}
