























import Vue from 'vue'
import Boiler from './Boiler.vue'
import BHKW_th from './BHKW_th.vue'
import BHKW_el from './BHKW_el.vue'
import DistrictHeating from './DistrictHeating.vue'
import HeatPump from './HeatPump.vue'
import Solar from './Solar.vue'
import Photovoltaic from './Photovoltaic.vue'

export default Vue.extend({
  name: 'EnergyGenerationInformation',
  components: {
    Boiler,
    'BHKW-th': BHKW_th,
    'BHKW-el': BHKW_el,
    DistrictHeating,
    HeatPump,
    Solar,
    Photovoltaic
  },
  data () {
    return {
      selected: 0
    }
  },
  computed: {
    hasBoiler (): boolean {
      return this.$store.getters['energy_generation/getBoilerGeneration'] !== null
    },
    hasBhkwTh (): boolean {
      return this.$store.getters['energy_generation/getBhkwThGeneration'] !== null
    },
    hasBhkwEl (): boolean {
      return this.$store.getters['energy_generation/getBhkwElGeneration'] !== null
    },
    hasDistrictHeating (): boolean {
      return this.$store.getters['energy_generation/getDistrictHeatingGeneration'] !== null
    },
    hasHeatPump (): boolean {
      return this.$store.getters['energy_generation/getHeatpumpGeneration'] !== null
    },
    hasSolar (): boolean {
      return this.$store.getters['energy_generation/getSolarGeneration'] !== null
    },
    hasPhotovoltaic (): boolean {
      return this.$store.getters['energy_generation/getPhotovoltaicsGeneration'] !== null
    }
  },

  created () {
    if (this.hasBoiler) {
      this.selected = 0
    } else if (this.hasBhkwTh) {
      this.selected = 1
    } else if (this.hasBhkwEl) {
      this.selected = 2
    } else if (this.hasDistrictHeating) {
      this.selected = 3
    } else if (this.hasHeatPump) {
      this.selected = 4
    } else if (this.hasSolar) {
      this.selected = 5
    } else if (this.hasPhotovoltaic) {
      this.selected = 6
    }
  }
})
