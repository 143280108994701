import { TProjectState } from './types'

export default {
  loadingProject: false,
  loadingProjects: true,
  projects: [],
  selectedProject: null,
  selectedProjectId: null,
  loadingProjectComponents: false,
  loadingProjectComponentDetails: false,
  projectComponents: {}
} as TProjectState
