import { ActionTree } from 'vuex'
import { AppState } from './types'
import { TRootState } from '../types'
import moment from 'moment'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import { aedifionApi } from '@aedifion.io/aedifion-api'

export default {
  fetchPortfolioAggregationKpis: async ({ commit, rootGetters }): Promise<void> => {
    const availableProjectIds: number[] = rootGetters['projects/getProjectIds']

    const ENERGY_CONSUMPTION_KPI = 'energy_consumption_absolute'
    const CO2_EMISSIONS_KPI = 'co2_emissions_absolute'

    const startDate = moment().startOf('year').utc().toDate()
    const endDate = moment().utc().toDate()

    commit('SET_LOADING_PORTFOLIO_AGGREGATION_KPIS', true)

    try {
      const kpiRequests = [
        aedifionApi.Analytics.getKpiAggregation(availableProjectIds, ENERGY_CONSUMPTION_KPI, startDate, endDate, 'metric', 'EUR', 'sum', 'sum'),
        aedifionApi.Analytics.getKpiAggregation(availableProjectIds, CO2_EMISSIONS_KPI, startDate, endDate, 'metric', 'EUR', 'sum', 'sum')
      ]

      const [energyConsumptionKpi, co2EmissionsKpi] = await Promise.all(kpiRequests)

      commit('SET_ENERGY_CONSUMPTION', energyConsumptionKpi)
      commit('SET_CO2_EMISSIONS', co2EmissionsKpi)
    } catch (error) {
      Vue.notify({
        text: i18n.t('app.portfolio.kpis.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      console.debug(error)
    } finally {
      commit('SET_LOADING_PORTFOLIO_AGGREGATION_KPIS', false)
    }
  }
} as ActionTree<AppState, TRootState>
