import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TCompanyAvatarUplaodResponse, TCompanyUpdateResponse } from './responseTypes'
import { TCompanyUpdateRequest } from './requestTypes'

const resource = 'company'

export default {
  /* get: (
    token: string|null,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TUserWithContext>> => {
    return axiosInstance.get(`/${resource}`, {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }, */
  update: (request: {
    token: string|null;
    body: TCompanyUpdateRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TCompanyUpdateResponse>> => {
    return axiosInstance.put(`/${resource}`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  uploadAvatar: (request: {
    token: string|null;
    body: FormData;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TCompanyAvatarUplaodResponse>> => {
    return axiosInstance.post(`/${resource}/avatar`, request.body, {
      ...request.config,
      headers: {
        Authorization: `Bearer ${request.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
} as IRepository
