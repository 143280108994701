






















































































































































































































































import Vue, { PropType } from 'vue'
import Card from '@/components/ui/Card.vue'

import { STATUS, TAlertUpdate } from '@/services/alerta/resources/common/responseTypes'
import { TAlertTableRow } from '@/store/alerts/types'

import { AlertsHelper } from '@/utils/helpers/alerts'

export default Vue.extend({
  name: 'AlarmDetailsControl',

  components: {
    Card
  },

  computed: {
    ackIsBlocked (): boolean {
      return this.alert.originalData.status === STATUS.ACK ||
             this.alert.originalData.status === STATUS.CLOSED ||
             this.alert.originalData.status === STATUS.SHELVED
    },

    reopeningIsVisible (): boolean {
      return this.alert.originalData.status === STATUS.ACK ||
             this.alert.originalData.status === STATUS.SHELVED
    },

    reopeningIsBlocked (): boolean {
      return this.alert.originalData.status !== STATUS.ACK &&
             this.alert.originalData.status !== STATUS.SHELVED
    },

    closingIsBlocked (): boolean {
      return this.alert.originalData.status === STATUS.CLOSED
    },

    shelvingIsBlocked (): boolean {
      return this.alert.originalData.status === STATUS.CLOSED ||
             this.alert.originalData.status === STATUS.SHELVED
    },

    lastPriority (): string|null {
      if (this.alert.originalData.history && this.alert.originalData.history.length > 1) {
        let lastUpdate: TAlertUpdate|null = null
        for (const update of this.alert.originalData.history) {
          if (update.updateTime !== undefined) {
            if ((lastUpdate && lastUpdate.updateTime !== undefined && lastUpdate.updateTime < update.updateTime) || !lastUpdate) {
              lastUpdate = update
            }
          }
        }
        if (lastUpdate) {
          return AlertsHelper.getPriority(lastUpdate.severity)
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },

  methods: {
    showRequestError (error: any) {
      const reason = error.response.data ? error.response.data.error : ''
      this.$notify({
        text: this.$t('alarms.details.control.action.error', { reason }) as string,
        type: 'error',
        group: 'requests',
        duration: 6000
      })
    },

    async setStatus (status: STATUS) {
      try {
        await this.$store.dispatch('alerts/setStatus', { alertId: this.alert.id, status: status })
      } catch (error) {
        this.showRequestError(error)
      }
    },

    async toggleWatched () {
      try {
        await this.$store.dispatch('alerts/toggleWatched', { alertId: this.alert.id, watched: !this.alert.watched })
      } catch (error) {
        this.showRequestError(error)
      }
    }
  },

  props: {
    alert: {
      required: true,
      type: Object as PropType<TAlertTableRow>
    }
  }
})
