





















































































import Vue from 'vue'
import moment from 'moment'
import LineChart from '@/components/ui/Charts/LineChart.vue'
import ComponentsList from '@/components/ui/ComponentsList.vue'
import { Component, ComponentInProject, ComponentInProjectAttribute, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'

export default Vue.extend({
  name: 'Operations',

  components: {
    ComponentsList,
    LineChart
  },

  computed: {
    hasSeries (): boolean {
      return this.series.length > 0
    },

    systemComponents (): Component[] {
      return this.$store.getters['componentsnew/getSystemComponents']
    },

    projectComponents (): ComponentInProject[] {
      if (this.selectedComponentId === null) return []
      else {
        return this.$store.getters['projects/getProjectComponentsOfComponent'](this.selectedComponentId)
      }
    },

    selectedProjectComponentsLinkAttributeValue (): string|null {
      if (this.selectedProjectComponentId === null) return null
      else {
        const selectedProjectComponent: ComponentInProjectWithContext = this.$store.getters['projects/getProjectComponentsOfComponent'](this.selectedComponentId).find((projectComponent: ComponentInProjectWithContext) => projectComponent.id === this.selectedProjectComponentId)
        if (selectedProjectComponent === null) return null
        else {
          const linkAttribute: ComponentInProjectAttribute|null = selectedProjectComponent?.attributes?.find((attribute: ComponentInProjectAttribute) => {
            return attribute.alphanumeric_id?.includes('+LINK')
          }) ?? null
          if (linkAttribute === null) return null
          else return linkAttribute.value ?? null
        }
      }
    },

    series (): Array<any> {
      return this.$store.state.operations.timeseries
    },

    isLoadingComponentDetails (): boolean {
      return this.$store.state.projects.loadingProjectComponentDetails
    },

    isLoadingTimeseries (): boolean {
      return this.$store.state.operations.loadingTimeseries
    },

    projectId (): number {
      return this.$store.getters['project/getProjectID']
    },

    showVisualizationWarning (): boolean {
      return this.series.length === 0 &&
        this.selectedComponentId !== null &&
        this.selectedProjectComponentId !== null
    },

    activePins: {
      get () {
        return this.$store.state.operations.activePins
      },

      set (pins: string[]) {
        this.$store.dispatch('operations/setActivePins', pins)
      }
    },

    dateRangeText (): string {
      if (this.visualizationRange.length > 1) {
        const sortedDates: string[] = this.getSortedDates(this.visualizationRange)
        return `${moment(sortedDates[0]).format('DD.MM.YYYY')} - ${moment(sortedDates[1]).format('DD.MM.YYYY')}`
      } else {
        return `${moment(this.visualizationRange[0]).format('DD.MM.YYYY')}`
      }
    }
  },

  data () {
    return {
      datepickerMenu: false as boolean,
      selectedComponentId: null as number|null,
      selectedProjectComponentId: null as number|null,
      visualizationRange: [
        moment().subtract(1, 'week').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ] as string[]
    }
  },

  destroyed () {
    this.$store.dispatch('operations/setActivePins', [])
    this.$store.dispatch('operations/clearTimeseries')
  },

  methods: {
    getSortedDates (dates: string[]) {
      if (dates.length > 0) {
        return dates.sort((a: string, b: string) => {
          return moment(a).isBefore(b) ? -1 : 1
        })
      } else {
        return dates
      }
    },

    onDatepickerMenuUpdate (value: boolean) {
      if (!value) {
        const sortedDates: string[] = this.getSortedDates(this.visualizationRange)
        this.$store.dispatch('operations/fetchTimeseriesForAllDatapoints', {
          dateRange: sortedDates
        })
      }
    },

    onSelectPin (payload: { dataPointID: string; selected: boolean; pinNames: { nameDE?: string; nameEN?: string } }) {
      const { dataPointID, selected } = payload
      const projectComponent: ComponentInProjectWithContext|null = this.projectComponents.find((projectComponent: ComponentInProjectWithContext) => projectComponent.id === this.selectedProjectComponentId) ?? null
      const componentName: string|null = projectComponent?.display_name ?? null

      // TODO: extract the determination of start and end-date into a separate method
      if (selected) {
        this.$store.dispatch('operations/fetchTimeseriesForDatapoint', {
          dataPointID,
          pinNames: payload.pinNames,
          name: componentName,
          dateRange: this.visualizationRange.map((date: string) => moment(date).toDate())
        })
      } else {
        this.$store.dispatch('operations/removeTimeseriesForDatapoint', dataPointID)
      }
    },

    onSystemComponentChange (componentId: number) {
      this.$store.dispatch('projects/fetchProjectComponents', { componentId })
      this.selectedComponentId = componentId
    },

    onProjectComponentChange (projectComponent: ComponentInProject) {
      if (!this.selectedProjectComponentId || this.selectedProjectComponentId !== projectComponent.id!) {
        this.selectedProjectComponentId = projectComponent.id!
        this.$store.dispatch('projects/fetchProjectComponentDetails', { componentInProjectId: projectComponent.id, componentId: projectComponent.component_id })
      }
    }
  }
})
