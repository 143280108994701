import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { TComponentsState, TProjectKpiTable } from './types'

export default {
  areComponentsLoadingForAllProjects: (state: TComponentsState): boolean => state.componentsInProjectsLoading,
  areComponentsInProjectLoading: (state: TComponentsState): boolean => state.componentInProjectLoading,
  areKpiTablesLoading: (state: TComponentsState): boolean => state.kpisLoading,
  getDigitalTwinForProject: (state: TComponentsState, project_id: number): TComponentInProjectWithContextResponse|undefined => {
    return state.digitalTwins[project_id]
  },
  getDigitalTwins: (state: TComponentsState): {
    [project_id: number]: TComponentInProjectWithContextResponse;
  } => state.digitalTwins,
  getKpiTables: (state: TComponentsState): {
    [project_id: number]: TProjectKpiTable;
  } => {
    return state.kpiTables
  }
} as GetterTree<TComponentsState, TRootState>
