








































































































































































































































































import { TProject } from '@/services/aedifion/resources/project/responseTypes'
import TooltipCard from '@/components/ui/TooltipCard.vue'
import Vue, { PropType } from 'vue'
import Card from './Card.vue'
import { STATUS, TAlert } from '@/services/alerta/resources/common/responseTypes'
import { TProjectKpiTable } from '@/store/components/types'

export default Vue.extend({
  props: {
    project: {
      required: false,
      type: Object as PropType<TProject>
    },
    alarms: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    energy: {
      required: false,
      type: String as PropType<string>,
      default: 'grey'
    },
    co2: {
      required: false,
      type: String as PropType<string>,
      default: 'grey'
    },
    status: {
      required: false,
      type: String as PropType<string>,
      default: 'grey'
    },
    access: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  components: {
    Card,
    TooltipCard
  },
  data () {
    return {
      dialog: false,
      dummyImage: require('../../assets/mock/dummy-image.jpg')
    }
  },
  methods: {
    async openSettingsModal (): Promise<void> {
      await this.$store.dispatch('project/selectProject', this.project.id)
      this.$emit('openProjectSettingsModal')
    }
  },

  computed: {
    kpiTable (): TProjectKpiTable {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.$store.state.components.kpiTables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === this.project.id)! as TProjectKpiTable ?? null
    },
    isValueCO2 (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.co2?.toFixed(0) ?? null
    },
    projectionValueCO2 (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.co2Projection?.toFixed(0) ?? null
    },
    CO2BenchmarkColor (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.benchmarks.co2_emissions
    },
    isValueElectricity (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.consumptionElectricityPerSquaredMeters?.toFixed(0) ?? null
    },
    isValueHeat (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.consumptionHeatPerSquaredMeters?.toFixed(0) ?? null
    },
    projectionValueEnergyConsumptionHeat (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.consumptionHeatProjection?.toFixed(0) ?? null
    },
    projectionValueEnergyConsumptionElectricity (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.consumptionElectricityProjection?.toFixed(0) ?? null
    },
    energyConsumptionElectricityColor (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.benchmarks.electricity_consumption
    },
    energyConsumptionHeatColor (): string|null {
      if (this.kpiTable === null) return null
      return this.kpiTable.benchmarks.heat_consumption
    },
    newAlarms (): number|null {
      const alarms = this.$store.getters['alerts/getProjectsOpenAlerts'](this.project.handle)
      if (alarms === null) return null
      else {
        return alarms.filter((alarm: TAlert) => alarm.status !== STATUS.ACK).length
      }
    },
    openAlarms (): number|null {
      const alarms = this.$store.getters['alerts/getProjectsOpenAlerts'](this.project.handle)
      if (alarms === null) return null
      else {
        return this.$store.getters['alerts/getProjectsOpenAlerts'](this.project.handle).length
      }
    }
  }
})
