import { GetterTree } from 'vuex'
import { TRootState } from '@/store/types'
import { TCompanyState } from './types'
import { TCompany } from '@/services/aedifion/resources/company/responseTypes'

export default {
  getCompany: (state: TCompanyState): TCompany|null => state.company,
  getCompanyName: (state: TCompanyState): string|null => state.company ? state.company.name : null,
  isCompanyLoading: (state: TCompanyState): boolean => state.loading
} as GetterTree<TCompanyState, TRootState>
