import {
  MutationTree
} from 'vuex'
import { TProjectState, TSystemFaultsData } from './types'
import get from 'lodash.get'
import { TComponentAttribute, TComponentInProjectWithContextResponse, TProjectWithContext, TPostComponentAttributeResponse } from '@/services/aedifion/resources/project/responseTypes'

export default {
  SELECT_PROJECT: (state: TProjectState, project: TProjectWithContext) => {
    state.project = project
    state.projectId = get(project, 'project.id', null)
  },
  SET_PROJECT_AVATAR_LOADING: (state: TProjectState, loading: boolean) => {
    state.loadingAvatar = loading
  },
  SET_PROJECT_ATTIRBUTE_LOADING: (state: TProjectState, loading: boolean) => {
    state.loadingAttribute = loading
  },
  REMOVE_PROJECT_AVATAR: (state: TProjectState) => {
    if (state.project && state.project.project && state.project.project.avatar_url) {
      state.project.project.avatar_url = undefined
    }
  },
  SET_PROJECT_AVATAR: (state: TProjectState, avatar_url: string) => {
    if (state.project && state.project.project) {
      state.project.project.avatar_url = avatar_url
    }
  },
  SET_PROJECT_UPDATE_LOADING: (state: TProjectState, loading: boolean) => {
    state.loadingDetails = loading
  },
  SET_PROJECT_UPDATE_DETAILS: (state: TProjectState, {
    name,
    description,
    latitude,
    longitude
  }: {
    name: string;
    description: string;
    latitude: string;
    longitude: string;
  }) => {
    if (state.project && state.project.project && state.project.project.name) {
      state.project.project.name = name
    }
    if (state.project && state.project.project && state.project.project.description) {
      state.project.project.description = description
    }
    if (state.project && state.project.project && state.project.project.latitude) {
      state.project.project.latitude = latitude
    }
    if (state.project && state.project.project && state.project.project.longitude) {
      state.project.project.longitude = longitude
    }
  },
  SET_PROJECTS_COMPONENTS_ATTRIBUTE_VALUE: (state: TProjectState, payload: TPostComponentAttributeResponse['resource']) => {
    const attributeIndex: number|undefined = state.digitalTwin?.attributes.findIndex((attribute: TComponentAttribute) => attribute.alphanumeric_id === payload.alphanumeric_id)
    if (state.digitalTwin && attributeIndex !== undefined && attributeIndex >= 0) {
      state.digitalTwin.attributes.splice(attributeIndex, 1, payload)
    }
  },
  DELETE_PROJECTS_COMPONENTS_ATTRIBUTE_VALUE: (state: TProjectState, payload: TPostComponentAttributeResponse['resource']) => {
    const attributeIndex: number|undefined = state.digitalTwin?.attributes.findIndex((attribute: TComponentAttribute) => attribute.alphanumeric_id === payload.alphanumeric_id)
    if (state.digitalTwin && attributeIndex !== undefined && attributeIndex >= 0) {
      state.digitalTwin.attributes.splice(attributeIndex, 1)
    }
  },
  SELECT_DIGITAL_TWIN: (state: TProjectState, payload: TComponentInProjectWithContextResponse) => {
    state.digitalTwin = payload
  },
  SET_LOADING_TIMEZONE: (state: TProjectState, loading: boolean) => {
    state.loadingTimezone = loading
  },
  SET_CURRENT_TIMEZONE: (state: TProjectState, timezone: string|null) => {
    state.currentTimezone = timezone
  },
  SET_LOADING_WEATHER: (state: TProjectState, loading: boolean) => {
    state.loadingWeather = loading
  },
  SET_CURRENT_WEATHER: (state: TProjectState, weather: any|null) => {
    state.currentWeather = weather
  },
  SET_LOADING_OCCUPANTS: (state: TProjectState, loading: boolean) => {
    state.loadingLatestOccupantsCount = loading
  },
  SET_LOADING_CAR_OCCUPANTS: (state: TProjectState, loading: boolean) => {
    state.loadingLatestCarOccupantsCount = loading
  },
  SET_OCCUPANTS: (state: TProjectState, occupants: number|null) => {
    state.latestOccupantsCount = occupants
  },
  SET_CAR_OCCUPANTS: (state: TProjectState, occupants: number|null) => {
    state.latestCarOccupantsCount = occupants
  },
  SET_FAULTS_LOADING: (state: TProjectState, loading: boolean) => {
    state.loadingFaults = loading
  },
  SET_FAULTS: (state: TProjectState, systemFaultsWithData: TSystemFaultsData) => {
    state.faults = systemFaultsWithData
  },
  UNSUBSCRIBE_PROJECTS_SUBSCRIPTION: (state: TProjectState) => {
    if (state.unsubscribeProjectsSubscription !== null) {
      state.unsubscribeProjectsSubscription()
      state.unsubscribeProjectsSubscription = null
    }
  }
} as MutationTree<TProjectState>
