








































import Vue, { PropType } from 'vue'
import Card from '@/components/ui/Card.vue'
import { TAlertTableRow } from '@/store/alerts/types'

export default Vue.extend({
  name: 'AlarmDetailsHistory',

  components: {
    Card
  },

  computed: {
    alertHistory (): Array<TAlertTableRow> {
      return this.$store.getters['alerts/getAlertUpdateTableData'](this.alert.id)
    }
  },

  data () {
    return {
      search: '',
      sortBy: 'date',
      sortDesc: true,
      headers: [
        {
          text: this.$t('alarms.headers.date'),
          align: 'start',
          value: 'date'
        },
        {
          text: this.$t('alarms.headers.priority'),
          value: 'priority'
        },
        {
          text: this.$t('alarms.headers.status'),
          value: 'status'
        },
        {
          text: this.$t('alarms.headers.value'),
          value: 'value'
        },
        {
          text: this.$t('alarms.headers.event'),
          value: 'event'
        }
      ]
    }
  },

  props: {
    alert: {
      required: true,
      type: Object as PropType<TAlertTableRow>
    }
  }
})
