




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import variablepie from 'highcharts/modules/variable-pie'
import nodata from 'highcharts/modules/no-data-to-display'

variablepie(Highcharts)
nodata(Highcharts)

export default Vue.extend({
  name: 'PieChart',
  components: {
    highcharts: Chart
  },
  props: {
    title: {
      required: false,
      type: String as PropType<string>
    },
    series: {
      required: true,
      type: Array as PropType<Array<{ name: string; data: { name: string; y: number } }>>
    },
    innerLabels: {
      required: false,
      type: Boolean as PropType<boolean>
    },
    innerSize: {
      required: false,
      type: String as PropType<string>,
      default: '30%'
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal === true) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  beforeCreate () {
    /**
     * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
     * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
     */
    Highcharts.setOptions({
      lang: {
        loading: this.$t('data.loading') as string,
        noData: this.$t('data.not_available') as string
      }
    })
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    chartOptions () {
      return {
        noData: {
          style: {
            fontFamily: 'myriad-pro',
            fontWeight: 'bold',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        loading: {
          useHTML: true
        },
        chart: {
          type: 'variablepie',
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E'
          }
        },
        plotOptions: {
          series: {
            animation: false
          },
          variablepie: {
            size: '100%',
            dataLabels: {
              color: '#fff',
              distance: this.innerLabels ? -60 : 0,
              alignTo: this.innerLabels ? undefined : 'connectors',
              style: {
                fontSize: 12
              }
            }
          }
        },
        colors: [
          '#004B8E',
          '#638A82',
          '#936362',
          '#9C9770',
          '#AEBFC9',
          '#931322',
          '#D4A92A'
        ],
        credits: {
          enabled: false
        },
        title: {
          text: this.title ? this.title : null,
          style: {
            color: '#004B8E'
          }
        },
        tooltip: { pointFormat: '<b>{point.absValue} {point.unit}</b>' },
        series: this.series.map((series: any) => {
          const { name, data, unit, absValue } = series
          return {
            name,
            data,
            minPointSize: 10,
            innerSize: this.innerSize,
            custom: {
              unit,
              absValue
            } as Highcharts.Dictionary<string>
          }
        })
      }
    }
  }
})
