import { TBenchmarkAttributeZones, TBenchmarkZones } from '@/utils/helpers/types'
import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TCO2EmissionState } from './types'
import get from 'lodash.get'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'

export default {
  getTargetCo2EmissionsValue: (state: TCO2EmissionState, getters, rootState): number|null => {
    if (!rootState.project.digitalTwin) return null
    const foundTargetValue: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_TAR_CO2_EMI')

    if (foundTargetValue === null) return null
    return parseFloat(foundTargetValue)
  },

  getHeatEmissions: (state: TCO2EmissionState): number|null => state.chart.heat,
  getElectricityEmissions: (state: TCO2EmissionState): number|null => state.chart.electricity,
  getPhotovoltaicsAvoidance: (state: TCO2EmissionState): number|null => state.chart.avoidance_photovoltaics,
  getSolarAvoidance: (state: TCO2EmissionState): number|null => state.chart.avoidance_solar,
  getCHPAvoidance: (state: TCO2EmissionState): number|null => state.chart.avoidance_chp,
  getHeatPumpAvoidance: (state: TCO2EmissionState): number|null => state.chart.avoidance_heat_pump,

  hasPhotovoltaicsSystem: (state: TCO2EmissionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+ELS+PV_CO2_EMI_AVD')
  },
  hasSolarSystem: (state: TCO2EmissionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+HS+SOL_CO2_EMI_AVD')
  },
  hasCHPSystem: (state: TCO2EmissionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+HS+CHP_CO2_EMI_AVD_SUM')
  },
  hasHeatPumpSystem: (state: TCO2EmissionState, getters, rootState): boolean => {
    return !!getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+HS+HP_CO2_EMI_AVD_SUM')
  },

  getCo2EmissionsBenchmark: (state: TCO2EmissionState): number|null => state.benchmark.co2Emission,
  getCo2EmissionsColor: (state: TCO2EmissionState): string|null => state.benchmark.co2EmissionColor,

  getYear: (state: TCO2EmissionState): string|number|null => state.year,
  getMonth: (state: TCO2EmissionState): string|number|null => state.month,
  getCo2EmissionsBenchmarkZones: (state: TCO2EmissionState, getters, rootState): TBenchmarkZones|null => {
    const benchmarkZones: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_EFZ')
    if (benchmarkZones === null) return null
    try {
      const benchmarks: TBenchmarkAttributeZones = JSON.parse(benchmarkZones)
      if (benchmarks.benchmark_co2_emissions) {
        return benchmarks.benchmark_co2_emissions
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },

  isYearSelected: (state: TCO2EmissionState): boolean => state.isYearSelected,
  isMonthSelected: (state: TCO2EmissionState): boolean => !state.isYearSelected,
  hasPrevious: (state: TCO2EmissionState): boolean => state.hasPrevious,
  hasNext: (state: TCO2EmissionState): boolean => state.hasNext,
  isLoadingData: (state: TCO2EmissionState): boolean => state.loading
} as GetterTree<TCO2EmissionState, TRootState>
