
























































































































import Vue, { PropType } from 'vue'
import moment from 'moment'
import { TAlert } from '@/services/alerta/resources/common/responseTypes'

export default Vue.extend({
  props: {
    warning: {
      required: false,
      type: Boolean as PropType<boolean>
    },
    error: {
      required: false,
      type: Boolean as PropType<boolean>
    },
    info: {
      required: false,
      type: Boolean as PropType<boolean>
    },
    title: {
      required: true,
      type: String as PropType<string>
    },
    icon: {
      required: true,
      type: String as PropType<string>
    },
    alarm: {
      required: false,
      type: Object as PropType<TAlert|null>
    },
    alarmCount: {
      required: false,
      type: Number as PropType<number|null>
    }
  },
  computed: {
    getColor (): string {
      return this.error ? 'error' : this.warning ? 'warning' : this.info ? 'grey' : 'green'
    },
    getLatestAlarmTime (): string {
      return this.alarm ? moment(this.alarm.lastReceiveTime).format('HH:mm:ss') : 'no information'
    },
    getLatestAlarmDate (): string {
      return this.alarm ? moment(this.alarm.lastReceiveTime).format('DD.MM.YY') : 'no information'
    }
  }
})
