/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { readLabels, storeLabels } from '@/services/database/labels'
import { ActionTree } from 'vuex'
import i18n from '@/plugins/i18n'
import { LabelsState } from './types'
import { aedifionApi, LabelsSystems } from '@aedifion.io/aedifion-api'
import Vue from 'vue'
import { TRootState } from '../types'

export default {
  /**
   * Requests label definitions from the API and stores them in the IndexedDB.
   * WARNING: This action should not be called manually because it will circumvent the store that
   * already holds the label definitions. Calling this action manually is unnecessary.
   * @param checksum The checksum of the label definitions.
   */
  fetchLabelDefinitions: async ({ commit, state }, locale: string): Promise<void> => {
    if (state.systems === null) return
    let labelDefinitions: any|null = null
    try {
      labelDefinitions = await readLabels(state.systems.labels_checksum!, locale)
      if (labelDefinitions !== null) {
        commit('SET_DEFINITIONS', labelDefinitions)
        return
      }
    } catch (error) {
      console.error(error)
    }

    state.loadingDefinitions = true
    const hasUsersLocale: boolean = state.systems?.locales?.some((systemsLocale: string) => systemsLocale.includes(locale)) ?? false
    const usedLocale = hasUsersLocale ? locale : 'en'
    try {
      labelDefinitions = await aedifionApi.Meta.getLabelsDefinitions(usedLocale)
      commit('SET_DEFINITIONS', labelDefinitions)
    } catch (error) {
      const errorMessage = `${i18n.t('notifications.errors.fetch', { resource: i18n.t('notifications.resources.label_definitions'), supportEmail: 'testMail' })}`
      Vue.notify({
        text: errorMessage,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
    } finally {
      state.loadingDefinitions = false
    }

    if (labelDefinitions !== null) {
      try {
        storeLabels(state.systems!.labels_checksum!, labelDefinitions, usedLocale)
      } catch (error) {
        console.error(error)
      }
    }
  },

  fetchLabelSystems: async ({ commit, dispatch, state }): Promise<void> => {
    state.loadingSystems = true
    let labelSystems: LabelsSystems|null = null

    try {
      labelSystems = await aedifionApi.Meta.getLabelsSystems()
      commit('SET_SYSTEMS', labelSystems)
    } catch (error) {
      const errorMessage = `${i18n.t('notifications.errors.fetch', { resource: i18n.t('notifications.resources.label_systems'), supportEmail: 'testMail' })}`
      Vue.notify({
        text: errorMessage,
        group: 'all',
        duration: 6000,
        type: 'error'
      })
    } finally {
      state.loadingSystems = false
    }

    if (labelSystems !== null) {
      dispatch('fetchLabelDefinitions', i18n.locale)
    }
  }
} as ActionTree<LabelsState, TRootState>
