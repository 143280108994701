import {
  Module
} from 'vuex'
import { TRootState } from '../types'
import { TEnergyManagementState } from './types'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
} as Module<TEnergyManagementState, TRootState>
