























import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    color: {
      required: true,
      type: String as PropType<string>
    },
    icon: {
      required: true,
      type: String as PropType<string>
    },
    title: {
      required: true,
      type: String as PropType<string>
    },
    selected: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>
    }
  }
})
