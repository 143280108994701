import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosResponse } from 'axios'
import { TWeatherCurrentResponse } from './responseTypes'
import { TWeatherCurrentRequest } from './requestTypes'

const resource = 'current.json'

export default {
  get: (request: {
    params?: TWeatherCurrentRequest;
  }): Promise<AxiosResponse<TWeatherCurrentResponse>> => {
    return axiosInstance.get(`/${resource}`, {
      params: request.params
    })
  }
} as IRepository
