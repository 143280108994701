import { TBenchmarkAttributeZones, TBenchmarkZones } from '@/utils/helpers/types'
import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TEnergyConsumptionState } from './types'
import get from 'lodash.get'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'

export default {
  getTargetHeatValue: (state: TEnergyConsumptionState, getters, rootState): number|null => {
    if (!rootState.project.digitalTwin) return null
    const foundSetValue: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_TAR_EN_H_CONSUM_MAX')

    if (foundSetValue === null) return null
    return parseFloat(foundSetValue)
  },
  getTargetElectricityValue: (state: TEnergyConsumptionState, getters, rootState): number|null => {
    if (!rootState.project.digitalTwin) return null
    const foundTargetValue: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_TAR_EN_EL_CONSUM_MAX')

    if (foundTargetValue === null) return null
    return parseFloat(foundTargetValue)
  },
  getElectricityConsumption: (state: TEnergyConsumptionState): number|null => state.chart.electricity,
  getHeatConsumption: (state: TEnergyConsumptionState): number|null => state.chart.heat,

  getElectricityConsumptionBenchmark: (state: TEnergyConsumptionState): number|null => state.benchmark.electricity,
  getHeatConsumptionBenchmark: (state: TEnergyConsumptionState): number|null => state.benchmark.heat,
  getElectricityConsumptionColor: (state: TEnergyConsumptionState): string|null => state.benchmark.electricityColor,
  getHeatConsumptionColor: (state: TEnergyConsumptionState): string|null => state.benchmark.heatColor,
  getYear: (state: TEnergyConsumptionState): string|number|null => state.year,
  getMonth: (state: TEnergyConsumptionState): string|number|null => state.month,
  getElectricityBenchmarkZones: (state: TEnergyConsumptionState, gettters, rootState): TBenchmarkZones|null => {
    const benchmarkZones: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_EFZ')
    if (benchmarkZones === null) return null
    try {
      const benchmarks: TBenchmarkAttributeZones = JSON.parse(benchmarkZones)
      if (benchmarks.benchmark_electricity_consumption) {
        return benchmarks.benchmark_electricity_consumption
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },
  getHeatBenchmarkZones: (state: TEnergyConsumptionState, getters, rootState): TBenchmarkZones|null => {
    const benchmarkZones: string|null = getAttrbiuteValueByAlphanumericId(get(rootState, 'project.digitalTwin.attributes'), 'B_EFZ')
    if (benchmarkZones === null) return null
    try {
      const benchmarks: TBenchmarkAttributeZones = JSON.parse(benchmarkZones)
      if (benchmarks.benchmark_heat_consumption) {
        return benchmarks.benchmark_heat_consumption
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },
  isYearSelected: (state: TEnergyConsumptionState): boolean => state.isYearSelected,
  isMonthSelected: (state: TEnergyConsumptionState): boolean => !state.isYearSelected,
  hasPrevious: (state: TEnergyConsumptionState): boolean => state.hasPrevious,
  hasNext: (state: TEnergyConsumptionState): boolean => state.hasNext,
  isLoadingData: (state: TEnergyConsumptionState): boolean => state.loading
} as GetterTree<TEnergyConsumptionState, TRootState>
