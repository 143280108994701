import { Module } from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { TElevatorsState } from './types'
import { TRootState } from '../types'

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
} as Module<TElevatorsState, TRootState>
