import get from 'lodash.get'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GetterTree } from 'vuex'

import moment from 'moment'

import { SEVERITY, STATUS, TAlert, TAlertUpdate } from '@/services/alerta/resources/common/responseTypes'
import { TEventType } from '@/store/project/types'
import { TRootState } from '../types'
import { TAlertsState, TAlertTableRow, TAlarmsFilter, TAlertUpdateTableRow } from './types'

import { AlertsHelper } from '@/utils/helpers/alerts'
import { AlertsFilterHelper } from '@/utils/helpers/alertsfilter'
import * as DateHelper from '@/utils/helpers/dates'

const alertToTableRow = (alert: TAlert, events: Array<TEventType>, usersEmail: string): TAlertTableRow => {
  const rawData: TAlert['rawData'] = alert.rawData ? JSON.parse(alert.rawData) : undefined
  return {
    id: alert.id,
    date: DateHelper.getRelativeDate(moment(alert.createTime)),
    name: rawData?.id ?? 'N/A',
    event: AlertsHelper.getEventName(alert, events),
    priority: AlertsHelper.getPriority(alert.severity),
    priorityColor: AlertsHelper.getPriorityColor(alert.severity),
    status: AlertsHelper.getTranslatedStatus(alert.status),
    type: AlertsHelper.getType(alert),
    value: String(alert.value),
    watched: AlertsHelper.isWatchedBy(alert, usersEmail),
    originalData: alert
  }
}

export default {
  hasProjectAlarmIndication: (state: TAlertsState) => (project_handle: string) => {
    const projectsAlerts: TAlert[] = state.alerts.alerts.filter((alert: TAlert) => get(alert, ['attributes', 'project_handle'], -1) === project_handle)
    let prioA = 0
    let prioB = 0

    projectsAlerts.forEach((alert: TAlert) => {
      if ([SEVERITY.CRITICAL, SEVERITY.WARNING].includes(alert.severity!) && alert.status === STATUS.OPEN) {
        if (alert.severity! === SEVERITY.CRITICAL) {
          prioA++
        } else if (alert.severity! === SEVERITY.WARNING) {
          prioB++
        }
      }
    })

    return prioA > 0 || prioB >= 4
  },

  getProjectsOpenAlerts: (state: TAlertsState) => (project_handle: string): TAlert[] =>
    state.alerts.alerts.filter(
      (alert: TAlert) =>
        get(alert, ['attributes', 'project_handle'], null) === project_handle && alert.status === STATUS.OPEN
    ),

  getProjectsAlerts: (state: TAlertsState) => (project_handle: string): TAlert[] =>
    state.alerts.alerts.filter(
      (alert: TAlert) =>
        get(
          alert,
          ['attributes', 'project_handle'],
          -1
        ) === project_handle
    ),

  getAlertName: (state: TAlertsState) => (alert_id: string): string|null => {
    const alert = state.alerts.alerts.find((alert: TAlert) => alert.id === alert_id)
    if (alert) {
      return alert.resource
    } else {
      return null
    }
  },

  getAlertRow: (state: TAlertsState, getters, rootState, rootGetters) => (alert_id: string): TAlertTableRow|null => {
    const alert = state.alerts.alerts.find((alert: TAlert) => alert.id === alert_id)
    if (alert === undefined) {
      // this should never happen
      return null
    } else {
      return alertToTableRow(alert, rootGetters['project/getEvents'], rootGetters['user/getUser'].email)
    }
  },

  getAlertUpdateTableData: (state: TAlertsState) => (alert_id: string): TAlertUpdateTableRow[] => {
    const alert = state.alerts.alerts.find((alert: TAlert) => alert.id === alert_id)
    if (alert === undefined || !alert.history) {
      return []
    }
    return alert.history.reduce((accumulator: Array<TAlertUpdateTableRow>, currentValue: TAlertUpdate) => {
      if (currentValue.type !== 'note') {
        accumulator.push({
          id: currentValue.id,
          date: DateHelper.getRelativeDate(moment(currentValue.updateTime)),
          event: currentValue.event,
          priority: AlertsHelper.getPriority(currentValue.severity),
          priorityColor: AlertsHelper.getPriorityColor(currentValue.severity),
          status: AlertsHelper.getTranslatedStatus(currentValue.status),
          value: String(currentValue.value)
        })
      }
      return accumulator
    }, new Array<TAlertUpdateTableRow>())
  },

  getFilteredAlertTableData: (state: TAlertsState, getters, rootState, rootGetters) => (project_handle: string, severities: SEVERITY[]): TAlertTableRow[] => {
    const filters = getters.getFilters
    const filtersByColumn = AlertsFilterHelper.sortFilterByColumn(filters)
    const usersEmail = rootGetters['user/getUser'].email

    const alerts: TAlert[] = state.alerts.alerts.filter(
      (alert: TAlert) => {
        const alertsProjectHandle = get(alert, ['attributes', 'project_handle'], null)
        const severity = get(alert, 'severity', SEVERITY.UNKOWN)
        if (alertsProjectHandle === project_handle && severities.includes(severity)) {
          return AlertsFilterHelper.alertMatchesFilter(alert, filtersByColumn, usersEmail)
        } else {
          return false
        }
      }
    )
    const events: Array<TEventType> = rootGetters['project/getEvents']
    return alerts.map((alert: TAlert) => {
      return alertToTableRow(alert, events, usersEmail)
    })
  },

  getFilters: (state: TAlertsState): Array<TAlarmsFilter> => {
    return state.filters
  },

  getAlertsNoteUpdates: (state: TAlertsState) => (alertId: string): Array<TAlertUpdate> => {
    const alert = state.alerts.alerts.find((alert: TAlert) => alert.id === alertId)
    if (alert === undefined || !alert.history) {
      return []
    }
    return alert.history.filter((alertUpdate: TAlertUpdate) => {
      return alertUpdate.type === 'note'
    })
  }
} as GetterTree<TAlertsState, TRootState>
