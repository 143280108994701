import moment from 'moment'
import { TElevatorsState } from './types'

export default {
  loading: true,
  loadingElevators: true,
  loadingAnalyticsFunctions: true,
  loadingInstances: true,
  loadingInstanceResults: true,
  loadingInstanceResult: true,
  elevators: [],
  functions: [],
  instances: [],
  results: [],
  analysis_result: null,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false
} as TElevatorsState
