import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TCharginStationsState } from './types'

export default {
  getStations: (state: TCharginStationsState): TComponentInProjectWithContextResponse[] => state.stations,

  getComponentsLoadingState: (state: TCharginStationsState): boolean => state.loadingComponents,
  getOccupancyAndOperationsLoadingState: (state: TCharginStationsState): boolean => state.loadingTimeseries,
  isLoadingTableData: (state: TCharginStationsState): boolean => state.loadingTableData,
  isLoadingChartData: (state: TCharginStationsState): boolean => state.loadingChartData,

  getOccupancy: (state: TCharginStationsState): number|null => state.occupancy,
  getRelativeUsage: (state: TCharginStationsState): number|null => state.relativeUsage,
  getUsageTime: (state: TCharginStationsState): number|null => state.usageTime,
  getNoUsageTime: (state: TCharginStationsState): number|null => state.noUsageTime,
  areAllStationsOperating: (state: TCharginStationsState): boolean|null => state.allSystemsRunning,
  hasStations: (state: TCharginStationsState): boolean => state.stations.length > 0,
  hasMissingOccupancy: (state: TCharginStationsState): boolean => {
    const datapoints: string[] = state.stations
      .map((station: TComponentInProjectWithContextResponse) => getPinsDatapointByAlphanumericId(station.pins, 'CST+STAT_OPR'))
      .filter((datapoint: string|null) => datapoint !== null) as string[]

    return state.stations.length > datapoints.length
  },
  getThisYearsUsageTime: (state: TCharginStationsState): number|null => state.absoluteUsageThisYear,
  getLastYearsUsageTime: (state: TCharginStationsState): number|null => state.absoluteUsageLastYear,
  getThisYearsElectricityConsumption: (state: TCharginStationsState): number|null => state.energyConsumptionThisYear,
  getLastYearsElectricityConsumption: (state: TCharginStationsState): number|null => state.energyConsumptionLastYear,

  getYear: (state: TCharginStationsState): string|number|null => state.year,
  getMonth: (state: TCharginStationsState): string|number|null => state.month,
  getAvailableHoursForSelectedTime: (state: TCharginStationsState) => state.hoursPerSelectedTime,

  isYearSelected: (state: TCharginStationsState): boolean => state.isYearSelected,
  isMonthSelected: (state: TCharginStationsState): boolean => !state.isYearSelected,
  hasPrevious: (state: TCharginStationsState): boolean => state.hasPrevious,
  hasNext: (state: TCharginStationsState): boolean => state.hasNext
} as GetterTree<TCharginStationsState, TRootState>
