import { AnalysisFunction, AnalysisResult, AnalysisResultSummery, InstanceConfig } from '@/services/aedifion/resources/analytics/responseTypes'
import moment, { Moment } from 'moment'
import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { TElevatorsState } from './types'

export default {
  getElevatorsAnalysisFunctionId: (state: TElevatorsState): number|null => {
    const elevatorsAnalysisFunction: AnalysisFunction|undefined = state.functions.find((analysisFunction: AnalysisFunction) => analysisFunction.alphanumeric_id === 'elevators_operating_time_analysis')
    if (elevatorsAnalysisFunction !== undefined) {
      return elevatorsAnalysisFunction.id
    }
    return null
  },
  getElevatorsAnalysisInstanceId: (state: TElevatorsState, getters): number|null => {
    const elevatorsFunctionId: number|null = getters.getElevatorsAnalysisFunctionId
    if (elevatorsFunctionId === null) return null
    const elevatorsAnalysisInstance: InstanceConfig|undefined = state.instances.find((instance: InstanceConfig) => instance.config.analysisfunction_id === elevatorsFunctionId)
    if (elevatorsAnalysisInstance !== undefined) {
      return elevatorsAnalysisInstance.id
    }
    return null
  },
  hasElevators: (state: TElevatorsState): boolean => state.elevators.length > 0,
  hasInstances: (state: TElevatorsState): boolean => state.instances.length > 0,
  monthlyAnalysisResults: (state: TElevatorsState): AnalysisResultSummery[] => {
    const monthlyResults: Map<string, AnalysisResultSummery> = new Map()
    state.results.filter((result: AnalysisResultSummery) => result.status === 'Success.').forEach((result: AnalysisResultSummery) => {
      const startDate: Moment = moment(result.input_parameters.start)
      const endDate: Moment = moment(result.input_parameters.end)

      const isStartAtFirstDayOfMonth = startDate.isSame(moment(startDate).startOf('month'), 'day')
      const isEndAtFirstDayOfNextMonth = endDate.isSame(moment(startDate).add(1, 'months').startOf('month'), 'day')

      if (isStartAtFirstDayOfMonth && isEndAtFirstDayOfNextMonth) {
        const yearAndMonth: string = startDate.format('YYYY-MM')
        monthlyResults.set(yearAndMonth, result)
      }
    })

    const monthlyAnalysisResults: AnalysisResultSummery[] = []
    monthlyResults.forEach((result: AnalysisResultSummery) => {
      monthlyAnalysisResults.push(result)
    })

    return monthlyAnalysisResults
  },
  yearlyAnalysisResults: (state: TElevatorsState): AnalysisResultSummery[] => {
    const yearlyResults: Map<string, AnalysisResultSummery> = new Map()

    state.results.filter((result: AnalysisResultSummery) => result.status === 'Success.').forEach((result: AnalysisResultSummery) => {
      const startDate: Moment = moment(result.input_parameters.start)
      const endDate: Moment = moment(result.input_parameters.end)

      const isStartAtFirstDayOfYear = startDate.isSame(moment(startDate).startOf('year'), 'day')
      const isEndAtFirstDayOfNextYear = endDate.isSame(moment(startDate).add(1, 'years').startOf('year'), 'day')

      if (isStartAtFirstDayOfYear && isEndAtFirstDayOfNextYear) {
        const year: string = startDate.format('YYYY')
        yearlyResults.set(year, result)
      }
    })

    const yearlyAnalysisResults: AnalysisResultSummery[] = []
    yearlyResults.forEach((result: AnalysisResultSummery) => {
      yearlyAnalysisResults.push(result)
    })

    return yearlyAnalysisResults
  },
  getAnalysisResult: (state: TElevatorsState): AnalysisResult|null => state.analysis_result,
  hasPrevious: (state: TElevatorsState, getters): boolean => {
    const currentSelection: Moment = moment({ year: state.year, month: state.month }).startOf('month')
    let resultArray: AnalysisResultSummery[] = []
    if (state.isYearSelected) {
      resultArray = getters.yearlyAnalysisResults
    } else {
      resultArray = getters.monthlyAnalysisResults
    }
    const previousResult: AnalysisResultSummery|undefined = resultArray.find((result: AnalysisResultSummery) => {
      const resultYear: number = moment(result.input_parameters.start).year()
      const resultMonth: number = moment(result.input_parameters.start).month()
      const resultDate: Moment = moment({ year: resultYear, month: resultMonth })
      if (state.isYearSelected) {
        return resultYear < state.year
      } else {
        return resultDate.isBefore(currentSelection)
      }
    })

    if (previousResult !== undefined) {
      return true
    }

    return false
  },
  hasNext: (state: TElevatorsState, getters): boolean => {
    const currentSelection: Moment = moment({ year: state.year, month: state.month }).startOf('month')
    let resultArray: AnalysisResultSummery[] = []
    if (state.isYearSelected) {
      resultArray = getters.yearlyAnalysisResults
    } else {
      resultArray = getters.monthlyAnalysisResults
    }
    const nextResult: AnalysisResultSummery|undefined = resultArray.find((result: AnalysisResultSummery) => {
      const resultYear: number = moment(result.input_parameters.start).year()
      const resultMonth: number = moment(result.input_parameters.start).month()
      const resultDate: Moment = moment({ year: resultYear, month: resultMonth })
      if (state.isYearSelected) {
        return resultYear > state.year
      } else {
        return resultDate.isAfter(currentSelection)
      }
    })

    if (nextResult !== undefined) {
      return true
    }

    return false
  },
  getAnalysisResultForCurrentTimeSelection: (state: TElevatorsState, getters): string|null => {
    let result_id: string|null = null
    if (state.isYearSelected) {
      const resultSummery: AnalysisResultSummery|undefined = getters.yearlyAnalysisResults.find((result: AnalysisResultSummery) => {
        return moment(result.input_parameters.start).year() === state.year
      })
      if (resultSummery !== undefined) {
        result_id = resultSummery.result_id
      }
    } else {
      const resultSummery: AnalysisResultSummery|undefined = getters.monthlyAnalysisResults.find((result: AnalysisResultSummery) => {
        return (moment(result.input_parameters.start).year() === state.year) && (moment(result.input_parameters.start).month() === state.month)
      })
      if (resultSummery !== undefined) {
        result_id = resultSummery.result_id
      }
    }
    return result_id
  },
  isYearSelected: (state: TElevatorsState): boolean => state.isYearSelected,
  isMonthSelected: (state: TElevatorsState): boolean => !state.isYearSelected,
  getMonth: (state: TElevatorsState): number => state.month,
  getYear: (state: TElevatorsState): number => state.year,
  isLoading: (state: TElevatorsState): boolean => state.loading,
  isLoadingResult: (state: TElevatorsState): boolean => state.loadingInstanceResult
} as GetterTree<TElevatorsState, TRootState>
