

















































import Vue, { PropType } from 'vue'
import moment from 'moment'

import { TAlertUpdate } from '@/services/alerta/resources/common/responseTypes'
import { TNote } from '@/store/alerts/types'

import * as DateHelper from '@/utils/helpers/dates'

export default Vue.extend({
  name: 'NoteCard',

  computed: {
    formattedDate (): string {
      return `<i>${DateHelper.getRelativeDate(moment(this.update.updateTime))}</i>`
    },

    note (): TNote {
      return this.update.text ? JSON.parse(this.update.text) : {}
    }
  },

  props: {
    update: {
      required: true,
      type: Object as PropType<TAlertUpdate>
    }
  }
})
