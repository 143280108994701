import { getMeterPinUnitByAlphanumericID, meterCounterPinIDs, meterHasOneEnergyManagementPin } from '@/utils/helpers/meters'
import {
  GetterTree
} from 'vuex'
import get from 'lodash.get'
import { TRootState } from '../types'
import { TEnergyManagementState, TFlattenedMeterComponent } from './types'
import { getAPILanguageResponseKey } from '@/utils/helpers/translations'
import { computeTotalConsumptionValue, getLastValue } from '@/utils/helpers/timeseries'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'
import i18n from '@/plugins/i18n'
import { AssignedPin, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'

function getTypeName (alphanumeric_id: string|null): string {
  if (alphanumeric_id === null) {
    return i18n.t('energy_management.meters.table.unknown') as string
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (alphanumeric_id!.includes('ELM')) {
    return i18n.t('energy_management.meters.table.ELM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('GASM')) {
    return i18n.t('energy_management.meters.table.GASM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('WSM')) {
    return i18n.t('energy_management.meters.table.WSM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('HM')) {
    return i18n.t('energy_management.meters.table.HM') as string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (alphanumeric_id!.includes('CM')) {
    return i18n.t('energy_management.meters.table.CM') as string
  } else {
    return i18n.t('energy_management.meters.table.unknown') as string
  }
}

function formatNumber (num: number|null) {
  if (num === null) return null

  if (num % 1 !== 0 && num.toString().split('.')[1].length > 2) {
    return Number(num.toFixed(2))
  }
  return num
}

export default {
  isLoadingMeters: (state: TEnergyManagementState): boolean => state.loadingMeters,
  isLoadingMetersLatestTimeseries: (state: TEnergyManagementState): boolean => state.loadingMetersLatestTimeseries,
  showConsumption: (state: TEnergyManagementState): boolean => state.showConsumption,
  getMeters: (state: TEnergyManagementState): TFlattenedMeterComponent[] => {
    const showConsumption: boolean = state.showConsumption
    const requiredMeters: TFlattenedMeterComponent[] = state.meters
      .filter(
        (meter: ComponentInProjectWithContext) => meterHasOneEnergyManagementPin(meter)
      )
      .map((meter: ComponentInProjectWithContext) => {
        const meterName = get(meter, 'nameEN', i18n.t('energy_management.meters.no_name_specified') as string)
        const meterNumberAttribute = getAttrbiuteValueByAlphanumericId(meter.attributes!, '+M_NUM') ?? i18n.t('energy_management.meters.no_number_specified') as string
        const meterMedia: string = get(meter, `component.${getAPILanguageResponseKey()}`, 'No media specified') // Media
        const meterPins = meter.pins!
          .filter((pin: AssignedPin) => meterCounterPinIDs.includes(pin.alphanumeric_id!))
          .map((pin: AssignedPin) => {
            const monthlyObservations = [
              getLastValue(get(state, ['timeseries', 0, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 1, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 2, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 3, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 4, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 5, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 6, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 7, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 8, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 9, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 10, get(pin, 'dataPointID', '')], [])),
              getLastValue(get(state, ['timeseries', 11, get(pin, 'dataPointID', '')], []))
            ]

            const monthlyConsumption = monthlyObservations.map((observation: number|null, index) => {
              if (index === 0) {
                return null
              }

              if (observation === null || monthlyObservations[index - 1] === null) {
                return null
              } else {
                return observation - monthlyObservations[index - 1]!
              }
            })

            let consumptionOfJanuary = null
            const lastValueOfLastYear = getLastValue(get(state, `timeseriesOfLastYear.${get(pin, 'dataPointID', undefined)}`, []))
            if (lastValueOfLastYear !== null && monthlyObservations[0] !== null) {
              consumptionOfJanuary = monthlyObservations[0] - lastValueOfLastYear
            }

            const monthlyValues = {
              jan: showConsumption ? formatNumber(consumptionOfJanuary) : formatNumber(monthlyObservations[0]),
              feb: showConsumption ? formatNumber(monthlyConsumption[1]) : formatNumber(monthlyObservations[1]),
              mar: showConsumption ? formatNumber(monthlyConsumption[2]) : formatNumber(monthlyObservations[2]),
              apr: showConsumption ? formatNumber(monthlyConsumption[3]) : formatNumber(monthlyObservations[3]),
              may: showConsumption ? formatNumber(monthlyConsumption[4]) : formatNumber(monthlyObservations[4]),
              jun: showConsumption ? formatNumber(monthlyConsumption[5]) : formatNumber(monthlyObservations[5]),
              jul: showConsumption ? formatNumber(monthlyConsumption[6]) : formatNumber(monthlyObservations[6]),
              aug: showConsumption ? formatNumber(monthlyConsumption[7]) : formatNumber(monthlyObservations[7]),
              sep: showConsumption ? formatNumber(monthlyConsumption[8]) : formatNumber(monthlyObservations[8]),
              oct: showConsumption ? formatNumber(monthlyConsumption[9]) : formatNumber(monthlyObservations[9]),
              nov: showConsumption ? formatNumber(monthlyConsumption[10]) : formatNumber(monthlyObservations[10]),
              dec: showConsumption ? formatNumber(monthlyConsumption[11]) : formatNumber(monthlyObservations[11])
            }

            const type = getTypeName(get(pin, 'alphanumeric_id', null))

            return {
              id: get(pin, 'dataPointID', 'No datapoint ID specified'), // Identifier
              alphanumeric_id: get(pin, 'alphanumeric_id', 'No alphanumeric ID specified'), // Alphanumeric ID
              type,
              name: meterName, // Name
              originalName: get(pin, 'dataPointID', 'No datapoint ID specified'), // Datapoint ID
              identifier: meterNumberAttribute, // Counter number
              media: meterMedia, // Media
              unit: getMeterPinUnitByAlphanumericID(get(pin, 'alphanumeric_id', null)),
              selectable: true,
              meter_id: meter.id!,
              monthly: monthlyValues
            }
          })
        return meterPins
      })
      .flat()

    return requiredMeters
  }
} as GetterTree<TEnergyManagementState, TRootState>
