import Vue from 'vue'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { TUserState } from './types'
import { IRepository } from '@/utils/types/repository'
import { TUserAvatarUplaodResponse, TUserUpdatePasswordResponse, TUserUpdateResponse, TUserWithContext } from '@/services/aedifion/resources/user/responseTypes'
import { AxiosResponse } from 'axios'
import { TUserUpdatePasswordRequest, TUserUpdateRequest } from '@/services/aedifion/resources/user/requestTypes'

// Load the aedifionApiRepository for users
import aedifionApiRepository from '@/services/aedifion'
import i18n from '@/plugins/i18n'
import { TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
const User: IRepository = aedifionApiRepository.get('user')

export default {
  fetch: async ({ commit, rootState }) => {
    const token = rootState.auth.access_token

    commit('SET_USER_LOADING', true)
    try {
      const userResponse: AxiosResponse<TUserWithContext> = await User.get({ token })
      const user: TUserWithContext = userResponse.data
      commit('company/SET_COMPANY', user.company, { root: true })
      commit('SET_USER', { user: user.user, password_lock: user.password_locked_until })
      commit('SET_USER_ROLES', { companyRoles: user.companyroles, projectRoles: user.roles })
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_USER_LOADING', false)
    }
  },
  fetchProjects: async ({ commit, rootState }) => {
    const token = rootState.auth.access_token

    commit('projects/SET_PROJECTS_LOADING', true, { root: true })
    try {
      const projectsResponse: AxiosResponse<TProjectWithContext[]> = await User.getProjects({ token })
      const projects: TProjectWithContext[] = projectsResponse.data
      commit('projects/SET_PROJECTS', { projects }, { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      commit('projects/SET_PROJECTS_LOADING', false, { root: true })
    }
  },
  update: async ({ commit, rootState }, userPayload: TUserUpdateRequest) => {
    const token = rootState.auth.access_token

    commit('SET_USER_LOADING', true)
    try {
      const userResponse: AxiosResponse<TUserUpdateResponse> = await User.update({
        token,
        body: userPayload
      })
      const user: TUserUpdateResponse = userResponse.data
      commit('SET_USER', { user: user.resource })
      Vue.notify({
        text: i18n.t('account.user.update.success') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
    } catch (error) {
      if (error.code && error.code !== 401) {
        Vue.notify({
          text: i18n.t('account.user.update.error', { reason: error.response.data.error }) as string,
          group: 'requests',
          duration: 6000,
          type: 'error'
        })
      }
      throw error
    } finally {
      commit('SET_USER_LOADING', false)
    }
  },
  uploadAvatar: async ({ commit, rootState }, formData: FormData) => {
    const token = rootState.auth.access_token

    commit('SET_USER_LOADING', true)
    try {
      const userAvatarResponse: AxiosResponse<TUserAvatarUplaodResponse> = await User.uploadAvatar({
        token,
        body: formData
      })
      const user: TUserAvatarUplaodResponse = userAvatarResponse.data
      commit('SET_USER_AVATAR', user.resource.avatar_url)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit('SET_USER_LOADING', false)
    }
  },
  updatePassword: async ({ rootState }, payload: TUserUpdatePasswordRequest) => {
    const token = rootState.auth.access_token
    try {
      const userPasswordResponse: AxiosResponse<TUserUpdatePasswordResponse> = await User.updatePassword({
        token,
        body: payload
      })
      const passwordResponse: TUserUpdatePasswordResponse = userPasswordResponse.data
      Vue.notify({
        text: i18n.t('account.user.passwordupdate.success') as string,
        group: 'requests',
        duration: 6000,
        type: 'success'
      })
      return passwordResponse
    } catch (error) {
      console.error(error)
      Vue.notify({
        text: i18n.t('account.user.passwordupdate.error', { reason: error.response.data.error }) as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      throw error
    }
  }
} as ActionTree<TUserState, TRootState>
