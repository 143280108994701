




















































































import { TEnergyGenerationTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'Heat',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyGenerationData (): TEnergyGenerationTable|null {
      return this.$store.getters['media_tables/getEnergyGeneration']
    },
    gasBoiler (): TEnergyGenerationTable['gas_boiler']|null {
      if (this.energyGenerationData === null) return null
      return this.energyGenerationData.gas_boiler
    },
    heatGenerationOfCurrentYear (): string|null {
      if (this.gasBoiler === null) return null
      return this.gasBoiler.heat_generation.currentYear !== null ? formatValue(this.gasBoiler.heat_generation.currentYear, 0) : this.gasBoiler.heat_generation.currentYear
    },
    heatGenerationOfLastYear (): string|null {
      if (this.gasBoiler === null) return null
      return this.gasBoiler.heat_generation.lastYear !== null ? formatValue(this.gasBoiler.heat_generation.lastYear, 0) : this.gasBoiler.heat_generation.lastYear
    },
    fullLoadHoursOfCurrentYear (): string|null {
      if (this.gasBoiler === null) return null
      return this.gasBoiler.full_load_hours.currentYear !== null ? formatValue(this.gasBoiler.full_load_hours.currentYear, 0) : this.gasBoiler.full_load_hours.currentYear
    },
    fullLoadHoursOfLastYear (): string|null {
      if (this.gasBoiler === null) return null
      return this.gasBoiler.full_load_hours.lastYear !== null ? formatValue(this.gasBoiler.full_load_hours.lastYear, 0) : this.gasBoiler.full_load_hours.lastYear
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
