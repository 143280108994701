var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-card',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"primary--text",attrs:{"hide-details":"","label":_vm.$t('alarms.search.label'),"outlined":"","placeholder":_vm.$t('alarms.search.placeholder')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fas fa-search ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('AlarmsFilter',{staticClass:"mr-4"}),_c('AlarmsFilterChips')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"primary--text",attrs:{"headers":_vm.headers,"items":_vm.alerts,"search":_vm.search},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.priorityColor,"dark":""}},[_vm._v(" "+_vm._s(item.priority)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:[item.watched ? 'mr-1' : ''],attrs:{"color":"primary","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")]),(item.watched)?_c('v-chip',{attrs:{"color":"primary","outlined":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" fas fa-bookmark ")])],1):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatValue")(item.value,2))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","to":{ name: 'alarm', params: { alarm: item.id }}}},[_vm._v(" "+_vm._s(_vm.$t('alarms.actions.details'))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }