import { AssignedPin, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'

export const meterCounterPinIDs: string[] = [
  'CM+EN_C',
  'GASM+VOL',
  'WSM+VOL',
  'ELM+EN_EL_ACT',
  'ELM+EN_EL_REA',
  'HM+EN_H'
]

export const meterHasOneEnergyManagementPin = (meter: ComponentInProjectWithContext): boolean => {
  return meter.pins!.filter((pin: AssignedPin) => meterCounterPinIDs.includes(pin.alphanumeric_id!)).length > 0
}

export const getMeterPinUnitByAlphanumericID = (alphanumeric_id: string|null): string|null => {
  if (alphanumeric_id === null) return null
  switch (alphanumeric_id) {
    case 'HM+EN_H':
      return 'kWh'
    case 'ELM+EN_EL_ACT':
      return 'kWh'
    case 'ELM+EN_EL_REA':
      return 'MVarh'
    default:
      return null
  }
}
