import {
  GetterTree
} from 'vuex'
import weatherCodes from '@/services/weatherapi/resources/conditions.json'
import { TRootState } from '../types'
import { TEventType, TProjectState, TSystemFaultsData } from './types'
import get from 'lodash.get'
import { TComponentAttribute, TComponentInProjectWithContextResponse, TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import i18n from '@/plugins/i18n'
import { getAttrbiuteValueByAlphanumericId } from '@/utils/helpers/attributes'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { TProjectKpiTable } from '../components/types'

export default {
  getProjectID: (state: TProjectState): number|null => {
    return state.projectId
  },
  getProjectHandle: (state: TProjectState): string|null => {
    return get(state, 'project.project.handle', null)
  },
  getProjectAvatar: (state: TProjectState): string|undefined => {
    return get(state, 'project.project.avatar_url', `${window.location.origin}/img/dummy-image.jpg`)
  },
  getTotalBenchmarkColor: (state: TProjectState, getters, rootState, rootGetters): string => {
    const kpitables: TProjectKpiTable[] = rootGetters['components/getKpiTables']
    const kpis: TProjectKpiTable|undefined = kpitables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === getters.getProjectID)
    if (kpis) {
      let redSum = 0
      let yellowSum = 0
      let greenSum = 0
      Object.values(kpis.benchmarks).forEach((benchmark: any) => {
        if (benchmark === 'red') {
          redSum++
        } else if (benchmark === 'yellow') {
          yellowSum++
        } else if (benchmark === 'green') {
          greenSum++
        }
      })
      if (redSum > 0) {
        return 'error'
      } else if (yellowSum > 0) {
        return 'warning'
      } else if (greenSum > 0) {
        return 'success'
      }
      return 'grey'
    }
    return 'grey'
  },
  hasEnergyBenchmarkIssues (state: TProjectState, getters, rootState, rootGetters): boolean {
    const kpitables: TProjectKpiTable[] = rootGetters['components/getKpiTables']
    const kpis: TProjectKpiTable|undefined = kpitables.find((kpiTable: TProjectKpiTable) => kpiTable.project_id === getters.getProjectID)
    if (kpis) {
      return kpis.benchmarks.electricity_consumption === 'red' || kpis.benchmarks.heat_consumption === 'red'
    }
    return false
  },
  isProjectLoading: (state: TProjectState): boolean => state.loading,
  isLoadingProjectAvatar: (state: TProjectState): boolean => state.loadingAvatar,
  isLoadingProjectDetails: (state: TProjectState): boolean => state.loadingDetails,
  isLoadingAttribute: (state: TProjectState): boolean => state.loadingAttribute,
  getProject: (state: TProjectState): TProjectWithContext|null => state.project,
  getDigitalTwin: (state: TProjectState): TComponentInProjectWithContextResponse|null => state.digitalTwin,
  getProjectsLatLng: (state: TProjectState): { lng: number; lat: number }|null => {
    const lng = get(state, 'project.project.longitude', null)
    const lat = get(state, 'project.project.latitude', null)

    if (lat === null || lng === null) return null

    try {
      const latLng: { lng: number; lat: number } = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }
      return latLng
    } catch (error) {
      console.error(error)
      return null
    }
  },
  isProjectsTimezoneLoading: (state: TProjectState): boolean => {
    return state.loadingTimezone
  },
  getProjectsTimezone: (state: TProjectState): string|null => {
    return state.currentTimezone
  },
  isProjectsWeatherLoading: (state: TProjectState): boolean => state.loadingWeather,
  getProjectsWeather: (state: TProjectState): any => state.currentWeather,
  getProjectsWeatherLocale: (state: TProjectState): null|string => {
    if (!state.currentWeather) return null
    const weatherCode = weatherCodes.find((codeObj: any) => codeObj.code === state.currentWeather.current.condition.code)
    if (weatherCode) {
      const weatherLocale = weatherCode.languages.find((language: any) => language.lang_iso === i18n.locale.slice(0, 2) as string)
      if (weatherLocale) {
        return weatherLocale.day_text
      }
    }

    return null
  },
  getProjectsGrossFloorArea: (state: TProjectState): number|null => {
    if (!state.digitalTwin) return null
    const grossFloorArea: string|null = getAttrbiuteValueByAlphanumericId(state.digitalTwin.attributes, 'B_GFA_AV')
    if (grossFloorArea) return parseFloat(grossFloorArea)
    return null
  },
  getOccupantsLimit: (state: TProjectState): number|null => {
    if (!state.digitalTwin) return null
    const limit: string|null = getAttrbiuteValueByAlphanumericId(state.digitalTwin.attributes, 'B_COUNT_PEO_MAX')
    if (limit) return parseFloat(limit)
    return null
  },
  getOccupantsDataPointID: (state: TProjectState): string|null => {
    if (!state.digitalTwin) return null
    return getPinsDatapointByAlphanumericId(state.digitalTwin.pins, 'B+COUNT_PEO')
  },
  getOccupants: (state: TProjectState): number|null => state.latestOccupantsCount,
  isOccupantsLoading: (state: TProjectState): boolean => state.loadingLatestOccupantsCount,
  getCarOccupantsLimit: (state: TProjectState): number|null => {
    if (!state.digitalTwin) return null
    const limit = getAttrbiuteValueByAlphanumericId(state.digitalTwin.attributes, 'B_COUNT_CAR_MAX')
    if (limit) return parseFloat(limit)
    return null
  },
  getCarOccupantsDataPointID: (state: TProjectState): string|null => {
    if (!state.digitalTwin) return null
    return getPinsDatapointByAlphanumericId(state.digitalTwin.pins, 'B+VEH_PRKG+COUNT_CAR')
  },
  getCarOccupants: (state: TProjectState): number|null => state.latestCarOccupantsCount,
  isCarOccupantsLoading: (state: TProjectState): boolean => state.loadingLatestCarOccupantsCount,
  isFaultsLoading: (state: TProjectState): boolean => state.loadingFaults,
  getFaults: (state: TProjectState): TSystemFaultsData => state.faults,
  getCoolingSystemFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+CS_MALFS'], undefined),
  getCompressedAirFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+AIR_COMP_MALFS'], undefined),
  getElevatorFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELE_MALFS'], undefined),
  getFireAlarmFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+FALS_MALFS'], undefined),
  getGasWarningFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+GALS_MALFS'], undefined),
  getGateFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+GATS_MALFS'], undefined),
  getHeatingSystemFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+HS_MALFS'], undefined),
  getMainDoorFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELS+DOOR_MN_MALFS'], undefined),
  getPowerSupplyFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELS+GPS_MALFS'], undefined),
  getPressureBoostingSystemFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+WAS+WS_PBS_MALFS'], undefined),
  getSafetyLightingFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELS+SLIGS_MALFS'], undefined),
  getShadingSystemFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELS+SHA_MALFS'], undefined),
  getTurnstileFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+ELS+TUS_MALFS'], undefined),
  getVentilationFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+VENS_MALFS'], undefined),
  getWaterLevelDetectorFault: (state: TProjectState): number|undefined|null => get(state, ['faults', 'B+WAS+WS_LEV_MALFS'], undefined),
  getEvents: (state: TProjectState): TEventType[] => {
    if (!state.digitalTwin) return []
    const events: TComponentAttribute|undefined = state.digitalTwin.attributes.find((attribute: TComponentAttribute) => {
      return attribute.alphanumeric_id === 'B_EVS'
    })

    if (events !== undefined && !!events.value) {
      return JSON.parse(events.value) as TEventType[]
    } else {
      return []
    }
  }
} as GetterTree<TProjectState, TRootState>
