




















































































import { TEnergyGenerationTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'BHKW_th',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyGenerationData (): TEnergyGenerationTable|null {
      return this.$store.getters['media_tables/getEnergyGeneration']
    },
    bhkw_th (): TEnergyGenerationTable['bhkw_th']|null {
      if (this.energyGenerationData === null) return null
      return this.energyGenerationData.bhkw_th
    },
    heatGenerationOfCurrentYear (): string|null {
      if (this.bhkw_th === null) return null
      return this.bhkw_th.heat_generation.currentYear !== null ? formatValue(this.bhkw_th.heat_generation.currentYear, 0) : null
    },
    heatGenerationOfLastYear (): string|null {
      if (this.bhkw_th === null) return null
      return this.bhkw_th.heat_generation.lastYear !== null ? formatValue(this.bhkw_th.heat_generation.lastYear, 0) : null
    },
    fullLoadHoursOfCurrentYear (): string|null {
      if (this.bhkw_th === null) return null
      return this.bhkw_th.full_load_hours.currentYear !== null ? formatValue(this.bhkw_th.full_load_hours.currentYear, 0) : null
    },
    fullLoadHoursOfLastYear (): string|null {
      if (this.bhkw_th === null) return null
      return this.bhkw_th.full_load_hours.lastYear !== null ? formatValue(this.bhkw_th.full_load_hours.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
