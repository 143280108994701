import Vue from 'vue'
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'

// Content routes
import Portfolio from '../views/Portfolio.vue'
import Project from '../views/Project/Project.vue'
import Energy from '../views/EnergyManagement/Energy.vue'
import Alarms from '../views/Alarms/Alarms.vue'
import AlarmDetails from '../views/Alarms/AlarmDetails.vue'
import Operations from '../views/Operations.vue'
import Account from '../views/Account.vue'
import NotFound from '../views/404.vue'
import AuthSilentRenew from '@/views/AuthSilentRenew.vue'

// Authentication routes
import AuthCallback from '@/components/functional/AuthCallback.vue'
import AuthFailed from '../views/AuthFailed.vue'
import i18n from '@/plugins/i18n'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    component: AuthSilentRenew,
    meta: {
      title: i18n.t('auth.silentRenew.title')
    },
    name: 'auth-silent-renew',
    path: '/auth-silent-renew'
  },
  {
    path: '/',
    name: 'portfolio',
    component: Portfolio,
    meta: {
      title: i18n.t('links.meta.title.portfolio')
    }
  },
  {
    path: '/project',
    redirect: '/',
    name: 'projects'
  },
  {
    path: '/project/:id',
    component: Project,
    name: 'project',
    meta: {
      title: i18n.t('links.meta.title.project'),
      requiresDigitalTwin: true,
      allowsProjectModal: true
    }
  },
  {
    path: '/project/:id/energy',
    name: 'energy',
    component: Energy,
    meta: {
      title: i18n.t('links.meta.title.energy'),
      allowsProjectModal: true,
      requiresDigitalTwin: true
    }
  },
  {
    path: '/project/:id/alarms',
    name: 'alarms',
    component: Alarms,
    meta: {
      title: i18n.t('links.meta.title.alarms'),
      requiresDigitalTwin: true,
      allowsProjectModal: true
    }
  },
  {
    path: '/project/:id/alarms/:alarm',
    name: 'alarm',
    component: AlarmDetails,
    meta: {
      title: i18n.t('links.meta.title.alarmdetails'),
      allowsProjectModal: true
    }
  },
  {
    path: '/project/:id/operations',
    name: 'operations',
    component: Operations,
    meta: {
      title: i18n.t('links.meta.title.operations'),
      allowsProjectModal: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: i18n.t('links.meta.title.account')
    }
  },
  {
    path: '/auth',
    name: 'auth-callback',
    component: AuthCallback,
    meta: {
      title: i18n.t('links.meta.title.forwarding')
    }
  },
  {
    path: '/auth-failed',
    name: 'auth-failed',
    component: AuthFailed,
    meta: {
      title: i18n.t('links.meta.title.login_required')
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: i18n.t('links.meta.title.not_found')
    }
  }
]

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        behavior: 'smooth',
        selector: to.hash
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
} as RouterOptions)

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'auth'))

// check if project resource is required and fetch it
router.beforeEach(async (to, from, next) => {
  if (to.params.id) {
    try {
      const projectSetSuccessfully: boolean = await store.dispatch('project/selectProject', parseInt(to.params.id))
      store.commit('project/UNSUBSCRIBE_PROJECTS_SUBSCRIPTION')
      if (projectSetSuccessfully) next()
      else next({ name: 'not-found' })
    } catch (error) {
      next({ name: 'not-found' })
    }
  } else {
    next()
  }
})

// check if digital twin resource is required and fetch it
router.beforeEach(async (to, from, next) => {
  if (to.params.id && to.meta.requiresDigitalTwin) {
    try {
      const digitalTwinSetSuccessfully: boolean = await store.dispatch('project/selectDigitalTwin', parseInt(to.params.id))
      if (digitalTwinSetSuccessfully) next()
      else next({ name: 'portfolio', query: { error: 'digital-twin' } })
    } catch (error) {
      next({ name: 'portfolio', query: { error: 'digital-twin' } })
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = 'ROM Building Connect | ' + to.meta.title
  })
})

export default router as VueRouter
