import { TProjectState } from './types'

export default {
  unsubscribeProjectsSubscription: null,
  loading: false,
  loadingAvatar: false,
  loadingDetails: false,
  loadingAttribute: false,
  project: null,
  projectId: null,
  digitalTwin: null,
  kpis: null,
  loadingTimezone: false,
  currentTimezone: null,
  loadingWeather: false,
  currentWeather: null,
  loadingLatestOccupantsCount: false,
  loadingLatestCarOccupantsCount: false,
  latestOccupantsCount: null,
  latestCarOccupantsCount: null,
  loadingFaults: true,
  faults: {}
} as TProjectState
