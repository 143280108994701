import { render, staticRenderFns } from "./Splash.vue?vue&type=template&id=7892e500&scoped=true&"
import script from "./Splash.vue?vue&type=script&lang=ts&"
export * from "./Splash.vue?vue&type=script&lang=ts&"
import style0 from "./Splash.vue?vue&type=style&index=0&id=7892e500&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7892e500",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VImg,VOverlay})
