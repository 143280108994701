import moment from 'moment'

import { TAlert } from '@/services/alerta/resources/common/responseTypes'
import { TAlarmsFilter } from '@/store/alerts/types'
import { TEventType } from '@/store/project/types'
import { AlertsHelper } from '@/utils/helpers/alerts'
import get from 'lodash.get'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import { getTranslatedEventName } from './events'

export class AlertsFilterHelper {
/**
   * Iterates over a list of filters and turns them into a map in which the filtered columns are the keys and their filters the values.
   * @param filters The filters that should be sorted.
   * @returns Filters per column.
   */
  static sortFilterByColumn (filters: Array<TAlarmsFilter>): Map<string, Array<TAlarmsFilter>> {
    const result = new Map()
    if (filters) {
      for (const filter of filters) {
        if (result.has(filter.column)) {
          const filtersForColumn = result.get(filter.column)
          filtersForColumn.push(filter)
          result.set(filter.column, filtersForColumn)
        } else {
          result.set(filter.column, [filter])
        }
      }
    }
    return result
  }

  /**
   * Checks if an alert matches a set of filters, which have to be sorted by their columns.
   * @param alert The alert that has to be checked.
   * @param filtersByColumn Filters, sorted per column.
   * @returns true if the alert matches all filters, otherwise false.
   */
  static alertMatchesFilter (alert: TAlert, filtersByColumn: Map<string, Array<TAlarmsFilter>>, usersEmail: string): boolean {
    if (filtersByColumn.size === 0) {
      return true
    }
    let result = true
    for (const [column, filters] of filtersByColumn) {
      if (column === 'createTime') {
        if (alert.createTime) {
          const createTimeAsMoment = moment(alert.createTime)
          let lowerLimit
          let upperLimit
          if (Array.isArray(filters[0].value) && filters[0].value.length > 1) {
            const limits = filters[0].value as Array<string>
            const sortedLimits = limits.sort()
            lowerLimit = moment(sortedLimits[0])
            upperLimit = moment(sortedLimits[1])
          } else {
            lowerLimit = upperLimit = moment(String(filters[0].value))
          }
          result = createTimeAsMoment.isSameOrAfter(lowerLimit, 'days') && createTimeAsMoment.isSameOrBefore(upperLimit, 'days')
        } else {
          result = false
        }
      } else if (column === 'severity') {
        result = alert.severity !== undefined && filters.find(item => item.value === alert.severity) !== undefined
      } else if (column === 'status') {
        result = alert.status !== undefined && filters.find(item => item.value === alert.status) !== undefined
      } else if (column === 'type') {
        result = alert.type !== undefined && filters.find(item => item.value === alert.type) !== undefined
      } else if (column === 'watched') {
        result = AlertsHelper.isWatchedBy(alert, usersEmail)
      } else if (column === 'category') {
        result = filters.find(item => item.value === get(alert, 'attributes.category', null)) !== undefined
      } else {
        console.warn('Unhandled filter column: ' + column)
      }
      if (!result) {
        break
      }
    }
    return result
  }

  /**
   * Converts all filters encoded in an URL query with a specific key into a list of filters.
   * @param key The key that should be converted.
   * @param query The URL query which may contain filters with the key.
   * @param options All possible values for the filters with the key.
   * @returns The list of converted filters.
   */
  static convertUrlQueryKeyToFilter (key: string, query: Record<string, any>, options: Array<{ text: string; value: string}>): Array<TAlarmsFilter> {
    let values = query[key]
    if (!Array.isArray(values)) {
      values = [values]
    }
    const result = [] as Array<TAlarmsFilter>
    for (const value of values) {
      const matchingOption = options.find(item => (item.value === value))
      if (matchingOption) {
        result.push({
          text: matchingOption.text,
          column: key,
          value: value
        })
      } else {
        console.warn(`'${value}' is not a valid filter for column '${key}'.`)
      }
    }
    return result
  }

  /**
   * Converts a URL query into a list of filters.
   * @param query The URL query which may contain filters.
   * @returns The list of converted filters.
   */
  static convertUrlQueryToFilters (query: Record<string, any>): Array<TAlarmsFilter> {
    const result = [] as Array<TAlarmsFilter>
    for (const key of Object.keys(query)) {
      if (key === 'createTime') {
        const value = query[key]
        if (Array.isArray(value)) {
          const date1 = moment(value[0])
          const date2 = moment(value[1])
          const start = moment.min(date1, date2)
          const end = moment.max(date1, date2)
          result.push({
            text: `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`,
            column: key,
            value: [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
          })
        } else {
          const date = moment(value)
          result.push({
            text: date.format('DD.MM.YYYY'),
            column: key,
            value: date.format('YYYY-MM-DD')
          })
        }
      } else if (key === 'severity') {
        const filter = AlertsFilterHelper.convertUrlQueryKeyToFilter('severity', query, AlertsHelper.allPriorities)
        Array.prototype.push.apply(result, filter)
      } else if (key === 'status') {
        const filter = AlertsFilterHelper.convertUrlQueryKeyToFilter('status', query, AlertsHelper.allStatuses)
        Array.prototype.push.apply(result, filter)
      } else if (key === 'type') {
        const filter = AlertsFilterHelper.convertUrlQueryKeyToFilter('type', query, AlertsHelper.allTypes)
        Array.prototype.push.apply(result, filter)
      } else if (key === 'watched') {
        result.push({
          text: String(i18n.t('alarms.filter.watchedOnlyValue')),
          column: 'watched',
          value: true
        })
      } else if (key === 'category') {
        const events: TEventType[] = store.getters['project/getEvents']
        const filterOptions = events.map(event => {
          return {
            // here we can also assume that event.name is never undefined and always returns a correct name
            text: getTranslatedEventName(event.name) as string,
            value: event.category
          }
        })
        const filter = AlertsFilterHelper.convertUrlQueryKeyToFilter('category', query, filterOptions)
        Array.prototype.push.apply(result, filter)
      } else {
        console.warn(`'${key}' is not a valid alarms filter column.`)
      }
    }
    return result
  }

  /**
   * Converts a list of filters into an URL query.
   * @param filters The list of filters to convert.
   * @returns The converted URL query.
   */
  static convertFiltersToUrlQuery (filters: Array<TAlarmsFilter>): Record<string, any> {
    const result = {}
    const filtersByColumn = AlertsFilterHelper.sortFilterByColumn(filters)
    for (const [column, filters] of filtersByColumn) {
      const filterValues = filters.map(item => item.value)
      if (filterValues.length === 1) {
        Object.assign(result, {
          [column]: filterValues[0]
        })
      } else {
        Object.assign(result, {
          [column]: filterValues
        })
      }
    }
    return result
  }
}
