











































































































































import { AssignedPin, Component, ComponentInProject } from '@aedifion.io/aedifion-api'
import Vue, { PropType } from 'vue'

const componentsWithoutIcons = ['S', 'B', 'ELE']

export default Vue.extend({
  name: 'ComponentsList',

  data () {
    return {
      activeProjectComponents: [] as Array<any>,
      compareIsActive: true as boolean,
      selectedSystemComponent: null as number | null,
      activeComponent: null as number|null,
      selectedPins: this.value as Array<string>
    }
  },

  props: {
    systemComponents: {
      required: true,
      type: Array as PropType<Component[]>
    },
    projectComponents: {
      required: true,
      default: () => [],
      type: Array as PropType<ComponentInProject[]>
    },
    loadingSystemComponents: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>
    },
    loadingProjectComponents: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>
    },
    isLoadingComponentDetails: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>
    },
    value: {
      required: false,
      default: () => [],
      type: Array as PropType<Array<string>>
    }
  },

  computed: {
    componentIcon (): string|null {
      if (this.systemComponent !== null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (componentsWithoutIcons.includes(this.systemComponent.alphanumeric_id!)) {
          return 'far fa-layer-plus'
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return `fak fa-${this.systemComponent.alphanumeric_id!.toLowerCase().replaceAll('_', '-')}`
        }
      } else return null
    },

    systemComponent (): Component|null {
      if (this.selectedSystemComponent !== null) {
        const foundSystemComponent: Component|undefined = this.systemComponents.find((component: Component) => component.id === this.selectedSystemComponent)
        if (foundSystemComponent) {
          return foundSystemComponent
        } else return null
      } else return null
    },

    systemComponentSelectItems () {
      return this.systemComponents.map((component: Component) => {
        const localeName: string = this.getLocaleName(component)
        return {
          text: localeName,
          value: component.id
        }
      })
    }
  },

  methods: {
    getLocaleName (item: Component | ComponentInProject | AssignedPin): string {
      if (this.$i18n.locale.includes('de')) {
        return item.nameDE ?? item.nameEN!
      } else {
        return item.nameEN!
      }
    },

    onSelectComponentHandler (item: ComponentInProject) {
      this.activeComponent = item.id!
      this.$emit('select-component', item)
    },

    onSelectPinHandler (dataPointID: string, nameDE?: string, nameEN?: string) {
      this.$emit('input', this.selectedPins)
      if (this.selectedPins.includes(dataPointID)) {
        this.$emit('select-pin', {
          dataPointID,
          selected: true,
          pinNames: {
            nameDE,
            nameEN
          }
        })
      } else {
        this.$emit('select-pin', {
          dataPointID,
          selected: false,
          pinNames: {
            nameDE,
            nameEN
          }
        })
      }
    },

    onSystemComponentChange (systemComponentId: number) {
      this.$emit('select-system-component', systemComponentId)
    }
  }
})
