import { TCharginStationsState } from './types'
import moment from 'moment'

export default {
  loadingComponents: true,
  loadingTimeseries: true,
  loadingTableData: true,
  loadingChartData: true,
  stations: [],
  currentUsage: null,
  absoluteUsageThisYear: null,
  absoluteUsageLastYear: null,
  relativeUsage: null,
  usageTime: null,
  noUsageTime: null,
  hoursPerSelectedTime: 0,
  energyConsumptionThisYear: null,
  energyConsumptionLastYear: null,
  allSystemsRunning: null,
  occupancy: null,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false
} as TCharginStationsState
