/**
 * Alphanumeric IDs for datapoints that are required to compute the faults
 */
export const faultSystems: string[] = [
  'B+CS_MALFS', // cold systems
  'B+AIR_COMP_MALFS', // compresed air
  'B+ELE_MALFS', // elevators
  'B+FALS_MALFS', // fire alarms
  'B+GALS_MALFS', // gas warning systems
  'B+GATS_MALFS', // gate systems
  'B+HS_MALFS', // heating systems
  'B+ELS+DOOR_MN_MALFS', // main doors
  'B+ELS+GPS_MALFS', // power supplies
  'B+WAS+WS_PBS_MALFS', // pressure boosting systems
  'B+ELS+SLIGS_MALFS', // safety lightings
  'B+ELS+SHA_MALFS', // shading systems
  'B+ELS+TUS_MALFS', // turnstiles
  'B+VENS_MALFS', // ventilation systems
  'B+WAS+WS_LEV_MALFS' // water level detector
]
