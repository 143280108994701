import { TCO2EmissionState } from './types'
import moment from 'moment'

export default {
  chart: {
    heat: null,
    electricity: null,
    avoidance_photovoltaics: null,
    avoidance_solar: null,
    avoidance_chp: null,
    avoidance_heat_pump: null
  },
  loading: true,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false,
  benchmark: {
    co2Emission: null,
    co2EmissionColor: null,
    co2Projection: null
  }
} as TCO2EmissionState
