import moment from 'moment'
import i18n from '@/plugins/i18n'

/**
 * This function returns a start date for timeseries requests with selectable years and months.
 * @param yearSelected Whether the year-selection is enabled - if not, the function assumes that the month-selection is enabled
 * @param year The currently selected year.
 * @param month The currently selected month (index notation, starting with 0 = January).
 * @param yearBefore Whether a previous year is requested.
 * @param monthBefore Whether a previous month is requested.
 * @param yearAfter Whether the next year is requested.
 * @param monthAfter Whehter the next month is requested.
 */
export const getStartDate = (yearSelected: boolean, year: number, month: number, yearBefore?: boolean, monthBefore?: boolean, yearAfter?: boolean, monthAfter?: boolean): string => {
  if (yearSelected) {
    if (yearBefore) {
      return moment({ year, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'year').toISOString(true)
    } else if (yearAfter) {
      return moment({ year, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'year').toISOString(true)
    } else {
      return moment({ year, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(true)
    }
  } else {
    if (monthBefore) {
      return moment({ year, month, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'month').toISOString(true)
    } else if (monthAfter) {
      return moment({ year, month, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'month').toISOString(true)
    } else {
      return moment({ year, month, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(true)
    }
  }
}

export const getEndDate = (yearSelected: boolean, year: number, month: number, yearBefore?: boolean, monthBefore?: boolean, yearAfter?: boolean, monthAfter?: boolean): string => {
  const now = moment()
  if (yearSelected) {
    if (yearBefore) {
      return moment({ year: year - 1 }).endOf('year').toISOString(true)
    } else if (yearAfter) {
      return moment({ year: year + 1 }).endOf('year').toISOString(true)
    } else {
      if (year === now.year()) {
        return now.set({ year }).toISOString(true)
      } else {
        return moment({ year }).endOf('year').toISOString(true)
      }
    }
  } else {
    const isThisMonth: boolean = (year === now.year() && month === now.month())
    if (monthBefore) {
      if (isThisMonth) {
        return moment().set({ year, month }).subtract(1, 'month').endOf('month').toISOString(true)
      } else {
        return moment({
          year,
          month
        })
          .subtract(1, 'month')
          .endOf('month')
          .toISOString(true)
      }
    } else if (monthAfter) {
      return moment().set({
        year,
        month,
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999
      }).add(1, 'month').endOf('month').toISOString(true)
    } else {
      if (isThisMonth) {
        return moment().set({ year, month }).toISOString(true)
      } else {
        return moment().set({
          year,
          month,
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999
        }).endOf('month').toISOString(true)
      }
    }
  }
}

export const getRelativeDate = (original: moment.Moment): string => {
  const now = moment()
  const formattedDate = original.format('DD.MM.YYYY, HH:mm:ss')
  if (original.year() === now.year()) {
    if (original.dayOfYear() === now.dayOfYear()) {
      return `${i18n.t('alarms.date.today')}, ${formattedDate}`
    } else if (original.dayOfYear() === now.dayOfYear() - 1) {
      return `${i18n.t('alarms.date.yesterday')}, ${formattedDate}`
    }
  }
  return formattedDate
}
