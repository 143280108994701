import Vue from 'vue'
import {
  ActionTree
} from 'vuex'
import { TRootState } from '../types'
import { TWaterConsumptionState } from './types'
import moment, { Moment } from 'moment'
import get from 'lodash.get'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import { getEndDate, getStartDate } from '@/utils/helpers/dates'
import { computeTotalConsumptionValue } from '@/utils/helpers/timeseries'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { INTERPOLATION_METHODS } from '@/services/aedifion/resources/project/requestTypes'
const Project: IRepository = aedifionApiRepository.get('projects')

export default {
  fetchData: async ({ commit, state, rootState, rootGetters }) => {
    commit('SET_LOADING_DATA', true)
    const token = rootGetters['auth/oidcAccessToken']
    const project_id = rootGetters['project/getProjectID']
    const month: number = get(state, 'month', moment().month())
    const year: number = get(state, 'year', moment().year())
    const hasYear = state.isYearSelected

    const freshWater: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+WAS+WS_FRESH_CONSUM')
    const grayWater: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+WAS+WS_GRAY_CONSUM')
    const rainWater: string|null = getPinsDatapointByAlphanumericId(get(rootState, 'project.digitalTwin.pins', []), 'B+WAS+RAIN_WS_CONSUM')

    if (!freshWater && !grayWater && !rainWater) {
      commit('SET_LOADING_DATA', false)
      return null
    }
    const datapoints: string[] = []
    if (freshWater) datapoints.push(freshWater)
    if (grayWater) datapoints.push(grayWater)
    if (rainWater) datapoints.push(rainWater)
    try {
      const timeseriesResponse: AxiosResponse<[string, number][]>[] = await Promise.all([
        Project.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: datapoints.join(','),
            start: getStartDate(hasYear, year, month),
            end: getEndDate(hasYear, year, month),
            samplerate: '1d',
            short: true,
            closed_interval: true
          }
        }),
        Project.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: datapoints.join(','),
            end: getEndDate(hasYear, year, month, hasYear, !hasYear),
            short: true,
            max: 1,
            interpolation: INTERPOLATION_METHODS.PREVIOUS
          }
        }),
        Project.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: datapoints.join(','),
            start: getStartDate(hasYear, year, month, false, false, hasYear, !hasYear),
            end: '2100-01-01',
            samplerate: '1d',
            short: true,
            closed_interval: false,
            interpolation: INTERPOLATION_METHODS.NONE
          }
        })
      ])

      const hasPreviousData = Object.keys(timeseriesResponse[1].data).filter((datapoint: string) => {
        return get(timeseriesResponse[1], `data.${datapoint}`, []).length > 0
      }).length > 0

      const hasNextData = Object.keys(timeseriesResponse[2].data).filter((datapoint: string) => {
        return get(timeseriesResponse[2], `data.${datapoint}`, []).length > 0
      }).length > 0

      // compute consumption and store in the state
      const freshWaterConsumption = computeTotalConsumptionValue(get(timeseriesResponse[0], `data.${freshWater}`, null))
      const grayWaterConsumption = computeTotalConsumptionValue(get(timeseriesResponse[0], `data.${grayWater}`, null))
      const rainWaterConsumption = computeTotalConsumptionValue(get(timeseriesResponse[0], `data.${rainWater}`, null))
      commit('SET_DATA', {
        fresh_water: freshWaterConsumption,
        gray_water: grayWaterConsumption,
        rain_water: rainWaterConsumption,
        hasPreviousData,
        hasNextData
      })
    } catch (error) {
      Vue.notify({
        text: 'Water consumption data could not be fetched' as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return error
    } finally {
      commit('SET_LOADING_DATA', false)
    }
  },
  switchChartToMonth: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', false)
    dispatch('fetchData')
  },
  switchChartToYear: ({ commit, dispatch }) => {
    commit('SET_YEAR_SELECTED', true)
    dispatch('fetchData')
  },
  selectPreviousYear: ({ state, commit, dispatch }) => {
    const previous: number = get(state, 'year', moment().year()) - 1
    commit('SWITCH_YEAR', previous)
    dispatch('fetchData')
  },
  selectPreviousMonth: ({ state, commit, dispatch }) => {
    const previous: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).subtract(1, 'month')
    commit('SWITCH_MONTH', previous.month())
    commit('SWITCH_YEAR', previous.year())
    dispatch('fetchData')
  },
  selectNextYear: ({ state, commit, dispatch }) => {
    const next: number = get(state, 'year', moment().year()) + 1
    commit('SWITCH_YEAR', next)
    dispatch('fetchData')
  },
  selectNextMonth: ({ state, commit, dispatch }) => {
    const next: Moment = moment({ month: get(state, 'month', moment().month()), year: get(state, 'year', moment().year()) }).add(1, 'month')
    commit('SWITCH_MONTH', next.month())
    commit('SWITCH_YEAR', next.year())
    dispatch('fetchData')
  }
} as ActionTree<TWaterConsumptionState, TRootState>
