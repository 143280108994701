




















































































import { TEnergyGenerationTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'Photovoltaic',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyGenerationData (): TEnergyGenerationTable|null {
      return this.$store.getters['media_tables/getEnergyGeneration']
    },
    photovoltaic (): TEnergyGenerationTable['photovoltaic']|null {
      if (this.energyGenerationData === null) return null
      return this.energyGenerationData.photovoltaic
    },
    powerPeakOfCurrentYear (): string|null {
      if (this.photovoltaic === null) return null
      return this.photovoltaic.power_peak.currentYear !== null ? formatValue(this.photovoltaic.power_peak.currentYear, 0) : null
    },
    powerPeakOfLastYear (): string|null {
      if (this.photovoltaic === null) return null
      return this.photovoltaic.power_peak.lastYear !== null ? formatValue(this.photovoltaic.power_peak.lastYear, 0) : null
    },
    revenueOfCurrentYear (): string|null {
      if (this.photovoltaic === null) return null
      return this.photovoltaic.revenue.currentYear !== null ? formatValue(this.photovoltaic.revenue.currentYear, 0) : null
    },
    revenueOfLastYear (): string|null {
      if (this.photovoltaic === null) return null
      return this.photovoltaic.revenue.lastYear !== null ? formatValue(this.photovoltaic.revenue.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
