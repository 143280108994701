







































import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FaultCard',

  computed: {
    statusColor (): string {
      return this.color.includes('--border') ? this.color.split('--border')[0] : this.color
    }
  },

  props: {
    borderedColor: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>
    },
    title: {
      required: true,
      type: String as PropType<string>
    },
    icon: {
      required: true,
      type: String as PropType<string>
    },
    tooltip: {
      required: true,
      type: String as PropType<string>
    },
    color: {
      required: false,
      type: String as PropType<string>,
      default: 'grey'
    }
  }
})
