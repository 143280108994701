
























































import Vue from 'vue'
import moment from 'moment'
import get from 'lodash.get'
import AreaChart from '@/components/ui/Charts/AreaChart.vue'
import Card from '@/components/ui/Card.vue'

import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import { TAssignedPin, TComponentInProjectWithContextResponse, TObservationShort } from '@/services/aedifion/resources/project/responseTypes'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
const Datapoint: IRepository = aedifionApiRepository.get('datapoints')

type TAreaChartSeriesType = {
  name: string;
  data: Array<[string, number|null]>;
  color: string;
  tooltip: {
    pointFormat: string;
  };
}

export default Vue.extend({
  name: 'AnnualDurationLines',
  components: {
    AreaChart,
    Card
  },
  data () {
    return {
      thisYearsHours: moment.duration(moment().endOf('year').diff(moment().startOf('year'))).asHours() as number,
      electricitySeries: [
        {
          name: this.$t('energy_management.annual_duration_lines.electricity.series_name'),
          data: [] as unknown as TAreaChartSeriesType['data'],
          color: '#004B8E',
          tooltip: {
            pointFormat: this.$t('duration_line.point_format')
          }
        }
      ] as TAreaChartSeriesType[],
      heatSeries: [
        {
          name: this.$t('energy_management.annual_duration_lines.heat.series_name'),
          data: [] as unknown as TAreaChartSeriesType['data'],
          color: '#D4A92A',
          tooltip: {
            pointFormat: this.$t('duration_line.point_format')
          }
        }
      ] as TAreaChartSeriesType[],
      heatError: null as null|string,
      electricityError: null as null|string,
      loadingHeat: false,
      loadingElectricity: false
    }
  },
  computed: {
    currentYear (): number {
      return moment().year()
    }
  },
  methods: {
    parseTimeseriesData (timeseries: TObservationShort[]): Array<[string, number|null]> {
      return timeseries
        .sort((a: TObservationShort, b: TObservationShort) => { return b[1] - a[1] })
        .map((obs: TObservationShort) => {
          return [
            moment(obs[0]).format('DD.MM.YYYY, HH:mm:ss'),
            obs[1] !== null && obs[1] !== undefined ? parseInt(obs[1].toFixed(0)) : obs[1]
          ]
        })
    }
  },
  async mounted () {
    const token: string = this.$store.getters['auth/oidcAccessToken']
    const project_id: string = this.$store.getters['project/getProjectID']

    const digitalTwin: TComponentInProjectWithContextResponse = this.$store.getters['project/getDigitalTwin']
    const pins: TAssignedPin[] = get(digitalTwin, 'pins', [])
    const electricityDatapoint: string|null = getPinsDatapointByAlphanumericId(pins, 'B+ELS+EN_EL_CONSUM_SUM_15m')
    const heatDatapoint: string|null = getPinsDatapointByAlphanumericId(pins, 'B+EN_H_CONSUM_SUM_15m')

    if (electricityDatapoint !== null) {
      try {
        this.loadingElectricity = true
        const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Datapoint.getTimeseries({
          token,
          params: {
            project_id,
            dataPointID: electricityDatapoint,
            short: true,
            start: moment().startOf('year').toISOString(true),
            end: moment().toISOString(true),
            interpolation: 'none',
            samplerate: '1h',
            closed_interval: false
          }
        })

        this.electricitySeries[0].data = this.parseTimeseriesData(timeseriesResponse.data)
      } catch (error) {
        this.electricityError = get(error, 'response.data.message', this.$t('energy_management.requests.annual_duration_lines.failed_request'))
      } finally {
        this.loadingElectricity = false
      }
    } else {
      this.electricityError = this.$t('energy_management.requests.annual_duration_lines.no_datapoint') as string
    }

    if (heatDatapoint !== null) {
      try {
        this.loadingHeat = true
        const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Datapoint.getTimeseries({
          token,
          params: {
            project_id,
            dataPointID: heatDatapoint,
            short: true,
            start: moment().startOf('year').toISOString(true),
            end: moment().endOf('year').toISOString(true),
            interpolation: 'none',
            samplerate: '1h',
            closed_interval: false
          }
        })

        this.heatSeries[0].data = this.parseTimeseriesData(timeseriesResponse.data)
      } catch (error) {
        this.heatError = get(error, 'response.data.message', this.$t('energy_management.requests.annual_duration_lines.failed_request'))
      } finally {
        this.loadingHeat = false
      }
    } else {
      this.heatError = this.$t('energy_management.requests.annual_duration_lines.no_datapoint') as string
    }
  }
})
