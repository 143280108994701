import { ComponentsState } from './types'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { aedifionApi, Component } from '@aedifion.io/aedifion-api'
import Vue from 'vue'
import i18n from '@/plugins/i18n'

export default {
  async fetchComponents ({ commit }) {
    commit('SET_COMPONENTS_LOADING', true)

    try {
      const components: Component[] = await aedifionApi.Component.getComponents()
      commit('SET_COMPONENTS', components)
    } catch (error) {
      Vue.notify({
        text: i18n.t('components.fetch.error') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      commit('SET_COMPONENTS_LOADING', false)
    }
  }
} as ActionTree<ComponentsState, TRootState>
