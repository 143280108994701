import { TEnergyConsumptionState } from './types'
import moment from 'moment'

export default {
  chart: {
    electricity: null,
    heat: null
  },
  loading: true,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false,
  benchmark: {
    heat: null,
    heatProjection: null,
    electricity: null,
    electricityProjection: null,
    heatColor: null,
    electricityColor: null
  }
} as TEnergyConsumptionState
