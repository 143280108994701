





























import Vue from 'vue'
import FaultCard from '@/components/ui/FaultCard.vue'
import { getFaultColorByStatusCode, getFaultTranslationStringByStatusCode } from '@/utils/helpers/faults'

export default Vue.extend({
  name: 'Faults',
  components: {
    FaultCard
  },
  data () {
    return {
      faultsubscription: null as any
    }
  },
  mounted () {
    this.faultsubscription = setInterval(() => {
      this.$store.dispatch('project/fetchFaults', true)
    }, 5000)
  },
  destroyed () {
    clearInterval(this.faultsubscription)
  },
  computed: {
    availableFaults (): any[] {
      return this.faults.filter((fault: any) => fault.available === true)
    },

    loadingFaults (): boolean {
      return this.$store.getters['project/isFaultsLoading']
    },

    faults (): any[] {
      return [
        {
          title: this.$t('faults.systems.cooling_system'),
          icon: 'fal fa-temperature-frigid',
          color: getFaultColorByStatusCode(this.$store.getters['project/getCoolingSystemFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getCoolingSystemFault'])),
          available: this.$store.getters['project/getCoolingSystemFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.heating_system'),
          icon: 'fal fa-heat',
          color: getFaultColorByStatusCode(this.$store.getters['project/getHeatingSystemFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getHeatingSystemFault'])),
          available: this.$store.getters['project/getHeatingSystemFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.power_supply'),
          icon: 'fal fa-plug',
          color: getFaultColorByStatusCode(this.$store.getters['project/getPowerSupplyFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getPowerSupplyFault'])),
          available: this.$store.getters['project/getPowerSupplyFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.air_handling_systems'),
          icon: 'fal fa-fan',
          color: getFaultColorByStatusCode(this.$store.getters['project/getVentilationFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getVentilationFault'])),
          available: this.$store.getters['project/getVentilationFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.pressure_increase'),
          icon: 'fal fa-tachometer',
          color: getFaultColorByStatusCode(this.$store.getters['project/getPressureBoostingSystemFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getPressureBoostingSystemFault'])),
          available: this.$store.getters['project/getPressureBoostingSystemFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.fire_alarm_system'),
          icon: 'fal fa-fire',
          color: getFaultColorByStatusCode(this.$store.getters['project/getFireAlarmFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getFireAlarmFault'])),
          available: this.$store.getters['project/getFireAlarmFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.elevators'),
          icon: 'fal fa-sort',
          color: getFaultColorByStatusCode(this.$store.getters['project/getElevatorFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getElevatorFault'])),
          available: this.$store.getters['project/getElevatorFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.gas_warning_system'),
          icon: 'fal fa-burn',
          color: getFaultColorByStatusCode(this.$store.getters['project/getGasWarningFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getGasWarningFault'])),
          available: this.$store.getters['project/getGasWarningFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.safety_lighting'),
          icon: 'fal fa-sign-out',
          color: getFaultColorByStatusCode(this.$store.getters['project/getSafetyLightingFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getSafetyLightingFault'])),
          available: this.$store.getters['project/getSafetyLightingFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.water_level_alarm'),
          icon: 'fal fa-water',
          color: getFaultColorByStatusCode(this.$store.getters['project/getWaterLevelDetectorFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getWaterLevelDetectorFault'])),
          available: this.$store.getters['project/getWaterLevelDetectorFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.compressed_air'),
          icon: 'fal fa-tachometer-alt-fastest',
          color: getFaultColorByStatusCode(this.$store.getters['project/getCompressedAirFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getCompressedAirFault'])),
          available: this.$store.getters['project/getCompressedAirFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.shading_system'),
          icon: 'fal fa-house-day',
          color: getFaultColorByStatusCode(this.$store.getters['project/getShadingSystemFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getShadingSystemFault'])),
          available: this.$store.getters['project/getShadingSystemFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.main_doors'),
          icon: 'fal fa-door-closed',
          color: getFaultColorByStatusCode(this.$store.getters['project/getMainDoorFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getMainDoorFault'])),
          available: this.$store.getters['project/getMainDoorFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.turnstiles'),
          icon: 'fal fa-info-circle',
          color: getFaultColorByStatusCode(this.$store.getters['project/getTurnstileFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getTurnstileFault'])),
          available: this.$store.getters['project/getTurnstileFault'] !== undefined
        },
        {
          title: this.$t('faults.systems.gate_systems'),
          icon: 'fal fa-info-circle',
          color: getFaultColorByStatusCode(this.$store.getters['project/getGateFault']),
          text: this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getGateFault'])),
          available: this.$store.getters['project/getGateFault'] !== undefined
        }
      ]
    }
  }
})
