import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import {
  MutationTree
} from 'vuex'
import { TLightingSystemConsumptionData, TLightingSystemsState } from './types'

export default {
  SET_LOADING_SYSTEMS: (state: TLightingSystemsState, loading: boolean) => {
    state.loadingComponents = loading
  },
  SET_LOADING_DATA: (state: TLightingSystemsState, loading: boolean) => {
    state.loadingTimeseries = loading
  },
  SET_LOADING_OPERATION_INFORMATION: (state: TLightingSystemsState, loading: boolean) => {
    state.loadingOperationStates = loading
  },
  SET_LIGHTING_SYSTEMS: (state: TLightingSystemsState, systems: TComponentInProjectWithContextResponse[]) => {
    state.systems = systems
    state.consumption = []
    systems.forEach((system: TComponentInProjectWithContextResponse) => {
      state.consumption.push({
        id: system.id!,
        nameEN: system.nameEN!,
        nameDE: system.nameDE!,
        currentConsumption: null,
        pastConsumption: null,
        status: null
      })
    })
  },
  SET_DATA: (state: TLightingSystemsState, consumption: TLightingSystemConsumptionData[]) => {
    state.consumption = consumption
  }
} as MutationTree<TLightingSystemsState>
