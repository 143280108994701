var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[
    'rom__status__card',
    'elevation-4',
    'd-flex',
    'justify-start',
    'align-center',
    'info'
  ],staticStyle:{"background-color":"white !important"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[(!_vm.note.avatar_url)?_c('v-icon',{attrs:{"large":""}},[_vm._v(" fas fa-user ")]):_c('v-img',{attrs:{"lazy-src":require("../../assets/mock/dummy-image.jpg"),"src":_vm.note.avatar_url,"alt":_vm.note.name}})],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.note.name)+" ")]),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.formattedDate)}})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.note.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }