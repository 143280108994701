












































































import Vue from 'vue'
import get from 'lodash.get'
import moment from 'moment'
import EventCard from '@/components/ui/EventCard.vue'
import { getTranslatedEventName } from '@/utils/helpers/events'
import { STATUS, TAlert } from '@/services/alerta/resources/common/responseTypes'
import { TProject } from '@/services/aedifion/resources/project/responseTypes'
import { TEventType } from '@/store/project/types'

export default Vue.extend({
  name: 'Events',
  components: {
    EventCard
  },
  data () {
    return {
      eventsDialog: false as boolean,
      eventsDialogTitle: '' as string
    }
  },
  computed: {
    project (): TProject|null {
      return get(this.$store.getters['project/getProject'], 'project', null)
    },
    alerts (): TAlert[] {
      return this.$store.getters['alerts/getProjectsAlerts'](get(this.project, 'handle', null))
    },
    projectsEvents (): TEventType[] {
      return this.$store.getters['project/getEvents']
    },
    events (): any[] {
      return this.projectsEvents.map((event: TEventType) => {
        const errors: TAlert[] = this.alerts.filter((alert: TAlert) => {
          return moment().subtract(1, 'day').isBefore(moment(alert.lastReceiveTime).local()) &&
            alert.status === STATUS.OPEN &&
            get(alert, 'attributes.category', false) === event.category
        })
        const warnings: TAlert[] = this.alerts.filter((alert: TAlert) => {
          return moment().subtract(7, 'days').isBefore(moment(alert.lastReceiveTime).local()) &&
            get(alert, 'attributes.category', false) === event.category
        })
        const isError = errors.length > 0
        const isWarning = warnings.length > 0
        const eventName = getTranslatedEventName(event.name)
        return {
          title: eventName,
          icon: event.icon,
          active: event.active,
          warning: isWarning && !isError && event.active,
          error: isError && event.active,
          alarm: event.active ? isError ? get(errors, '0', null) : isWarning ? get(warnings, '0', null) : null : null,
          alarmCount: isError && event.active ? errors.length : null
        }
      })
    }
  },
  methods: {
    openEventsDialog (title: string) {
      this.eventsDialogTitle = title
      this.eventsDialog = true
    }
  }
})
