import { SORT_ORDER } from '../types'

export enum FAVORITE_MODE {
  ONLY = 'only',
  EXCLUDE = 'exclude'
}

export enum COMPONENTS_IN_PROJECT_SORT_BY {
  NAME_EN = 'nameEN',
  NAME_DE = 'nameDE',
  ABBREVIATION = 'abbreviation',
  ID = 'id'
}

export enum INTERPOLATION_METHODS {
  PREVIOUS = 'previous',
  LINEAR = 'linear',
  NULL = 'null',
  NONE = 'none'
}

export enum AGGREGATION_METHODS {
  MEAN = 'mean',
  MIN = 'min',
  MAX = 'max',
  MODE = 'mode',
  MEDIAN = 'median',
  DISTINCT = 'distinct',
  COUNT = 'count'
}

export type TComponentsInProjectRequest = {
  page?: number;
  per_page?: number;
  search?: string;
  filter?: string;
  sort_order?: SORT_ORDER;
  sort_by?: COMPONENTS_IN_PROJECT_SORT_BY;
}

export type TProjectTimeseriesRequest = {
  dataPointIDs: string;
  start?: string;
  end?: string;
  max?: number;
  samplerate?: string;
  interpolation?: INTERPOLATION_METHODS;
  aggregation?: AGGREGATION_METHODS;
  short?: boolean;
  closed_interval?: boolean;
}

export type TProjectUpdateRequest = {
  address?: string;
  description?: string;
  latitude?: string;
  longitude?: string;
  name?: string;
}

export type TPostComponentAttributeRequestParams = {
  componentinproject_id: number;
}

export type TPostComponentAttributeRequestBody = {
  attribute_details: {
    key: string;
    value: string;
  };
}

export type TPutComponentAttributeRequestParams = {
  componentinproject_id: number;
  key: string;
  value: string;
}

export type TDeleteComponentAttributeRequestParams = {
  componentinproject_id: number;
  key: string;
}

export type TDatapointsByPageRequest = {
  project_id: number;
  page?: number;
  per_page?: number;
  tags?: string;
  sort_by?: string;
  sort_order?: SORT_ORDER;
  search?: string;
  match_case?: boolean;
  writable?: boolean;
  favorite_mode?: FAVORITE_MODE;
}
