import i18n from '@/plugins/i18n'

export function textForLocale (german?: string, english?: string): string|null {
  const locale = i18n.locale
  if (locale.includes('de')) {
    if (german) return german
    else if (english) return english
    else return null
  } else {
    return english || null
  }
}
