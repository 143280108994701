















































































import Vue from 'vue'
import { mapActions } from 'vuex'

import Navigation from '@/components/ui/Navigation.vue'
import ProjectSettingsModal from '@/views/Project/Settings.vue'
import Topbar, { TopbarPropsType } from '@/components/ui/Topbar.vue'
import Splash from '@/components/ui/Splash.vue'
import { TUser } from './services/aedifion/resources/user/responseTypes'
import { OLYMPUS_PROJECT_ID } from '@/settings'
import { TComponentInProjectWithContextResponse } from './services/aedifion/resources/project/responseTypes'

export default Vue.extend({
  name: 'App',
  components: {
    Navigation,
    Splash,
    Topbar,
    ProjectSettingsModal
  },
  methods: {
    ...mapActions('auth', [
      'signOutOidc'
    ]),
    openProjectSettingsModal (): void {
      (this.$refs.projectSettingsModal as any).openModal()
    }
  },
  computed: {
    gltLink (): any {
      const projectId = this.$store.getters['project/getProjectID']
      if (projectId === OLYMPUS_PROJECT_ID && this.$route.name !== 'portfolio') {
        return {
          name: this.$t('navigation.links.glt.title') as string,
          icon: 'fas fa-chart-bar',
          href: 'https://remote-services.sauter-cloud.com/',
          target: '_blank',
          active: true
        }
      } else return undefined
    },
    grafanaLink (): any {
      const projectId = this.$store.getters['project/getProjectID']
      if (!projectId) return undefined

      const buildingComponent = this.$store.getters['components/getDigitalTwins'] as Record<string, TComponentInProjectWithContextResponse>

      const hasLinkAttribute = buildingComponent[projectId]?.attributes?.find((attr) => attr.alphanumeric_id === 'B+LINK')

      const grafanaUrl = 'https://dashboard.rom-bc.de/bc-live/login'

      if (hasLinkAttribute && this.$route.name !== 'portfolio') {
        return {
          name: this.$t('navigation.links.custom.title') as string,
          icon: 'fas fa-chart-bar',
          href: grafanaUrl,
          target: '_blank',
          active: true
        }
      }

      return undefined
    },
    user (): TUser {
      return this.$store.getters['user/getUser']
    },
    userLoading (): boolean {
      return this.$store.getters['user/isUserLoading']
    },
    compactUser (): TopbarPropsType['user'] {
      return {
        fullName: this.$store.getters['user/getFullName'],
        avatar: this.user.avatar_url ? this.user.avatar_url : null,
        companyName: this.$store.getters['company/getCompanyName']
      }
    },
    authed (): boolean {
      return this.$store.getters['auth/oidcIsAuthenticated']
    },
    authCheckPending (): boolean {
      // return true
      return !this.$store.getters['auth/oidcAuthenticationIsChecked']
    },
    selectedProject (): number|null {
      return this.$store.getters['project/getProjectID']
    },
    links (): Array<{ name: string; icon: string; link?: string; active: boolean; href?: string; target?: string }> {
      const links = [
        {
          name: this.$t('navigation.links.portfolio.title') as string,
          icon: 'fal fa-city',
          link: '/',
          active: this.authed
        },
        {
          name: this.$t('navigation.links.project.title') as string,
          icon: 'fal fa-building',
          link: `/project/${this.selectedProject}`,
          active: this.authed && !!this.selectedProject
        },
        {
          name: this.$t('navigation.links.energy.title') as string,
          icon: 'fal fa-battery-full',
          link: `/project/${this.selectedProject}/energy`,
          active: this.authed && !!this.selectedProject
        },
        {
          name: this.$t('navigation.links.alarms.title') as string,
          icon: 'fal fa-bell',
          link: `/project/${this.selectedProject}/alarms`,
          active: this.authed && !!this.selectedProject
        },
        {
          name: this.$t('navigation.links.operations.title') as string,
          icon: 'fal fa-clipboard',
          link: `/project/${this.selectedProject}/operations`,
          active: this.authed && !!this.selectedProject
        }
      ]

      if (this.gltLink !== undefined) links.push(this.gltLink)

      if (this.grafanaLink !== undefined && this.gltLink === undefined) links.push(this.grafanaLink)

      return links
    }
  }
})
