import get from 'lodash.get'
import { IRepository, IRepositoryFactory } from '@/utils/types/repository'

// Import the resources
import TilequeryRepository from './resources/tilequery'

const repositories: { [key: string]: IRepository } = {
  tilequeries: TilequeryRepository
}

export default {
  get: (name: string): IRepository => {
    return get(repositories, name)
  }
} as IRepositoryFactory
