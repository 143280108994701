




















































































import { TEnergyConsumptionTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'Heat',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyConsumptionData (): TEnergyConsumptionTable|null {
      return this.$store.getters['media_tables/getEnergyConsumption']
    },
    heatLoads (): TEnergyConsumptionTable['heat']|null {
      if (this.energyConsumptionData === null) return null
      return this.energyConsumptionData.heat
    },
    peakLoadOfCurrentYear (): string|null {
      if (this.heatLoads === null) return null
      return this.heatLoads.peak_load.currentYear !== null ? formatValue(this.heatLoads.peak_load.currentYear, 0) : null
    },
    peakLoadOfLastYear (): string|null {
      if (this.heatLoads === null) return null
      return this.heatLoads.peak_load.lastYear !== null ? formatValue(this.heatLoads.peak_load.lastYear, 0) : null
    },
    baseLoadOfCurrentYear (): string|null {
      if (this.heatLoads === null) return null
      return this.heatLoads.base_load.currentYear !== null ? formatValue(this.heatLoads.base_load.currentYear, 0) : null
    },
    baseLoadOfLastYear (): string|null {
      if (this.heatLoads === null) return null
      return this.heatLoads.base_load.lastYear !== null ? formatValue(this.heatLoads.base_load.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
