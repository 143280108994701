import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TAlertsRequest } from './requestTypes'
import { TAlertsResponse } from '@/services/alerta/resources/common/responseTypes'

const resource = 'alerts'

export default {
  getAlerts: (request: {
    token: string|null;
    params?: TAlertsRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TAlertsResponse>> => {
    return axiosInstance.get(`/${resource}`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  }
} as IRepository
