/* eslint-disable @typescript-eslint/ban-ts-ignore */
import get from 'lodash.get'
import { IRepository, IRepositoryFactory } from '@/utils/types/repository'

import {
  BaseAPI,
  ComponentApi,
  DatapointApi,
  ProjectApi,
  MetaApi
} from '@aedifion.io/aedifion-api'

// Import the resources
import UserRepository from './resources/user'
import CompanyRepository from './resources/company'
import ProjectsRepository from './resources/project'
import DatapointsRepository from './resources/datapoint'
import AnalyticsRepository from './resources/analytics'

const repositories: { [key: string]: IRepository } = {
  user: UserRepository,
  company: CompanyRepository,
  projects: ProjectsRepository,
  datapoints: DatapointsRepository,
  analytics: AnalyticsRepository
}

export default {
  get: (name: string): IRepository => {
    return get(repositories, name)
  }
} as IRepositoryFactory

/**
 * Interface which implements only the setAccessToken method
 * required to change the accessToken after initialization
 * of the extended BaseAPI
 */
export interface ISetAccessToken extends BaseAPI {
  setAccessToken (token: string): void;
}

class CustomMetaApi extends MetaApi implements ISetAccessToken {
  public setAccessToken (token: string): void {
    this.configuration.accessToken = token
  }
}

class CustomComponentApi extends ComponentApi implements ISetAccessToken {
  public setAccessToken (token: string): void {
    this.configuration.accessToken = token
  }
}

class CustomDatapointApi extends DatapointApi implements ISetAccessToken {
  public setAccessToken (token: string): void {
    this.configuration.accessToken = token
  }
}

class CustomProjectApi extends ProjectApi implements ISetAccessToken {
  public setAccessToken (token: string): void {
    this.configuration.accessToken = token
  }
}

const Component = new CustomComponentApi({
  // @ts-ignore
  basePath: window.configuration.AEDIFION_API_URL_SHORT
})

const Datapoint = new CustomDatapointApi({
  // @ts-ignore
  basePath: window.configuration.AEDIFION_API_URL_SHORT
})

const Project = new CustomProjectApi({
  // @ts-ignore
  basePath: window.configuration.AEDIFION_API_URL_SHORT
})

const Meta = new CustomMetaApi({
  // @ts-ignore
  basePath: window.configuration.AEDIFION_API_URL_SHORT
})

/**
 * Returns all available APIs that are exposed by the aedifion-api pacakge
 * @returns ISetAccessToken[] - an array of interfaces that implement the setAccessToken method
 * and extend the BaseAPI
 */
const getAll = (): ISetAccessToken[] => {
  return [
    Component,
    Datapoint,
    Project,
    Meta
  ]
}
export {
  Meta,
  Component,
  Datapoint,
  Project,
  getAll
}
