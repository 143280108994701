import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TGetAnalyticsFunctionsRequest, TGetAnalyticsInstanceResultsRequest, TGetAnalyticsInstancesRequest, TGetInstanceResultRequest } from './requestTypes'
import { TGetAnalyticsFunctionsResponse, TGetAnalyticsInstanceResultsResponse, TGetAnalyticsInstancesResponse, TGetInstanceResultResponse } from './responseTypes'

const resource = 'analytics'

export default {
  getFunctions: (request: {
    token: string|null;
    params: TGetAnalyticsFunctionsRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TGetAnalyticsFunctionsResponse>> => {
    return axiosInstance.get(`/${resource}/functions`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getInstances: (request: {
    token: string|null;
    params: TGetAnalyticsInstancesRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TGetAnalyticsInstancesResponse>> => {
    return axiosInstance.get(`/${resource}/instances`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getInstanceResults: (request: {
    token: string|null;
    id: number;
    params: TGetAnalyticsInstanceResultsRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TGetAnalyticsInstanceResultsResponse>> => {
    return axiosInstance.get(`/${resource}/instance/${request.id}/results`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  },
  getResult: (request: {
    token: string|null;
    id: number;
    second_level_id: string;
    params: TGetInstanceResultRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<TGetInstanceResultResponse>> => {
    return axiosInstance.get(`/${resource}/instance/${request.id}/result/${request.second_level_id}`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  }
} as IRepository
