












import Vue from 'vue'

import EnergyConsumption from './Media/EnergyConsumption.vue'
import EnergyGeneration from './Media/EnergyGeneration.vue'
import CO2Emissions from './Media/CO2Emissions.vue'
import WaterConsumption from './Media/WaterConsumption.vue'

export default Vue.extend({
  name: 'Media',
  components: {
    EnergyConsumption,
    EnergyGeneration,
    CO2Emissions,
    WaterConsumption
  },
  beforeMount () {
    this.$store.dispatch('media_tables/fetchMediaTablesData')
  },
  data () {
    return {
      mediaExpansionPanels: [0, 1, 2, 3] as number[]
    }
  }
})
