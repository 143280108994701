










































































































































































































































import Vue from 'vue'
import moment from 'moment'
import PieChart from '@/components/ui/Charts/PieChart.vue'
import BulletGraph from '@/components/ui/Charts/BulletGraph.vue'
import { TBenchmarkZones } from '@/utils/helpers/types'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'MediaContent',
  components: {
    PieChart,
    BulletGraph
  },
  mounted () {
    this.$store.dispatch('energy_consumption/fetchData')
  },
  methods: {
    selectMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('energy_consumption/switchChartToMonth')
      }
    },
    selectYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('energy_consumption/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('energy_consumption/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('energy_consumption/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('energy_consumption/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('energy_consumption/selectNextMonth')
      }
    }
  },
  computed: {
    hasBenchmarkData (): boolean {
      return this.hasHeatBenchmarkData && this.hasElectricityBenchmarkData
    },
    hasElectricityBenchmarkData (): boolean {
      return this.benchmarkElectricity &&
        (this.benchmarkElectricity.isValue || this.electricityProjectionValue)
    },
    hasHeatBenchmarkData (): boolean {
      return this.benchmarkHeat &&
        (this.benchmarkHeat.isValue || this.heatProjectionValue)
    },
    loadingProjectsDigitalTwin (): boolean {
      return this.$store.getters['project/areComponentsInProjectLoading']
    },
    loadingData (): boolean {
      return this.$store.getters['energy_consumption/isLoadingData']
    },
    electricity (): number|null {
      return this.$store.getters['energy_consumption/getElectricityConsumption']
    },
    electricityBenchmark (): number|null {
      return this.$store.getters['energy_consumption/getElectricityConsumptionBenchmark']
    },
    electricityProjectionValue (): number|null {
      return this.$store.state.energy_consumption.benchmark.electricityProjection
    },
    heatBenchmark (): number|null {
      return this.$store.getters['energy_consumption/getHeatConsumptionBenchmark']
    },
    heatProjectionValue (): number|null {
      return this.$store.state.energy_consumption.benchmark.heatProjection
    },
    heat (): number|null {
      return this.$store.getters['energy_consumption/getHeatConsumption']
    },
    electricityColor (): string|null {
      return this.$store.getters['energy_consumption/getElectricityConsumptionColor']
    },
    heatColor (): string|null {
      return this.$store.getters['energy_consumption/getHeatConsumptionColor']
    },
    electricityRelative (): string|null {
      if (this.electricity === null) return null
      if (this.heat === null) return '100'
      if (this.heat === 0 && this.electricity === 0) return '0'

      return formatValue((this.electricity / (this.electricity + this.heat)) * 100.00, 0)
    },
    heatRelative (): string|null {
      if (this.heat == null) return null
      if (this.electricity === null) return '100'
      if (this.heat === 0 && this.electricity === 0) return '0'

      return formatValue((this.heat / (this.electricity + this.heat)) * 100.00, 0)
    },
    range (): string {
      if (this.yearSelected) {
        return this.year.toString()
      } else {
        return moment({ month: this.month }).format('MMMM')
      }
    },
    year (): number {
      return this.$store.getters['energy_consumption/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['energy_consumption/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['energy_consumption/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['energy_consumption/hasPrevious']
    },
    month (): number {
      return this.$store.getters['energy_consumption/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['energy_consumption/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      const monthName: string = moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
      return `${monthName}, ${this.year}`
    },
    timeseries (): any[] {
      const timeseriesData: any = {
        name: this.$t('energy_consumption.series.name'),
        data: []
      }

      if (this.heat !== null && this.heatRelative !== null) {
        timeseriesData.data.push({
          name: `${this.$t('utils.heat')}: ${this.heatRelative}%`,
          y: parseInt(this.heatRelative),
          unit: this.$t('units.kilowatthours'),
          absValue: formatValue(this.heat, 2)
        })
      }

      if (this.electricity !== null && this.electricityRelative !== null) {
        timeseriesData.data.push({
          name: `${this.$t('utils.electricity')}: ${this.electricityRelative}%`,
          y: parseInt(this.electricityRelative),
          unit: this.$t('units.kilowatthours'),
          absValue: formatValue(this.electricity, 2)
        })
      }

      return [timeseriesData]
    },
    electricityBenchmarkZones (): TBenchmarkZones|null {
      return this.$store.getters['energy_consumption/getElectricityBenchmarkZones']
    },
    heatBenchmarkZones (): TBenchmarkZones|null {
      return this.$store.getters['energy_consumption/getHeatBenchmarkZones']
    },
    heatSetValue (): number|null {
      return this.$store.getters['energy_consumption/getTargetHeatValue']
    },
    electricitySetValue (): number|null {
      return this.$store.getters['energy_consumption/getTargetElectricityValue']
    },
    benchmarkHeat (): any {
      return this.heatSetValue !== null && this.heatBenchmarkZones !== null ? {
        title: `${this.$t('energy_consumption.benchmark.heat.title')} ${moment().year()}`,
        subtitle: `<span class="hc-cat-title">${this.$t('project.media.title.energy_consumption')}</span><br><b>kWh/(m<sup>2</sup>a)</b>`,
        setValue: this.heatSetValue,
        projectionValue: this.heatProjectionValue ? parseFloat(this.heatProjectionValue.toFixed(0)) : null,
        isValue: this.heatBenchmark !== null ? parseFloat(this.heatBenchmark.toFixed(0)) : 0,
        isColor: this.heatColor,
        unit: 'kWh/m^2a',
        seriesName: this.$t('energy_consumption.per_squared_meters_yearly'),
        zones: this.heatBenchmarkZones
      } : null
    },
    benchmarkElectricity (): any {
      return this.electricitySetValue !== null && this.electricityBenchmarkZones !== null ? {
        title: `${this.$t('energy_consumption.benchmark.electricity.title')} ${moment().year()}`,
        subtitle: `<span class="hc-cat-title">${this.$t('project.media.title.energy_consumption')}</span><br><b>kWh/(m<sup>2</sup>a)</b>`,
        setValue: this.electricitySetValue,
        projectionValue: this.electricityProjectionValue ? parseFloat(this.electricityProjectionValue.toFixed(0)) : null,
        isValue: this.electricityBenchmark !== null ? parseFloat(this.electricityBenchmark.toFixed(0)) : 0,
        isColor: this.electricityColor,
        unit: 'kWh/m^2a',
        seriesName: this.$t('energy_consumption.per_squared_meters_yearly'),
        zones: this.electricityBenchmarkZones
      } : null
    },
    positiveHeatFeedback (): number|string|null {
      let valueToUse = null
      if (this.heatProjectionValue !== null) valueToUse = this.heatProjectionValue
      if (this.heatBenchmark === null || this.heatSetValue === null) return null
      if (valueToUse === null) valueToUse = this.heatBenchmark
      const percentage: number|null = 100 - ((valueToUse / this.heatSetValue) * 100.0)

      if (percentage >= 70) {
        return this.$t('energy_consumption.feedback.heat', { percent: '70' }) as string
      } else if (percentage >= 50) {
        return this.$t('energy_consumption.feedback.heat', { percent: '50' }) as string
      } else if (percentage >= 30) {
        return this.$t('energy_consumption.feedback.heat', { percent: '30' }) as string
      } else {
        return null
      }
    },
    positiveElectricityFeedback (): number|string|null {
      let valueToUse = null
      if (this.electricityProjectionValue !== null) valueToUse = this.electricityProjectionValue
      if (this.electricityBenchmark === null || this.electricitySetValue === null) return null
      if (valueToUse === null) valueToUse = this.electricityBenchmark
      const percentage: number|null = 100 - ((valueToUse / this.electricitySetValue) * 100.0)

      if (percentage >= 70) {
        return this.$t('energy_consumption.feedback.electricity', { percent: '70' }) as string
      } else if (percentage >= 50) {
        return this.$t('energy_consumption.feedback.electricity', { percent: '50' }) as string
      } else if (percentage >= 30) {
        return this.$t('energy_consumption.feedback.electricity', { percent: '30' }) as string
      } else {
        return null
      }
    }
  }
})
