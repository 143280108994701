/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionTree } from 'vuex'
import { TRootState } from '@/store/types'
import { TProjectState } from './types'
import { aedifionApi, Component, ComponentInProject, ComponentInProjectWithContext, ComponentsInProject } from '@aedifion.io/aedifion-api'
import i18n from '@/plugins/i18n'
import Vue from 'vue'

export default {
  async fetchProjectComponents ({ commit, rootGetters, rootState, state }, payload: { componentId: number }) {
    state.loadingProjectComponents = true
    const projectId = rootGetters['project/getProjectID']
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const alphenumericId: string = rootState.componentsnew.components.find((component: Component) => component.id === payload.componentId)!.alphanumeric_id!
    try {
      const componentsResponse: ComponentsInProject = await aedifionApi.Project.getProjectComponents(projectId, 1, 1000, undefined, `alphanumeric_id=${alphenumericId}`)
      commit('SET_PROJECT_COMPONENTS', { componentId: payload.componentId, components: componentsResponse.items })
    } catch (error) {
      Vue.notify({
        text: i18n.t('request.error.project_components') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      state.loadingProjectComponents = false
    }
  },

  async fetchProjectComponentDetails ({ rootGetters, state }, payload: { componentInProjectId: number; componentId: number }) {
    const projectId = rootGetters['project/getProjectID']
    if (state.projectComponents[payload.componentId] === undefined) return
    const componentIndex = state.projectComponents[payload.componentId]
      .findIndex(
        (componentInProject: ComponentInProject|ComponentInProjectWithContext) =>
          componentInProject.id === payload.componentInProjectId
      )
    if ((state.projectComponents[payload.componentId][componentIndex] as ComponentInProjectWithContext).component !== undefined) {
      return
    } else {
      state.loadingProjectComponentDetails = true
    }
    try {
      const projectComponent: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId, payload.componentInProjectId)
      Vue.set(state.projectComponents[projectComponent.component!.id!], componentIndex, projectComponent)
    } catch (error) {
      Vue.notify({
        text: i18n.t('request.error.project_components_details') as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
    } finally {
      state.loadingProjectComponentDetails = false
    }
  }
} as ActionTree<TProjectState, TRootState>
