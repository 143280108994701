import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import {
  GetterTree
} from 'vuex'
import { TRootState } from '../types'
import { TLightingSystemConsumptionData, TLightingSystemsState } from './types'

export default {
  getSystemsLoadingState: (state: TLightingSystemsState): boolean => state.loadingComponents,
  getSystemsConsumptionLoadingState: (state: TLightingSystemsState): boolean => state.loadingTimeseries,
  getSystems: (state: TLightingSystemsState): TComponentInProjectWithContextResponse[] => state.systems,
  getSystemsWithConsumption: (state: TLightingSystemsState): TLightingSystemConsumptionData[] => state.consumption
} as GetterTree<TLightingSystemsState, TRootState>
