






















































































import { TWaterConsumptionTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'EnergyConsumptionInformation',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    waterConsumptionData (): TWaterConsumptionTable|null {
      return this.$store.getters['media_tables/getWaterConsumption']
    },
    freshWaterConsumptionOfCurrentYear (): string|null {
      if (this.waterConsumptionData === null) return null
      return this.waterConsumptionData.fresh_water.currentYear !== null ? formatValue(this.waterConsumptionData.fresh_water.currentYear * 1000, 0) : null
    },
    freshWaterConsumptionOfLastYear (): string|null {
      if (this.waterConsumptionData === null) return null
      return this.waterConsumptionData.fresh_water.lastYear !== null ? formatValue(this.waterConsumptionData.fresh_water.lastYear * 1000, 0) : null
    },
    hasFreshWaterData (): boolean {
      return this.freshWaterConsumptionOfCurrentYear !== null || this.freshWaterConsumptionOfLastYear !== null
    },
    greyWaterConsumptionOfCurrentYear (): string|null {
      if (this.waterConsumptionData === null) return null
      return this.waterConsumptionData.grey_water.currentYear !== null ? formatValue(this.waterConsumptionData.grey_water.currentYear * 1000, 0) : null
    },
    greyWaterConsumptionOfLastYear (): string|null {
      if (this.waterConsumptionData === null) return null
      return this.waterConsumptionData.grey_water.lastYear !== null ? formatValue(this.waterConsumptionData.grey_water.lastYear * 1000, 0) : null
    },
    hasGreyWaterData (): boolean {
      return this.greyWaterConsumptionOfCurrentYear !== null || this.greyWaterConsumptionOfLastYear !== null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
