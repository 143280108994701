








































import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    color: {
      required: false,
      type: String as PropType<string>,
      default: 'info'
    },
    icon: {
      required: false,
      type: String as PropType<string>,
      default: null
    },
    iconColor: {
      required: false,
      type: String as PropType<string>,
      default: null
    },
    tooltip: {
      required: false,
      type: String as PropType<string>,
      default: null
    }
  }
})
