
















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'Splash',

  props: {
    show: {
      required: true,
      type: Boolean as PropType<boolean>
    }
  }
})
