export enum SEVERITY {
  SECURITY = 'security',
  CRITICAL = 'critical',
  MAJOR = 'major',
  MINOR = 'minor',
  WARNING = 'warning',
  INFORMATIONAL = 'informational',
  DEBUG = 'debug',
  TRACE = 'trace',
  INDETERMINATE = 'indeterminate',
  CLEARED = 'cleared',
  NORMAL = 'normal',
  OK = 'ok',
  UNKOWN = 'unknown'
}

export enum STATUS {
  OPEN = 'open',
  ASSIGN = 'assign',
  ACK = 'ack',
  CLOSED = 'closed',
  EXPIRED = 'expired',
  BLACKOUT = 'blackout',
  SHELVED = 'shelved',
  UNKNOWN = 'unknown'
}

export enum TYPE {
  THROUGHPUT = 'throughput',
  THRESHOLD = 'threshold'
}

export type TAlertUpdate = {
  event: string;
  id: string;
  severity?: SEVERITY;
  status?: STATUS;
  text?: string;
  type?: string;
  updateTime?: string;
  user: string;
  value?: number|string;
}

export type TAlert = {
  attributes?: { [key: string]: string };
  correlate?: string[];
  createTime?: string;
  environment?: string;
  event: string;
  group?: string;
  history: TAlertUpdate[];
  id: string;
  lastReceiveId?: string;
  lastReceiveTime?: string;
  origin?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData?: any;
  resource: string;
  service?: string[];
  severity?: SEVERITY;
  status?: STATUS;
  tags?: string[];
  text?: string;
  timeout?: string;
  type?: string;
  value?: number|string;
}

export type TAlertsResponse = {
  alerts: TAlert[];
  autoRefresh: boolean;
  lastTime: string;
  more: boolean;
  page: number;
  pageSize: number;
  pages: number;
  severityCounts: { [severity: string]: number };
  status: string;
  statusCounts: { [status: string]: number };
  total: number;
}

export type TAlertResponse = {
  alert: TAlert;
  status: string;
  total: number;
}
