



















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'TooltipCard',

  props: {
    linkText: {
      default: null,
      required: false,
      type: String as PropType<string>
    },

    linkUrl: {
      default: null,
      required: false,
      type: String as PropType<string>
    }
  }
})
