var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"icon":"fas fa-sort-circle","status":[{
    icon: 'fas fa-circle',
    title: _vm.elevatorState,
    color: _vm.elevatorColor,
  }]},scopedSlots:_vm._u([{key:"card-title",fn:function(){return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('project.title.elevators')))])]},proxy:true}])},[(!_vm.disabled && !_vm.isLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('BarChart',{attrs:{"title":_vm.yearSelected ? _vm.$t('utils.peak.hours_over_year') + " " + _vm.year : _vm.$t('utils.peak.hours_over_month', { month: _vm.getMonthName, year: _vm.year }),"subtitle":_vm.$t('project.elevators.title.runtime_per_weekday'),"series":_vm.barSeries,"loading":_vm.isLoadingResult || _vm.isLoading}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('PieChart',{attrs:{"title":_vm.yearSelected ? _vm.$t('utils.availability_in') + " " + _vm.year : _vm.$t('utils.availability_month', { month: _vm.getMonthName, year: _vm.year }),"series":_vm.pieSeries,"loading":_vm.isLoadingResult || _vm.isLoading,"innerLabels":true}})],1),_c('v-col',{attrs:{"cols":"4 offset-4"}},[_c('div',{staticClass:"d-flex justify-space-between align-center flex-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","rounded":"","color":"primary","disabled":!_vm.hasPrevious || _vm.isLoading},on:{"click":_vm.previous}},on),[_c('v-icon',[_vm._v("far fa-chevron-left")])],1)]}}],null,false,3948157150)},[(_vm.monthSelected)?_c('span',[_vm._v(_vm._s(_vm.getMonthBefore))]):_vm._e(),(_vm.yearSelected)?_c('span',[_vm._v(_vm._s(_vm.year - 1))]):_vm._e()]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-chip',{staticClass:"ma-2 px-8",attrs:{"outlined":_vm.monthSelected,"color":"primary"},on:{"click":_vm.switchToYear}},[(_vm.yearSelected)?_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-check-circle")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('units.year'))+" ")],1),_c('v-chip',{staticClass:"ma-2 px-8",attrs:{"outlined":_vm.yearSelected,"color":"primary"},on:{"click":_vm.switchToMonth}},[(_vm.monthSelected)?_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-check-circle")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('units.month'))+" ")],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","rounded":"","color":"primary","disabled":(!_vm.hasNext && ((!_vm.isNextCurrentMonth && _vm.monthSelected) || (!_vm.isNextCurrentYear && _vm.yearSelected))) || _vm.isLoading},on:{"click":_vm.next}},on),[_c('v-icon',[_vm._v("far fa-chevron-right")])],1)]}}],null,false,602399621)},[(_vm.monthSelected)?_c('span',[_vm._v(_vm._s(_vm.getMonthAfter))]):_vm._e(),(_vm.yearSelected)?_c('span',[_vm._v(_vm._s(_vm.year + 1))]):_vm._e()])],1)])],1)],1):(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('span',{staticClass:"title mb-2"},[_vm._v(_vm._s(_vm.$t('elevators.loading_data')))]),_c('v-progress-circular',{attrs:{"indeterminate":"","size":"30","color":"primary"}})],1):_c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('span',{staticClass:"title mb-4"},[_vm._v(_vm._s(_vm.$t('elevators.no_data_available')))]),_c('v-icon',{attrs:{"x-large":""}},[_vm._v("far fa-sort-circle")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }