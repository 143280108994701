import { TAlert, TAlertsResponse } from '@/services/alerta/resources/common/responseTypes'
import { Subscription } from 'rxjs'
import { MutationTree } from 'vuex'
import { TAlertsState, TAlarmsFilter } from './types'

export default {
  SET_LOADING: (state: TAlertsState, loading: boolean) => {
    state.loading = loading
  },
  SET_ALERTS: (state: TAlertsState, alerts: TAlertsResponse) => {
    state.alerts = alerts
  },
  UPDATE_ALERT: (state: TAlertsState, alert: TAlert) => {
    const index = state.alerts.alerts.findIndex(item => item.id === alert.id)
    if (index >= 0) {
      state.alerts.alerts.splice(index, 1, alert)
    }
  },
  START_POLLING: (state: TAlertsState, subscription: Subscription) => {
    state.polling = subscription
  },
  END_POLLING: (state: TAlertsState) => {
    state.polling = null
  },
  SET_FILTERS: (state: TAlertsState, filters: Array<TAlarmsFilter>) => {
    if (state.filters !== filters) {
      state.filters = filters
    }
  },
  REMOVE_FILTER: (state: TAlertsState, filter: TAlarmsFilter) => {
    const index = state.filters.findIndex(item => item.column === filter.column && item.value === filter.value)
    if (index >= 0) {
      state.filters.splice(index, 1)
    }
  }
} as MutationTree<TAlertsState>
