import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosResponse } from 'axios'
import { TTimezoneResponse } from './responseTypes'

const resource = 'tilequery'

export default {
  getTimezone: (request: {
    id?: string;
  }): Promise<AxiosResponse<TTimezoneResponse>> => {
    return axiosInstance.get(`/${resource}/${request.id}`)
  }
} as IRepository
