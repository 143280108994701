import { AnalysisFunction, AnalysisResult, AnalysisResultSummery, InstanceConfig } from '@/services/aedifion/resources/analytics/responseTypes'
import { TComponentsInProjectResponse } from '@/services/aedifion/resources/project/responseTypes'
import { MutationTree } from 'vuex'
import { TElevatorsState } from './types'

export default {
  SET_LOADING: (state: TElevatorsState, loading: boolean) => (state.loading = loading),
  SET_LOADING_ELEVATORS: (state: TElevatorsState, loading: boolean) => (state.loadingElevators = loading),
  SET_LOADING_ANALYTICS_FUNCTIONS: (state: TElevatorsState, loading: boolean) => (state.loadingAnalyticsFunctions = loading),
  SET_LOADING_INSTANCES: (state: TElevatorsState, loading: boolean) => (state.loadingInstances = loading),
  SET_LOADING_INSTANCE_RESULTS: (state: TElevatorsState, loading: boolean) => (state.loadingInstanceResults = loading),
  SET_LOADING_INSTANCE_RESULT: (state: TElevatorsState, loading: boolean) => (state.loadingInstanceResult = loading),
  SET_ANALYSIS_FUNCTIONS: (state: TElevatorsState, analysisFunctions: AnalysisFunction[]) => (state.functions = analysisFunctions),
  SET_INSTANCES: (state: TElevatorsState, instances: InstanceConfig[]) => (state.instances = instances),
  SET_ELEVATORS: (state: TElevatorsState, elevators: TComponentsInProjectResponse['items']) => (state.elevators = elevators),
  SET_INSTANCE_RESULTS: (state: TElevatorsState, instance_results: AnalysisResultSummery[]) => (state.results = instance_results),
  SET_ELEVATOR_ANALYSIS_RESULT: (state: TElevatorsState, result: AnalysisResult|null) => (state.analysis_result = result),
  SET_YEAR_SELECTED: (state: TElevatorsState, isYearSelected: boolean) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TElevatorsState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TElevatorsState, month: number) => {
    state.month = month
  }
} as MutationTree<TElevatorsState>
