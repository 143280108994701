import {
  MutationTree
} from 'vuex'
import { TEnergyGenerationState } from './types'

export default {
  SET_CHART_AND_BENCHMARK_DATA: (state: TEnergyGenerationState, payload: {
    energyGenerationPerSquaredMeters: number|null;
    energyGenerationColor: string|null;
    energyGenerationProjection: number|null;
    boiler: number|null;
    district_heating: number|null;
    heat_pump: number|null;
    bhkw_th: number|null;
    bhkw_el: number|null;
    photovoltaics: number|null;
    solar: number|null;
  }) => {
    state.benchmark.energyGeneration = payload.energyGenerationPerSquaredMeters
    state.benchmark.energyGenerationColor = payload.energyGenerationColor
    state.benchmark.energyGenerationProjection = payload.energyGenerationProjection
    state.chart.boiler = payload.boiler
    state.chart.heat_pump = payload.heat_pump
    state.chart.district_heating = payload.district_heating
    state.chart.bhkw_el = payload.bhkw_el
    state.chart.bhkw_th = payload.bhkw_th
    state.chart.photovoltaics = payload.photovoltaics
    state.chart.solar = payload.solar
  },
  SET_LOADING_DATA: (state: TEnergyGenerationState, loading: boolean) => {
    state.loading = loading
  },
  SET_DATA: (state: TEnergyGenerationState, payload: {
    boiler: number|null;
    district_heating: number|null;
    heat_pump: number|null;
    bhkw_th: number|null;
    bhkw_el: number|null;
    photovoltaics: number|null;
    solar: number|null;
    hasPreviousData: boolean;
    hasNextData: boolean;
  }) => {
    state.chart.boiler = payload.boiler
    state.chart.heat_pump = payload.heat_pump
    state.chart.district_heating = payload.district_heating
    state.chart.bhkw_el = payload.bhkw_el
    state.chart.bhkw_th = payload.bhkw_th
    state.chart.photovoltaics = payload.photovoltaics
    state.chart.solar = payload.solar
    state.hasPrevious = payload.hasPreviousData
    state.hasNext = payload.hasNextData
  },
  SET_YEAR_SELECTED: (state: TEnergyGenerationState, isYearSelected: boolean) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TEnergyGenerationState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TEnergyGenerationState, month: number) => {
    state.month = month
  }
} as MutationTree<TEnergyGenerationState>
