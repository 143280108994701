<template>
  <div class="d-flex flex-column justify-center align-center">
    <v-tabs
      v-model="tabs"
    >
      <v-tab>{{$t('utils.electricity')}}</v-tab>
      <v-tab>{{$t('utils.heat')}}</v-tab>
    </v-tabs>
    <div class="d-flex justify-center align-center pa-4">
      <Electricity v-if="tabs===0"/>
      <Heat v-if="tabs===1"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Electricity from './Electricity.vue'
import Heat from './Heat.vue'

export default Vue.extend({
  name: 'EnergyConsumptionInformation',
  components: {
    Electricity,
    Heat
  },
  data () {
    return {
      tabs: 0
    }
  }
})
</script>
