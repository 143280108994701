




















































































import { TEnergyGenerationTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'Solar',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyGenerationData (): TEnergyGenerationTable|null {
      return this.$store.getters['media_tables/getEnergyGeneration']
    },
    solar (): TEnergyGenerationTable['solar']|null {
      if (this.energyGenerationData === null) return null
      return this.energyGenerationData.solar
    },
    heatGenerationOfCurrentYear (): string|null {
      if (this.solar === null) return null
      return this.solar.heat_generation.currentYear !== null ? formatValue(this.solar.heat_generation.currentYear, 0) : null
    },
    heatGenerationOfLastYear (): string|null {
      if (this.solar === null) return null
      return this.solar.heat_generation.lastYear !== null ? formatValue(this.solar.heat_generation.lastYear, 0) : null
    },
    pumpFlowOfCurrentYear (): string|null {
      if (this.solar === null) return null
      return this.solar.pump_flow.currentYear !== null ? formatValue(this.solar.pump_flow.currentYear, 0) : null
    },
    pumpFlowOfLastYear (): string|null {
      if (this.solar === null) return null
      return this.solar.pump_flow.lastYear !== null ? formatValue(this.solar.pump_flow.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
