import { TAssignedPin, TComponentInProjectWithContextResponse, TTimeseriesShort } from '@/services/aedifion/resources/project/responseTypes'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
import { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { TRootState } from '../types'
import { TEnergyConsumptionTable, TEnergyGenerationTable, TMediaTablesState, TWaterConsumptionTable } from './types'
import get from 'lodash.get'
import aedifionApiRepository from '@/services/aedifion'
import { IRepository } from '@/utils/types/repository'
import moment from 'moment'
import { INTERPOLATION_METHODS } from '@/services/aedifion/resources/project/requestTypes'
import { calculateLoadFrom15MinAvgData, computeTotalConsumptionValue, computeTotalConsumptionValuePerDay } from '@/utils/helpers/timeseries'
const Project: IRepository = aedifionApiRepository.get('projects')

export default {
  fetchMediaTablesData: async ({ commit, rootGetters }): Promise<void> => {
    commit('SET_LOADING', true)
    const token: string = rootGetters['auth/oidcAccessToken']
    const project_id: number = rootGetters['project/getProjectID']
    const digitalTwin: TComponentInProjectWithContextResponse = rootGetters['project/getDigitalTwin']
    const digitalTwinPins: TAssignedPin[] = get(digitalTwin, 'pins', [])

    if (project_id === null || project_id === undefined) throw new Error('No project selected or no accessible project_id.')
    if (!digitalTwin) throw new Error('No digital twin for this project specified.')
    if (!digitalTwinPins || digitalTwinPins.length === 0) throw new Error('Digital twin has no assigned PINs that can be used for this action.')

    /**
     * Collect all datapointIDs in an array
     */
    const dataPointIDs: string[] = []

    /**
     * Get all the datapointIDs from the PINs alphanumericIDs
     */

    const electricityConsumption15mDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+ELS+EN_EL_CONSUM_SUM_15m')
    if (electricityConsumption15mDatapointID !== null) dataPointIDs.push(electricityConsumption15mDatapointID)

    const heatConsumption15mDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+EN_H_CONSUM_SUM_15m')
    if (heatConsumption15mDatapointID !== null) dataPointIDs.push(heatConsumption15mDatapointID)

    const boilersHeatGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+BOI_EN_H_GEN_SUM')
    if (boilersHeatGenerationDatapointID !== null) dataPointIDs.push(boilersHeatGenerationDatapointID)

    const boilersFullLoadHoursDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+BOI_EN_H_GEN_FLH')
    if (boilersFullLoadHoursDatapointID !== null) dataPointIDs.push(boilersFullLoadHoursDatapointID)

    const bhkw_th_HeatGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+CHP_EN_H_GEN_SUM')
    if (bhkw_th_HeatGenerationDatapointID !== null) dataPointIDs.push(bhkw_th_HeatGenerationDatapointID)

    const bhkw_th_FullLoadHoursDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+CHP_EN_H_GEN_FLH')
    if (bhkw_th_FullLoadHoursDatapointID !== null) dataPointIDs.push(bhkw_th_FullLoadHoursDatapointID)

    const bhkw_el_ElectricityGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+CHP_EN_EL_GEN_SUM')
    if (bhkw_el_ElectricityGenerationDatapointID !== null) dataPointIDs.push(bhkw_el_ElectricityGenerationDatapointID)

    const bhkw_el_FullLoadHoursDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+CHP_EN_EL_GEN_FLH')
    if (bhkw_el_FullLoadHoursDatapointID !== null) dataPointIDs.push(bhkw_el_FullLoadHoursDatapointID)

    const disctrictHeatingGeneration15mDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+H_DISCT+EN_H_CONSUM_15m')
    if (disctrictHeatingGeneration15mDatapointID !== null) dataPointIDs.push(disctrictHeatingGeneration15mDatapointID)

    const heatPumpHeatGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+HP_EN_H_GEN_SUM')
    if (heatPumpHeatGenerationDatapointID !== null) dataPointIDs.push(heatPumpHeatGenerationDatapointID)

    const heatPumpElectricityConsumptionDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+HP_EN_EL_CONSUM_SUM')
    if (heatPumpElectricityConsumptionDatapointID !== null) dataPointIDs.push(heatPumpElectricityConsumptionDatapointID)

    const solarHeatGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+SOL_EN_H_GEN_SUM')
    if (solarHeatGenerationDatapointID !== null) dataPointIDs.push(solarHeatGenerationDatapointID)

    const solarPumpFlowDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+HS+SOL_EN_EL_CONSUM_PU_SUM')
    if (solarPumpFlowDatapointID !== null) dataPointIDs.push(solarPumpFlowDatapointID)

    const photovoltaicsElectricityGeneration15mDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+ELS+PV_EL_GEN_SUM_15m')
    if (photovoltaicsElectricityGeneration15mDatapointID !== null) dataPointIDs.push(photovoltaicsElectricityGeneration15mDatapointID)

    const photovoltaicsElectricityGenerationDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+ELS+PV_EL_GEN_SUM')
    if (photovoltaicsElectricityGenerationDatapointID !== null) dataPointIDs.push(photovoltaicsElectricityGenerationDatapointID)

    const freshWaterConsumptionDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+WAS+WS_FRESH_CONSUM')
    if (freshWaterConsumptionDatapointID !== null) dataPointIDs.push(freshWaterConsumptionDatapointID)

    const greyWaterConsumptionDatapointID: string|null = getPinsDatapointByAlphanumericId(digitalTwinPins, 'B+WAS+WS_GRAY_CONSUM')
    if (greyWaterConsumptionDatapointID !== null) dataPointIDs.push(greyWaterConsumptionDatapointID)

    /**
     * Define start and end dates for the requests
     */

    const currentYearStart = moment().startOf('year').toISOString(true)
    const currentYearEnd = moment().toISOString(true)

    const lastYearStart = moment().subtract(1, 'year').startOf('year').toISOString(true)
    const lastYearEnd = moment().subtract(1, 'year').endOf('year').toISOString(true)

    try {
      /**
       * Throw an error if no datapoints are available for this action
       */
      if (dataPointIDs.length === 0) throw new Error('No datapoints specified to request timeseries data for.')

      const timeseriesResponse: AxiosResponse<TTimeseriesShort>[] = await Promise.all([
        Project.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: dataPointIDs.join(','),
            short: true,
            start: currentYearStart,
            end: currentYearEnd,
            interpolation: INTERPOLATION_METHODS.NONE,
            samplerate: '1h',
            closed_interval: false
          }
        }),
        Project.getTimeseries({
          token,
          id: project_id,
          params: {
            dataPointIDs: dataPointIDs.join(','),
            start: lastYearStart,
            end: lastYearEnd,
            samplerate: '1d',
            short: true,
            closed_interval: true,
            interpolation: INTERPOLATION_METHODS.NONE
          }
        })
      ])

      /**
       * Calculate EnergyConsumption - Electricity peaks
       */
      let electricityPeakLoadCurrentYear: number|null = null
      let electricityBaseLoadCurrentYear: number|null = null
      let electricityPeakLoadLastYear: number|null = null
      let electricityBaseLoadLastYear: number|null = null
      if (electricityConsumption15mDatapointID !== null) {
        const electricityConsumption15mTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${electricityConsumption15mDatapointID}`, undefined)
        const electricityConsumption15mTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${electricityConsumption15mDatapointID}`, undefined)
        if (electricityConsumption15mTimeseriesCurrentYear !== undefined) {
          electricityPeakLoadCurrentYear = calculateLoadFrom15MinAvgData(electricityConsumption15mTimeseriesCurrentYear, false, true)
          electricityBaseLoadCurrentYear = calculateLoadFrom15MinAvgData(electricityConsumption15mTimeseriesCurrentYear, false, false)
        }
        if (electricityConsumption15mTimeseriesLastYear !== undefined) {
          electricityPeakLoadLastYear = calculateLoadFrom15MinAvgData(electricityConsumption15mTimeseriesLastYear, false, true)
          electricityBaseLoadLastYear = calculateLoadFrom15MinAvgData(electricityConsumption15mTimeseriesLastYear, false, false)
        }
      }

      /**
       * Calculate EnergyConsumption - Heat peaks
       */
      let heatPeakLoadCurrentYear: number|null = null
      let heatBaseLoadCurrentYear: number|null = null
      let heatPeakLoadLastYear: number|null = null
      let heatBaseLoadLastYear: number|null = null
      if (heatConsumption15mDatapointID !== null) {
        const heatConsumption15mTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${heatConsumption15mDatapointID}`, undefined)
        const heatConsumption15mTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${heatConsumption15mDatapointID}`, undefined)
        if (heatConsumption15mTimeseriesCurrentYear !== undefined) {
          heatPeakLoadCurrentYear = calculateLoadFrom15MinAvgData(heatConsumption15mTimeseriesCurrentYear, false, true)
          heatBaseLoadCurrentYear = calculateLoadFrom15MinAvgData(heatConsumption15mTimeseriesCurrentYear, false, false)
        }
        if (heatConsumption15mTimeseriesLastYear !== undefined) {
          heatPeakLoadLastYear = calculateLoadFrom15MinAvgData(heatConsumption15mTimeseriesLastYear, false, true)
          heatBaseLoadLastYear = calculateLoadFrom15MinAvgData(heatConsumption15mTimeseriesLastYear, false, false)
        }
      }

      commit('SET_ENERGY_CONSUMPTION_TABLE_DATA', {
        electricity: {
          base_load: {
            currentYear: electricityBaseLoadCurrentYear,
            lastYear: electricityBaseLoadLastYear
          },
          peak_load: {
            currentYear: electricityPeakLoadCurrentYear,
            lastYear: electricityPeakLoadLastYear
          }
        },
        heat: {
          base_load: {
            currentYear: heatBaseLoadCurrentYear,
            lastYear: heatBaseLoadLastYear
          },
          peak_load: {
            currentYear: heatPeakLoadCurrentYear,
            lastYear: heatPeakLoadLastYear
          }
        }
      } as TEnergyConsumptionTable)

      /**
       * Calculate EnergyGeneration - boiler
       */
      let boilersHeatGenerationCurrentYear: number|null = null
      let boilersHeatGenerationLastYear: number|null = null
      let boilersFullLoadHoursCurrentYear: number|null = null
      let boilersFullLoadHoursLastYear: number|null = null
      if (boilersHeatGenerationDatapointID !== null) {
        const boilersHeatGenerationTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${boilersHeatGenerationDatapointID}`, undefined)
        const boilersHeatGenerationTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${boilersHeatGenerationDatapointID}`, undefined)
        if (boilersHeatGenerationTimeseriesCurrentYear !== undefined) {
          boilersHeatGenerationCurrentYear = computeTotalConsumptionValue(boilersHeatGenerationTimeseriesCurrentYear)
        }
        if (boilersHeatGenerationTimeseriesLastYear !== undefined) {
          boilersHeatGenerationLastYear = computeTotalConsumptionValue(boilersHeatGenerationTimeseriesLastYear)
        }
      }
      if (boilersFullLoadHoursDatapointID !== null) {
        const boilersFullLoadHoursTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${boilersFullLoadHoursDatapointID}`, undefined)
        const boilersFullLoadHoursTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${boilersFullLoadHoursDatapointID}`, undefined)
        if (boilersFullLoadHoursTimeseriesCurrentYear !== undefined) {
          boilersFullLoadHoursCurrentYear = computeTotalConsumptionValue(boilersFullLoadHoursTimeseriesCurrentYear)
        }
        if (boilersFullLoadHoursTimeseriesLastYear !== undefined) {
          boilersFullLoadHoursLastYear = computeTotalConsumptionValue(boilersFullLoadHoursTimeseriesLastYear)
        }
      }

      /**
       * Calculate EnergyGeneration - bhkw_th
       */
      let bhkw_th_HeatGenerationCurrentYear: number|null = null
      let bhkw_th_HeatGenerationLastYear: number|null = null
      let bhkw_th_FullLoadHoursCurrentYear: number|null = null
      let bhkw_th_FullLoadHoursLastYear: number|null = null
      if (bhkw_th_HeatGenerationDatapointID !== null) {
        const bhkw_th_HeatGenerationTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${bhkw_th_HeatGenerationDatapointID}`, undefined)
        const bhkw_th_HeatGenerationTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${bhkw_th_HeatGenerationDatapointID}`, undefined)
        if (bhkw_th_HeatGenerationTimeseriesCurrentYear !== undefined) {
          bhkw_th_HeatGenerationCurrentYear = computeTotalConsumptionValue(bhkw_th_HeatGenerationTimeseriesCurrentYear)
        }
        if (bhkw_th_HeatGenerationTimeseriesLastYear !== undefined) {
          bhkw_th_HeatGenerationLastYear = computeTotalConsumptionValue(bhkw_th_HeatGenerationTimeseriesLastYear)
        }
      }
      if (bhkw_th_FullLoadHoursDatapointID !== null) {
        const bhkw_th_FullLoadHoursTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${bhkw_th_FullLoadHoursDatapointID}`, undefined)
        const bhkw_th_FullLoadHoursTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${bhkw_th_FullLoadHoursDatapointID}`, undefined)
        if (bhkw_th_FullLoadHoursTimeseriesCurrentYear !== undefined) {
          bhkw_th_FullLoadHoursCurrentYear = computeTotalConsumptionValue(bhkw_th_FullLoadHoursTimeseriesCurrentYear)
        }
        if (bhkw_th_FullLoadHoursTimeseriesLastYear !== undefined) {
          bhkw_th_FullLoadHoursLastYear = computeTotalConsumptionValue(bhkw_th_FullLoadHoursTimeseriesLastYear)
        }
      }

      /**
       * Calculate EnergyGeneration - bhkw_el
       */
      let bhkw_el_ElectricityGenerationCurrentYear: number|null = null
      let bhkw_el_ElectricityGenerationLastYear: number|null = null
      let bhkw_el_FullLoadHoursCurrentYear: number|null = null
      let bhkw_el_FullLoadHoursLastYear: number|null = null
      if (bhkw_el_ElectricityGenerationDatapointID !== null) {
        const bhkw_el_ElectricityGenerationTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${bhkw_el_ElectricityGenerationDatapointID}`, undefined)
        const bhkw_el_ElectricityGenerationTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${bhkw_el_ElectricityGenerationDatapointID}`, undefined)
        if (bhkw_el_ElectricityGenerationTimeseriesCurrentYear !== undefined) {
          bhkw_el_ElectricityGenerationCurrentYear = computeTotalConsumptionValue(bhkw_el_ElectricityGenerationTimeseriesCurrentYear)
        }
        if (bhkw_el_ElectricityGenerationTimeseriesLastYear !== undefined) {
          bhkw_el_ElectricityGenerationLastYear = computeTotalConsumptionValue(bhkw_el_ElectricityGenerationTimeseriesLastYear)
        }
      }
      if (bhkw_el_FullLoadHoursDatapointID !== null) {
        const bhkw_el_FullLoadHoursTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${bhkw_el_FullLoadHoursDatapointID}`, undefined)
        const bhkw_el_FullLoadHoursTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${bhkw_el_FullLoadHoursDatapointID}`, undefined)
        if (bhkw_el_FullLoadHoursTimeseriesCurrentYear !== undefined) {
          bhkw_el_FullLoadHoursCurrentYear = computeTotalConsumptionValue(bhkw_el_FullLoadHoursTimeseriesCurrentYear)
        }
        if (bhkw_el_FullLoadHoursTimeseriesLastYear !== undefined) {
          bhkw_el_FullLoadHoursLastYear = computeTotalConsumptionValue(bhkw_el_FullLoadHoursTimeseriesLastYear)
        }
      }

      /**
       * Calculate EnergyGeneration - District heating loads
       */
      let districtHeatingBaseLoadCurrentYear: number|null = null
      let districtHeatingBaseLoadLastYear: number|null = null
      let districtHeatingPeakLoadCurrentYear: number|null = null
      let districtHeatingPeakLoadLastYear: number|null = null
      if (disctrictHeatingGeneration15mDatapointID !== null) {
        const districtHeatingTimeseriesCurrentYear: [string, number|null][]|undefined = get(timeseriesResponse[0], `data.${disctrictHeatingGeneration15mDatapointID}`, undefined)
        const districtHeatingTimeseriesLastYear: [string, number|null][]|undefined = get(timeseriesResponse[1], `data.${disctrictHeatingGeneration15mDatapointID}`, undefined)
        if (districtHeatingTimeseriesCurrentYear !== undefined) {
          districtHeatingBaseLoadCurrentYear = calculateLoadFrom15MinAvgData(districtHeatingTimeseriesCurrentYear, true, false)
          districtHeatingPeakLoadCurrentYear = calculateLoadFrom15MinAvgData(districtHeatingTimeseriesCurrentYear, true, true)
        }
        if (districtHeatingTimeseriesLastYear !== undefined) {
          districtHeatingBaseLoadLastYear = calculateLoadFrom15MinAvgData(districtHeatingTimeseriesLastYear, true, false)
          districtHeatingPeakLoadLastYear = calculateLoadFrom15MinAvgData(districtHeatingTimeseriesLastYear, true, true)
        }
      }

      /**
       * Calculate EnergyGeneration - Heat pump
       */
      let heatPumpHeatGenerationCurrentYear: number|null = null
      let heatPumpHeatGenerationLastYear: number|null = null
      let heatPumpElectricityConsumptionCurrentYear: number|null = null
      let heatPumpElectricityConsumptionLastYear: number|null = null
      if (heatPumpHeatGenerationDatapointID !== null) {
        const heatPumpHeatGenerationCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${heatPumpHeatGenerationDatapointID}`, undefined)
        const heatPumpHeatGenerationLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[1], `data.${heatPumpHeatGenerationDatapointID}`, undefined)
        if (heatPumpHeatGenerationCurrentYearTimeseries !== undefined) {
          heatPumpHeatGenerationCurrentYear = computeTotalConsumptionValue(heatPumpHeatGenerationCurrentYearTimeseries)
        }
        if (heatPumpHeatGenerationLastYearTimeseries !== undefined) {
          heatPumpHeatGenerationLastYear = computeTotalConsumptionValue(heatPumpHeatGenerationLastYearTimeseries)
        }
      }
      if (heatPumpElectricityConsumptionDatapointID !== null) {
        const heatPumpElectricityConsumptionCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${heatPumpElectricityConsumptionDatapointID}`, undefined)
        const heatPumpElectricityConsumptionLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[1], `data.${heatPumpElectricityConsumptionDatapointID}`, undefined)
        if (heatPumpElectricityConsumptionCurrentYearTimeseries !== undefined) {
          heatPumpElectricityConsumptionCurrentYear = computeTotalConsumptionValue(heatPumpElectricityConsumptionCurrentYearTimeseries)
        }
        if (heatPumpElectricityConsumptionLastYearTimeseries !== undefined) {
          heatPumpElectricityConsumptionLastYear = computeTotalConsumptionValue(heatPumpElectricityConsumptionLastYearTimeseries)
        }
      }

      /**
       * Calculate EnergyGeneration - Solar
       */
      let solarHeatGenerationCurrentYear: number|null = null
      let solarHeatGenerationLastYear: number|null = null
      let solarPumpFlowCurrentYear: number|null = null
      let solarPumpFlowLastYear: number|null = null
      if (solarHeatGenerationDatapointID !== null) {
        const solarHeatGenerationCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${solarHeatGenerationDatapointID}`, undefined)
        const solarHeatGenerationLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[1], `data.${solarHeatGenerationDatapointID}`, undefined)
        if (solarHeatGenerationCurrentYearTimeseries !== undefined) {
          solarHeatGenerationCurrentYear = computeTotalConsumptionValue(solarHeatGenerationCurrentYearTimeseries)
        }
        if (solarHeatGenerationLastYearTimeseries !== undefined) {
          solarHeatGenerationLastYear = computeTotalConsumptionValue(solarHeatGenerationLastYearTimeseries)
        }
      }
      if (solarPumpFlowDatapointID !== null) {
        const solarPumpFlowCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${solarPumpFlowDatapointID}`, undefined)
        const solarPumpFlowLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[1], `data.${solarPumpFlowDatapointID}`, undefined)
        if (solarPumpFlowCurrentYearTimeseries !== undefined) {
          solarPumpFlowCurrentYear = computeTotalConsumptionValue(solarPumpFlowCurrentYearTimeseries)
        }
        if (solarPumpFlowLastYearTimeseries !== undefined) {
          solarPumpFlowLastYear = computeTotalConsumptionValue(solarPumpFlowLastYearTimeseries)
        }
      }

      /**
       * Calculate EnergyGeneration - Photovoltaics
       */
      let photovoltaicsPeakLoadCurrentYear: number|null = null
      let photovoltaicsPeakLoadLastYear: number|null = null
      if (photovoltaicsElectricityGeneration15mDatapointID !== null) {
        const photovoltaicsPeakLoadCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${photovoltaicsElectricityGeneration15mDatapointID}`, undefined)
        const photovoltaicsPeakLoadLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${photovoltaicsElectricityGeneration15mDatapointID}`, undefined)
        if (photovoltaicsPeakLoadCurrentYearTimeseries !== undefined) {
          photovoltaicsPeakLoadCurrentYear = calculateLoadFrom15MinAvgData(photovoltaicsPeakLoadCurrentYearTimeseries, true, true)
        }
        if (photovoltaicsPeakLoadLastYearTimeseries !== undefined) {
          photovoltaicsPeakLoadLastYear = calculateLoadFrom15MinAvgData(photovoltaicsPeakLoadLastYearTimeseries, true, true)
        }
      }

      let photovoltaicsRevenueCurrentYear: number|null = null
      let photovoltaicsRevenueLastYear: number|null = null
      if (photovoltaicsElectricityGenerationDatapointID !== null) {
        const photovoltaicsRevenueCurrentYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${photovoltaicsElectricityGenerationDatapointID}`, undefined)
        const photovoltaicsRevenueLastYearTimeseries: [string, number|null][] = get(timeseriesResponse[0], `data.${photovoltaicsElectricityGenerationDatapointID}`, undefined)
        if (photovoltaicsRevenueCurrentYearTimeseries !== undefined) {
          photovoltaicsRevenueCurrentYear = computeTotalConsumptionValue(photovoltaicsRevenueCurrentYearTimeseries)
        }
        if (photovoltaicsRevenueLastYearTimeseries !== undefined) {
          photovoltaicsRevenueLastYear = computeTotalConsumptionValue(photovoltaicsRevenueLastYearTimeseries)
        }
      }

      commit('SET_ENERGY_GENERATION_TABLE_DATA', {
        gas_boiler: {
          heat_generation: {
            currentYear: boilersHeatGenerationCurrentYear,
            lastYear: boilersHeatGenerationLastYear
          },
          full_load_hours: {
            currentYear: boilersFullLoadHoursCurrentYear,
            lastYear: boilersFullLoadHoursLastYear
          }
        },
        bhkw_th: {
          heat_generation: {
            currentYear: bhkw_th_HeatGenerationCurrentYear,
            lastYear: bhkw_th_HeatGenerationLastYear
          },
          full_load_hours: {
            currentYear: bhkw_th_FullLoadHoursCurrentYear,
            lastYear: bhkw_th_FullLoadHoursLastYear
          }
        },
        bhkw_el: {
          electricity_generation: {
            currentYear: bhkw_el_ElectricityGenerationCurrentYear,
            lastYear: bhkw_el_ElectricityGenerationLastYear
          },
          full_load_hours: {
            currentYear: bhkw_el_FullLoadHoursCurrentYear,
            lastYear: bhkw_el_FullLoadHoursLastYear
          }
        },
        district_heating: {
          base_load: {
            currentYear: districtHeatingBaseLoadCurrentYear,
            lastYear: districtHeatingBaseLoadLastYear
          },
          peak_load: {
            currentYear: districtHeatingPeakLoadCurrentYear,
            lastYear: districtHeatingPeakLoadLastYear
          }
        },
        heat_pump: {
          heat_generation: {
            currentYear: heatPumpHeatGenerationCurrentYear,
            lastYear: heatPumpHeatGenerationLastYear
          },
          electricity_usage: {
            currentYear: heatPumpElectricityConsumptionCurrentYear,
            lastYear: heatPumpElectricityConsumptionLastYear
          }
        },
        solar: {
          heat_generation: {
            currentYear: solarHeatGenerationCurrentYear,
            lastYear: solarHeatGenerationLastYear
          },
          pump_flow: {
            currentYear: solarPumpFlowCurrentYear,
            lastYear: solarPumpFlowLastYear
          }
        },
        photovoltaic: {
          power_peak: {
            currentYear: photovoltaicsPeakLoadCurrentYear,
            lastYear: photovoltaicsPeakLoadLastYear
          },
          revenue: {
            currentYear: photovoltaicsRevenueCurrentYear,
            lastYear: photovoltaicsRevenueLastYear
          }
        }
      } as TEnergyGenerationTable)

      /**
       * Calculate WaterConsumption - per day
       */
      let freshWaterConsumptionPerDayCurrentYear: number|null = null
      let freshWaterConsumptionPerDayLastYear: number|null = null
      if (freshWaterConsumptionDatapointID !== null) {
        const freshWaterConsumptionPerDayTimeseriesCurrentYear: [string, number|null][] = get(timeseriesResponse[0], `data.${freshWaterConsumptionDatapointID}`, undefined)
        const freshWaterConsumptionPerDayTimeseriesLastYear: [string, number|null][] = get(timeseriesResponse[1], `data.${freshWaterConsumptionDatapointID}`, undefined)
        if (freshWaterConsumptionPerDayTimeseriesCurrentYear !== undefined) {
          freshWaterConsumptionPerDayCurrentYear = computeTotalConsumptionValuePerDay(freshWaterConsumptionPerDayTimeseriesCurrentYear)
        }
        if (freshWaterConsumptionPerDayTimeseriesLastYear !== undefined) {
          freshWaterConsumptionPerDayLastYear = computeTotalConsumptionValuePerDay(freshWaterConsumptionPerDayTimeseriesLastYear)
        }
      }

      let greyWaterConsumptionPerDayCurrentYear: number|null = null
      let greyWaterConsumptionPerDayLastYear: number|null = null
      if (greyWaterConsumptionDatapointID !== null) {
        const greyWaterConsumptionPerDayTimeseriesCurrentYear: [string, number|null][] = get(timeseriesResponse[0], `data.${greyWaterConsumptionDatapointID}`, undefined)
        const greyWaterConsumptionPerDayTimeseriesLastYear: [string, number|null][] = get(timeseriesResponse[1], `data.${greyWaterConsumptionDatapointID}`, undefined)
        if (greyWaterConsumptionPerDayTimeseriesCurrentYear !== undefined) {
          greyWaterConsumptionPerDayCurrentYear = computeTotalConsumptionValuePerDay(greyWaterConsumptionPerDayTimeseriesCurrentYear)
        }
        if (greyWaterConsumptionPerDayTimeseriesLastYear !== undefined) {
          greyWaterConsumptionPerDayLastYear = computeTotalConsumptionValuePerDay(greyWaterConsumptionPerDayTimeseriesLastYear)
        }
      }

      commit('SET_WATER_CONSUMPTION_TABLE_DATA', {
        fresh_water: {
          currentYear: freshWaterConsumptionPerDayCurrentYear,
          lastYear: freshWaterConsumptionPerDayLastYear
        },
        grey_water: {
          currentYear: greyWaterConsumptionPerDayCurrentYear,
          lastYear: greyWaterConsumptionPerDayLastYear
        }
      } as TWaterConsumptionTable)
    } catch (error) {
      console.warn('Error while fetching timeseries data for the media cards tables.', error)
    } finally {
      commit('SET_LOADING', false)
    }
  }
} as ActionTree<TMediaTablesState, TRootState>
