import get from 'lodash.get'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import { SEVERITY, TYPE, TAlert, STATUS } from '@/services/alerta/resources/common/responseTypes'
import { TEventType } from '@/store/project/types'
import { getTranslatedEventName } from './events'

export class AlertsHelper {
  /**
   * Creates a formatted date string that is relative to the alerts creation date if it's from the last two days by prefixing "Today, " or "Yesterday, ".
   * Otherwise it simply returns a formatted date.
   * @param alert The alert for which to create the relative date.
   * @returns Formatted date.
   */
  static getRelativeDate (alert: TAlert): string {
    const now = moment()
    const createTime = moment(alert.createTime)
    if (createTime.year() === now.year() && createTime.dayOfYear() >= now.dayOfYear() - 1) {
      if (createTime.dayOfYear() === now.dayOfYear()) {
        return `${i18n.t('alarms.date.today')}, ${createTime.format('HH:mm:ss')}`
      } else if (createTime.dayOfYear() === now.dayOfYear() - 1) {
        return `${i18n.t('alarms.date.yesterday')}, ${createTime.format('HH:mm:ss')}`
      }
    }
    return createTime.format('DD.MM.YYYY, HH:mm:ss')
  }

  /**
   * If the alert has a category, it returns the name of the associated event, otherwise it returns the alert's event value.
   * @param alert The alert for which to retrieve the event name.
   * @param events Events defined for the project.
   * @returns Event name.
   */
  static getEventName (alert: TAlert, events: Array<TEventType> = []): string {
    let result: string = alert.event
    const category: string|null = get(alert, 'attributes.category', null)
    if (category) {
      const event = events.find(event => event.category === category)
      if (event) {
        // here we can also assume that the returned value is a string since event.name will never be undefined
        result = getTranslatedEventName(event.name) as string
      }
    }
    return result
  }

  static allPriorities = [
    {
      text: `${i18n.t('alarms.priority')} A`,
      value: SEVERITY.CRITICAL
    }, {
      text: `${i18n.t('alarms.priority')} B`,
      value: SEVERITY.WARNING
    }, {
      text: `${i18n.t('alarms.priority')} C`,
      value: SEVERITY.INFORMATIONAL
    }
  ]

  /**
   * Returns the priority that matches an alert's severity, if there is one. Otherwise returns the severity.
   * @param severity The severity to translate into a priority.
   * @returns Priority for the severity.
   */
  static getPriority (severity?: SEVERITY): string {
    let result = ''
    if (severity !== undefined) {
      const priority = AlertsHelper.allPriorities.find(item => (item.value === severity))
      if (priority) {
        result = priority.text
      } else {
        result = severity as string
      }
    }
    return result
  }

  /**
   * Returns the priority color that matches an alert's severity, if there is one. Otherwise returns 'info'.
   * @param severity The severity to translate into a priority color.
   * @returns Color for the severity.
   */
  static getPriorityColor (severity?: SEVERITY): string {
    let result = 'info'
    if (severity !== undefined) {
      if (severity === SEVERITY.CRITICAL) {
        result = 'error'
      } else if (severity === SEVERITY.WARNING) {
        result = 'warning'
      }
    }
    return result
  }

  static allStatuses = [
    {
      text: i18n.t('alarms.status.open') as string,
      value: STATUS.OPEN
    }, {
      text: i18n.t('alarms.status.assign') as string,
      value: STATUS.ASSIGN
    }, {
      text: i18n.t('alarms.status.ack') as string,
      value: STATUS.ACK
    }, {
      text: i18n.t('alarms.status.closed') as string,
      value: STATUS.CLOSED
    }, {
      text: i18n.t('alarms.status.expired') as string,
      value: STATUS.EXPIRED
    }, {
      text: i18n.t('alarms.status.blackout') as string,
      value: STATUS.BLACKOUT
    }, {
      text: i18n.t('alarms.status.shelved') as string,
      value: STATUS.SHELVED
    }, {
      text: i18n.t('alarms.status.unknown') as string,
      value: STATUS.UNKNOWN
    }
  ]

  /**
   * Returns translation associated with an alert's status.
   * @param status The status to translate.
   * @returns Translated status.
   */
  static getTranslatedStatus (status?: STATUS): string {
    let result = ''
    if (status !== undefined) {
      const matchingStatus = AlertsHelper.allStatuses.find(item => (item.value === status))
      if (matchingStatus) {
        result = matchingStatus.text
      }
    }
    return result
  }

  static allTypes = [
    {
      text: i18n.t('alarms.type.throughput') as string,
      value: TYPE.THROUGHPUT
    }, {
      text: i18n.t('alarms.type.threshold') as string,
      value: TYPE.THRESHOLD
    }
  ]

  /**
   * Returns translation associated with the alert's type.
   * There should only be alarms with types 'throughput' and 'threshold', so only those are translated.
   * @param alert The alert for which to translate the type.
   * @returns Translated type.
   */
  static getType (alert: TAlert): string {
    let result = ''
    if (alert.type) {
      const type = AlertsHelper.allTypes.find(item => (item.value === alert.type))
      if (type) {
        result = type.text
      } else {
        result = alert.type as string
      }
    }
    return result
  }

  static isWatchedBy (alert: TAlert, usersEmail: string): boolean {
    return alert.tags !== undefined && alert.tags.includes(`watch:${usersEmail}`)
  }

  static getTagValue (alert: TAlert, tagKey: string): string|null {
    const alertTags = get(alert, 'tags', [])
    for (const alertTag of alertTags) {
      const tagItems = alertTag.split('=')
      if (tagItems.length === 2 && tagItems[0] === tagKey) {
        return tagItems[1]
      }
    }
    return null
  }
}
