import moment from 'moment'

export type DateRange = [string, string?]

export type Zoom = {
  start: Date;
  end: Date;
}

export type StartEndDate = {
  start: Date|undefined;
  end: Date;
}

/**
 * Returns the time range as required for API requests. If a zoom is given that is returned as is.
 * Otherwise it makes sure the date range contains both a start and end date and limits the end date to the current time.
 * @param dateRange The range to be used if no zoom is given. Is assumed to be UTC.
 * @param zoom Optional zoom. Preferred over the date range and assumed to be in UTC.
 * @returns The applicable time range in UTC with a precision of minutes.
 */
export const getStartAndEndDate = (dateRange: DateRange, zoom?: Zoom, live?: boolean): StartEndDate => {
  let start, end: Date|undefined
  if (live) {
    const nowUtc = moment.utc().milliseconds(0)
    start = moment(nowUtc).subtract(15, 'minutes').toDate()
    end = nowUtc.toDate()
  } else if (zoom) {
    start = zoom.start
    end = zoom.end
  } else {
    // Take the start of the day of the first item in the date range and set it to midnight in local time, then convert to UTC, adjusting the time.
    start = moment(dateRange[0]).startOf('day').utc().toDate()
    let endMoment: moment.Moment
    if (dateRange.length === 1) {
      endMoment = moment(dateRange[0])
    } else {
      endMoment = moment(dateRange[1])
    }
    const nowLocal = moment()
    if (endMoment.isSame(nowLocal, 'day')) {
      end = nowLocal.seconds(0).milliseconds(0).utc().toDate()
    } else {
      end = endMoment.endOf('day').utc().toDate()
    }
  }
  return {
    end,
    start
  }
}
