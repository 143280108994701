




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import nodata from 'highcharts/modules/no-data-to-display'
nodata(Highcharts)

export default Vue.extend({
  name: 'BarChart',
  components: {
    highcharts: Chart
  },
  props: {
    title: {
      required: true,
      type: String as PropType<string>
    },
    subtitle: {
      required: false,
      type: String as PropType<string>
    },
    series: {
      required: true,
      type: Array as PropType<Array<object|Highcharts.SeriesOptionsType>>
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal === true) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  beforeCreate () {
    /**
     * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
     * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
     */
    Highcharts.setOptions({
      lang: {
        loading: this.$t('data.loading') as string,
        noData: this.$t('data.not_available') as string
      }
    })
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'myriad-pro'
          }
        },
        plotOptions: {
          column: {
            centerInCategory: true,
            pointWidth: 30
          }
        },
        colors: [
          '#004B8E',
          '#638A82',
          '#936362',
          '#9C9770',
          '#AEBFC9',
          '#931322',
          '#D4A92A'
        ],
        credits: {
          enabled: false
        },
        title: {
          text: this.title,
          style: {
            color: '#004B8E'
          }
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: 0,
            style: {
              fontSize: '14px',
              fontFamily: 'myriad-pro',
              color: '#004B8E'
            }
          }
        },
        yAxis: {
          min: 0,
          title: this.subtitle ? {
            text: this.subtitle,
            style: {
              color: '#004B8E'
            }
          } : {},
          labels: {
            style: {
              color: '#004B8E'
            }
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '<b>{point.absValue}</b><br><b>{point.y}</b> {point.unit}'
        },
        series: this.series.map((series: any) => {
          return {
            ...series,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.absValue}',
              y: 10,
              style: {
                fontSize: '13px',
                fontFamily: 'myriad-pro'
              }
            }
          }
        })
      }
    }
  }
})
