


















































import { EventData } from 'mapbox-gl'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'AvatarCard',
  props: {
    title: {
      required: true,
      type: String as PropType<string>
    },
    uploadable: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    avatar: {
      required: true,
      type: String as PropType<string>
    },
    avatarAlt: {
      required: false,
      type: String as PropType<string>
    }
  },
  methods: {
    chooseFile (): void {
      (this.$refs.fileInput as HTMLElement).click()
    },
    emitUplaod (fileEvent: EventData): void {
      const file: File = fileEvent.target.files[0]
      if (file) this.$emit('upload', file)
    }
  }
})
