




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import nodata from 'highcharts/modules/no-data-to-display'

nodata(Highcharts)

export default Vue.extend({
  name: 'AreaChart',
  components: {
    highcharts: Chart
  },
  beforeCreate () {
    /**
     * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
     * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
     */
    Highcharts.setOptions({
      lang: {
        loading: this.$t('data.loading') as string,
        noData: this.$t('data.not_available') as string
      }
    })
  },
  props: {
    title: {
      required: false,
      type: String as PropType<string>
    },
    series: {
      required: true,
      type: Array as PropType<Array<any>>
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableLegend: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    disableTitle: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    },
    xAxisType: {
      required: false,
      default: 'datetime',
      type: String as PropType<string>
    },
    xAxisMin: {
      required: false,
      default: undefined,
      type: Number as PropType<number>
    },
    xAxisMax: {
      required: false,
      default: undefined,
      type: Number as PropType<number>
    },
    xAxisTitle: {
      required: false,
      default: undefined,
      type: String as PropType<string>
    },
    yAxisTitle: {
      required: false,
      default: undefined,
      type: String as PropType<string>
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  computed: {
    chartOptions () {
      return {
        noData: {
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        loading: {
          style: {
            fontFamily: 'myriad-pro',
            fontWeight: 'bold',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        chart: {
          type: 'area',
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E'
          }
        },
        plotOptions: {
          series: {
            lineWidth: 2,
            turboThreshold: 9000
          }
        },
        legend: {
          enabled: !this.disableLegend,
          itemStyle: {
            color: '#004B8E',
            fontWeight: 'semibold'
          }
        },
        colors: [
          '#004B8E',
          '#638A82',
          '#936362',
          '#9C9770',
          '#AEBFC9',
          '#931322',
          '#D4A92A'
        ],
        credits: {
          enabled: false
        },
        title: {
          text: this.title ? this.title : null,
          style: {
            color: '#004B8E'
          }
        },
        xAxis: {
          min: this.xAxisMin,
          max: this.xAxisMax,
          type: this.xAxisType,
          title: {
            text: this.xAxisTitle,
            style: {
              fontFamily: 'myriad-pro',
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              fontFamily: 'myriad-pro',
              color: '#004B8E'
            }
          }
        },
        yAxis: {
          title: {
            enabled: !this.disableTitle,
            text: this.yAxisTitle,
            style: {
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              color: '#004B8E'
            }
          }
        },
        series: this.series
      }
    }
  }
})
