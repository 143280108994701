/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gatherAllLeafNodes, lookupLeafNode } from '@/utils/helpers/labels'
import { GetterTree } from 'vuex'
import { LabelsState } from './types'
import { TRootState } from '../types'

const CURRENCY_SYSTEMS = 'currency_systems'
const UNITS_SYSTEMS = 'units_systems'

export default {
  allLeafNodes: (state: LabelsState) => (rootNodeId: string): any[]|null => {
    if (state.definitions === null) {
      return null
    }
    const rootNode: any|null = state.definitions[rootNodeId] ?? null
    return rootNode === null ? [] : gatherAllLeafNodes(rootNode)
  },

  allRootNodes: (state: LabelsState): any[]|null => {
    if (state.definitions === null) {
      return null
    } else {
      const result: any[] = []
      for (const rootNode in state.definitions) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { children, ...fieldsToCopy } = state.definitions[rootNode]
        result.push(fieldsToCopy)
      }
      return result
    }
  },

  availableCurrencies: (state: LabelsState): string[]|null => {
    return state.systems?.[CURRENCY_SYSTEMS] ?? null
  },

  availableUnitSystems: (state: LabelsState): string[]|null => {
    return state.systems?.[UNITS_SYSTEMS] ?? null
  },

  label: (state: LabelsState) => (rootNodeId: string, leafNodeId?: string): any|null => {
    if (state.definitions === null) {
      return null
    }
    const rootNode: any|null = state.definitions[rootNodeId] ?? null
    if (rootNode === null) {
      return null
    } else if (leafNodeId === undefined) {
      return rootNode
    } else {
      const rootAndLeafeNodeString = `${rootNodeId}/${leafNodeId}`
      if (state.labelDefinitionLookupMap.has(rootAndLeafeNodeString)) {
        return state.labelDefinitionLookupMap.get(rootAndLeafeNodeString)
      } else {
        const leafNode: any = lookupLeafNode(rootNode, leafNodeId)
        state.labelDefinitionLookupMap.set(rootAndLeafeNodeString, leafNode)
        return leafNode
      }
    }
  }
} as GetterTree<LabelsState, TRootState>
