import { UserManagerSettings } from 'oidc-client'
import { VuexOidcClientSettings } from 'vuex-oidc'

export const oidcSettings: VuexOidcClientSettings & UserManagerSettings = {
  authority: window.configuration.OAUTH_AUTHORITY,
  clientId: window.configuration.OAUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth`,
  postLogoutRedirectUri: window.location.origin,
  responseType: 'code',
  response_mode: 'query',
  scope: 'openid',
  loadUserInfo: false,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  monitorSession: false,
  silentRedirectUri: `${window.location.origin}/auth-silent-renew`
}
