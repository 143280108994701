































































































































































import Vue from 'vue'
import { AlertsHelper } from '@/utils/helpers/alerts'
import { AlertsFilterHelper } from '@/utils/helpers/alertsfilter'
import { TAlarmsFilter } from '@/store/alerts/types'
import { TEventType } from '@/store/project/types'

import moment from 'moment'
import { getTranslatedEventName } from '@/utils/helpers/events'

export default Vue.extend({
  name: 'AlarmsFilter',

  computed: {
    dateRange: {
      get (): Array<string> {
        return this.dateSelection
      },
      set (dates: Array<string>) {
        if (dates.length === 2 && dates[1] < dates[0]) {
          const tmp = dates[0]
          dates[0] = dates[1]
          dates[1] = tmp
        }
        this.dateSelection = dates
      }
    },

    dateRangeText (): string {
      return this.dateSelection ? this.dateSelection.join(' ~ ') : ''
    },

    categoryOptions (): Array<{ text: string; value: string }> {
      const allEvents: Array<TEventType> = this.$store.getters['project/getEvents']
      return allEvents.map((event: TEventType) => {
        const eventName = getTranslatedEventName(event.name) as string
        return {
          // in this case we can assume to always get a name returned since the event.name will never be undefined
          text: eventName,
          value: event.category
        }
      })
    }
  },

  data () {
    return {
      dateSelection: [] as Array<string>,
      dialogOpen: false,
      categorySelection: [] as Array<string>,
      priorityOptions: AlertsHelper.allPriorities,
      prioritySelection: [] as Array<string>,
      statusOptions: AlertsHelper.allStatuses,
      statusSelection: [] as Array<string>,
      typeOptions: AlertsHelper.allTypes,
      typeSelection: [] as Array<string>,
      showDateRangePicker: false,
      watchedOnly: false
    }
  },

  methods: {
    openDialog () {
      const filters: Array<TAlarmsFilter> = this.$store.getters['alerts/getFilters']
      if (filters.length > 0) {
        this.categorySelection = filters.filter(item => item.column === 'category').map(item => item.value as string)
        this.dateSelection = filters.filter(item => item.column === 'createTime').map(item => item.value as string)
        this.prioritySelection = filters.filter(item => item.column === 'severity').map(item => item.value as string)
        this.statusSelection = filters.filter(item => item.column === 'status').map(item => item.value as string)
        this.typeSelection = filters.filter(item => item.column === 'type').map(item => item.value as string)
        this.watchedOnly = filters.find(item => item.column === 'watched')?.value as boolean
      } else {
        this.categorySelection = []
        this.dateSelection = []
        this.prioritySelection = []
        this.statusSelection = []
        this.typeSelection = []
        this.watchedOnly = false
      }
      this.dialogOpen = true
    },

    submit () {
      const filters = new Array<TAlarmsFilter>()

      if (this.dateSelection && this.dateSelection.length > 0) {
        let text: string
        let value: string | Array<string>
        if (this.dateSelection.length === 1) {
          text = moment(this.dateSelection[0]).format('DD.MM.YYYY')
          value = this.dateSelection[0]
        } else {
          text = `${moment(this.dateSelection[0]).format('DD.MM.YYYY')} - ${moment(this.dateSelection[1]).format('DD.MM.YYYY')}`
          value = this.dateSelection
        }
        filters.push({
          text: text,
          column: 'createTime',
          value: value
        })
      }

      for (const priority of this.prioritySelection) {
        const selectedItem = this.priorityOptions.find(item => item.value === priority)
        if (selectedItem) {
          filters.push({
            text: selectedItem.text,
            column: 'severity',
            value: selectedItem.value
          })
        }
      }

      for (const status of this.statusSelection) {
        const selectedItem = this.statusOptions.find(item => item.value === status)
        if (selectedItem) {
          filters.push({
            text: selectedItem.text,
            column: 'status',
            value: selectedItem.value
          })
        }
      }

      for (const type of this.typeSelection) {
        const selectedItem = this.typeOptions.find(item => item.value === type)
        if (selectedItem) {
          filters.push({
            text: selectedItem.text,
            column: 'type',
            value: selectedItem.value
          })
        }
      }

      for (const event of this.categorySelection) {
        const selectedItem = this.categoryOptions.find(item => item.value === event)
        if (selectedItem) {
          filters.push({
            text: selectedItem.text,
            column: 'category',
            value: selectedItem.value
          })
        }
      }

      if (this.watchedOnly) {
        filters.push({
          text: String(this.$t('alarms.filter.watchedOnlyValue')),
          column: 'watched',
          value: this.watchedOnly
        })
      }

      this.$store.commit('alerts/SET_FILTERS', filters)
      this.dialogOpen = false
      this.$router.replace({ query: AlertsFilterHelper.convertFiltersToUrlQuery(filters) })
    }
  }
})
