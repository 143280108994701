import {
  MutationTree
} from 'vuex'
import { TWaterConsumptionState } from './types'

export default {
  SET_CHART_AND_BENCHMARK_DATA: (state: TWaterConsumptionState, payload: {
    fresh_water: number|null;
    rain_water: number|null;
    gray_water: number|null;
    waterConsumption: number|null;
    waterConsumptionColor: string|null;
    waterConsumptionProjection: number|null;
  }) => {
    state.benchmark.waterConsumption = payload.waterConsumption
    state.benchmark.waterConsumptionColor = payload.waterConsumptionColor
    state.benchmark.waterConsumptionProjection = payload.waterConsumptionProjection
    state.chart.fresh_water = payload.fresh_water
    state.chart.gray_water = payload.gray_water
    state.chart.rain_water = payload.rain_water
  },
  SET_LOADING_DATA: (state: TWaterConsumptionState, loading: boolean) => {
    state.loading = loading
  },
  SET_DATA: (state: TWaterConsumptionState, payload: {
    fresh_water: number|null;
    rain_water: number|null;
    gray_water: number|null;
    hasPreviousData: boolean;
    hasNextData: boolean;
  }) => {
    state.chart.fresh_water = payload.fresh_water
    state.chart.gray_water = payload.gray_water
    state.chart.rain_water = payload.rain_water
    state.hasPrevious = payload.hasPreviousData
    state.hasNext = payload.hasNextData
  },
  SET_YEAR_SELECTED: (state: TWaterConsumptionState, isYearSelected: boolean) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TWaterConsumptionState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TWaterConsumptionState, month: number) => {
    state.month = month
  }
} as MutationTree<TWaterConsumptionState>
