import Vue from 'vue'
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import i18n from '@/plugins/i18n'

const baseURL = 'https://api.weatherapi.com/v1/'

const instance: AxiosInstance = axios.create({
  baseURL,
  params: {
    key: window.secrets.WEATHERAPI_TOKEN
  }
})

// Register repsponse interceptor
instance.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  return response
}, (error: AxiosError): AxiosError|void => {
  console.error(error)
  const status: number|undefined = error.response?.status
  const message: string|undefined = error.response?.data.error
  if (status && [401, 403, 500].includes(status)) {
    Vue.notify({
      group: 'all',
      type: 'error',
      title: message,
      text: i18n.t('errors.401') as string,
      duration: 10000
    })
  }
  throw error
})

export default instance as AxiosInstance
