import { TEnergyGenerationState } from './types'
import moment from 'moment'

export default {
  chart: {
    boiler: null,
    heat_pump: null,
    district_heating: null,
    bhkw_th: null,
    bhkw_el: null,
    photovoltaics: null,
    solar: null
  },
  loading: true,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false,
  benchmark: {
    energyGeneration: null,
    energyGenerationColor: null,
    energyGenerationProjection: null
  }
} as TEnergyGenerationState
