import i18n from '@/plugins/i18n'

export const getAPILanguageResponseKey = (): string => {
  switch (i18n.locale) {
    case 'de':
      return 'nameDE'
    case 'en':
      return 'nameEN'
    default:
      return 'nameDE'
  }
}
