




















































































import { TEnergyConsumptionTable } from '@/store/media_tables/types'
import Vue from 'vue'
import moment from 'moment'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'Electricity',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyConsumptionData (): TEnergyConsumptionTable|null {
      return this.$store.getters['media_tables/getEnergyConsumption']
    },
    electricityLoads (): TEnergyConsumptionTable['electricity']|null {
      if (this.energyConsumptionData === null) return null
      return this.energyConsumptionData.electricity
    },
    peakLoadOfCurrentYear (): string|null {
      if (this.electricityLoads === null) return null
      return this.electricityLoads.peak_load.currentYear !== null ? formatValue(this.electricityLoads.peak_load.currentYear, 0) : null
    },
    baseLoadOfCurrentYear (): string|null {
      if (this.electricityLoads === null) return null
      return this.electricityLoads.base_load.currentYear !== null ? formatValue(this.electricityLoads.base_load.currentYear, 0) : null
    },
    peakLoadOfLastYear (): string|null {
      if (this.electricityLoads === null) return null
      return this.electricityLoads.peak_load.lastYear !== null ? formatValue(this.electricityLoads.peak_load.lastYear, 0) : null
    },
    baseLoadOfLastYear (): string|null {
      if (this.electricityLoads === null) return null
      return this.electricityLoads.base_load.lastYear !== null ? formatValue(this.electricityLoads.base_load.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
