import { TUserState } from './types'

export default {
  firstName: '',
  lastName: '',
  email: '',
  id: 0,
  company_id: 0,
  loading: true,
  password_locked_until: 'None',
  contact: {
    addresses: {
      home: '',
      work: ''
    },
    phones: {
      home: '',
      mobile: '',
      work: ''
    }
  },
  companyRoles: [],
  projectRoles: []
} as TUserState
