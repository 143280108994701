


























import Vue from 'vue'

export default Vue.extend({
  name: 'HeaderIcon',
  computed: {
    electricityColor (): string|null {
      return this.$store.getters['energy_consumption/getElectricityConsumptionColor']
    },
    heatColor (): string|null {
      return this.$store.getters['energy_consumption/getHeatConsumptionColor']
    }
  }
})
