




































































































































































































































import Vue from 'vue'
import moment from 'moment'

import Card from '@/components/ui/Card.vue'
import PieChart from '@/components/ui/Charts/PieChart.vue'
import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import { formatValue } from '@/filters/formatting'

type TTimeseriesDataSeries = {
  name: string;
  y: number;
  unit: string;
  absValue: string;
}

type TTimeseriesData = {
  name: string;
  data: TTimeseriesDataSeries[];
}

export default Vue.extend({
  name: 'ChargingStations',
  components: {
    Card,
    PieChart
  },
  data () {
    return {
      occupancySubscription: null as number|null
    }
  },
  async mounted () {
    this.$store.dispatch('charging_stations/fetchTableData')
    this.$store.dispatch('charging_stations/fetchChartData')
    await this.$store.dispatch('charging_stations/fetchChargingStations')
    this.$store.dispatch('charging_stations/fetchOccupancy', true)
    if (this.hasStations) {
      this.occupancySubscription = setInterval(() => {
        this.$store.dispatch('charging_stations/fetchOccupancy', true)
      }, 5000)
    }
  },
  destroyed () {
    if (this.occupancySubscription !== null) {
      clearInterval(this.occupancySubscription)
    }
  },
  methods: {
    switchToMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('charging_stations/switchChartToMonth')
      }
    },
    switchToYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('charging_stations/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('charging_stations/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('charging_stations/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('charging_stations/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('charging_stations/selectNextMonth')
      }
    }
  },
  computed: {
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    },
    loadingComponents (): boolean {
      return this.$store.getters['charging_stations/getComponentsLoadingState']
    },
    loadingData (): boolean {
      return this.$store.getters['charging_stations/getOccupancyAndOperationsLoadingState']
    },
    loadingTableData (): boolean {
      return this.$store.getters['charging_stations/isLoadingTableData']
    },
    loading (): boolean {
      return this.loadingComponents || this.loadingData || this.loadingTableData
    },
    loadingChartData (): boolean {
      return this.$store.getters['charging_stations/isLoadingChartData']
    },
    amountOfStations (): number {
      const stations: TComponentInProjectWithContextResponse[] = this.$store.getters['charging_stations/getStations']
      return stations.length
    },
    occupancy (): number|null {
      const occ: number|null = this.$store.getters['charging_stations/getOccupancy']
      return occ !== null ? occ : 0
    },
    areChargingStationsOperating (): boolean|null {
      return this.$store.getters['charging_stations/areAllStationsOperating']
    },
    allStationsOccupied (): boolean|null {
      if (this.occupancy !== null) {
        return this.occupancy === this.amountOfStations
      } else {
        return null
      }
    },
    hasMissingOccupancyData (): boolean {
      return this.$store.getters['charging_stations/hasMissingOccupancy']
    },
    year (): number {
      return this.$store.getters['charging_stations/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['charging_stations/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['charging_stations/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['charging_stations/hasPrevious']
    },
    month (): number {
      return this.$store.getters['charging_stations/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['charging_stations/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
    },
    thisYearsUsageTime (): number|null {
      return this.$store.getters['charging_stations/getThisYearsUsageTime']
    },
    lastYearsUsageTime (): number|null {
      return this.$store.getters['charging_stations/getLastYearsUsageTime']
    },
    thisYearsElectricityConsumption (): number|null {
      return this.$store.getters['charging_stations/getThisYearsElectricityConsumption']
    },
    lastYearsElectricityConsumption (): number|null {
      return this.$store.getters['charging_stations/getLastYearsElectricityConsumption']
    },
    usageTime (): number|null {
      return this.$store.getters['charging_stations/getUsageTime']
    },
    noUsageTime (): number|null {
      return this.$store.getters['charging_stations/getNoUsageTime']
    },
    relativeUsage (): number|null {
      return this.$store.getters['charging_stations/getRelativeUsage']
    },
    availableHours (): number {
      return this.$store.getters['charging_stations/getAvailableHoursForSelectedTime']
    },
    hasStations (): boolean {
      return this.$store.getters['charging_stations/hasStations']
    },
    timeseries (): TTimeseriesData[] {
      const timeseriesData: TTimeseriesData = {
        name: `${this.$t('utils.usage_time')} ${this.$t('units.in_hours')}`,
        data: []
      }

      if (this.usageTime !== null && this.relativeUsage !== null) {
        timeseriesData.data.push({
          name: `${this.$t('utils.usage_time')}: ${this.relativeUsage}%`,
          y: this.relativeUsage,
          unit: this.$t('units.hours'),
          absValue: formatValue(this.usageTime, 1)
        } as TTimeseriesDataSeries)

        if (this.noUsageTime !== null) {
          timeseriesData.data.push({
            name: `${this.$t('utils.no_usage_time')}: ${100 - this.relativeUsage}%`,
            y: 100 - this.relativeUsage,
            unit: this.$t('units.hours'),
            absValue: formatValue(this.noUsageTime, 1)
          } as TTimeseriesDataSeries)
        }
      }
      return [timeseriesData]
    }
  }
})
