import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { TEnergyConsumptionTable, TEnergyGenerationTable, TMediaTablesState, TWaterConsumptionTable } from './types'

export default {
  isLoading: (state: TMediaTablesState): boolean => state.loading,
  getEnergyConsumption: (state: TMediaTablesState): TEnergyConsumptionTable|null => state.energy_consumption,
  getEnergyGeneration: (state: TMediaTablesState): TEnergyGenerationTable|null => state.energy_generation,
  getWaterConsumption: (state: TMediaTablesState): TWaterConsumptionTable|null => state.water_consumption
} as GetterTree<TMediaTablesState, TRootState>
