import { TWaterConsumptionState } from './types'
import moment from 'moment'

export default {
  chart: {
    fresh_water: null,
    gray_water: null,
    rain_water: null
  },
  loading: true,
  isYearSelected: true,
  year: moment().year(),
  month: moment().month(),
  hasPrevious: false,
  hasNext: false,
  benchmark: {
    waterConsumption: null,
    waterConsumptionColor: null,
    waterConsumptionProjection: null
  }
} as TWaterConsumptionState
