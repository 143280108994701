import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '@/utils/oidc'
import { TRootState } from './types'

// Import all VueX modules
import UserModule from './user'
import CompanyModule from './company'
import ProjectsModule from './projects'
import ProjectModule from './project'
import ComponentsModule from './components'
import AlertsModule from './alerts'
import LabelsModule from './labels'
import AppModule from './app'

// Media modules
import EnergyConsumptionModule from './energy_consumption'
import EnergyGenerationModule from './energy_generation'
import CO2EmissionModule from './co2_emission'
import WaterConsumptionModule from './water_consumption'
import ChargingStationsModule from './charging_stations'
import LightingSystemsModule from './lighting_systems'
import OperationsModule from './operations'
import EnergyManagementModule from './energy_management'
import MediaTablesModule from './media_tables'
import ElevatorsModule from './elevators'
import ComponentsNewModules from './componentsnew'
import { getAll, ISetAccessToken } from '@/services/aedifion'
import { aedifionApi } from '@aedifion.io/aedifion-api'

Vue.use(Vuex)

const store: Store<TRootState> = new Vuex.Store({
  modules: {
    // OIDC authentication
    auth: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        isAuthenticatedBy: 'access_token',
        publicRoutePaths: ['/auth-failed']
      }
    ),
    app: AppModule,
    user: UserModule,
    company: CompanyModule,
    projects: ProjectsModule,
    project: ProjectModule,
    components: ComponentsModule,
    componentsnew: ComponentsNewModules,
    alerts: AlertsModule,
    energy_consumption: EnergyConsumptionModule,
    energy_generation: EnergyGenerationModule,
    co2_emission: CO2EmissionModule,
    water_consumption: WaterConsumptionModule,
    charging_stations: ChargingStationsModule,
    lighting_systems: LightingSystemsModule,
    operations: OperationsModule,
    energy_management: EnergyManagementModule,
    media_tables: MediaTablesModule,
    elevators: ElevatorsModule,
    labels: LabelsModule
  }
} as StoreOptions<TRootState>)

const unsubscribe = store.subscribe((action) => {
  if (action.type === 'auth/setOidcAuth' && !store.getters['user/isUserLoaded']) {
    const accessToken: string = store.state.auth.access_token as string
    getAll().forEach((element: ISetAccessToken) => {
      element.setAccessToken(accessToken)
    })
    aedifionApi.initServices(window.configuration.AEDIFION_API_URL_SHORT, accessToken)
    store.dispatch('user/fetch')
    store.dispatch('elevators/fetchAnalyticsFunctions')
    store.dispatch('alerts/startPollingAlerts')
    store.dispatch('labels/fetchLabelSystems')
    store.dispatch('componentsnew/fetchComponents')
    store.dispatch('user/fetchProjects')
      .then(() => {
        store.dispatch('app/fetchPortfolioAggregationKpis')
      })
  }
}, { prepend: true })

// Due to some reason, the 'auth/setOidcAuth' action/mutation is being called twice, on initial login
// Therefore, the subscribtion has to be cancelled after the first time and the user data is being called to fetch
store.subscribeAction((action) => {
  if (action.type === 'user/fetch') {
    unsubscribe()
  }
})

export default store
