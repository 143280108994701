


























































































































































import Vue from 'vue'
import StatusCard from '@/components/ui/StatusCard.vue'
import timezones from 'moment-timezone'

export default Vue.extend({
  name: 'Status',
  components: {
    StatusCard
  },
  data () {
    return {
      timeSubscription: null as any,
      currentTime: timezones(),
      displayTime: null as any,
      unsubscribeTimezoneSubscription: null as any
    }
  },
  mounted () {
    this.timeSubscription = setInterval(() => {
      this.currentTime = timezones()
      if (this.timezone) {
        this.displayTime = this.currentTime.tz(this.timezone).locale(this.$i18n.locale.slice(0, 2))
      }
    }, 60000)

    this.unsubscribeTimezoneSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === 'project/SET_CURRENT_TIMEZONE' && mutation.payload !== null) {
        this.displayTime = this.currentTime.tz(mutation.payload).locale(this.$i18n.locale.slice(0, 2))
      }
    })
  },
  destroyed () {
    clearInterval(this.timeSubscription)
    if (this.unsubscribeTimezoneSubscription !== null) {
      this.unsubscribeTimezoneSubscription()
    }
  },
  computed: {
    loadingTimezone (): boolean {
      return this.$store.getters['project/isProjectsTimezoneLoading']
    },
    timezone (): string|null {
      return this.$store.getters['project/getProjectsTimezone']
    },
    getDate (): string|null {
      return this.displayTime ? this.displayTime.format('dddd, DD.MM.') : null
    },
    getTime (): string|null {
      return this.displayTime ? this.displayTime.format('HH:mm') + ' ' + this.$t('utils.time') : null
    },
    loadingOccupants (): boolean {
      return this.$store.getters['project/isOccupantsLoading']
    },
    loadingCarOccupants (): boolean {
      return this.$store.getters['project/isCarOccupantsLoading']
    },
    occupantsCount (): number|null {
      return this.$store.getters['project/getOccupants']
    },
    carOccupantsCount (): number|null {
      return this.$store.getters['project/getCarOccupants']
    },
    occupantsLimit (): boolean {
      return this.$store.getters['project/getOccupantsLimit']
    },
    carOccupantsLimit (): boolean {
      return this.$store.getters['project/getCarOccupantsLimit']
    },
    loadingWeather (): boolean {
      return this.$store.getters['project/isProjectsWeatherLoading']
    },
    weather (): boolean {
      return this.$store.getters['project/getProjectsWeather']
    },
    localizedWeather (): null|string {
      return this.$store.getters['project/getProjectsWeatherLocale']
    }
  }
})
