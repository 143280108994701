/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Returns a deeply nested node. Lookup is done recursively with a simple backtracking algorithm.
 * @param node Recursively look up the searched leaf node in this node.
 * @param leafNodeId The ID of the leaf node that is being looked up.
 * @returns Null if no leaf node was found for the leaf node ID, else returns the found leaf node.
 */
export function lookupLeafNode (node: any, leafNodeId: string): any|null {
  if (node.id === leafNodeId) {
    return node
  } else {
    if (node.children && node.children.length > 0) {
      for (const nextNode of node.children) {
        const foundNode: any|null = lookupLeafNode(nextNode, leafNodeId)
        if (foundNode !== null) return foundNode
      }
      return null
    } else {
      return null
    }
  }
}

/**
 * Recursively gather all descendants of node that are leaf node, ie. that don't have children
 * @param node The current node in the recursion
 * @returns All leaf descendants of node
 */
export function gatherAllLeafNodes (node: any): any[] {
  const result: any[] = []
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      result.push(...gatherAllLeafNodes(child))
    }
  } else {
    result.push(node)
  }
  return result
}
