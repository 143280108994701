





























































































import Vue, { PropType } from 'vue'
import { Route } from 'vue-router'
import get from 'lodash.get'

export type TopbarPropsType = {
  user: {
    fullName: string;
    avatar: string|null;
    companyName: string;
  };
  loading: boolean;
}

type TBreadcrumbItem = {
  disabled: boolean;
  exact: boolean;
  href?: string;
  link: boolean;
  text: string | number;
  to: string | object;
}

export default Vue.extend({
  props: {
    user: {
      required: false,
      type: Object as PropType<TopbarPropsType['user']>
    },
    loading: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>
    }
  },
  computed: {
    breadcrumbs (): Array<TBreadcrumbItem> {
      const result: Array<TBreadcrumbItem> = []
      if ((this.$route as Route).path !== '/' && (this.$route as Route).name !== 'not-found') {
        const projectId = get(this.$route, 'params.id', null)
        const projectName = projectId ? this.projectName(parseInt(get(this.$route, 'params.id', null))) : ''
        const pathSegments = this.$route.path.slice(1).split('/')
        for (const [index, segment] of pathSegments.entries()) {
          let pagelinkTranslation: string|null = null
          if (index === 1) {
            pagelinkTranslation = projectName
          } else if (index > 1 && pathSegments[index - 1] === 'alarms') {
            pagelinkTranslation = this.alarmName(get(this.$route, 'params.alarm', null))
          } else {
            pagelinkTranslation = String(this.$t(`navigation.links.${segment}.title`))
          }
          result.push({
            disabled: index === (pathSegments.length - 1),
            exact: true,
            link: true,
            text: pagelinkTranslation,
            to: `/${pathSegments.slice(0, index + 1).join('/')}`
          })
        }
      } else if ((this.$route as Route).name === 'not-found') {
        result.push({
          disabled: true,
          exact: true,
          link: true,
          text: String(this.$t('navigation.links.404.title')),
          to: '/404'
        })
      } else {
        result.push({
          disabled: true,
          exact: true,
          link: true,
          text: String(this.$t('navigation.links.portfolio.title')),
          to: '/'
        })
      }
      return result
    }
  },

  data () {
    return {
      englishVersion: false as boolean
    }
  },

  methods: {
    alarmName (id: string): string {
      return this.$store.getters['alerts/getAlertName'](id)
    },

    projectName (id: number): string {
      return this.$store.getters['projects/getProjectsName'](id)
    },

    toggleEnglish (englishVersion: boolean) {
      if (englishVersion) {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'de'
      }
      this.$store.dispatch('labels/fetchLabelSystems')
    }
  }
})
