import { MutationTree } from 'vuex'
import { AppState } from './types'
import { KPIAggregationResult } from '@aedifion.io/aedifion-api'

export default {
  SET_LOADING_PORTFOLIO_AGGREGATION_KPIS: (state: AppState, loading: boolean): void => {
    state.loadingPortfolioAggregationKpis = loading
  },

  SET_CO2_EMISSIONS: (state: AppState, co2Emissions: KPIAggregationResult|null): void => {
    state.co2Emissions = co2Emissions
  },

  SET_ENERGY_CONSUMPTION: (state: AppState, energyConsumption: KPIAggregationResult|null): void => {
    state.energyConsumption = energyConsumption
  }
} as MutationTree<AppState>
