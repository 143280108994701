





























































































































































































































































































































































































import Vue from 'vue'
import PieChart from '@/components/ui/Charts/PieChart.vue'
import BulletGraph from '@/components/ui/Charts/BulletGraph.vue'
import { computeRelativeValue } from '@/utils/helpers/timeseries'
import moment from 'moment'
import { TBenchmarkZones } from '@/utils/helpers/types'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'CO2Emissions',
  components: {
    PieChart,
    BulletGraph
  },
  mounted () {
    this.$store.dispatch('co2_emission/fetchData')
  },
  methods: {
    switchToMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('co2_emission/switchChartToMonth')
      }
    },
    switchToYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('co2_emission/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('co2_emission/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('co2_emission/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('co2_emission/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('co2_emission/selectNextMonth')
      }
    }
  },
  computed: {
    loadingProjectsDigitalTwin (): boolean {
      return this.$store.getters['project/areComponentsInProjectLoading']
    },
    loadingData (): boolean {
      return this.$store.getters['co2_emission/isLoadingData']
    },
    emissionProjectionValue (): number|null {
      return this.$store.state.co2_emission.benchmark.co2Projection
    },
    heat (): number|null {
      return this.$store.getters['co2_emission/getHeatEmissions']
    },
    heatTonnes (): number|null {
      return this.$store.getters['co2_emission/getHeatEmissions']
    },
    heatRelative (): string|null {
      return computeRelativeValue(this.heat, [
        this.electricity
      ], true, 0)
    },
    electricity (): number|null {
      return this.$store.getters['co2_emission/getElectricityEmissions']
    },
    electricityTonnes (): number|null {
      return this.$store.getters['co2_emission/getElectricityEmissions']
    },
    electricityRelative (): string|null {
      return computeRelativeValue(this.electricity, [
        this.heat
      ], true, 0)
    },
    hasPhotovoltaicsSystem (): boolean {
      return this.$store.getters['co2_emission/hasPhotovoltaicsSystem']
    },
    photovoltaics (): number|null {
      return this.$store.getters['co2_emission/getPhotovoltaicsAvoidance']
    },
    photovoltaicsTonnes (): number|null {
      return this.$store.getters['co2_emission/getPhotovoltaicsAvoidance']
    },
    hasSolarSystem (): boolean {
      return this.$store.getters['co2_emission/hasSolarSystem']
    },
    solar (): number|null {
      return this.$store.getters['co2_emission/getSolarAvoidance']
    },
    solarTonnes (): number|null {
      return this.$store.getters['co2_emission/getSolarAvoidance']
    },
    hasHeatPumpSystem (): boolean {
      return this.$store.getters['co2_emission/hasHeatPumpSystem']
    },
    heatPump (): number|null {
      return this.$store.getters['co2_emission/getHeatPumpAvoidance']
    },
    heatPumpTonnes (): number|null {
      return this.$store.getters['co2_emission/getHeatPumpAvoidance']
    },
    hasCHPSystem (): boolean {
      return this.$store.getters['co2_emission/hasCHPSystem']
    },
    chp (): number|null {
      return this.$store.getters['co2_emission/getCHPAvoidance']
    },
    chpTonnes (): number|null {
      return this.$store.getters['co2_emission/getCHPAvoidance']
    },
    emissionBenchmark (): number|null {
      return this.$store.getters['co2_emission/getCo2EmissionsBenchmark']
    },
    emissionBenchmarkColor (): string|null {
      return this.$store.getters['co2_emission/getCo2EmissionsColor']
    },
    year (): number {
      return this.$store.getters['co2_emission/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['co2_emission/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['co2_emission/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['co2_emission/hasPrevious']
    },
    month (): number {
      return this.$store.getters['co2_emission/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['co2_emission/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
    },
    timeseries (): any[] {
      const timeseriesData: any = {
        name: this.$t('co2_emissions.series.title'),
        data: []
      }

      if (this.heat !== null && this.heatRelative !== null) {
        timeseriesData.data.push({
          name: `${this.$t('utils.heat')}: ${this.heatRelative}%`,
          y: parseInt(this.heatRelative),
          unit: 't',
          absValue: formatValue(this.heat, 2)
        })
      }

      if (this.electricity !== null && this.electricityRelative !== null) {
        timeseriesData.data.push({
          name: `${this.$t('utils.electricity')}: ${this.electricityRelative}%`,
          y: parseInt(this.electricityRelative),
          unit: 't',
          absValue: formatValue(this.electricity, 2)
        })
      }

      return [timeseriesData]
    },
    emissionBenchmarkZones (): TBenchmarkZones|null {
      return this.$store.getters['co2_emission/getCo2EmissionsBenchmarkZones']
    },
    emissionTargetValue (): number|null {
      return this.$store.getters['co2_emission/getTargetCo2EmissionsValue']
    },
    benchmark (): any {
      return this.emissionTargetValue !== null && this.emissionBenchmarkZones !== null ? {
        title: this.$t('co2_emissions.benchmark.title', { year: moment().year() }),
        subtitle: `<span class="hc-cat-title">${this.$t('co2_emissions.benchmark.subtitle', { year: moment().year() })}</span><br><b>kg/(m<sup>2</sup>a)</b>`,
        setValue: this.emissionTargetValue,
        projectionValue: this.emissionProjectionValue ? parseFloat(this.emissionProjectionValue.toFixed(0)) : null,
        isValue: this.emissionBenchmark !== null ? parseFloat(this.emissionBenchmark.toFixed(0)) : null,
        isColor: this.emissionBenchmarkColor,
        unit: 'kg/(m2a)',
        seriesName: this.$t('co2_emissions.benchmark.emissions_per_squared_meter_yearly'),
        zones: this.emissionBenchmarkZones
      } : null
    }
  }
})
