






























































































































































































































































import { TComponentInProjectWithContextResponse, TProject, TProjectWithContext } from '@/services/aedifion/resources/project/responseTypes'
import Vue from 'vue'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import mapboxgl, { EventData, Marker } from 'mapbox-gl'

mapboxgl.accessToken = window.secrets.MAPBOX_TOKEN

export default Vue.extend({
  name: 'Settings',
  data () {
    return {
      isModalOpen: false,
      dummyImage: require('../../assets/mock/dummy-image.jpg'),
      validationRules: {
        required: (value: string|number) => !!value || 'Required'
      },
      map: null as any,
      mapMarker: null as Marker|null,
      mapboxLoaded: false,
      newProjectCoords:
        get(this.$store.getters['project/getProject'], 'project.latitude', false) &&
        get(this.$store.getters['project/getProject'], 'project.longitude', false)
          ? {
            lat: parseFloat(get(this.$store.getters['project/getProject'], 'project.latitude', 0)),
            lng: parseFloat(get(this.$store.getters['project/getProject'], 'project.longitude', 0))
          }
          : null as null | { lat: number; lng: number },
      showsMap: false,
      project: {
        name: get(this.$store.getters['project/getProject'], 'project.name', null) as string|null,
        description: get(this.$store.getters['project/getProject'], 'project.description', null) as string|null
      },
      occupants: this.$store.getters['project/getOccupantsLimit'] as string|number|null,
      grossFloorArea: this.$store.getters['project/getProjectsGrossFloorArea'] as string|number|null,
      cars: this.$store.getters['project/getCarOccupantsLimit'] as string|number|null,
      unsubscribeSetProjectMutation: null as any
    }
  },
  mounted () {
    this.renderMap()
    this.map.on('idle', () => {
      this.map.resize()
      this.mapboxLoaded = true
    })
    this.unsubscribeSetProjectMutation = this.$store.subscribe((mutation) => {
      if (mutation.type === 'project/SELECT_PROJECT') {
        if (this.mapMarker !== null) this.mapMarker.remove()
        const project: TProjectWithContext = mutation.payload
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newMarkerPosition: null|{ lat: number; lng: number } = project.project!.latitude && project.project!.longitude
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ? { lat: parseFloat(project.project!.latitude), lng: parseFloat(project.project!.longitude) }
          : null
        this.addNewMarker(newMarkerPosition)
        this.project.name = get(project, 'project.name', null)
        this.project.description = get(project, 'project.description', null)
      }

      if (mutation.type === 'project/SELECT_DIGITAL_TWIN') {
        this.occupants = this.currentProjectsOccupantsLimit
        this.grossFloorArea = this.currentProjectsGrossFloorArea
        this.cars = this.currentProjectsCarsLimit
      }
    })
  },
  destroyed () {
    if (this.unsubscribeSetProjectMutation !== null) this.unsubscribeSetProjectMutation()
  },
  methods: {
    closeModal (): void {
      this.isModalOpen = false
    },
    deleteAvatar (): void {
      if (this.currentProjectsAvatar !== null) {
        this.$store.dispatch('project/deleteAvatarImage')
      }
    },
    chooseNewAvatarImage (): void {
      (this.$refs.fileInput as HTMLElement).click()
    },
    uploadAvatar (fileEvent: EventData): void {
      const file: File = fileEvent.target.files[0]
      if (file) {
        const projectAvatarFormData: FormData = new FormData()
        projectAvatarFormData.append('avatar', file)
        this.$store.dispatch('project/uploadAvatarImage', projectAvatarFormData)
      }
    },
    loadingAvatarImage (): boolean {
      return this.$store.getters['project/isLoadingProjectAvatar']
    },
    openModal (): void {
      this.isModalOpen = true
    },
    addNewMarker (coords: { lat: number; lng: number }|null = null): void {
      const marker = new mapboxgl.Marker({
        color: '#288946',
        draggable: true
      })
        .setLngLat(coords !== null ? [coords.lng, coords.lat] : [
          6.940514,
          50.941064
        ])
        .addTo(this.map)

      this.newProjectCoords = coords !== null ? coords : {
        lat: 50.941064,
        lng: 6.940514
      }

      const markerDragEnd = () => {
        const { lng, lat } = marker.getLngLat()
        this.newProjectCoords = {
          lat,
          lng
        }
      }

      marker.on('dragend', markerDragEnd)

      this.mapMarker = marker
    },
    resetDetails (): void {
      // reset project details (name & description)
      this.project.name = this.currentProjectsName
      this.project.description = this.currentProjectsDescription
      if (this.currentProjectsPosition && this.mapMarker !== null) {
        this.mapMarker.remove()
        this.addNewMarker(this.currentProjectsPosition)
      } else {
        if (this.mapMarker !== null) this.mapMarker.remove()
      }
      this.occupants = this.currentProjectsOccupantsLimit
      this.grossFloorArea = this.currentProjectsGrossFloorArea
      this.cars = this.currentProjectsCarsLimit
    },
    renderMap (): void {
      this.map = new mapboxgl.Map({
        container: 'mapbox-project-settings-modal',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [10.404954, 51.420008],
        zoom: 5
      })
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      )
      this.map.addControl(new mapboxgl.FullscreenControl())

      // Add marker if lat long is available
      if (this.currentProject && this.currentProject.project && this.currentProject.project.latitude && this.currentProject.project.longitude) {
        const marker: Marker = new mapboxgl.Marker({
          color: '#288946',
          draggable: true
        })
          .setLngLat([
            parseFloat(this.currentProject.project.longitude),
            parseFloat(this.currentProject.project.longitude)
          ])
          .addTo(this.map)

        const markerDragEnd = () => {
          const { lng, lat } = marker.getLngLat()
          this.newProjectCoords = {
            lat,
            lng
          }
        }

        marker.on('dragend', markerDragEnd)
        this.mapMarker = marker
      }
    },
    switchMapAndAvatar (): void {
      this.showsMap = !this.showsMap
    },
    switchProject (nextProjectID: number): void {
      if (this.currentProjectID !== nextProjectID) {
        this.$router.push({ name: 'project', params: { id: nextProjectID.toString() } })
      }
    },
    async updateProjectDetails () {
      if (this.projectDetailsTouched) {
        const updatedSuccessfully: boolean|{
          name: string;
          description: string;
          latitude: string;
          longitude: string;
        } = await this.$store.dispatch('project/updateDetails', {
          name: this.project.name !== null ? this.project.name : undefined,
          description: this.project.description !== null ? this.project.description : undefined,
          latitude: this.newProjectCoords !== null ? this.newProjectCoords.lat.toString() : undefined,
          longitude: this.newProjectCoords !== null ? this.newProjectCoords.lng.toString() : undefined
        })

        if (updatedSuccessfully !== false && updatedSuccessfully !== true) {
          this.newProjectCoords = null
          const newCoords: { lat: number; lng: number } = {
            lat: parseFloat(updatedSuccessfully.latitude),
            lng: parseFloat(updatedSuccessfully.longitude)
          }
          // delete old marker
          if (this.mapMarker !== null) this.mapMarker.remove()

          // add new marker
          this.addNewMarker(newCoords)
        }
      }

      if (this.occupantsTouched) {
        this.$store.dispatch('project/updateAttribute', {
          value: this.occupants !== '' ? this.occupants : null,
          alphanumeric_id: 'B_COUNT_PEO_MAX'
        })
      }

      if (this.grossFloorAreaTouched) {
        await this.$store.dispatch('project/updateAttribute', {
          value: this.grossFloorArea !== '' ? this.grossFloorArea : null,
          alphanumeric_id: 'B_GFA_AV'
        })
        this.$store.dispatch('components/computeDigitalTwinKPIs', this.currentProjectsDigitalTwin)
      }

      if (this.carsLimitTouched) {
        this.$store.dispatch('project/updateAttribute', {
          value: this.cars !== '' ? this.cars : null,
          alphanumeric_id: 'B_COUNT_CAR_MAX'
        })
      }
    }
  },
  computed: {
    currentProjectID (): number {
      return this.$store.getters['project/getProjectID']
    },
    currentProject (): TProjectWithContext {
      return this.$store.getters['project/getProject']
    },
    currentProjectsName (): string|null {
      return get(this.currentProject, 'project.name', null)
    },
    currentProjectsDescription (): string|null {
      return get(this.currentProject, 'project.description', null)
    },
    currentProjectsAvatar (): string|null {
      if (this.currentProject && this.currentProject.project?.avatar_url) {
        return this.currentProject.project.avatar_url
      } else {
        return null
      }
    },
    currentProjectsDigitalTwin (): TComponentInProjectWithContextResponse|null {
      return this.$store.getters['project/getDigitalTwin']
    },
    currentProjectsOccupantsLimit (): number|null {
      return this.$store.getters['project/getOccupantsLimit']
    },
    currentProjectsGrossFloorArea (): number|null {
      return this.$store.getters['project/getProjectsGrossFloorArea']
    },
    currentProjectsTotalBenchmarkColor (): string {
      return this.$store.getters['project/getTotalBenchmarkColor']
    },
    currentProjectsPosition (): { lat: number; lng: number }|null {
      if (this.currentProject && this.currentProject.project && this.currentProject.project.latitude && this.currentProject.project.longitude) {
        return {
          lat: parseFloat(this.currentProject.project.latitude),
          lng: parseFloat(this.currentProject.project.longitude)
        }
      } else {
        return null
      }
    },
    currentProjectsCarsLimit (): number|null {
      return this.$store.getters['project/getCarOccupantsLimit']
    },
    changedProjectPosition (): boolean {
      return !isEqual(this.newProjectCoords, this.currentProjectsPosition)
    },
    isLoadingAvatar (): boolean {
      return this.$store.getters['project/isLoadingProjectAvatar']
    },
    isLoadingDetails (): boolean {
      return this.$store.getters['project/isLoadingProjectDetails']
    },
    isLoadingAttribute (): boolean {
      return this.$store.getters['project/isLoadingAttribute']
    },
    hasAlarmIssues (): boolean {
      const projectHandle: string|null = get(this.currentProject, 'project.handle', false)
      if (projectHandle !== null) {
        return this.$store.getters['alerts/hasProjectAlarmIndication'](projectHandle)
      } else {
        return false
      }
    },
    hasEnergyIssues (): boolean {
      return this.$store.getters['project/hasEnergyBenchmarkIssues']
    },
    availableProjects (): [{
      text: string;
      value: number;
    }] {
      return this.$store.getters['projects/getProjects']
        .map((project: TProject) => {
          return {
            text: project.name,
            value: project.id
          }
        })
    },
    projectDetailsTouched (): boolean {
      if (
        this.project.name === '' && this.currentProjectsName === null &&
        this.project.description === '' && this.currentProjectsDescription === null &&
        isEqual(this.newProjectCoords, this.currentProjectsPosition)
      ) return false

      return this.project.name !== this.currentProjectsName ||
        this.project.description !== this.currentProjectsDescription ||
        !isEqual(this.newProjectCoords, this.currentProjectsPosition)
    },
    occupantsTouched (): boolean {
      if (this.occupants === '' && this.currentProjectsOccupantsLimit === null) return false
      if (this.occupants !== null) {
        if (typeof this.occupants === 'string') {
          return parseFloat(this.occupants) !== this.currentProjectsOccupantsLimit
        } else {
          return this.occupants !== this.currentProjectsOccupantsLimit
        }
      } else {
        return this.currentProjectsOccupantsLimit !== null
      }
    },
    grossFloorAreaTouched (): boolean {
      if (this.grossFloorArea === '' && this.currentProjectsGrossFloorArea === null) return false
      if (this.grossFloorArea !== null) {
        if (typeof this.grossFloorArea === 'string') {
          return parseFloat(this.grossFloorArea) !== this.currentProjectsGrossFloorArea
        } else {
          return this.grossFloorArea !== this.currentProjectsGrossFloorArea
        }
      } else {
        return this.currentProjectsGrossFloorArea !== null
      }
    },
    carsLimitTouched (): boolean {
      if (this.cars === '' && this.currentProjectsCarsLimit === null) return false
      if (this.cars !== null) {
        if (typeof this.cars === 'string') {
          return parseFloat(this.cars) !== this.currentProjectsCarsLimit
        } else {
          return this.cars !== this.currentProjectsCarsLimit
        }
      } else {
        return this.currentProjectsCarsLimit !== null
      }
    }
  }
})
