

























import Vue, { PropType } from 'vue'
import moment from 'moment'
import get from 'lodash.get'
import { AxiosResponse } from 'axios'

import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'

import Card from '@/components/ui/Card.vue'
import LineChart from '@/components/ui/Charts/LineChart.vue'
import { TAlertTableRow } from '@/store/alerts/types'
import { AlertsHelper } from '@/utils/helpers/alerts'
import { formatValue } from '@/filters/formatting'

const Datapoint: IRepository = aedifionApiRepository.get('datapoints')

type AlarmLine = {
  color: string;
  value: number;
  width: number;
}

type ThresholdLine = {
  color: string;
  dashStyle: string;
  label: {
    align: string;
    text: string;
  };
  value: number;
  width: number;
}

export default Vue.extend({
  name: 'AlarmDetailsProgression',

  components: {
    Card,
    LineChart
  },

  computed: {
    chartData (): Array<any> {
      const data = []
      for (const value of this.timeseriesData) {
        data.push({
          x: moment(value[0]).valueOf(),
          y: value[1] ? parseFloat(value[1].toFixed(2)) : null,
          marker: {
            enabled: false
          }
        })
      }
      return [{
        data: data,
        step: 'left',
        type: 'line'
      }]
    },

    // Currently unused. To reenable, add ':xPlotLines="chartAlarmLines"' to the LineChart
    chartAlarmLines (): Array<AlarmLine> {
      const result = []
      for (const historyValue of this.alert.originalData.history) {
        if (historyValue.type === 'new' || historyValue.type === 'value') {
          result.push(this.createAlarmLine(moment(historyValue.updateTime).valueOf()))
        }
      }
      return result
    },

    chartThresholdLines (): Array<ThresholdLine>|null {
      let result = null
      if (this.showThresholds) {
        const alertType = get(this.alert.originalData, 'type', null)
        if (alertType === 'threshold') {
          const crit = get(this.alert.originalData, ['attributes', 'threshold_crit'], null)
          const dead = get(this.alert.originalData, ['attributes', 'threshold_dead'], null)
          const ok = get(this.alert.originalData, ['attributes', 'threshold_ok'], null)
          if (crit || dead || ok) {
            result = []
            if (crit) {
              result.push(this.createThresholdLine(crit, 'red', String(this.$t('alarms.details.progression.threshold.maximum'))))
            }
            if (ok) {
              result.push(this.createThresholdLine(ok, 'green', String(this.$t('alarms.details.progression.threshold.minimum'))))
            }
          }
        } else if (alertType === 'throughput') {
          const crit = get(this.alert.originalData, ['attributes', 'threshold_crit'], null)
          const info = get(this.alert.originalData, ['attributes', 'threshold_info'], null)
          const warn = get(this.alert.originalData, ['attributes', 'threshold_warn'], null)
          if (crit || info || warn) {
            result = []
            if (crit) {
              result.push(this.createThresholdLine(crit, 'red', String(this.$t('alarms.details.progression.threshold.crit'))))
            }
            if (info) {
              result.push(this.createThresholdLine(info, 'green', String(this.$t('alarms.details.progression.threshold.info'))))
            }
            if (warn) {
              result.push(this.createThresholdLine(warn, 'yellow', String(this.$t('alarms.details.progression.threshold.warn'))))
            }
          }
        }
      }
      return result
    },

    chartValueRange (): [number, number]|null {
      if (!this.showThresholds || this.timeseriesData.length === 0) {
        return null
      }

      let min = null
      let max = null
      for (const value of this.timeseriesData) {
        if (!min || value[1] < min) {
          min = value[1]
        }
        if (!max || value[1] > max) {
          max = value[1]
        }
      }
      const alertType = get(this.alert.originalData, 'type', null)
      if (alertType === 'threshold') {
        const crit = get(this.alert.originalData, ['attributes', 'threshold_crit'], null)
        const dead = get(this.alert.originalData, ['attributes', 'threshold_dead'], null)
        const ok = get(this.alert.originalData, ['attributes', 'threshold_ok'], null)
        min = Math.min(min!, crit, dead, ok)
        max = Math.max(max!, crit, dead, ok)
      } else if (alertType === 'throughput') {
        const crit = get(this.alert.originalData, ['attributes', 'threshold_crit'], null)
        const info = get(this.alert.originalData, ['attributes', 'threshold_info'], null)
        const warn = get(this.alert.originalData, ['attributes', 'threshold_warn'], null)
        min = Math.min(min!, crit, info, warn)
        max = Math.max(max!, crit, info, warn)
      }
      return [min!, max!]
    }
  },

  data () {
    return {
      chartDataLoading: false,
      showThresholds: false,
      timeseriesData: [] as Array<[string, number]>
    }
  },

  methods: {
    createAlarmLine (value: number): AlarmLine {
      return {
        color: 'red',
        value: value,
        width: 3
      }
    },

    createThresholdLine (value: number, color: string, labelText: string): ThresholdLine {
      return {
        color: color,
        dashStyle: 'dash',
        label: {
          align: 'right',
          text: labelText
        },
        value: value,
        width: 2
      }
    }
  },

  async mounted () {
    const datapointId = AlertsHelper.getTagValue(this.alert.originalData, 'datapoint')
    if (datapointId === null) {
      return
    }
    this.chartDataLoading = true
    try {
      const token: string = this.$store.getters['auth/oidcAccessToken']
      const projectId: string = this.$store.getters['project/getProjectID']
      const timeseriesResponse: AxiosResponse<[string, number][]> = await Datapoint.getTimeseries({
        token,
        params: {
          project_id: projectId,
          dataPointID: datapointId,
          start: moment.utc().subtract(24, 'hours').format(),
          end: moment.utc().format(),
          samplerate: 'auto',
          short: true,
          closed_intervals: true
        }
      })
      this.timeseriesData = timeseriesResponse.data
    } catch (error) {
      const errorMessage: string = get(error.response, 'data.message', this.$t('alarms.details.progression.request.unknownError'))
      Vue.notify({
        text: this.$t('alarms.details.progression.request.error', { reason: errorMessage }) as string,
        group: 'requests',
        duration: 6000,
        type: 'error'
      })
      return error
    } finally {
      this.chartDataLoading = false
    }
  },

  props: {
    alert: {
      required: true,
      type: Object as PropType<TAlertTableRow>
    }
  }
})
