var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[
    { 'red': !!_vm.error },
    { 'warning': !!_vm.warning },
    { 'grey': !!_vm.info },
    { 'green': !_vm.info && !_vm.warning && !_vm.error },
    'ma-4',
    'd-flex',
    'flex-column',
    'rom__event__card__wrapper',
    'elevation-4'
  ],staticStyle:{"background-color":"white !important"}},[_c('v-card-title',{class:[
      { 'red': !!_vm.error },
      { 'warning': !!_vm.warning },
      { 'white--text': !!_vm.error || !!_vm.warning },
      { 'grey--text': !!_vm.info || (!_vm.warning && !_vm.error) },
      'text-center',
      'text-subtitle-2',
      'justify-center',
      'rom__event__card__title'
    ]},[_vm._v(_vm._s(_vm.title))]),(!_vm.warning && !_vm.error)?_c('v-divider'):_vm._e(),_c('div',{class:[
      'd-flex',
      'flex-column',
      'justify-space-around',
      'align-center',
      'px-4'
    ],staticStyle:{"height":"100%"}},[_c('div',{class:[
        'd-flex',
        'flex-column',
        'justify-center',
        'align-center',
        'rom__event__card__content'
      ]},[(_vm.error)?_c('v-badge',{attrs:{"bordered":"","bottom":"","overlap":"","color":"red","icon":"mdi-lock","content":!!_vm.alarmCount ? _vm.alarmCount : null}},[_c('v-icon',{attrs:{"x-large":"","color":_vm.getColor}},[_vm._v(_vm._s(_vm.icon))])],1):_c('v-icon',{attrs:{"x-large":"","color":_vm.getColor}},[_vm._v(_vm._s(_vm.icon))]),_c('span',{staticClass:"body-1 mt-4 grey--text"},[_vm._v(_vm._s(_vm.error ? _vm.$t('events.active') : _vm.warning ? _vm.$t('events.active_at') : _vm.info ? _vm.$t('events.not_available') : _vm.$t('events.no_alerts')))])],1),_c('div',{staticClass:"rom__event__card__actions"},[(_vm.error)?_c('v-btn',{attrs:{"block":"","large":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.$emit('open-alert', _vm.alarm.id)}}},[_vm._v(" "+_vm._s(_vm.$t('project.events.open_alarm'))+" ")]):_vm._e(),(!_vm.error && _vm.warning)?_c('div',{staticClass:"d-flex justify-center align-center flex-column grey--text"},[_c('div',{staticClass:"d-flex justify-left align-center flex-row"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-calendar-day")]),_c('span',[_vm._v(_vm._s(_vm.getLatestAlarmDate))])],1),_c('div',{staticClass:"d-flex justify-left align-center flex-row mb-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-clock")]),_c('span',[_vm._v(_vm._s(_vm.getLatestAlarmTime))])],1)]):_vm._e(),(_vm.info)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"icon":"","rounded":"","color":"grey"},on:{"click":function($event){return _vm.$emit('open-info')}}},[_c('v-icon',[_vm._v("fas fa-question-circle")])],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }