import { TBenchmarkAttributeZones, TBenchmarks, TBenchmarkZones } from './types'

export const computeBenchmarkCriticality = (benchmark_zones: TBenchmarkZones|undefined, value: number|null): string => {
  if (value === null || !benchmark_zones) return 'grey'

  if (benchmark_zones.green[0] < benchmark_zones.red[0]) {
    if (value <= benchmark_zones.green[1]) {
      return 'green'
    } else if (value > benchmark_zones.green[1] && value < benchmark_zones.red[0]) {
      return 'warning'
    } else {
      return 'red'
    }
  } else if (benchmark_zones.green[0] > benchmark_zones.red[0]) {
    if (value <= benchmark_zones.red[1]) {
      return 'red'
    } else if (value > benchmark_zones.red[1] && value < benchmark_zones.green[0]) {
      return 'warning'
    } else {
      return 'green'
    }
  } else {
    return 'green'
  }
}

export const calculateAllBenchmarks = (
  electricityConsumption: number|null,
  heatConsumption: number|null,
  energyGeneration: number|null,
  co2Emissions: number|null,
  waterConsumption: number|null,
  zones: TBenchmarkAttributeZones|null
): TBenchmarks => {
  if (!zones) {
    return {
      electricity_consumption: 'grey',
      heat_consumption: 'grey',
      energy_generation: 'grey',
      co2_emissions: 'grey',
      water_consumption: 'grey'
    }
  }

  return {
    electricity_consumption: computeBenchmarkCriticality(zones.benchmark_electricity_consumption, electricityConsumption),
    heat_consumption: computeBenchmarkCriticality(zones.benchmark_heat_consumption, heatConsumption),
    energy_generation: computeBenchmarkCriticality(zones.benchmark_energy_generation, energyGeneration),
    co2_emissions: computeBenchmarkCriticality(zones.benchmark_co2_emissions, co2Emissions),
    water_consumption: computeBenchmarkCriticality(zones.benchmark_water_consumption, waterConsumption)
  }
}
