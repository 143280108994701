































































































































































































































































































































import Vue from 'vue'
import Card from '@/components/ui/Card.vue'
import AvatarCard from '@/components/ui/AvatarCard.vue'
import { TUser } from '@/services/aedifion/resources/user/responseTypes'
import { TCompany } from '@/services/aedifion/resources/company/responseTypes'
import { isEqual, get } from 'lodash'
import moment from 'moment'

export default Vue.extend({
  name: 'Account',
  components: {
    Card,
    AvatarCard
  },
  computed: {
    userData (): TUser {
      return this.$store.getters['user/getUser']
    },
    userLoading (): boolean {
      return this.$store.getters['user/isUserLoading']
    },
    companyData (): TCompany {
      return this.$store.getters['company/getCompany']
    },
    companyLoading (): boolean {
      return this.$store.getters['company/isCompanyLoading']
    },
    passwordLocked (): boolean {
      const pwLock: string|null = this.$store.getters['user/passwordLock']
      if (pwLock === null || isEqual('None', pwLock)) return false
      return moment().isBefore(moment(pwLock))
    },
    passwordLockedUntil (): string {
      return this.passwordLocked ? moment(this.$store.getters['user/passwordLock']).local().format('DD.MM.YYYY, HH:mm') : ''
    }
  },
  methods: {
    touchedUserData () {
      const { address, firstName, lastName, phone } = this.userData
      this.user.pristine = isEqual(
        {
          address,
          firstName,
          lastName,
          phone
        },
        {
          address: this.user.address,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          phone: this.user.phone
        }
      )
    },
    touchedCompanyData () {
      const { address } = this.companyData
      this.company.pristine = isEqual(
        {
          address
        },
        {
          address: this.company.address
        }
      )
    },
    async updateUser () {
      const { address, firstName, lastName, phone } = this.user
      const data: any = {
        address,
        firstName,
        lastName,
        phone
      }

      try {
        await this.$store.dispatch('user/update', data)
        this.user = {
          ...this.user,
          address: this.user.address,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          phone: this.user.phone
        }
        // Reset the pristine flag
        this.user.pristine = true
      } catch (error) {
        console.error(error)
      }
    },
    async updateCompany () {
      const { address } = this.company
      try {
        await this.$store.dispatch('company/update', { address })
        // Reset the pristine flag
        this.touchedCompanyData()
      } catch (error) {
        console.error(error)
      }
    },
    async uploadUserAvatar (file: File) {
      const userAvatarFormData: FormData = new FormData()
      userAvatarFormData.append('avatar', file)
      try {
        await this.$store.dispatch('user/uploadAvatar', userAvatarFormData)
        this.$notify({
          text: this.$t('account.userAvatar.success') as string,
          type: 'success',
          group: 'requests',
          duration: 6000
        })
      } catch (error) {
        const reason = error.response.data.error
        this.$notify({
          text: this.$t('account.companyAvatar.error', { reason }) as string,
          type: 'error',
          group: 'requests',
          duration: 6000
        })
      } finally {
        (this.$refs.userAvatar as Vue).$data.image = null
      }
    },
    async uploadCompanyAvatar (file: File) {
      const companyAvatarFormData: FormData = new FormData()
      companyAvatarFormData.append('avatar', file)
      try {
        await this.$store.dispatch('company/uploadAvatar', companyAvatarFormData)
        this.$notify({
          text: this.$t('account.companyAvatar.success') as string,
          type: 'success',
          group: 'requests',
          duration: 6000
        })
      } catch (error) {
        const reason = error.response.data.error
        this.$notify({
          text: this.$t('account.companyAvatar.error', { reason }) as string,
          type: 'error',
          group: 'requests',
          duration: 6000
        })
      } finally {
        (this.$refs.companyAvatar as Vue).$data.image = null
      }
    },
    async updatePassword () {
      try {
        await this.$store.dispatch('user/updatePassword', {
          current_password: this.user.currentPassword,
          new_password: this.user.newPassword
        })
        this.user.currentPassword = ''
        this.user.newPassword = ''
        this.user.newPasswordConfirm = ''
        this.passwordError = ''
        this.passwordErrorStatus = 0
      } catch (error) {
        this.passwordError = error.response.data.error
        this.passwordErrorStatus = error.response.status
      }
    }
  },
  mounted () {
    this.unsubscribeStore = this.$store.subscribe((mutation) => {
      if (mutation.type === 'company/SET_COMPANY') {
        this.company.name = mutation.payload.name
        this.company.address = mutation.payload.address
      }

      if (mutation.type === 'user/SET_USER') {
        this.user.address = mutation.payload.address
        this.user.firstName = mutation.payload.firstName
        this.user.lastName = mutation.payload.lastName
        this.user.email = mutation.payload.email
        this.user.phone = mutation.payload.phone
      }
    })
  },
  destroyed () {
    this.unsubscribeStore()
  },
  data () {
    return {
      passwordError: '',
      passwordErrorStatus: 0,
      companyFormValidationState: true,
      accountFormValidationState: true,
      accountSecurityFormValidationState: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unsubscribeStore: null as any,
      user: {
        pristine: true,
        address: this.$store.getters['user/getUser'].address,
        firstName: this.$store.getters['user/getUser'].firstName,
        lastName: this.$store.getters['user/getUser'].lastName,
        email: this.$store.getters['user/getUser'].email,
        phone: this.$store.getters['user/getUser'].phone,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      company: {
        pristine: true,
        name: this.$store.getters['company/getCompany'] ? this.$store.getters['company/getCompany'].name : '',
        address: this.$store.getters['company/getCompany'] ? this.$store.getters['company/getCompany'].address : ''
      },
      rules: {
        basicRequired: [(value: string): boolean|string => !!value || this.$t('validation.required').toString()],
        currentPassword: [(value: string): boolean|string => !!value || this.$t('validation.required').toString()],
        newPassword: [
          (value: string): boolean|string => !!value || this.$t('validation.required').toString(),
          (value: string): boolean|string => (value || '').length >= 8 || this.$t('validation.min8').toString()
        ],
        newPasswordConfirm: [
          (value: string): boolean|string => !!value || this.$t('validation.required').toString(),
          (value: string): boolean|string => (value || '').length >= 8 || this.$t('validation.min8').toString()
        ]
      }
    }
  }
})
