import get from 'lodash.get'
import {
  TAssignedPin,
  TComponentInProjectWithContextResponse,
  TTimeseriesShort
} from '@/services/aedifion/resources/project/responseTypes'
import { faultSystems } from '../constants'

export const computeAllFaults = (operationalStateTimeseries: TTimeseriesShort): number|null => {
  let faults: number|null = null
  Object.keys(operationalStateTimeseries).forEach((dataPointID: string) => {
    const faultsValue: number|null = get(operationalStateTimeseries, [dataPointID, 0, 1], null) as number|null
    if (!!faultsValue && faults === null) {
      faults = 0
    }

    if (!!faultsValue && faultsValue >= 300 && faultsValue < 400) {
      faults = (faults as number) + 1
    }
  })

  return faults
}

export const computeNonOperatingSystems = (operationalStateTimeseries: TTimeseriesShort): number|null => {
  let faults: number|null = null
  Object.keys(operationalStateTimeseries).forEach((dataPointID: string) => {
    const faultsValue: number|null = get(operationalStateTimeseries, [dataPointID, 0, 1], null) as number|null
    if (!!faultsValue && faults === null) {
      faults = 0
    }

    if (!!faultsValue && faultsValue !== 102) {
      faults = (faults as number) + 1
    }
  })

  return faults
}

/**
 * Filter through the digital twin component and search for PINs that are mapped and match the faultSystems signature
 * Get the DataPointID from the PINs
 * Those DataPointIDs are referring necessarry timeseries for the faults calculation
 */
export const getAvailableFaultSources = (digitalTwin: TComponentInProjectWithContextResponse): string[] => {
  const faultPins: TAssignedPin[] = digitalTwin.pins.filter(
    (pin: TAssignedPin) => pin && pin.alphanumeric_id && faultSystems.includes(pin.alphanumeric_id)
  )
  return faultPins.map(
    (pin: TAssignedPin) => pin.dataPointID
  ) as string[]
}

export const getSystemsWithDatapoints = (digitalTwin: TComponentInProjectWithContextResponse): { [id: string]: string|null } => {
  const systemsWithDatapoints: { [id: string]: string|null } = {}
  faultSystems.forEach((faultSystem: string) => {
    systemsWithDatapoints[faultSystem] = null
  })

  digitalTwin.pins.forEach(
    (pin: TAssignedPin) => {
      if (pin && pin.alphanumeric_id && faultSystems.includes(pin.alphanumeric_id) && pin.dataPointID) {
        systemsWithDatapoints[pin.alphanumeric_id] = pin.dataPointID
      }
    }
  )

  return systemsWithDatapoints
}

export const isSystemRunning = (fault: number|null): boolean => {
  if (fault === null) return false
  if ([200].includes(fault)) return false

  if (fault >= 300 && fault < 400) {
    return false
  } else {
    return true
  }
}

/**
 * Returns the color that is representing the status code by definition.
 * @param statusCode The status code to be translated to a color.
 * @returns Color that the status code represents.
 */
export function getFaultColorByStatusCode (statusCode: number|null): string {
  if (statusCode === null) return 'grey'
  else if ([100, 101, 102].includes(statusCode)) return 'green'
  else if ([300].includes(statusCode)) return 'red'
  else if ([301].includes(statusCode)) return 'yellow'
  else if ([103].includes(statusCode)) return 'blue'
  else if ([200].includes(statusCode)) return 'green--border'
  else if ([0].includes(statusCode)) return 'grey'
  else return 'grey'
}

export function getFaultTranslationStringByStatusCode (statusCode: number|null): string {
  if (statusCode === null) return 'faults.systems.messages.no_data'
  else if ([100, 101, 102].includes(statusCode)) return 'faults.systems.messages.ready_for_operation'
  else if ([300].includes(statusCode)) return 'faults.systems.messages.equipment_error_action_required'
  else if ([301].includes(statusCode)) return 'faults.systems.messages.equipment_error_less_important'
  else if ([103].includes(statusCode)) return 'faults.systems.messages.non_critical_notification'
  else if ([200].includes(statusCode)) return 'faults.systems.messages.equipment_switched_off'
  else if ([0].includes(statusCode)) return 'faults.systems.messages.no_data'
  else return 'faults.systems.messages.no_data'
}
