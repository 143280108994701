




















































































import { TEnergyGenerationTable } from '@/store/media_tables/types'
import moment from 'moment'
import Vue from 'vue'
import { formatValue } from '@/filters/formatting'

export default Vue.extend({
  name: 'HeatPump',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['media_tables/isLoading']
    },
    energyGenerationData (): TEnergyGenerationTable|null {
      return this.$store.getters['media_tables/getEnergyGeneration']
    },
    heatPump (): TEnergyGenerationTable['heat_pump']|null {
      if (this.energyGenerationData === null) return null
      return this.energyGenerationData.heat_pump
    },
    heatGenerationOfCurrentYear (): string|null {
      if (this.heatPump === null) return null
      return this.heatPump.heat_generation.currentYear !== null ? formatValue(this.heatPump.heat_generation.currentYear, 0) : null
    },
    heatGenerationOfLastYear (): string|null {
      if (this.heatPump === null) return null
      return this.heatPump.heat_generation.lastYear !== null ? formatValue(this.heatPump.heat_generation.lastYear, 0) : null
    },
    electricityUsageOfCurrentYear (): string|null {
      if (this.heatPump === null) return null
      return this.heatPump.electricity_usage.currentYear !== null ? formatValue(this.heatPump.electricity_usage.currentYear, 0) : null
    },
    electricityUsageOfLastYear (): string|null {
      if (this.heatPump === null) return null
      return this.heatPump.electricity_usage.lastYear !== null ? formatValue(this.heatPump.electricity_usage.lastYear, 0) : null
    },
    currentYear (): number {
      return moment().year()
    },
    lastYear (): number {
      return moment().subtract(1, 'year').year()
    }
  }
})
