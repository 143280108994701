import {
  MutationTree
} from 'vuex'
import { TEnergyConsumptionState } from './types'

export default {
  SET_CHART_AND_BENCHMARK_DATA: (state: TEnergyConsumptionState, payload: {
    heat: number|null;
    heatProjection: number|null;
    electricity: number|null;
    electricityProjection: number|null;
    electricityPerSquaredMeters: number|null;
    heatPerSquaredMeters: number|null;
    electricityColor: string|null;
    heatColor: string|null;
  }) => {
    state.benchmark.electricity = payload.electricityPerSquaredMeters
    state.chart.electricity = payload.electricity
    state.chart.heat = payload.heat
    state.benchmark.heat = payload.heatPerSquaredMeters
    state.benchmark.heatProjection = payload.heatProjection
    state.benchmark.electricityProjection = payload.electricityProjection
    state.benchmark.electricityColor = payload.electricityColor
    state.benchmark.heatColor = payload.heatColor
  },
  SET_LOADING_DATA: (state: TEnergyConsumptionState, loading: boolean) => {
    state.loading = loading
  },
  SET_DATA: (state: TEnergyConsumptionState, payload: {
    heatConsumption: number|null;
    electricityConsumption: number|null;
    hasPreviousData: boolean;
    hasNextData: boolean;
  }) => {
    state.chart.heat = payload.heatConsumption
    state.chart.electricity = payload.electricityConsumption
    state.hasPrevious = payload.hasPreviousData
    state.hasNext = payload.hasNextData
  },
  SET_YEAR_SELECTED: (state: TEnergyConsumptionState, isYearSelected) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TEnergyConsumptionState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TEnergyConsumptionState, month: number) => {
    state.month = month
  }
} as MutationTree<TEnergyConsumptionState>
