import { TEventType } from '@/store/project/types'
import { textForLocale } from './locale'

export function getTranslatedEventName (name: TEventType['name']): string|null {
  if (typeof name === 'string') {
    return name
  } else {
    return textForLocale(name.de, name.en)
  }
}
