































































































































import Vue from 'vue'

import Card from '@/components/ui/Card.vue'
import moment from 'moment'
import BarChart from '@/components/ui/Charts/BarChart.vue'
import PieChart from '@/components/ui/Charts/PieChart.vue'
import { AnalysisResult, KPIResult } from '@/services/aedifion/resources/analytics/responseTypes'
import { getFaultColorByStatusCode, getFaultTranslationStringByStatusCode } from '@/utils/helpers/faults'

type ElevatorsAnalysisResult = AnalysisResult

type WeekdayValues = Array<{
  name: string;
  y: any;
}>

export default Vue.extend({
  name: 'Elevators',
  components: {
    BarChart,
    Card,
    PieChart
  },
  mounted () {
    this.$store.dispatch('elevators/fetchInformation')
  },
  methods: {
    switchToMonth (): void {
      if (!this.monthSelected) {
        this.$store.dispatch('elevators/switchChartToMonth')
      }
    },
    switchToYear (): void {
      if (!this.yearSelected) {
        this.$store.dispatch('elevators/switchChartToYear')
      }
    },
    previous (): void {
      if (this.yearSelected) {
        this.$store.dispatch('elevators/selectPreviousYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('elevators/selectPreviousMonth')
      }
    },
    next (): void {
      if (this.yearSelected) {
        this.$store.dispatch('elevators/selectNextYear')
      } else if (this.monthSelected) {
        this.$store.dispatch('elevators/selectNextMonth')
      }
    }
  },
  computed: {
    elevatorColor (): string {
      return getFaultColorByStatusCode(this.$store.getters['project/getElevatorFault'])
    },
    elevatorState (): string {
      return this.$t(getFaultTranslationStringByStatusCode(this.$store.getters['project/getElevatorFault'])) as string
    },
    disabled (): boolean {
      return !this.isLoading && (!this.$store.getters['elevators/hasElevators'] || !this.$store.getters['elevators/hasInstances'])
    },
    isLoading (): boolean {
      return this.$store.getters['elevators/isLoading']
    },
    isLoadingResult (): boolean {
      return this.$store.getters['elevators/isLoadingResult']
    },
    year (): number {
      return this.$store.getters['elevators/getYear']
    },
    yearSelected (): boolean {
      return this.$store.getters['elevators/isYearSelected']
    },
    hasNext (): boolean {
      return this.$store.getters['elevators/hasNext']
    },
    isNextCurrentMonth (): boolean {
      return moment().set({ month: this.month, year: this.year }).add(1, 'month').month() === moment().month() ||
        moment().set({ month: this.month, year: this.year }).add(1, 'month').startOf('month').isBefore(moment().startOf('month'))
    },
    isNextCurrentYear (): boolean {
      return moment().set({ year: this.year }).add(1, 'year').year() === moment().year() ||
        moment().set({ year: this.year }).add(1, 'year').startOf('year').isBefore(moment().startOf('year'))
    },
    hasPrevious (): boolean {
      return this.$store.getters['elevators/hasPrevious']
    },
    month (): number {
      return this.$store.getters['elevators/getMonth']
    },
    monthSelected (): boolean {
      return this.$store.getters['elevators/isMonthSelected']
    },
    getMonthBefore (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).subtract(1, 'month').format('MMMM')
    },
    getMonthAfter (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).add(1, 'month').format('MMMM')
    },
    getMonthName (): string {
      return moment().locale(this.$i18n.locale.slice(0, 2)).month(this.month).format('MMMM')
    },
    result (): ElevatorsAnalysisResult {
      return this.$store.getters['elevators/getAnalysisResult']
    },
    elevatorsAvailability (): number|null {
      if (!this.result || !this.result.kpi || this.result.kpi.length <= 0) return null
      const resultForAvailability: KPIResult|null = this.result.kpi.find((kpi: KPIResult) => {
        return kpi.name === 'elevators availability'
      }) ?? null

      if (resultForAvailability === null) {
        return null
      } else {
        return resultForAvailability.value as number
      }
    },
    elevatorsOperatingTimes (): WeekdayValues|null {
      if (!this.result || !this.result.plots || this.result.plots.length <= 0) {
        return null
      } else {
        const plot = this.result.plots.find((plot: any) => plot.identifier === 'elevator_operating_time') ?? null
        if (plot === null || !plot.data || plot.data.length <= 0) {
          return null
        } else {
          return plot.categories.map((day: string, index: number) => {
            return {
              name: this.$t(`week.${day.toLowerCase()}`) as string,
              y: parseFloat((plot.data[0].values[index] as number).toFixed(2)),
              unit: plot.unit === 'h' ? this.$t('units.hour.abbr') : plot.unit
            }
          })
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pieSeries (): any {
      if (this.result === null || this.elevatorsAvailability === null) return []
      const unavailability: number = 100 - this.elevatorsAvailability
      return [
        {
          name: this.$t('utils.availability_in_hours'),
          data: [
            {
              name: `${this.$t('utils.available')}: ${this.elevatorsAvailability.toFixed(0)}%`,
              y: this.elevatorsAvailability
            },
            {
              name: `${this.$t('utils.not_available')}: ${unavailability.toFixed(0)}%`,
              y: unavailability
            }
          ]
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    barSeries (): any {
      if (this.result === null || !this.elevatorsOperatingTimes) return []
      return [{
        data: this.elevatorsOperatingTimes
      }]
    }
  }
})
