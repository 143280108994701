import axiosInstance from '../../axiosInstance'
import { IRepository } from '@/utils/types/repository'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TDatapointTimeseriesRequest } from './requestTypes'

const resource = 'datapoint'

export default {
  getTimeseries: (request: {
    token: string|null;
    id: number;
    params: TDatapointTimeseriesRequest;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse<[string, number][]>> => {
    return axiosInstance.get(`/${resource}/timeseries`, {
      ...request.config,
      params: request.params,
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    })
  }
} as IRepository
