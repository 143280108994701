


























import Vue from 'vue'
import EnergyConsumptionInformation from './InformationTables/EnergyConsumption/EnergyConsumptionInformation.vue'
import HeaderIcon from './EnergyConsumption/HeaderIcon.vue'
import MediaContent from './EnergyConsumption/MediaContent.vue'

export default Vue.extend({
  name: 'EnergyConsumption',
  components: {
    EnergyConsumptionInformation,
    HeaderIcon,
    MediaContent
  },
  computed: {
    hasBenchmarkData (): boolean {
      return this.hasHeatBenchmarkData || this.hasElectricityBenchmarkData
    },
    hasTimeseriesData (): boolean {
      return this.heat !== null || this.electricity !== null
    },
    hasElectricityBenchmarkData (): boolean {
      return this.electricityBenchmark !== null || this.electricityProjectionValue !== null
    },
    hasHeatBenchmarkData (): boolean {
      return this.heatBenchmark !== null || this.heatProjectionValue !== null
    },
    heatBenchmark (): number|null {
      return this.$store.getters['energy_consumption/getHeatConsumptionBenchmark']
    },
    heatProjectionValue (): number|null {
      return this.$store.state.energy_consumption.benchmark.heatProjection
    },
    electricityBenchmark (): number|null {
      return this.$store.getters['energy_consumption/getElectricityConsumptionBenchmark']
    },
    electricityProjectionValue (): number|null {
      return this.$store.state.energy_consumption.benchmark.electricityProjection
    },
    heat (): number|null {
      return this.$store.getters['energy_consumption/getHeatConsumption']
    },
    electricity (): number|null {
      return this.$store.getters['energy_consumption/getElectricityConsumption']
    }
  }
})
