

























































































import Vue from 'vue'
import { SEVERITY } from '@/services/alerta/resources/common/responseTypes'
import { TAlertTableRow, TAlarmsFilter } from '@/store/alerts/types'
import { AlertsFilterHelper } from '@/utils/helpers/alertsfilter'

import AlarmsFilter from './AlarmsFilter.vue'
import AlarmsFilterChips from './AlarmsFilterChips.vue'

export default Vue.extend({
  name: 'Alarms',

  beforeCreate () {
    const queryFilter: Array<TAlarmsFilter> = AlertsFilterHelper.convertUrlQueryToFilters(this.$router.currentRoute.query)
    if (queryFilter.length > 0) {
      this.$store.commit('alerts/SET_FILTERS', queryFilter)
    } else {
      const storedFilters: Array<TAlarmsFilter> = this.$store.getters['alerts/getFilters']
      if (storedFilters.length > 0) {
        this.$router.replace({ query: AlertsFilterHelper.convertFiltersToUrlQuery(storedFilters) })
      }
    }
  },

  components: {
    AlarmsFilter,
    AlarmsFilterChips
  },

  computed: {
    alerts (): Array<TAlertTableRow> {
      const projectHandle: string|null = this.$store.getters['project/getProjectHandle']
      if (projectHandle) {
        return this.$store.getters['alerts/getFilteredAlertTableData'](
          projectHandle,
          [SEVERITY.CRITICAL, SEVERITY.WARNING, SEVERITY.INFORMATIONAL]
        )
      } else {
        return []
      }
    }
  },

  data () {
    return {
      search: '',
      headers: [
        {
          text: this.$t('alarms.headers.date'),
          filterable: false,
          value: 'date'
        },
        {
          text: this.$t('alarms.headers.name'),
          filterable: true,
          value: 'name'
        },
        {
          text: this.$t('alarms.headers.event'),
          filterable: false,
          value: 'event'
        },
        {
          text: this.$t('alarms.headers.priority'),
          filterable: false,
          value: 'priority'
        },
        {
          text: this.$t('alarms.headers.status'),
          filterable: false,
          value: 'status'
        },
        {
          text: this.$t('alarms.headers.type'),
          filterable: false,
          value: 'type'
        },
        {
          text: this.$t('alarms.headers.value'),
          filterable: false,
          value: 'value'
        },
        {
          text: this.$t('alarms.headers.action'),
          filterable: false,
          value: 'action',
          width: '1%'
        }
      ]
    }
  }
})
