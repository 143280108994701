import { TCompany } from '@/services/aedifion/resources/company/responseTypes'
import { MutationTree } from 'vuex'
import { TCompanyState } from './types'

export default {
  SET_COMPANY_LOADING: (state: TCompanyState, loading: boolean) => {
    state.loading = loading
  },
  SET_COMPANY: (state: TCompanyState, company: TCompany) => {
    state.loading = false
    state.company = company
  },
  SET_COMPANY_AVATAR: (state: TCompanyState, avatarUrl: string) => {
    if (state.company) {
      state.company.avatar_url = avatarUrl
    }
  }
} as MutationTree<TCompanyState>
