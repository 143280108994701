













import Vue from 'vue'
import { TAlarmsFilter } from '@/store/alerts/types'
import { AlertsFilterHelper } from '@/utils/helpers/alertsfilter'

export default Vue.extend({
  name: 'AlarmsFilterChips',

  computed: {
    filters (): Array<TAlarmsFilter> {
      return this.$store.getters['alerts/getFilters']
    }
  },

  methods: {
    removeFilter (filter: TAlarmsFilter): void {
      this.$store.commit('alerts/REMOVE_FILTER', filter)
      this.$router.replace({ query: AlertsFilterHelper.convertFiltersToUrlQuery(this.filters) })
    }
  }
})
