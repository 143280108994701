



































































import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    links: {
      type: Array as PropType<{name: string; icon: string; link: string; active: boolean}[]>,
      required: true
    }
  },
  computed: {
    isProjectSelected (): boolean {
      return !this.$store.getters['project/getProjectID'] !== null
    },
    routeAllowsProjectModal (): boolean {
      return this.$route.meta.allowsProjectModal === true
    }
  }
})
