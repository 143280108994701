





































































































import Vue, { PropType } from 'vue'
import Card from '@/components/ui/Card.vue'

import { TAlertUpdate } from '@/services/alerta/resources/common/responseTypes'
import { TAlertTableRow, TNote } from '@/store/alerts/types'

import NoteCard from './NoteCard.vue'

export default Vue.extend({
  name: 'AlarmDetailsNotes',

  components: {
    Card,
    NoteCard
  },

  computed: {
    noteUpdates (): Array<TAlertUpdate> {
      return this.$store.getters['alerts/getAlertsNoteUpdates'](this.alert.id)
    }
  },

  data () {
    return {
      addNoteDialogOpen: false,
      newNoteText: ''
    }
  },

  methods: {
    cancelNewNote () {
      this.addNoteDialogOpen = false
    },

    async saveNewNote () {
      this.addNoteDialogOpen = false
      try {
        const note: TNote = {
          name: this.$store.getters['user/getFullName'],
          avatar_url: this.$store.getters['user/getAvatarUrl'],
          text: this.newNoteText
        }
        await this.$store.dispatch('alerts/addNote', { alertId: this.alert.id, noteText: JSON.stringify(note) })
        this.newNoteText = ''
      } catch (error) {
        const reason = error.response.data ? error.response.data.error : ''
        this.$notify({
          text: this.$t('alarms.details.control.action.error', { reason }) as string,
          type: 'error',
          group: 'requests',
          duration: 6000
        })
      }
    }
  },

  props: {
    alert: {
      required: true,
      type: Object as PropType<TAlertTableRow>
    }
  }
})
