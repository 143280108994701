import { TComponentInProjectWithContextResponse } from '@/services/aedifion/resources/project/responseTypes'
import {
  MutationTree
} from 'vuex'
import { TCharginStationsState } from './types'

export default {
  SET_LOADING_STATIONS: (state: TCharginStationsState, loading: boolean) => {
    state.loadingComponents = loading
  },
  SET_CHARGING_STATIONS: (state: TCharginStationsState, chargingStations: TComponentInProjectWithContextResponse[]) => {
    state.stations = chargingStations
  },
  SET_LOADING_OCCUPANCY: (state: TCharginStationsState, loading: boolean) => {
    state.loadingTimeseries = loading
  },
  SET_SYSTEMS_OPERATION_STATE: (state: TCharginStationsState, operatingState: boolean|null) => {
    state.allSystemsRunning = operatingState
  },
  SET_OCCUPANCY: (state: TCharginStationsState, occupancy: number|null) => {
    state.occupancy = occupancy
  },
  SET_LOADING_TABLE_DATA: (state: TCharginStationsState, loading: boolean) => {
    state.loadingTableData = loading
  },
  SET_TIME_USAGE: (state: TCharginStationsState, { thisYearsTimeUsage, lastYearsTimeUsage }: { thisYearsTimeUsage: number|null; lastYearsTimeUsage: number|null }) => {
    state.absoluteUsageThisYear = thisYearsTimeUsage
    state.absoluteUsageLastYear = lastYearsTimeUsage
  },
  SET_ELECTRICITY_USAGE: (state: TCharginStationsState, { thisYearsElectricityConsumption, lastYearsElectricityConsumption }: { thisYearsElectricityConsumption: number|null; lastYearsElectricityConsumption: number|null }) => {
    state.energyConsumptionThisYear = thisYearsElectricityConsumption
    state.energyConsumptionLastYear = lastYearsElectricityConsumption
  },
  SET_LOADING_CHART_DATA: (state: TCharginStationsState, loading: boolean) => {
    state.loadingChartData = loading
  },
  SET_CHART_DATA: (state: TCharginStationsState, {
    hasPreviousData,
    hasNextData,
    usageTime,
    noUsageTime,
    relativeUsage,
    hoursPerSelectedTime
  }: {
    hasPreviousData: boolean;
    hasNextData: boolean;
    usageTime: number|null;
    noUsageTime: number|null;
    relativeUsage: number|null;
    hoursPerSelectedTime: number;
  }) => {
    state.hasNext = hasNextData
    state.hasPrevious = hasPreviousData
    state.usageTime = usageTime
    state.noUsageTime = noUsageTime
    state.relativeUsage = relativeUsage
    state.hoursPerSelectedTime = hoursPerSelectedTime
  },
  SET_YEAR_SELECTED: (state: TCharginStationsState, isYearSelected: boolean) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TCharginStationsState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TCharginStationsState, month: number) => {
    state.month = month
  }
} as MutationTree<TCharginStationsState>
