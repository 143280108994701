
































import Vue from 'vue'
import { TAlertTableRow } from '@/store/alerts/types'

import AlarmDetailsControl from './AlarmDetailsControl.vue'
import AlarmDetailsHistory from './AlarmDetailsHistory.vue'
import AlarmDetailsNotes from './AlarmDetailsNotes.vue'
import AlarmDetailsProgression from './AlarmDetailsProgression.vue'

export default Vue.extend({
  name: 'AlarmDetails',

  components: {
    AlarmDetailsControl,
    AlarmDetailsHistory,
    AlarmDetailsNotes,
    AlarmDetailsProgression
  },

  computed: {
    alert (): TAlertTableRow {
      return this.$store.getters['alerts/getAlertRow'](this.$route.params.alarm)
    }
  }
})
