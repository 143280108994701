/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDBPDatabase, IDBPObjectStore, openDB } from 'idb'

const LABELS_DATABASE_NAME = 'labels'
const LABELS_LATEST_CHECKSUM = 'labels_latest_checksum'
let LABELS_DATABASE_VERSION: number|null = null
let LATEST_LABELS_CHECKSUM: string|null = null

type NodesWithLocale = any & { locale?: string }

/**
 * Checks if the current database version can be used for the specified checksum.
 * If the databse does not exist, version 1 is returned. If no checksum was stored before,
 * the version number get's increased. If the passed checksum is newer, updates the version
 * number.
 * @param checksum The checksum to validate the database-version against.
 * @returns Version number of the database to be used.
 */
async function getLabelsDatabaseVersion (checksum: string): Promise<number> {
  const checksumInStorage: string|null = localStorage.getItem(LABELS_LATEST_CHECKSUM)
  if (LATEST_LABELS_CHECKSUM === null && checksumInStorage !== null) {
    LATEST_LABELS_CHECKSUM = checksumInStorage
  } else if (LATEST_LABELS_CHECKSUM === null && checksumInStorage === null) {
    LATEST_LABELS_CHECKSUM = checksum
    localStorage.setItem(LABELS_LATEST_CHECKSUM, checksum)
  }

  const upgradeRequired: boolean = checksum !== LATEST_LABELS_CHECKSUM

  if (upgradeRequired || LABELS_DATABASE_VERSION === null) {
    try {
      const database: IDBPDatabase = await openDB(LABELS_DATABASE_NAME, undefined, {
        upgrade (db) {
          db.createObjectStore(checksum, { keyPath: 'locale' })
        }
      })
      LABELS_DATABASE_VERSION = database.version
    } catch (err) {
      return Promise.reject((err as DOMException).message)
    }
  }

  if (upgradeRequired) {
    localStorage.setItem(LABELS_LATEST_CHECKSUM, checksum)
    LATEST_LABELS_CHECKSUM = checksum
    if (LABELS_DATABASE_VERSION === null) LABELS_DATABASE_VERSION = 1
    else LABELS_DATABASE_VERSION = LABELS_DATABASE_VERSION + 1
  }

  return LABELS_DATABASE_VERSION
}

/**
 * Requests the label definitions from the database.
 * @param checksum The checksum of the labels defintision to match the latest results.
 * @returns Label definitions.
 */
export async function readLabels (checksum: string, locale: string): Promise<any|null> {
  const version = await getLabelsDatabaseVersion(checksum)
  let database: IDBPDatabase
  try {
    database = await openDB(LABELS_DATABASE_NAME, version, {
      upgrade (db) {
        db.createObjectStore(checksum, { keyPath: 'locale' })
      }
    })
  } catch (err) {
    const error: DOMException = err as DOMException
    return Promise.reject(error)
  }

  const labelDefinitionsStore: IDBPObjectStore = database.transaction([checksum], 'readonly').objectStore(checksum)

  try {
    const labelDefinitionsWithLocale: NodesWithLocale = await labelDefinitionsStore.get(locale)
    if (labelDefinitionsWithLocale === undefined) return Promise.resolve(null)

    database.close()

    delete labelDefinitionsWithLocale.locale
    return Promise.resolve(labelDefinitionsWithLocale)
  } catch (err) {
    database.close()
    const error: DOMException = err as DOMException
    return Promise.reject(error)
  }
}

/**
 * Stores new label nodes for a specific checksum in the database.
 * @param checksum The checksum of the labels defintision to match the latest results.
 * @param labelDefinitions The label nodes to be stored.
 * @returns true if the store action succeed. Else throws an error.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function storeLabels (checksum: string, labelDefinitions: any, locale: string): Promise<boolean> {
  const version = await getLabelsDatabaseVersion(checksum)
  let database: IDBPDatabase
  try {
    database = await openDB(LABELS_DATABASE_NAME, version, {
      upgrade (db) {
        db.createObjectStore(checksum, { keyPath: 'locale' })
      }
    })
  } catch (err) {
    return Promise.reject(new Error('Could not open database for labels data.'))
  }

  const labelDefinitionsStore = database.transaction(checksum, 'readwrite').store
  const labelDefinitionsWithLocale = { ...labelDefinitions, locale }

  try {
    await labelDefinitionsStore.add(labelDefinitionsWithLocale)
    database.close()
    return Promise.resolve(true)
  } catch (err) {
    database.close()
    const error: DOMException = err as DOMException
    return Promise.reject(error)
  }
}

/**
 * Clears the labels store in the databse.
 * @param checksum The checksum of the labels defintision to match the latest results.
 * @returns true, if the clear action succeeded. Else throws an error.
 */
export async function clearLabelsStore (checksum: string): Promise<boolean> {
  const version = await getLabelsDatabaseVersion(checksum)
  let database: IDBPDatabase
  try {
    database = await openDB(LABELS_DATABASE_NAME, version, {
      upgrade (db) {
        db.createObjectStore(checksum, { keyPath: 'locale' })
      }
    })
  } catch (err) {
    return Promise.reject(new Error('Could not open database for labels data.'))
  }

  const labelDefinitionsStore = database.transaction(checksum, 'readwrite').store

  try {
    await labelDefinitionsStore.clear()
    database.close()
    return Promise.resolve(true)
  } catch (err) {
    database.close()
    const error: DOMException = err as DOMException
    return Promise.reject(error)
  }
}
