




import Vue, { PropType } from 'vue'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import nodata from 'highcharts/modules/no-data-to-display'
import heatmap from 'highcharts/modules/heatmap'

nodata(Highcharts)
heatmap(Highcharts)

export default Vue.extend({
  name: 'TemporalRasterPlot',
  components: {
    highcharts: Chart
  },
  beforeCreate () {
    /**
     * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
     * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
     */
    Highcharts.setOptions({
      lang: {
        loading: this.$t('data.loading') as string,
        noData: this.$t('data.not_available') as string
      }
    })
  },
  props: {
    title: {
      required: false,
      type: String as PropType<string>
    },
    series: {
      required: true,
      type: Array as PropType<Array<[number, number, number]>>
    },
    seriesTitle: {
      required: true,
      type: String as PropType<string>
    },
    unit: {
      required: false,
      type: String as PropType<string>
    },
    loading: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  watch: {
    loading: function (newVal: boolean) {
      if (newVal === true) {
        (this.$refs.chart as any).chart.showLoading()
      } else {
        (this.$refs.chart as any).chart.hideLoading()
      }
    }
  },
  computed: {
    min (): number {
      const flattenedSeries: number[] = this.series.map((seriesObservation: [number, number, number]) => {
        return seriesObservation[2]
      })
      return Math.min(...flattenedSeries)
    },
    max (): number {
      const flattenedSeries: number[] = this.series.map((seriesObservation: [number, number, number]) => {
        return seriesObservation[2]
      })
      return Math.max(...flattenedSeries)
    },
    chartOptions (): any {
      return {
        noData: {
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        loading: {
          style: {
            fontFamily: 'myriad-pro',
            fontWeight: 'bold',
            color: '#004B8E',
            fontSize: '16px',
            padding: '.5rem'
          },
          useHTML: true
        },
        chart: {
          type: 'heatmap',
          style: {
            fontFamily: 'myriad-pro',
            color: '#004B8E'
          }
        },
        legend: {
          itemStyle: {
            color: '#004B8E',
            fontWeight: 'semibold'
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.title ? this.title : null,
          style: {
            color: '#004B8E'
          }
        },
        boost: {
          useGPUTranslations: true
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: this.$t('charts.temporal_raster_plot.xaxis.title.text'),
            style: {
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              fontFamily: 'myriad-pro',
              color: '#004B8E'
            }
          }
        },
        yAxis: {
          title: {
            text: this.$t('charts.temporal_raster_plot.yaxis.title.text'),
            style: {
              color: '#004B8E'
            }
          },
          labels: {
            style: {
              color: '#004B8E'
            }
          },
          minPadding: 0,
          maxPadding: 0,
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0, 6, 12, 18, 24],
          tickWidth: 1,
          min: 0,
          max: 23,
          reversed: true
        },
        colorAxis: {
          stops: [
            [0, '#3060cf'],
            [0.7, '#fffbbc'],
            [1, '#c4463a']
          ],
          min: this.min,
          max: this.max,
          startOnTick: false,
          endOnTick: false,
          labels: {
            format: '{value}'
          }
        },
        series: [{
          name: this.seriesTitle,
          boostThreshold: 100,
          borderWidth: 0,
          nullColor: '#EFEFEF',
          colsize: 24 * 36e5,
          data: this.series,
          tooltip: {
            pointFormat: `{point.x:%e %b, %Y} {point.y}:00: <b>{point.value}${this.unit ? ' ' + this.unit : ''}</b>`
          }
        }]
      }
    }
  }
})
