import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../translations/en.json'
import de from '../translations/de.json'

Vue.use(VueI18n)

const messages = {
  en,
  de
}

function getBrowserLocales (): string[] {
  let browserLocales: readonly string[]
  if (navigator.languages === undefined && navigator.language === undefined) {
    browserLocales = ['en-US']
  } else if (navigator.languages === undefined) {
    browserLocales = [navigator.language]
  } else {
    browserLocales = navigator.languages
  }

  return browserLocales.map(locale => {
    return locale.trim()
  })
}

export default new VueI18n({
  locale: getBrowserLocales()[0].slice(0, 2),
  fallbackLocale: 'en-US',
  messages
})
