






















































import Vue from 'vue'

import Status from './Status.vue'
import Faults from './Faults.vue'
import Events from './Events.vue'
import Media from './Media.vue'
import Elevators from './Elevators.vue'
import ChargingStations from './ChargingStations.vue'
import LightingSystems from './LightingSystems.vue'

export default Vue.extend({
  name: 'Project',
  components: {
    Status,
    Faults,
    Events,
    Media,
    Elevators,
    ChargingStations,
    LightingSystems
  },
  beforeMount () {
    const latLng = this.$store.getters['project/getProjectsLatLng']
    this.$store.dispatch('project/fetchTimezone', latLng)
    this.$store.dispatch('project/fetchWeather', latLng)
    this.$store.dispatch('project/fetchOccupantsTimeseries')
    this.$store.dispatch('project/fetchCarOccupantsTimeseries')
    this.$store.dispatch('project/fetchFaults')
  }
})
