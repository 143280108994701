







































































































import Vue from 'vue'

import Card from '@/components/ui/Card.vue'
import { TLightingSystemConsumptionData } from '@/store/lighting_systems/types'
import moment from 'moment'

export default Vue.extend({
  name: 'LightingSystems',
  components: {
    Card
  },
  async mounted () {
    await this.$store.dispatch('lighting_systems/fetchLightingSystems')
    await this.$store.dispatch('lighting_systems/fetchEnergyData')
    this.$store.dispatch('lighting_systems/fetchStatus')
  },
  computed: {
    isLoadingComponents (): boolean {
      return this.$store.getters['lighting_systems/getSystemsLoadingState']
    },
    isLoadingData (): boolean {
      return this.$store.getters['lighting_systems/getSystemsConsumptionLoadingState']
    },
    consumptionData (): TLightingSystemConsumptionData[] {
      return this.$store.getters['lighting_systems/getSystemsWithConsumption']
    },
    year (): number {
      return moment().year()
    },
    yearBefore (): number {
      return moment().year() - 1
    }
  }
})
