import { TUser } from '@/services/aedifion/resources/user/responseTypes'
import { GetterTree } from 'vuex'
import { TRootState } from '../types'
import { TUserState } from './types'

export default {
  getUser: (state: TUserState): TUser => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, ...user } = state
    return user
  },
  isUserLoading: (state: TUserState): boolean => state.loading,
  isUserLoaded: (state: TUserState): boolean => !!state.id && !!state.company_id && !!state.firstName && !!state.lastName,
  getFullName: (state: TUserState): string => `${state.firstName} ${state.lastName}`,
  getAvatarUrl: (state: TUserState): string|null => state.avatar_url || null,
  passwordLock: (state: TUserState): string|null => state.password_locked_until ? state.password_locked_until : null
} as GetterTree<TUserState, TRootState>
