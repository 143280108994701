import {
  MutationTree
} from 'vuex'
import { TCO2EmissionState } from './types'

export default {
  SET_CHART_AND_BENCHMARK_DATA: (state: TCO2EmissionState, payload: {
    co2EmissionsPerSquaredMeters: number|null;
    co2EmissionsColor: string|null;
    co2Projection: number|null;
    heat: number|null;
    electricity: number|null;
    photovoltaics: number|null;
    chp: number|null;
    solar: number|null;
    heat_pump: number|null;
  }) => {
    state.benchmark.co2Emission = payload.co2EmissionsPerSquaredMeters
    state.benchmark.co2EmissionColor = payload.co2EmissionsColor
    state.benchmark.co2Projection = payload.co2Projection
    state.chart.heat = payload.heat
    state.chart.electricity = payload.electricity
    state.chart.avoidance_photovoltaics = payload.photovoltaics
    state.chart.avoidance_chp = payload.chp
    state.chart.avoidance_heat_pump = payload.heat_pump
    state.chart.avoidance_solar = payload.solar
  },
  SET_LOADING_DATA: (state: TCO2EmissionState, loading: boolean) => {
    state.loading = loading
  },
  SET_DATA: (state: TCO2EmissionState, payload: {
    heat: number|null;
    electricity: number|null;
    photovoltaics: number|null;
    chp: number|null;
    solar: number|null;
    heat_pump: number|null;
    hasPreviousData: boolean;
    hasNextData: boolean;
  }) => {
    state.chart.heat = payload.heat
    state.chart.electricity = payload.electricity
    state.chart.avoidance_photovoltaics = payload.photovoltaics
    state.chart.avoidance_chp = payload.chp
    state.chart.avoidance_solar = payload.solar
    state.chart.avoidance_heat_pump = payload.heat_pump
    state.hasPrevious = payload.hasPreviousData
    state.hasNext = payload.hasNextData
  },
  SET_YEAR_SELECTED: (state: TCO2EmissionState, isYearSelected: boolean) => {
    state.isYearSelected = isYearSelected
  },
  SWITCH_YEAR: (state: TCO2EmissionState, year: number) => {
    state.year = year
  },
  SWITCH_MONTH: (state: TCO2EmissionState, month: number) => {
    state.month = month
  }
} as MutationTree<TCO2EmissionState>
