









































import Vue from 'vue'
import moment from 'moment'
import get from 'lodash.get'

import Card from '@/components/ui/Card.vue'
import TemporalRasterPlot from '@/components/ui/Charts/TemporalRasterPlot.vue'

import { TAssignedPin, TComponentInProjectWithContextResponse, TObservationShort } from '@/services/aedifion/resources/project/responseTypes'
import { AxiosResponse } from 'axios'
import { IRepository } from '@/utils/types/repository'
import aedifionApiRepository from '@/services/aedifion'
import { getPinsDatapointByAlphanumericId } from '@/utils/helpers/pins'
const Datapoint: IRepository = aedifionApiRepository.get('datapoints')

export default Vue.extend({
  name: 'AggregatedHeatAndElectricity',
  components: {
    Card,
    TemporalRasterPlot
  },
  computed: {
    electricitySeries (): Array<[number, number, number|null]> {
      return this.electricityTimeseries.map((pair: TObservationShort) => {
        const rounded: number|null = pair[1] !== null ? Number(pair[1].toFixed(0)) : null
        return [
          moment(pair[0]).valueOf(),
          moment(pair[0]).hour(),
          rounded
        ]
      })
    },
    heatSeries (): Array<[number, number, number|null]> {
      return this.heatTimeseries.map((pair: TObservationShort) => {
        const rounded: number|null = pair[1] !== null ? Number(pair[1].toFixed(0)) : null
        return [
          moment(pair[0]).valueOf(),
          moment(pair[0]).hour(),
          rounded
        ]
      })
    }
  },
  data () {
    return {
      electricityTimeseries: [] as TObservationShort[],
      heatTimeseries: [] as TObservationShort[],
      loadingHeat: false,
      loadingElectricity: false,
      heatError: null as string|null,
      electricityError: null as string|null
    }
  },
  async mounted () {
    const digitalTwin: TComponentInProjectWithContextResponse = this.$store.getters['project/getDigitalTwin']
    const pins: TAssignedPin[] = get(digitalTwin, 'pins', [])
    const electricityDatapoint: string|null = getPinsDatapointByAlphanumericId(pins, 'B+ELS+EN_EL_CONSUM_SUM_15m')
    const heatDatapoint: string|null = getPinsDatapointByAlphanumericId(pins, 'B+EN_H_CONSUM_SUM_15m')

    const token: string = this.$store.getters['auth/oidcAccessToken']
    const project_id: string = this.$store.getters['project/getProjectID']

    if (electricityDatapoint !== null) {
      this.loadingElectricity = true
      try {
        const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Datapoint.getTimeseries({
          token,
          params: {
            dataPointID: electricityDatapoint,
            project_id,
            short: true,
            start: moment().subtract(1, 'year').startOf('date').toISOString(true),
            end: moment().toISOString(true),
            samplerate: '1h',
            interpolation: 'none',
            closed_interval: false
          }
        })

        this.electricityTimeseries = timeseriesResponse.data
      } catch (error) {
        this.electricityError = get(error, 'response.data.message', this.$t('energy_management.requests.temporal_raster_plot.failed_electricity_request'))
      } finally {
        this.loadingElectricity = false
      }
    } else {
      this.electricityError = this.$t('energy_management.requests.temporal_raster_plot.no_electricity_datapoint') as string
    }

    if (heatDatapoint !== null) {
      this.loadingHeat = true
      try {
        const timeseriesResponse: AxiosResponse<TObservationShort[]> = await Datapoint.getTimeseries({
          token,
          params: {
            dataPointID: heatDatapoint,
            project_id,
            short: true,
            start: moment().subtract(1, 'year').startOf('date').toISOString(true),
            end: moment().toISOString(true),
            samplerate: '1h',
            interpolation: 'none',
            closed_interval: false
          }
        })

        this.heatTimeseries = timeseriesResponse.data
      } catch (error) {
        this.heatError = get(error, 'response.data.message', this.$t('energy_management.requests.temporal_raster_plot.failed_electricity_request'))
      } finally {
        this.loadingHeat = false
      }
    } else {
      this.heatError = this.$t('energy_management.requests.temporal_raster_plot.no_electricity_datapoint') as string
    }
  }
})
